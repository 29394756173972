.loginPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.loginContainer {
  font-family: 'DM Sans', sans-serif;
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 300px;
}

.loginTitle {
  margin-bottom: 20px;
  color: #333;
}

.loginForm {
  display: flex;
  flex-direction: column;
}

.loginInput {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.loginButton {
  width: 100%;
  padding: 10px;
  background-color: #848ee0;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.loginButton:hover {
  background-color: #6c7bbf;
}


.previewButton {
  width: 100%;
  padding: 10px;
  background-color: #ccc;  /* Grey background */
  color: white;             /* Darker text color for contrast */
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}

.previewButton:hover {
  background-color: #b3b3b3 !important;
}


.loginError {
  color: red;
  margin-top: 10px;
}

.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.logoContainer img {
  height: 50px;
  margin-bottom: 10px;
  filter: invert(1);
}

.appName {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
}


