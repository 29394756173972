/* Top Bar */
.topBar {
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
  background-color: #ffffff;
  color: #4758EB;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  gap: 10px;
  flex-wrap: wrap;
}

.topBarLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.headerTextContainer {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 40px;
  flex-grow: 0;
}

.feedViewerVersion {
  font-size: 14px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
  color: #4758EB;
}

.headerSubtitle {
  font-size: 22px;
  font-family: 'DM Serif Text', serif;
  color: #292B3D;
}

.headerImage {
  height: 100px;
  width: auto;
  padding-top: 20px;
  object-fit: contain;
  margin-left: auto;
}

.topBarActions {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Credits Display */
.creditsContainer {
  display: flex; 
  align-items: center;
  background-color: #F8F9FF;
  padding: 6px 12px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);
}

.creditsText {
  display: flex; 
  align-items: center; 
  font-family: 'Lexend Deca', sans-serif;
  color: #292B3D;
  font-size: 14px;
  font-weight: 500;
}

.noCreditsText {
  font-family: 'Lexend Deca', sans-serif;
  color: #999;
  font-size: 14px;
}

/* Buttons */
.backButton {
  background-color: #d0d0d0;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.backButton:hover {
  background-color: #7c7c7c;
}

.logoutButton {
  background-color: #E0858C;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.logoutButton:hover {
  background-color: #EB4755 !important;
}

.clientDropdown {
  min-width: 200px;
  font-size: 12px;
}

.promptBuilderButton, .pdpGenerationButton {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.promptBuilderButton:hover, .pdpGenerationButton:hover {
  background-color: #848EE0 !important;
}

/* Rest of CSS remains the same */
.mainContent {
  padding: 20px;
  font-family: 'Lexend Deca', sans-serif;
}

.contentHeader {
  font-size: 24px;
  font-weight: 600;
  color: #292B3D;
  margin-bottom: 16px;
}

.contentDescription {
  font-size: 16px;
  color: #7C7F9C;
  margin-bottom: 24px;
}

/* For status messages */
.infoMessage, .successMessage, .errorMessage, .warningMessage {
  padding: 10px 16px;
  border-radius: 6px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
}

.infoMessage {
  background-color: #E0E6FF;
  color: #4758EB;
}

.successMessage {
  background-color: #E3F9E5;
  color: #0A8F0A;
}

.errorMessage {
  background-color: #FFEFEF;
  color: #E53939;
}

.warningMessage {
  background-color: #FFF8E0;
  color: #FFAD0D;
}

/* Tabs Styling - Updated to match PdpGeneration style */
.tabsContainer {
  margin: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.tabsList {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
  border-bottom: 1px solid #E0E1EB;
}

.tabItem {
  padding: 12px 20px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #7C7F9C;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  transition: all 0.3s;
  margin-right: 10px;
  background-color: transparent;
}

.tabItem:hover {
  color: #4758EB;
}

.activeTab {
  color: #4758EB;
  border-bottom: 3px solid #4758EB;
  font-weight: 600;
}

.tabContent {
  min-height: 400px;
  background-color: transparent;
  border-radius: 8px;
  padding: 0;
  position: relative;
  z-index: 1;
}

/* No Client Selected Styles */
.noClientSelectedContainer {
  margin: 20px;
  background-color: none;
  border-radius: 8px;
  padding: 50px 20px;
  text-align: center;
}

.noClientSelectedMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #F8F9FF;
  border-radius: 8px;
  max-width: 500px;
  margin: 0 auto;
}

.noClientIcon {
  color: #4758EB;
  margin-bottom: 16px;
  opacity: 0.7;
  background-color: #E0E6FF;
  padding: 16px;
  border-radius: 50%;
}

.noClientSelectedMessage h3 {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #292B3D;
  margin-bottom: 10px;
}

.noClientSelectedMessage p {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #7C7F9C;
  line-height: 1.5;
}

.buttonGroup {
  display: flex;
  gap: 12px;
  margin-top: 20px;
  justify-content: space-between;
} 