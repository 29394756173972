@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@400;600&display=swap');

/* Fullscreen overlay that dims the background */
.wizardOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 43, 61, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200; /* Increased to ensure it appears above other elements */
  padding: 20px;
}

/* Big modal in the center with minimal spacing around edges on mobile */
.wizardModal {
  background-color: #ffffff;
  width: 100%;
  max-width: 900px;
  max-height: 80vh;
  overflow-y: auto;
  border-radius: 8px;
  padding: 30px 40px;
  position: relative;
  font-family: 'Lexend Deca', sans-serif;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Simple step indicator with step circles */
.stepIndicator {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.stepNumber {
  width: 40px;
  height: 40px;
  background: #e9ecef;
  color: #6c757d;
  border-radius: 50%;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: default;
  font-size: 18px;
  transition: background-color 0.3s;
}
.stepNumber.active {
  background-color: #4758eb;
  color: #fff;
}
.stepNumber.completed {
  background-color: #5cd685;
  color: #fff;
}

/* Container for each step’s content */
.stepContainer {
  min-height: 250px;
}

.step {
  animation: fadeIn 0.3s ease;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Shared text styling */
.step h2 {
  margin-top: 0;
  font-size: 20px;
  margin-bottom: 20px;
  color: #4758eb;
}
.step label {
  display: block;
  margin-bottom: 6px;
  font-weight: 600;
  color: #292b3d;
}

/* Input styling */
.step input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #E0E1EB;
  border-radius: 4px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: border-color 0.3s;
}
.step input:focus {
  border-color: #4758eb;
  outline: none;
  box-shadow: 0 0 0 2px rgba(71, 88, 235, 0.3);
}

/* Step 1 project preview */
.preview {
  margin-top: 10px;
  font-size: 14px;
  color: #6c757d;
}

/* Verification button (Steps 2 & 3) */
.verifyBtn {
  min-width: 120px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #4758eb;
  color: #ffffff;
  border: none;
  padding: 0 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
}
.verifyBtn:hover {
  background-color: #343fbd;
}
.verifyBtn:disabled,
.verifyBtn:disabled:hover {
  background-color: #d0d0d0;
  cursor: not-allowed;
}

/* Error message styling */
.errorMsg {
  color: #e74c3c;
  margin-top: 10px;
}

.successMessageStep1 {
  padding-bottom: 2px !important;
}

/* Green success box for Merchant Center Verified */
.successBox {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #dfffe8;
  border: 1px solid #b6f2ca;
  border-radius: 4px;
  color: #2e7d32;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
}

/* Metadata box within success message */
.metadata {
  margin-top: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
}
.metadata p {
  margin: 4px 0;
}

/* Review card styling in step 4 */
.reviewCard {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  font-size: 15px;
}
.reviewItem {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}
.reviewItem:last-child {
  margin-bottom: 0;
}

/* Footer nav area with circular icon buttons */
.wizardFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  gap: 10px;
}

/* Base style for circular navigation buttons */
.buttonBase {
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}
.backButton {
  background-color: #d0d0d0;
  color: #ffffff;
}
.backButton:hover:not(:disabled) {
  background-color: #7c7c7c !important;
}
.nextButton {
  background-color: #4758eb;
  color: #fff;
}
.nextButton:hover:not(:disabled) {
  background-color: #343fbd !important;
}
.saveButton {
  background-color: #5cd685;
  color: #fff;
}
.saveButton:hover:not(:disabled) {
  background-color: #86e0a1;
}
.buttonBase:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Spinner (loading) */
.spinner {
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #4758eb;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Schedule section styling (Step 2) */
.scheduleSection {
  margin-top: 20px;
  background-color: #f4f4f4;
  border-radius: 6px;
  padding: 20px;
}
.scheduleSection h3 {
  margin-top: 0;
  font-size: 16px;
  margin-bottom: 10px;
  color: #4758eb;
}
.scheduleToggle {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  align-items: center;
}
.scheduleToggle label {
  margin: 0;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #292b3d;
}
.scheduleToggle input[type="radio"] {
  cursor: pointer;
  margin: 0 5px 0 0;
  accent-color: #4758eb;
}
.scheduleDetails {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 15px;
  font-size: 14px;
  border: 1px solid #e1e1e1;
}
.scheduleRow {
  margin-bottom: 8px;
}
.scheduleRow strong {
  display: inline-block;
  min-width: 100px;
  font-weight: 600;
}

/* Validation Summary Card for Google Ads (Step 3) */
.validationSummary {
  margin-top: 15px;
  padding: 10px 15px;
  background-color: #f9f9f9;
  border: 1px solid #e0e1eb;
  border-radius: 4px;
  font-family: 'Lexend Deca', sans-serif;
}
.validationSuccess {
  display: flex;
  align-items: center;
  color: #5cd685;
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 15px;
}
.validationMetrics p {
  margin: 5px 0;
  font-size: 14px;
}
.metricSubtext {
  font-size: 0.85em;
  color: #6c757d;
  margin-left: 8px;
}
.validationError {
  margin-top: 15px;
  padding: 10px 15px;
  background-color: #fdecea;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  color: #e74c3c;
  font-size: 14px;
  display: flex;
  align-items: center;
}

/* New: Usage Warning styling (yellow visual impression) */
.usageWarning {
  margin-top: 15px;
  padding: 10px 15px;
  background-color: #fff3cd;  /* Light yellow background */
  border: 1px solid #ffeeba;  /* Yellow border */
  border-radius: 4px;
  color: #856404;             /* Dark yellow text */
  font-size: 14px;
  display: flex;
  align-items: center;
}

/* Disabled hover state for base buttons */
.buttonBase:disabled:hover {
  background-color: #d0d0d0;
}

/* Two column container styling */
.twoColumnContainer {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 15px;
}
.schedulerSettings,
.backfillSettings {
  flex: 1 1 300px;
  background-color: #f9f9f9;
  padding: 10px 15px;
  border: 1px solid #e0e1eb;
  border-radius: 4px;
}
.sectionTitle {
  font-size: 14px;
  margin-bottom: 8px;
  color: #4758eb;
  font-weight: 600;
}
.customField {
  margin-bottom: 10px;
}
.infoIcon {
  background-color: #4758eb;
  border-radius: 50%;
  padding: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  margin-left: 4px;
}
.referenceSchedule {
  margin-top: 10px;
  font-size: 12px;
  color: #6c757d;
}
.selectedMerchantCenter {
  background-color: #f0f4ff;
  color: #4758eb;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 10px;
  display: inline-flex;
  align-items: center;
}

/* Review card details */
.summaryRow {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}
.summaryBox {
  flex: 1;
  background-color: #f9f9f9;
  border: 1px solid #e0e1eb;
  border-radius: 6px;
  padding: 15px;
  text-align: center;
}
.summaryBox h4 {
  margin: 0;
  font-size: 14px;
  color: #4758eb;
  margin-bottom: 8px;
}
.summaryBox span {
  font-size: 18px;
  font-weight: 600;
}

/* Distribution details */
.distributionDetails {
  margin-top: 10px;
  background-color: #f9f9f9;
  border: 1px solid #e0e1eb;
  border-radius: 6px;
  padding: 15px;
}
.distributionDetails ul {
  margin: 10px 0 0 20px;
}

/* Core Variant Eligibility messaging */
.variantMessage {
  margin-top: 15px;
  padding: 10px 15px;
  border-radius: 6px;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  background-color: #f9f9f9;
  border-left: 6px solid #ccc;
}
.variantBrilliant {
  border-left-color: #5cd685 !important;
}
.variantGood {
  border-left-color: #a5e3a8 !important;
}
.variantOk {
  border-left-color: #f1c40f !important;
}
.variantBad {
  border-left-color: #e74c3c !important;
}
.variantContent {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.variantTitle {
  font-size: 16px;
  font-weight: 600;
  color: #292b3d;
  display: flex;
  align-items: center;
  gap: 6px;
}
.variantDescription {
  font-size: 14px;
  color: #6c757d;
}


/* A row wrapper for each distribution line */
.distributionRow {
  display: flex;
  align-items: center;
  margin: 6px 0;
  font-size: 13px;
}

/* Label block for "X var(s): Y grp(s)" */
.distributionLabel {
  width: 25%;
  min-width: 150px; /* or whatever suits you */
  margin-right: 10px;
}

/* Container for each bar + percentage text */
.distributionBarContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 20px; /* space between the two bars */
}

/* Container for the entire distribution table in Step 5 */
.distributionGrid {
  display: grid;
  grid-template-columns: 140px 1fr 1fr; /* narrower left column */
  column-gap: 20px;
  row-gap: 8px;
  width: 100%;
  margin-top: 10px;
}

/* distributionGridHeader: optional headings for the middle & right columns */
.distributionGridHeader {
  font-weight: 600;
  color: #6c757d;
  font-size: 13px;
  padding-bottom: 4px;
  border-bottom: 1px solid #e0e1eb;
  display: flex;
  align-items: center;
}

/* The label on the left (e.g. "2 var(s): 1056 grp(s)") */
.distributionLabelCell {
  font-size: 13px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

/* For each bar cell (the bars + percentage text) */
.distributionBarCell {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Reuse your existing bar styles, or adapt them: */
.distributionBarBackground {
  width: 100%;
  height: 6px;
  margin-top: 4px;
  background-color: #E0E1EB;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}
.distributionBarFill {
  height: 100%;
  border-radius: 3px;
}
.distributionPercentage {
  color: #6c757d;
  font-size: 10px;
}

/* Optional: reduce default spacing in step 5’s text */
.step5Compact p {
  margin: 6px 0;
  font-size: 14px;
  line-height: 1.3;
}
