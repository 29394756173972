@import url(https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@400;600&family=DM+Sans:wght@400;500&family=DM+Serif+Text&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css);
@import url(https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css);
@import url(https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css);
@import url(https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css);
@import url(https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css);
@import url(https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@400;600&family=DM+Sans:wght@400;500&family=DM+Serif+Text&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Login_loginPage__bDxuF {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.Login_loginContainer__i_y6E {
  font-family: 'DM Sans', sans-serif;
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 300px;
}

.Login_loginTitle__1_YZJ {
  margin-bottom: 20px;
  color: #333;
}

.Login_loginForm__1W0wp {
  display: flex;
  flex-direction: column;
}

.Login_loginInput__39cBT {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.Login_loginButton__C5y0O {
  width: 100%;
  padding: 10px;
  background-color: #848ee0;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.Login_loginButton__C5y0O:hover {
  background-color: #6c7bbf;
}


.Login_previewButton__2Ffb6 {
  width: 100%;
  padding: 10px;
  background-color: #ccc;  /* Grey background */
  color: white;             /* Darker text color for contrast */
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}

.Login_previewButton__2Ffb6:hover {
  background-color: #b3b3b3 !important;
}


.Login_loginError__3Lr8k {
  color: red;
  margin-top: 10px;
}

.Login_logoContainer__2hRu_ {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.Login_logoContainer__2hRu_ img {
  height: 50px;
  margin-bottom: 10px;
  filter: invert(1);
}

.Login_appName__2Yi7c {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
}



/* src/components/css/Register.module.css */

.Register_registerModal__q_-xr {
  /* Optional: Additional styles if needed */
}

.Register_registerContainer__2bP5- {
  position: relative;
}

.Register_closeButton__30_U2 {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #4758EB;
  transition: color 0.3s;
}

.Register_closeButton__30_U2:hover {
  color: #EB4755;
}

.Register_registerContainer__2bP5- h2 {
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Lexend Deca', sans-serif;
  color: #4758EB;
}

.Register_formGroup__2w2XI {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.Register_formGroup__2w2XI label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #292B3D;
  font-family: 'Lexend Deca', sans-serif;
}

.Register_formGroup__2w2XI input[type="text"],
.Register_formGroup__2w2XI input[type="password"] {
  padding: 10px;
  border: 1px solid #E0E1EB;
  border-radius: 4px;
  font-family: 'Lexend Deca', sans-serif;
  transition: border-color 0.3s;
}

.Register_formGroup__2w2XI input[type="text"]:focus,
.Register_formGroup__2w2XI input[type="password"]:focus {
  border-color: #4758EB;
  outline: none;
}

.Register_adminCheckboxLabel__3KFAW {
  margin-bottom: 5px;
  font-weight: bold;
  color: #292B3D;
  font-family: 'Lexend Deca', sans-serif;
}

.Register_adminCheckboxContainer__3itES {
  display: flex;
  align-items: center;
}

.Register_submitButton___mkFK {
  width: 100%;
  padding: 10px;
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s;
}

.Register_submitButton___mkFK:hover {
  background-color: #848EE0;
}

.Register_message__3WrDa {
  margin-top: 10px;
  text-align: center;
  font-family: 'Lexend Deca', sans-serif;
}

.Register_messageSuccess__1ivq5 {
  color: green;
}

.Register_messageError__2kR04 {
  color: red;
}

/* src/components/css/AdminDashboard.module.css */

/* ---------------------- */
/*       Global Styles    */
/* ---------------------- */

.AdminDashboard_adminDashboardContainer__2JhO8 {
  font-family: 'Lexend Deca', sans-serif;
  color: #333;
  padding: 0;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: #f4f6f8;
}

/* ---------------------- */
/*       Top Bar Styles   */
/* ---------------------- */

.AdminDashboard_topBar__28Tsy {
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin: 20px;
  background-color: #ffffff;
  color: #4758EB;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
  position: relative;
}

.AdminDashboard_headerTextContainer__2aIhT {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 40px;
  flex-grow: 1;
}

.AdminDashboard_feedViewerVersion__2GU1s {
  font-size: 14px;
  font-weight: 600;
  color: #4758EB;
}

.AdminDashboard_headerSubtitle__2qa0X {
  font-size: 22px;
  font-family: 'DM Serif Text', serif;
  color: #292B3D;
}

.AdminDashboard_headerImage__1PtKc {
  height: 100px;
  width: auto;
  padding-top: 20px;
  object-fit: contain;
  margin-left: auto;
}

/* ---------------------- */
/*      Button Styles     */
/* ---------------------- */

/* Common Button Styles */
.AdminDashboard_button__2FfYX {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: all 0.3s ease;
  font-family: 'Lexend Deca', sans-serif;
}

/* Logout Button */
.AdminDashboard_logoutBtn__dTZUF {
  background-color: #E0858C;
  color: #ffffff;
  padding: 10px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.AdminDashboard_logoutBtn__dTZUF:hover {
  background-color: #EB4755 !important;
}

/* Register New User Button */
.AdminDashboard_addUserButton__d73uw {
  background-color: #5CD685;
  color: #ffffff;
  padding: 10px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.AdminDashboard_addUserButton__d73uw:hover {
  background-color: #94D1A8 !important;
}

/* Config Button */
.AdminDashboard_configBtn__2qcpC {
  background-color: #E0E1EB;
  color: #333;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.9em;
  transition: background-color 0.3s;
  margin-right: 5px;
}

.AdminDashboard_configBtn__2qcpC:hover {
  background-color: #4758EB;
  color: white;
}

/* Admin Tools Buttons */
.AdminDashboard_adminToolsBtn__3vVY7 {
  background-color: #4758EB;
  color: #ffffff;
  padding: 15px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.2s;
  font-family: 'Lexend Deca', sans-serif;
  flex: 1 1;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AdminDashboard_adminToolsBtn__3vVY7:hover {
  background-color: #3950C3 !important;
  opacity: 100% !important;
}

.AdminDashboard_adminToolsBtn__3vVY7:active {
  transform: translateY(0);
  background-color: #3950C3 !important;
}

/* Add Feed Button */
.AdminDashboard_addFeedButton__gIbrd {
  background-color: #5CD685;
  color: #ffffff;
  padding: 10px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.AdminDashboard_addFeedButton__gIbrd:hover {
  background-color: #94D1A8 !important;
}

/* Icon Stacking for Add Feed Button */
.AdminDashboard_iconStack__3eMEZ {
  position: relative;
  display: inline-block;
}

.AdminDashboard_iconStack__3eMEZ .AdminDashboard_plusIcon__1LNYY {
  position: absolute;
  bottom: -2px;
  right: -2px;
  color: #5cd685;
  background-color: #ffffff;
  border-radius: 50%;
  padding: 1px;
}

/* ---------------------- */
/*      Dashboard Layout  */
/* ---------------------- */

.AdminDashboard_dashboardContent__1F1GD {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  margin: 20px;
}

/* ---------------------- */
/*   Admin Tools Section  */
/* ---------------------- */

.AdminDashboard_adminTools__3Fwj6 {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.AdminDashboard_adminTools__3Fwj6 h2 {
  margin-bottom: 10px;
}

.AdminDashboard_toolsContainer__2E_H- {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 10px;
}

/* ---------------------- */
/*     Tab Navigation     */
/* ---------------------- */

.AdminDashboard_tabContainer__cIKlP {
  width: 100%;
}

.AdminDashboard_tabNav__2qxJt {
  display: flex;
  border-bottom: 1px solid #ccc;
  background-color: #f9f9f9;
}


.AdminDashboard_tab__3QQBO {
  flex: 1 1;
  color: #292B3D;
  padding: 12px 16px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  font-family: 'Lexend Deca', sans-serif;
  justify-content: center;
  font-size: 16px;
  transition: background-color 0.3s;
}

.AdminDashboard_tab__3QQBO:hover {
  background-color: #e0e0e0 !important;
}

.AdminDashboard_activeTab__1O0Mv {
  border-bottom: 3px solid #4758EB;
  font-weight: bold;
  background-color: #fff;
}

.AdminDashboard_tabIcon__2BKyZ {
  margin-right: 8px;
}

.AdminDashboard_tabContent__25pqX {
  padding-top: 16px;
}

.AdminDashboard_tabPanel__3xNo0 {
  display: block;
}

/* ---------------------- */
/* Configuration Section */
/* ---------------------- */

/* Configuration Container Styles */
.AdminDashboard_configurationContainer__306o2 {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  flex-wrap: wrap;
}

.AdminDashboard_userConfigContainer__2UvuY,
.AdminDashboard_feedConfigContainer__3lmg5 {
  flex: 1 1;
  min-width: 300px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  border-radius: 8px;
}


.AdminDashboard_tableHeader__1XjOT {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}


/* ---------------------- */
/*       Tables Styles    */
/* ---------------------- */

.AdminDashboard_userTableContainer__YB3I8,
.AdminDashboard_feedConfigurationContainer__1A0JU .AdminDashboard_userTableContainer__YB3I8 {
  max-height: 600px;
  overflow-y: auto;
  margin-top: 10px;
}

.AdminDashboard_userTable__3vKQm,
.AdminDashboard_feedConfigurationContainer__1A0JU .AdminDashboard_userTable__3vKQm {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.AdminDashboard_userTable__3vKQm th,
.AdminDashboard_userTable__3vKQm td,
.AdminDashboard_feedConfigurationContainer__1A0JU .AdminDashboard_userTable__3vKQm th,
.AdminDashboard_feedConfigurationContainer__1A0JU .AdminDashboard_userTable__3vKQm td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.AdminDashboard_userTable__3vKQm th,
.AdminDashboard_feedConfigurationContainer__1A0JU .AdminDashboard_userTable__3vKQm th {
  background-color: #4758EB;
  color: white;
  font-weight: bold;
}

.AdminDashboard_userTable__3vKQm tr:nth-child(even),
.AdminDashboard_feedConfigurationContainer__1A0JU .AdminDashboard_userTable__3vKQm tr:nth-child(even) {
  background-color: #f9f9f9;
}

.AdminDashboard_userTable__3vKQm tr:hover,
.AdminDashboard_feedConfigurationContainer__1A0JU .AdminDashboard_userTable__3vKQm tr:hover {
  background-color: #f5f5f5;
}

/* ---------------------- */
/*      Search Styles     */
/* ---------------------- */

.AdminDashboard_searchContainer__2IFYw {
  margin-top: 10px;
  margin-bottom: 10px;
}

.AdminDashboard_searchInput__lhQ7g {
  width: 100%;
  padding: 8px;
  border: 1px solid #E0E1EB;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.AdminDashboard_searchInput__lhQ7g:focus {
  border-color: #4758EB;
  outline: none;
}

/* ---------------------- */
/*      Action Buttons    */
/* ---------------------- */

/* Container for Action Buttons */
.AdminDashboard_actionButtons__1-Y6t {
  display: flex;
  grid-gap: 6px;
  gap: 6px; 
  flex-wrap: wrap;
  color: white;
}

/* Base Action Button Styles */
.AdminDashboard_actionButton__2gQdL {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px; 
  height: 32px; 
  border: none;
  padding: 0px;
  margin: 0px;
  border-radius: 50%;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  text-decoration: none;
}

.AdminDashboard_actionButton__2gQdL:hover {
  opacity: 0.8;
}

.AdminDashboard_actionButton__2gQdL:active {
  transform: scale(0.95);
}

/* 1. Credits Button */
.AdminDashboard_creditsBtn__eOiCh {
  background-color: #5cd685; 
}

.AdminDashboard_creditsBtn__eOiCh:hover {
  background-color: #4caf50 !important; 
}

/* 2. Assign User Button */
.AdminDashboard_assignBtn__29Psq {
  background-color: #4758eb; 
}

.AdminDashboard_assignBtn__29Psq:hover {
  background-color: #364fc7 !important; 
}

/* 3. Data Link Button */
.AdminDashboard_linkBtn__3B_uW {
  background-color: #4758eb; 
}

.AdminDashboard_linkBtn__3B_uW:hover {
  background-color: #364fc7 !important; 
}

/* 4. Modify/Settings Button */
.AdminDashboard_modifyBtn__-P34x {
  background-color: #ffa500; 
}

.AdminDashboard_modifyBtn__-P34x:hover {
  background-color: #e69500 !important; 
}

/* 5. Delete Button */
.AdminDashboard_deleteBtn__2JW1S {
  background-color: #e0858c; 
}

.AdminDashboard_deleteBtn__2JW1S:hover {
  background-color: #d66b7f !important; 
}

/* Remove default link styles */
.AdminDashboard_actionButton__2gQdL {
  text-decoration: none;
  color: inherit; 
}

/* ---------------------- */
/*     Monitoring Section */
/* ---------------------- */

.AdminDashboard_monitoringSection__2dynr {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.AdminDashboard_monitoringSection__2dynr h2 {
  margin-bottom: 20px;
}

/* Costs Scorecards Container */
.AdminDashboard_costsContainer__3xPvF {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 30px;
}

/* Generations Scorecards Container */
.AdminDashboard_generationsContainer__2D_cj {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 30px;
}

/* ---------------------- */
/*       Scorecard Styles  */
/* ---------------------- */

.AdminDashboard_scorecard__2lCnq {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  position: relative;
}

.AdminDashboard_scorecardIcon__1dMiM {
  font-size: 2.5rem;
  margin-right: 15px;
}

.AdminDashboard_scorecardContent__3vw4Q {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
}

.AdminDashboard_scorecardTitle__3FJuV {
  font-size: 1rem;
  color: #777;
  margin-bottom: 5px;
}

.AdminDashboard_scorecardValue__3Wq7F {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.AdminDashboard_spinner__2M87t {
  animation: AdminDashboard_spin__1UBHF 1s linear infinite;
}

.AdminDashboard_loadingIcon__-hhl4 {
  font-size: 24px;
  color: #4758EB;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
/* Spinner Animation */
@keyframes AdminDashboard_spin__1UBHF {
  to {
    transform: rotate(360deg);
  }
}

/* Specific Styles for Different Scorecards */
.AdminDashboard_totalCost__1-_vb {
  border-left: 5px solid #4758EB; 
}

.AdminDashboard_totalGenerations__3HKhf {
  border-left: 5px solid #4758EB; 
}

.AdminDashboard_comingSoonCard__12m2H {
  border-left: 5px solid #E0E1EB; 
  color: #555;
  position: relative;
  opacity: 0.9;
}

.AdminDashboard_comingSoonBadge__3XL1i {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgba(0,0,0,0.7);
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}

.AdminDashboard_comingSoonBadge__3XL1i svg {
  margin-top: -2px;
}

/* ---------------------- */
/*      Filters Styles    */
/* ---------------------- */

/* Optimized Filters Container */
.AdminDashboard_optimizedFiltersContainer__2IJo- {
  padding: 15px 20px; 
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; 
}

.AdminDashboard_optimizedFiltersContainer__2IJo- h3 {
  margin-bottom: 15px;
  color: #4758EB;
}

.AdminDashboard_filtersGrid__3v3bi {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr)); 
  grid-gap: 15px; 
  gap: 15px; 
}

.AdminDashboard_filterGroup__3THyy {
  display: flex;
  flex-direction: column;
}

.AdminDashboard_filterLabel__3jLUP {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: 500;
  color: #333;
  font-size: 0.95rem; 
}

.AdminDashboard_filterIcon__1Kbi1 {
  margin-right: 6px; 
  color: #4758EB;
  font-size: 1rem; 
}

.AdminDashboard_dateRangeContainer__4XGSU {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px; 
}

.AdminDashboard_toText__3twLU {
  font-weight: bold;
  color: #555;
  font-size: 0.9rem; 
}

.AdminDashboard_dateInput__2MR8R {
  width: 100%;
  padding: 6px 10px; 
  border: 1px solid #E0E1EB;
  border-radius: 4px;
  transition: border-color 0.3s;
  font-size: 0.9rem; 
}

.AdminDashboard_dateInput__2MR8R:focus {
  border-color: #4758EB;
  outline: none;
}

.AdminDashboard_selectInput__2nxL1 {
  font-size: 0.9rem; 
}

.AdminDashboard_filterButtons__2eESQ {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 20px;
  justify-content: flex-end;
}

.AdminDashboard_filterButton__Xxdjj {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.2s;
}

.AdminDashboard_applyButton__3dTpA {
  background-color: #4758EB;
  color: #ffffff;
}

.AdminDashboard_applyButton__3dTpA:hover {
  background-color: #364fc7;
}

.AdminDashboard_applyButton__3dTpA:active {
  transform: scale(0.98);
}

.AdminDashboard_resetButton__2fYyj {
  background-color: #e0858c;
  color: #ffffff;
}

.AdminDashboard_resetButton__2fYyj:hover {
  background-color: #d66b7f;
}

.AdminDashboard_resetButton__2fYyj:active {
  transform: scale(0.98);
}

/* ---------------------- */
/*      Charts Container    */
/* ---------------------- */

.AdminDashboard_chartsContainer__rzIjn {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  grid-gap: 40px; 
  gap: 40px;
}

.AdminDashboard_chartSection__3_CUB {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
}


.AdminDashboard_chartHeader__1RB5q {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.AdminDashboard_metricToggle__SdoTn {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.AdminDashboard_toggleLabel__1DrdU {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.AdminDashboard_toggleInput__zgQaa {
  display: none;
}

.AdminDashboard_toggleButton__1XuR8 {
  background-color: #e0e1eb;
  color: #333;
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 14px;
  -webkit-user-select: none;
          user-select: none;
}

.AdminDashboard_toggleButton__1XuR8:hover {
  background-color: #4758EB;
  color: #ffffff;
}

.AdminDashboard_activeToggle__1Vwqi {
  background-color: #4758EB;
  color: #ffffff;
}

.AdminDashboard_comingSoonChart__2Q6DJ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px; 
  font-size: 1.2rem;
  color: #555;
}

.AdminDashboard_comingSoonChart__2Q6DJ p {
  margin-top: 10px;
  font-weight: bold;
}

/* ---------------------- */
/*      Modal Styles      */
/* ---------------------- */

.AdminDashboard_modalBackdrop__teuR2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  animation: AdminDashboard_fadeIn__3IfOL 0.3s forwards;
}

.AdminDashboard_modalContent__17DKm {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  width: 90%;
  max-width: 800px; 
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  position: relative;
  transform: scale(0.9);
  animation: AdminDashboard_scaleIn__ntiDp 0.3s forwards;
}

/* Modal Animations */
@keyframes AdminDashboard_fadeIn__3IfOL {
  to {
    opacity: 1;
  }
}

@keyframes AdminDashboard_scaleIn__ntiDp {
  to {
    transform: scale(1);
  }
}

.AdminDashboard_modalHeader__2j20N {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.AdminDashboard_closeButton__1euV3 {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
}

.AdminDashboard_modalBody__33MuD {
  max-height: 60vh;
  overflow-y: auto;
}

/* ---------------------- */
/*      Message Styles    */
/* ---------------------- */

.AdminDashboard_message__3soRi {
  margin-top: 10px;
  text-align: center;
  font-family: 'Lexend Deca', sans-serif;
  padding: 10px;
  border-radius: 4px;
}

.AdminDashboard_messageSuccess__3hok3 {
  color: green;
  background-color: #e6ffe6;
  border: 1px solid green;
}

.AdminDashboard_messageError__2EXSW {
  color: red;
  background-color: #ffe6e6;
  border: 1px solid red;
}

/* ---------------------- */
/*      Responsive Design */
/* ---------------------- */

@media (max-width: 1024px) {
  .AdminDashboard_dashboardContent__1F1GD {
    flex-direction: column;
  }

  .AdminDashboard_mainContent__1U11V {
    flex: 1 1;
  }

  .AdminDashboard_leftContainer__20Otj {
    flex-direction: row; 
  }
}

@media (max-width: 768px) {
  .AdminDashboard_topBar__28Tsy {
    flex-direction: column;
    align-items: flex-start;
  }

  .AdminDashboard_headerImage__1PtKc {
    margin-left: 0;
    margin-top: 10px;
  }

  .AdminDashboard_configurationContainer__306o2,
  .AdminDashboard_feedConfigContainer__3lmg5 {
    padding: 15px;
  }

  .AdminDashboard_adminToolsBtn__3vVY7 {
    min-width: 150px;
    padding: 10px 15px;
  }

  .AdminDashboard_metricToggle__SdoTn {
    flex-direction: column;
    align-items: flex-start;
  }

  .AdminDashboard_comingSoonBadge__3XL1i {
    top: auto;
    bottom: 10px;
  }

  /* Stack User and Feed Config on smaller screens */
  .AdminDashboard_configurationContainer__306o2 {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .AdminDashboard_adminToolsBtn__3vVY7 {
    flex: 1 1 100%;
    min-width: unset;
  }

  .AdminDashboard_costsContainer__3xPvF,
  .AdminDashboard_generationsContainer__2D_cj {
    grid-template-columns: 1fr;
  }

  .AdminDashboard_scorecard__2lCnq {
    padding: 15px;
  }

  .AdminDashboard_scorecardIcon__1dMiM {
    font-size: 2rem;
    margin-right: 10px;
  }

  .AdminDashboard_chartsContainer__rzIjn {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    gap: 20px;
  }
}

/* ---------------------- */
/*   Additional Elements  */
/* ---------------------- */

/* Metric Selector Styles */
.AdminDashboard_metricSelector__35sjA {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.AdminDashboard_metricSelector__35sjA label {
  margin-right: 10px;
  font-weight: bold;
  color: #333;
}

.AdminDashboard_metricSelector__35sjA .AdminDashboard_react-select__control__2VEiP {
  min-width: 200px;
}

@media (max-width: 768px) {
  .AdminDashboard_metricSelector__35sjA {
    flex-direction: column;
    align-items: flex-start;
  }

  .AdminDashboard_metricSelector__35sjA label {
    margin-bottom: 5px;
  }
}

/* ---------------------- */
/*      Actions Column    */
/* ---------------------- */

/* Define a class for the Actions column to set a minimum width */
.AdminDashboard_actionsColumn__2o7mM {
  min-width: 150px; 
}

/* Ensure the Actions column text is centered */
.AdminDashboard_actionsColumn__2o7mM {
  text-align: center;
}

/* ---------------------- */
/*      Responsive Tables  */
/* ---------------------- */

/* Make tables responsive */
@media (max-width: 768px) {
  .AdminDashboard_userTable__3vKQm,
  .AdminDashboard_feedConfigurationContainer__1A0JU .AdminDashboard_userTable__3vKQm {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}

/* ---------------------- */
/*    Coming Soon Badge    */
/* ---------------------- */

/* Ensure the Coming Soon badge is properly styled */
.AdminDashboard_comingSoonBadge__3XL1i {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgba(0,0,0,0.7);
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}

.AdminDashboard_comingSoonBadge__3XL1i svg {
  margin-top: -2px;
}

/* ---------------------- */
/*   Cloud Cost Alert Styles */
/* ---------------------- */

.AdminDashboard_cloudCostAlert__jifH7 {
  display: flex;
  align-items: center;
  background-color: #fff3cd; 
  color: #856404; 
  padding: 8px 12px;
  border: 1px solid #ffeeba; 
  border-radius: 4px;
  font-size: 0.9rem;
}

.AdminDashboard_alertIcon__3VzU4 {
  margin-right: 8px;
  font-size: 1.2rem;
  color: #856404;
}

/* Adjustments for the Cloud Costs Value Container */

.AdminDashboard_scorecardValue__3Wq7F {
  display: flex;
  align-items: center;
  justify-content: flex-start; 
  height: 40px;
}

/* ---------------------- */
/*   Combined Scorecard Styles */
/* ---------------------- */

.AdminDashboard_combinedScorecard__LBzb1 {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;
}

.AdminDashboard_combinedScorecardHeader__21yTL {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.AdminDashboard_combinedScorecardIcon__mrJk0 {
  font-size: 2.5rem;
  margin-right: 15px;
  color: #4758EB;
}

.AdminDashboard_combinedScorecardContent__27Uxc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.AdminDashboard_combinedScorecardTitles__2iPRH {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.AdminDashboard_combinedScorecardTitle__EsaHA {
  font-size: 1rem;
  color: #777;
}

.AdminDashboard_combinedScorecardSubtitles__3lbRA {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  font-size: 0.9rem;
  color: #555;
  margin-bottom: -15px;
}

.AdminDashboard_combinedScorecardValue__2lOxU {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-top: 10px;
}

.AdminDashboard_loadingContainer__1qEVe {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px; 
}

.AdminDashboard_chartLoadingContainer__2OLH8 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px; 
}

.AdminDashboard_noData__oBJcc {
  text-align: center;
  padding: 20px;
  color: #888;
}

/* ---------------------- */
/*   Cloud Cost Scorecard */
/* ---------------------- */

.AdminDashboard_cloudCost__x_y4j {
  cursor: pointer;
}

.AdminDashboard_cloudCost__x_y4j:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
  transition: transform 0.2s, box-shadow 0.2s;
}

.AdminDashboard_interactiveIcon__1ofBM {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 1.2rem;
  color: #4758EB;
  opacity: 1;
  transition: opacity 0.3s;
}





/* src/components/css/Config.module.css */

.Config_configPage__3Guif {
  position: relative;
  font-family: 'Lexend Deca', sans-serif;
  color: #333;
}

.Config_configContainer__1PjKq {
  padding: 0px;
  max-width: 900px;
  margin: 0 auto;
}

.Config_header__3ydBE {
  text-align: center;
  margin-bottom: 30px;
}

.Config_section__1rLeV {
  margin-top: 20px;
}

.Config_noClientsMessage__EpMZ6 {
  padding: 1px;
  background-color: #fff3cd;
  border-left: 4px solid #ffeeba;
  border-radius: 4px;
  margin-bottom: 0px;
  color: #856404;
}

.Config_clientSection__V1gmC {
  display: flex;
  flex-direction: column;
}

.Config_clientLists__20mvK {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
}

.Config_listContainer__wlMTN {
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
}

.Config_listHeader__2uZMW {
  background-color: #f1f3f5;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-weight: bold;
  color: #4758EB;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Config_clientList__3JUi1 {
  flex-grow: 1;
  max-height: 300px;
  overflow-y: auto;
  background-color: #fafafa;
  border: 1px solid #E0E1EB;
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.Config_clientItem__2cZn8 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;
  border-bottom: 1px solid #E0E1EB;
  transition: background-color 0.2s;
}

.Config_clientItem__2cZn8:hover {
  background-color: #f1f3f5;
}

.Config_clientItem__2cZn8:last-child {
  border-bottom: none;
}

.Config_clientName__1Ezo9 {
  flex-grow: 1;
  font-size: 14px;
}

.Config_actionButton__1AvNW {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 6px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.Config_linkButton__3PVKH {
  color: #28a745;
}

.Config_linkButton__3PVKH:hover {
  background-color: rgba(40, 167, 69, 0.1) !important;
}

.Config_unlinkButton__PXOAn {
  color: #dc3545;
}

.Config_unlinkButton__PXOAn:hover {
  background-color: rgba(220, 53, 69, 0.1) !important;;
}

.Config_searchInput__zSrl2 {
  width: 100%;
  border: 1px solid #E0E1EB;
  border-radius: 4px;
  font-family: 'Lexend Deca', sans-serif;
  margin-bottom: 20px;
  margin-top:20px;
  transition: border-color 0.3s;
}

.Config_searchInput__zSrl2:focus {
  border-color: #4758EB;
  outline: none;
}

.Config_message__21jok {
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
}

.Config_errorMessage__1gL1g {
  color: #dc3545;
}

.Config_successMessage__3_knA {
  color: #28a745;
}

@media (max-width: 768px) {
  .Config_clientLists__20mvK {
    flex-direction: column;
  }

  .Config_listContainer__wlMTN {
    flex: 1 1 100%;
  }
}


.Config_creditButton__16tjn {
  color: #ffc107; /* Amber color for credit */
}

.Config_creditButton__16tjn:hover {
  background-color: rgba(255, 193, 7, 0.1) !important;
}
/* src/components/css/PopupUpdateCreditBalance.module.css */

.PopupUpdateCreditBalance_modalBackdrop__2QRme {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1500;
    animation: PopupUpdateCreditBalance_fadeIn__1TYO0 0.3s forwards;
  }
  
  .PopupUpdateCreditBalance_modalContent__20s65 {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: PopupUpdateCreditBalance_scaleIn__34O4B 0.3s forwards;
  }
  
  .PopupUpdateCreditBalance_closeButton__YwVep {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    cursor: pointer;
    color: #555;
    transition: color 0.3s;
  }
  
  .PopupUpdateCreditBalance_closeButton__YwVep:hover {
    color: #000;
  }
  
  .PopupUpdateCreditBalance_form__yTkD2 {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
  }
  
  .PopupUpdateCreditBalance_field__36yZm {
    display: flex;
    flex-direction: column;
  }
  
  .PopupUpdateCreditBalance_field__36yZm label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .PopupUpdateCreditBalance_field__36yZm input {
    padding: 8px;
    border: 1px solid #E0E1EB;
    border-radius: 4px;
    font-family: 'Lexend Deca', sans-serif;
    transition: border-color 0.3s;
  }
  
  .PopupUpdateCreditBalance_field__36yZm input:focus {
    border-color: #4758EB;
    outline: none;
  }
  
  .PopupUpdateCreditBalance_buttons__1SfGF {
    display: flex;
    justify-content: flex-end;
    grid-gap: 10px;
    gap: 10px;
  }
  
  .PopupUpdateCreditBalance_submitButton__Fy9Jp {
    background-color: #4758EB;
    color: #ffffff;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .PopupUpdateCreditBalance_submitButton__Fy9Jp:hover {
    background-color: #364fc7;
  }
  
  .PopupUpdateCreditBalance_submitButton__Fy9Jp:disabled {
    background-color: #a0a0a0;
    cursor: not-allowed;
  }
  
  .PopupUpdateCreditBalance_cancelButton__1o_ja {
    background-color: #f0f0f0;
    color: #333;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .PopupUpdateCreditBalance_cancelButton__1o_ja:hover {
    background-color: #dcdcdc !important;;
  }
  
  .PopupUpdateCreditBalance_error__3wR0c {
    color: #d66b7f;
    font-weight: bold;
  }
  
  .PopupUpdateCreditBalance_success__3M2C4 {
    color: #4caf50;
    font-weight: bold;
  }
  
  /* Modal Animations */
  @keyframes PopupUpdateCreditBalance_fadeIn__1TYO0 {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes PopupUpdateCreditBalance_scaleIn__34O4B {
    from {
      transform: scale(0.9);
    }
    to {
      transform: scale(1);
    }
  }
  
.PopupAddFeed_modalBackdrop__WqFEj {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.PopupAddFeed_modalContent__2PiQ2 {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  width: 90%;
  max-width: 1100px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  font-family: 'Lexend Deca', sans-serif;
  position: relative;
  overflow-y: auto;
  max-height: 90vh;
}

.PopupAddFeed_modalTitle__34rC2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #4758eb;
  text-align: center;
}

.PopupAddFeed_stepContainer__21Vve {
  margin-bottom: 30px;
  position: relative; /* For overlay positioning */
}

.PopupAddFeed_stepHeader__3PXlO {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.PopupAddFeed_stepNumber__dWSRy {
  width: 30px;
  height: 30px;
  background-color: #4758EB;
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 10px;
}

.PopupAddFeed_stepTitle__1Zcyv {
  font-size: 18px;
  color: #292B3D;
}

.PopupAddFeed_formGroup__2lhrV {
  margin-bottom: 20px;
}

.PopupAddFeed_label__1R1tN {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-weight: 600;
  color: #292b3d;
}

.PopupAddFeed_inputField__3LJ_y {
  width: 100%;
  padding: 10px;
  border: 1px solid #e0e1eb;
  border-radius: 4px;
  font-family: 'Lexend Deca', sans-serif;
  transition: border-color 0.3s;
}

.PopupAddFeed_inputField__3LJ_y:focus {
  border-color: #4758eb;
  outline: none;
}

.PopupAddFeed_buttonGroup__3zor7 {
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;
  gap: 10px;
}

.PopupAddFeed_cancelButton__rcXSf,
.PopupAddFeed_nextButton__2Z3-2 {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-family: 'Lexend Deca', sans-serif;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
}

.PopupAddFeed_cancelButton__rcXSf {
  background-color: #e0e1eb;
  color: #292B3D;
}

.PopupAddFeed_cancelButton__rcXSf:hover {
  background-color: #c5c7d6;
}

.PopupAddFeed_nextButton__2Z3-2 {
  background-color: #5CD685;
  color: #ffffff;
}

.PopupAddFeed_nextButton__2Z3-2:disabled {
  background-color: #E0EBE4 !important;
  cursor: not-allowed;
}

.PopupAddFeed_nextButton__2Z3-2:hover:not(:disabled) {
  background-color: #94D1A8 !important;
}

.PopupAddFeed_loadingOverlay__54LV9 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1001;
}

.PopupAddFeed_loadingOverlay__54LV9 p {
  margin-top: 10px;
  font-size: 16px;
  color: #4758EB;
}

.PopupAddFeed_errorMessage__2z0s- {
  display: flex;
  align-items: center;
  background-color: #f8d7da; /* Light red background */
  color: #721c24; /* Dark red text */
  padding: 10px;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  margin-top: 10px;
}

.PopupAddFeed_errorIcon__16sMB {
  margin-right: 10px;
  font-size: 20px;
}
.PopupAddFeed_successMessage__3tWEC {
  display: flex;
  align-items: center;
  background-color: #d4edda; /* Light green background */
  color: #155724; /* Dark green text */
  padding: 10px;
  border: 1px solid #c3e6cb;
  border-radius: 4px;
  margin-top: 10px;
}

.PopupAddFeed_successIcon__iOTNB {
  margin-right: 10px;
  font-size: 20px;
}

.PopupAddFeed_iconGroup__mxOww {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 10px;
}

.PopupAddFeed_iconOption__2mPvP {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  padding: 10px;
  border: 1px solid #e0e1eb;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.PopupAddFeed_iconOption__2mPvP span {
  margin-top: 5px;
  font-size: 12px;
  color: #292b3d;
  text-align: center;
}

.PopupAddFeed_iconOption__2mPvP:hover {
  background-color: #f0f0f0;
}

.PopupAddFeed_selectedOption__278_5 {
  border-color: #4758eb;
  background-color: #e8eaff;
}

.PopupAddFeed_pimLogo__2w42x,
.PopupAddFeed_dataTypeIcon__3RDil {
  width: 40px;
  height: 40px;
}

.PopupAddFeed_tooltip__Bq7cg {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  color: #d0d0d0;
  cursor: pointer;
}

.PopupAddFeed_tooltip__Bq7cg .PopupAddFeed_tooltiptext__3U2HX {
  visibility: hidden;
  width: 220px;
  background-color: #292B3D;
  color: #fff;
  text-align: left;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 25px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.PopupAddFeed_tooltip__Bq7cg:hover .PopupAddFeed_tooltiptext__3U2HX {
  visibility: visible;
  opacity: 1;
}

.PopupAddFeed_inputWithFeedback__KY3Ki {
  position: relative;
  display: flex;
  align-items: center;
}

.PopupAddFeed_feedbackMessage__aQL-O {
  min-height: 24px; /* Adjust based on your message height */
  margin-top: 5px;
}

.PopupAddFeed_spinner__3ykMN {
  position: absolute;
  right: 10px;
  animation: PopupAddFeed_spin__2CAJo 1s linear infinite;
  color: #4758eb;
}

.PopupAddFeed_mappingButton__1hLES {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: all 0.2s ease;
  margin-top: 10px;
}

.PopupAddFeed_mappingButton__1hLES:hover {
  background-color: #6b79f7;
}

.PopupAddFeed_mappingButton__1hLES:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Data Options */
.PopupAddFeed_dataOptions__oj8BL {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0px;
  position: relative;
}

.PopupAddFeed_dataOptions__oj8BL::before {
  left: 0;
}

.PopupAddFeed_dataOptions__oj8BL::after {
  right: 0;
}

.PopupAddFeed_tagInput__289vV {
  width: 150px; /* Limited width */
  padding: 10px;
  border: 1px solid #e0e1eb;
  border-radius: 4px;
  font-family: 'Lexend Deca', sans-serif;
  transition: border-color 0.3s;
}

.PopupAddFeed_tagInput__289vV:focus {
  border-color: #4758eb;
  outline: none;
}

/* File Info */
.PopupAddFeed_fileInfo__3bNA- {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-family: 'Lexend Deca', sans-serif;
  color: #4758eb;
}

.PopupAddFeed_fileInfo__3bNA- button {
  margin-left: auto;
  background: none;
  border: none;
  color: #eb4755;
  cursor: pointer;
  font-size: 14px;
}

.PopupAddFeed_fileInfo__3bNA- button:hover {
  text-decoration: underline;
}

/* Data Options and Or Divider */
.PopupAddFeed_orDivider__2iuyy {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  margin: 0 10px;
}

.PopupAddFeed_orDivider__2iuyy::before,
.PopupAddFeed_orDivider__2iuyy::after {
  content: '';
  flex: 1 1;
  height: 1px;
  background-color: #e0e1eb;
}

.PopupAddFeed_orDivider__2iuyy span {
  font-weight: bold;
  color: #292b3d;
  padding: 0 5px;
}

/* Adjust Form Groups */
.PopupAddFeed_dataOptions__oj8BL .PopupAddFeed_formGroup__2lhrV {
  width: 45%;
}

.PopupAddFeed_dataOptions__oj8BL .PopupAddFeed_formGroup__2lhrV input {
  width: 100%;
}

/* Upload Container */
.PopupAddFeed_uploadContainer__XUDb3 {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

/* Message Container */
.PopupAddFeed_messageContainer__WiK10 {
  min-height: 24px; /* Reserve space for messages to prevent layout shifts */
}

/* Disabled Overlay */
.PopupAddFeed_disabledOverlay__3PE4U {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  pointer-events: none; /* Allows underlying elements to retain their disabled state */
}

.PopupAddFeed_disabledText__1bgmf {
  color: #292b3d;
  font-size: 14px;
  text-align: center;
  padding: 0 10px;
}

/* Disabled Section Styles */
.PopupAddFeed_disabledSection__14DYn {
  opacity: 0.6;
  pointer-events: none; /* Prevents interaction */
}

/* Optionally, you can add hover effects only when not disabled */
.PopupAddFeed_disabledSection__14DYn .PopupAddFeed_iconOption__2mPvP:hover {
  background-color: inherit;
}

/* Tag Input Group */
.PopupAddFeed_tagGroup__2SoTR {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 20px;
}

.PopupAddFeed_tagLabel__1SuxU {
  width: 80px;
  font-weight: 600;
  color: #292b3d;
}

/* Responsive Design */
@media (max-width: 768px) {
  .PopupAddFeed_modalContent__2PiQ2 {
    width: 95%;
    padding: 20px;
  }

  .PopupAddFeed_iconGroup__mxOww {
    justify-content: center;
  }

  .PopupAddFeed_formGroup__2lhrV {
    margin-bottom: 15px;
  }

  .PopupAddFeed_buttonGroup__3zor7 {
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }

  .PopupAddFeed_dataOptions__oj8BL {
    flex-direction: column;
    align-items: stretch;
  }

  .PopupAddFeed_dataOptions__oj8BL::before,
  .PopupAddFeed_dataOptions__oj8BL::after {
    display: none;
  }

  .PopupAddFeed_orDivider__2iuyy {
    display: none;
  }

  .PopupAddFeed_dataOptions__oj8BL .PopupAddFeed_formGroup__2lhrV {
    width: 100%;
    margin: 10px 0;
  }

  .PopupAddFeed_uploadContainer__XUDb3 {
    flex-direction: column;
    align-items: stretch;
  }

  .PopupAddFeed_tagGroup__2SoTR {
    flex-direction: column;
    align-items: flex-start;
  }

  .PopupAddFeed_tagInput__289vV {
    width: 100%;
    margin-top: 10px;
  }
}


/* PopupAddFeed.module.css */

/* ... existing styles ... */

.PopupAddFeed_mappingContainer__1NjQi {
  display: flex;
  flex-direction: column;
}

.PopupAddFeed_mappingRow__bGRnF {
  display: flex;
  align-items: center;
}

.PopupAddFeed_mappingField__HIliY {
  flex: 1 1;
  font-weight: 600;
  color: #292b3d;
}

.PopupAddFeed_mappingDropdown__exiDP {
  flex: 2 1;
  padding: 8px;
  margin: 0px;
  border: 1px solid #e0e1eb;
  border-radius: 4px;
  font-family: 'Lexend Deca', sans-serif;
  transition: border-color 0.3s;
}

.PopupAddFeed_mappingDropdown__exiDP:focus {
  border-color: #4758eb;
  outline: none;
}

/* New class for "Found in feed" */
.PopupAddFeed_foundInFeed__LqE1Q {
  flex: 2 1;
  padding: 8px;
  font-family: 'Lexend Deca', sans-serif;
  color: #28a745; /* Green color to indicate success */
  font-weight: 600;
}

/* Styles for Missing Field Checkbox */
.PopupAddFeed_missingFieldLabel__g9-v9 {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
  color: #292b3d;
}

.PopupAddFeed_missingFieldCheckbox__3tLXQ {
  margin-right: 5px;
  cursor: pointer;
}

/* Responsive adjustments if needed */
@media (max-width: 768px) {
  .PopupAddFeed_mappingRow__bGRnF {
    flex-direction: column;
    align-items: flex-start;
  }

  .PopupAddFeed_mappingField__HIliY {
    width: 100%;
  }

  .PopupAddFeed_mappingDropdown__exiDP {
    width: 100%;
  }

  .PopupAddFeed_foundInFeed__LqE1Q {
    width: 100%;
  }

  .PopupAddFeed_missingFieldLabel__g9-v9 {
    margin-left: 0;
    margin-top: 5px;
  }
}


/* Add these new styles */
/* General Table Styling */
.PopupAddFeed_mappingTable__38arl {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  font-family: 'Lexend Deca', sans-serif;
}

/* Table Header Styling */
.PopupAddFeed_mappingTable__38arl thead {
  background-color: #4758EB;
  color: #ffffff;
  font-weight: bold;
}

.PopupAddFeed_mappingTable__38arl th,
.PopupAddFeed_mappingTable__38arl td {
  padding: 8px;
  text-align: left;
  border: 0px solid #e0e1eb;
  vertical-align: middle !important; /* Vertically center-align content */
  font-size: 14px;
}

/* Zebra Striping for Better Readability */
.PopupAddFeed_mappingTable__38arl tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Hover Effect */
.PopupAddFeed_mappingTable__38arl tbody tr:hover {
  background-color: #e0e1eb;
}

/* Adjusting Tooltip Icon Alignment */
.PopupAddFeed_mappingField__HIliY {
  margin-top: 9px;
  display: flex;          /* Use flexbox */
  align-items: center;    /* Vertically center items within flex container */
}

.PopupAddFeed_tooltip__Bq7cg {
  margin: 0; /* Remove margin if present */
  padding: 0; /* Remove padding if present */
}

/* Icon Status Styling */
.PopupAddFeed_statusIconSuccess__1moXj {
  color: #5CD685; /* Green */
  font-size: 18px;
}

.PopupAddFeed_statusIconWarning__3YyxL {
  color: #f0f000; /* Yellow */
  font-size: 18px;
}

.PopupAddFeed_statusIconError__3XRpM {
  color: #dc3545; /* Red */
  font-size: 18px;
}

/* Checkbox Alignment */
.PopupAddFeed_missingFieldCheckbox__3tLXQ {
  transform: scale(1.2);
  cursor: pointer;
  vertical-align: middle;
}

/* Tooltip Styling */
.PopupAddFeed_tooltip__Bq7cg {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  color: #4758EB;
  cursor: pointer;
}

.PopupAddFeed_tooltip__Bq7cg .PopupAddFeed_tooltiptext__3U2HX {
  visibility: hidden;
  width: 220px;
  background-color: #292B3D;
  color: #fff;
  text-align: left;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 105%;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.PopupAddFeed_tooltip__Bq7cg:hover .PopupAddFeed_tooltiptext__3U2HX {
  visibility: visible;
  opacity: 1;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .PopupAddFeed_mappingTable__38arl {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .PopupAddFeed_mappingTable__38arl th,
  .PopupAddFeed_mappingTable__38arl td {
    padding: 10px;
    font-size: 12px;
  }

  .PopupAddFeed_statusIconSuccess__1moXj,
  .PopupAddFeed_statusIconWarning__3YyxL,
  .PopupAddFeed_statusIconError__3XRpM {
    font-size: 16px;
  }
}

/* Optional: Sticky Header for Large Tables */
.PopupAddFeed_mappingTable__38arl thead th {
  position: sticky;
  top: 0;
  z-index: 2;
}

/* Warning Message Styling */
.PopupAddFeed_warningMessage__4k6Xo {
  display: flex;
  align-items: center;
  background-color: #fff3cd; /* Light yellow background */
  color: #856404; /* Dark yellow text */
  padding: 10px;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  margin-top: 10px;
}

.PopupAddFeed_duplicateWarning__1EbuM {
  display: flex;
  align-items: flex-start;
  background-color: #fff3cd; /* Light yellow background */
  color: #856404; /* Dark yellow text */
  padding: 15px;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  margin-top: 10px;
}

.PopupAddFeed_duplicateWarning__1EbuM .PopupAddFeed_warningIcon__1VynT {
  margin-right: 10px;
  font-size: 24px;
}

.PopupAddFeed_duplicateWarning__1EbuM p {
  margin: 0;
  font-size: 14px;
}

.PopupAddFeed_duplicateWarning__1EbuM strong {
  font-weight: bold;
}


.PopupAddFeed_infoMessage__3sUsJ {
  display: flex;
  align-items: flex-start;
  background-color: #e7f3fe; /* Light blue background */
  color: #31708f; /* Dark blue text */
  padding: 15px;
  border: 1px solid #bce8f1;
  border-radius: 4px;
  margin-top: 10px;
}

.PopupAddFeed_infoIcon__N1BKR {
  margin-right: 10px;
  font-size: 24px;
}

.PopupAddFeed_infoMessage__3sUsJ span {
  font-size: 14px;
}





/* Credit Balance Card */
.PopupAddFeed_creditBalanceCard__I45IG {
  background-color: #f9f9f9;
  border: 1px solid #e0e1eb;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.PopupAddFeed_creditBalanceCard__I45IG .PopupAddFeed_cardHeader__1xkld {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.PopupAddFeed_creditBalanceCard__I45IG .PopupAddFeed_cardIcon__E1iCu {
  font-size: 24px;
  color: #4758EB;
  margin-right: 10px;
}

.PopupAddFeed_creditBalanceCard__I45IG h4 {
  margin: 0;
  font-size: 18px;
  color: #292b3d;
}

/* Assign Users Card */
.PopupAddFeed_assignUsersCard__2NoJP {
  background-color: #f9f9f9;
  border: 1px solid #e0e1eb;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.PopupAddFeed_assignUsersCard__2NoJP .PopupAddFeed_cardHeader__1xkld {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.PopupAddFeed_assignUsersCard__2NoJP .PopupAddFeed_cardIcon__E1iCu {
  font-size: 24px;
  color: #4758EB;
  margin-right: 10px;
}

.PopupAddFeed_assignUsersCard__2NoJP h4 {
  margin: 0;
  font-size: 18px;
  color: #292b3d;
}

/* Additional Enhancements */
.PopupAddFeed_mappingHeader__3mITv {
  margin-bottom: 10px;
}

.PopupAddFeed_mappingTable__38arl th {
  background-color: #4758EB;
  color: #ffffff;
}

.PopupAddFeed_mappingTable__38arl td {
  /* Ensure text doesn't overflow */
  max-width: 200px;
  word-wrap: break-word;
}

.PopupAddFeed_foundInFeed__LqE1Q {
  /* Styling for 'Found in Feed' column if needed */
}

/* Button Styles */
.PopupAddFeed_buttonGroup__3zor7 {
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 20px;
}

/* Credit Balance and Assign Users Cards */
.PopupAddFeed_creditBalanceCard__I45IG,
.PopupAddFeed_assignUsersCard__2NoJP {
  margin-top: 20px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .PopupAddFeed_creditBalanceCard__I45IG,
  .PopupAddFeed_assignUsersCard__2NoJP {
    padding: 15px;
  }

  .PopupAddFeed_creditBalanceCard__I45IG .PopupAddFeed_cardHeader__1xkld,
  .PopupAddFeed_assignUsersCard__2NoJP .PopupAddFeed_cardHeader__1xkld {
    flex-direction: column;
    align-items: flex-start;
  }

  .PopupAddFeed_creditBalanceCard__I45IG .PopupAddFeed_cardIcon__E1iCu,
  .PopupAddFeed_assignUsersCard__2NoJP .PopupAddFeed_cardIcon__E1iCu {
    margin-bottom: 10px;
  }
}
/* src/components/popups/css/PopupAssignClient.module.css */

.PopupAssignClient_modalBackdrop__OlcBv {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1600;
    animation: PopupAssignClient_fadeIn__2-C8J 0.3s forwards;
  }
  
  .PopupAssignClient_modalContent__2bKne {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: PopupAssignClient_scaleIn__3rRdS 0.3s forwards;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .PopupAssignClient_closeButton__1bY2c {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    cursor: pointer;
    color: #555;
    transition: color 0.3s;
  }
  
  .PopupAssignClient_closeButton__1bY2c:hover {
    color: #000;
  }
  
  .PopupAssignClient_assignSection__1pMmZ {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }
  
  .PopupAssignClient_assignSection__1pMmZ label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .PopupAssignClient_buttons__1XSQM {
    display: flex;
    justify-content: flex-end;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 20px;
  }
  
  .PopupAssignClient_assignButton__3z-_3 {
    background-color: #4758EB;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .PopupAssignClient_assignButton__3z-_3:hover {
    background-color: #364fc7;
  }
  
  .PopupAssignClient_assignButton__3z-_3:disabled {
    background-color: #a0a0a0;
    cursor: not-allowed;
  }
  
  .PopupAssignClient_cancelButton__1gqin {
    background-color: #f0f0f0;
    color: #333;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .PopupAssignClient_cancelButton__1gqin:hover {
    background-color: #dcdcdc !important;
  }
  
  .PopupAssignClient_error__3MlvV {
    color: #d66b7f;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .PopupAssignClient_success__3uj-k {
    color: #4caf50;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .PopupAssignClient_linkedUsersSection__2OfwH {
    margin-top: 30px;
  }
  
  .PopupAssignClient_linkedUsersSection__2OfwH h3 {
    margin-bottom: 10px;
  }
  
  .PopupAssignClient_linkedUsersList__8Rhug {
    list-style: none;
    padding: 0;
  }
  
  .PopupAssignClient_linkedUserItem__kkuY3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .PopupAssignClient_username__e9Jhb {
    font-weight: 500;
  }
  
  .PopupAssignClient_unassignButton__3u4ec {
    background-color: #ff4d4f;
    color: #ffffff;
    border: none;
    padding: 5px;
    border-radius: 100px;
    font-size: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .PopupAssignClient_unassignButton__3u4ec:hover {
    background-color: #d9363e;
  }
  
  .PopupAssignClient_unassignButton__3u4ec:disabled {
    background-color: #a0a0a0;
    cursor: not-allowed;
  }
  
  /* Modal Animations */
  @keyframes PopupAssignClient_fadeIn__2-C8J {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes PopupAssignClient_scaleIn__3rRdS {
    from {
      transform: scale(0.9);
    }
    to {
      transform: scale(1);
    }
  }
  
/* src/popups/css/DeleteFeedPopup.module.css */

.DeleteFeedPopup_modalBackdrop__3FBc9 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .DeleteFeedPopup_modalContent__3w53D {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    max-width: 500px;
    width: 90%;
  }
  
  .DeleteFeedPopup_errorMessage__2yw4l {
    color: red;
  }
  
  .DeleteFeedPopup_confirmInput__3a9Th {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
  }
  
  .DeleteFeedPopup_buttonsContainer__1w4Fp {
    display: flex;
    justify-content: flex-end;
  }
  
  .DeleteFeedPopup_cancelButton__ksdmZ,
  .DeleteFeedPopup_deleteButton__13U6q {
    padding: 10px 20px;
    margin-left: 10px;
  }
  
  .DeleteFeedPopup_deleteButton__13U6q {
    background-color: #d9534f;
    color: #fff;
  }
  
  .DeleteFeedPopup_loadingContainer__QdD0p {
    display: flex;
    align-items: center;
  }
  
  .DeleteFeedPopup_spinner__zUNNV {
    margin-right: 10px;
    animation: DeleteFeedPopup_spin__JnIlT 1s linear infinite;
  }
  
  @keyframes DeleteFeedPopup_spin__JnIlT {
    100% {
      transform: rotate(360deg);
    }
  }
  
/* src/components/css/ClientDashboard.module.css */

/* Top Bar Styling */
.ClientDashboard_topBar__1lFMm {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 20px;
  background-color: #ffffff;
  color: #4758EB;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  grid-gap: 10px;
  gap: 10px;
}

/* Logout Button Styling */
.ClientDashboard_logoutBtn__1U0yZ {
  background-color: #E0858C; /* Matching backButton background */
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.ClientDashboard_logoutBtn__1U0yZ:hover {
  background-color: #EB4755 !important; /* Matching backButton hover */
}

.ClientDashboard_logoutBtn__1U0yZ svg {
  margin-right: 0; /* Remove margin since button is circular */
}

/* Header Text Container */
.ClientDashboard_headerTextContainer__JiS1t {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 40px;
  flex-grow: 1; /* Allows it to take available space */
}

.ClientDashboard_feedViewerVersion__3klOm {
  font-size: 14px;
  font-weight: 600;
  color: #4758EB;
  font-family: 'Lexend Deca', sans-serif;
}

.ClientDashboard_headerSubtitle__Sw-oC {
  font-size: 22px;
  font-family: 'DM Serif Text', serif;
  color: #292B3D;
  margin-top: 4px;
}

/* Header Image Styling */
.ClientDashboard_headerImage__2JFoQ {
  height: 100px;
  width: auto;
  padding-top: 20px;
  object-fit: contain;
  margin-left: auto;
}

/* Dashboard Content Styling */
.ClientDashboard_dashboardContent__3CxWP {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

/* Section Container Styling */
.ClientDashboard_sectionContainer__27mF9 {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ClientDashboard_sectionContainer__27mF9 h3 {
  font-family: 'Lexend Deca', sans-serif;
  color: #4758EB;
  margin-bottom: 10px;
}

.ClientDashboard_sectionContainer__27mF9 p {
  font-family: 'DM Sans', sans-serif;
  color: #292B3D;
  margin-bottom: 20px;
}

/* Button Group Styling */
.ClientDashboard_buttonGroup__18xuG {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}

/* Dashboard Button Styling */
.ClientDashboard_dashboardBtn__38tjn {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
}

.ClientDashboard_dashboardBtn__38tjn:hover {
  background-color: #848EE0 !important;
}

.ClientDashboard_dashboardBtn__38tjn:disabled {
  background-color: #d0d0d0; /* Light gray background */
  color: #a0a0a0; /* Gray text */
  cursor: not-allowed; /* Not-allowed cursor */
}

.ClientDashboard_dashboardBtn__38tjn:disabled .ClientDashboard_icon__dH-mg {
  color: #a0a0a0; /* Gray icon color */
}

.ClientDashboard_dashboardBtn__38tjn .ClientDashboard_icon__dH-mg {
  margin-right: 8px;
}

.ClientDashboard_dashboardBtn__38tjn:disabled:hover {
  background-color: #d0d0d0 !important; /* Light gray background */
}


/* Responsive Design */
@media (max-width: 768px) {
  .ClientDashboard_dashboardBtn__38tjn {
    width: 100%;
  }

  .ClientDashboard_topBar__1lFMm {
    flex-direction: column;
    align-items: flex-start;
  }

  .ClientDashboard_headerImage__2JFoQ {
    margin-left: 0;
    margin-top: 10px;
  }
}


/* Container for the entire preview page */
.Preview_previewContainer__2__zh {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: #f9f9f9;
  color: #333;
  font-family: Arial, sans-serif;
}

/* Header row: back button + title */
.Preview_headerContainer__25in7 {
  display: flex;
  align-items: center;
  margin-top: 10px;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 1rem;
}

/* Back button styling */
.Preview_backButton__2C5Kj {
  background-color: #555;
  border: none;
  color: #f0f0f0;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.Preview_backButton__2C5Kj:hover {
  background-color: #55555578 !important;
  color: #ffffff;
}

/* Title styling */
.Preview_title__2xaXH {
  font-size: 1.5rem;
  margin: 0;
  font-weight: 600;
}

/* Row for dropdown and URL input */
.Preview_inputRow__wXG8D {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  margin-bottom: 1rem;
}

/* Container for the react-select dropdown */
.Preview_selectContainer__jS96H {
  width: 180px;
}

/* URL input field styling */
.Preview_idInput__1wKrG {
  flex: 1 1;
  min-height: 38px;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 8px;
  font-size: 14px;
}

/* Generate button styling */
.Preview_generateButton__OBMFT {
  width: 100%;
  padding: 10px;
  background-color: #4758EB;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin: 1rem auto;
}
.Preview_generateButton__OBMFT:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

/* Error message styling */
.Preview_error__2Wi95 {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

/* Image warning styling */
.Preview_imageWarning__1qm3c {
  display: flex;
  align-items: center;
  background-color: #fff3cd;
  color: #856404;
  border: 1px solid #ffeeba;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 15px;
}
.Preview_warningIcon__35cw3 {
  margin-right: 10px;
  font-size: 20px;
  color: #856404;
}

/* Loading container styling */
.Preview_loadingContainer__3Gq6S {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.Preview_loadingContainer__3Gq6S p {
  margin-top: 10px;
  font-size: 16px;
  color: #555;
}

/* Attribute comparison container */
.Preview_attributeComparison__3yByS {
  margin-top: 20px;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}
.Preview_attributeComparison__3yByS h4 {
  margin-top: 0;
}

/* Desktop Version: Side-by-side table */
.Preview_comparisonTable__18zre {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}
.Preview_comparisonTable__18zre thead th {
  background-color: #f3f3f3;
  color: #333;
  text-align: left;
  padding: 10px;
  font-weight: 600;
  border-bottom: 1px solid #ddd;
}
.Preview_comparisonTable__18zre tbody td {
  padding: 10px;
  border-bottom: 1px solid #eee;
}
.Preview_attrName__1Rsts {
  font-weight: bold;
  background-color: #f9f9f9;
}
.Preview_attributeOriginal__23IPp,
.Preview_attributeGenerated__jbelU {
  background-color: #fff;
}

/* Highlight changed rows */
.Preview_changedRow__3SDSc {
  background-color: #f7fff7;
}

/* Mobile Version: Vertically stacked cards for comparison */
.Preview_mobileComparison__1OvNk {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}
.Preview_comparisonCard__3Xqod {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 12px;
}
/* Highlight changed cards (mobile) */
.Preview_changedCard__3ofWU {
  background-color: #f7fff7;
}
.Preview_cardHeader__2NL6w {
  background-color: #f1f1f1;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
}
.Preview_cardContent__2U5ok {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.Preview_cardSection__2j4P4 {
  padding: 8px;
  margin-bottom: 0;
  border-top: 1px solid #eee;
}
.Preview_beforeSection__3c64z {
  background-color: #fafafa;
}
.Preview_afterSection__2jv9l {
  background-color: #f7fff7;
}
.Preview_cardSectionTitle__nKwfN {
  font-weight: bold;
}
.Preview_cardSectionValue__2d4uZ {
  line-height: 1.4;
}

/* Responsive display classes */
.Preview_desktopOnly__2UbmL {
  display: none;
}
.Preview_mobileOnly__sBKIJ {
  display: block;
}
@media (min-width: 768px) {
  .Preview_desktopOnly__2UbmL {
    display: table;
  }
  .Preview_mobileOnly__sBKIJ {
    display: none;
  }
  .Preview_productPreviewContainer__1mMw9 {
    flex-direction: row;
    align-items: center;
  }
  .Preview_productImageWrapper__Sxwyn {
    margin-bottom: 0;
  }
}

/* Product preview container */
.Preview_productPreviewContainer__1mMw9 {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  padding: 10px;
  grid-gap: 15px;
  gap: 15px;
}
.Preview_productImageWrapper__Sxwyn {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #f9f9f9;
  border-radius: 4px;
  border: 1px solid #eee;
}
.Preview_productPreviewImage__8ZxrR {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.Preview_noImagePlaceholder__3W4Qr {
  font-size: 14px;
  color: #999;
  text-align: center;
  padding: 0 5px;
}
.Preview_productInfo__124rt {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}
.Preview_productTitle__3EnZ_ {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}
.Preview_productBrand__2Wyln {
  margin: 0;
  font-size: 0.9rem;
  color: #666;
}
.Preview_infoButton__1M36j {
  background-color: #4758EB;
  border: none;
  color: #f0f0f0;
  font-size: 18px;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  transition: background-color 0.3s, color 0.3s;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Preview_infoButton__1M36j:hover {
  background-color: #848EE0 !important;
}

/* --- Enhanced Popup Styles --- */

/* Animations */
@keyframes Preview_fadeIn__y7L-s {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes Preview_slideDown__1IIcW {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

/* Popup overlay */
.Preview_popupOverlay__2H8ul {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  animation: Preview_fadeIn__y7L-s 0.3s ease-out;
}

/* Popup content container */
.Preview_popupContent__1Hivu {
  background: #fff;
  border-radius: 8px;
  position: relative;
  max-width: 1000px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  animation: Preview_slideDown__1IIcW 0.3s ease-out;
}

/* Popup header */
.Preview_popupHeader__2qi6B {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f5f5f5;
  padding: 12px 20px;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

/* Popup title */
.Preview_popupTitle__1RK7u {
  margin: 0;
  font-size: 1.4rem;
  font-weight: 600;
  color: #333;
}

/* Close button inside popup header */
.Preview_closePopupButton__3U_e2 {
  color: white;
  background-color: #555;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.Preview_closePopupButton__3U_e2:hover {
  background-color: #55555578 !important;
  color: #ffffff;
}

/* Popup body */
.Preview_popupBody__1UT2B {
  padding: 20px;
}

/* Enhanced table styling inside the popup */
.Preview_popupDataTable__11bT5 {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
  font-size: 0.95rem;
}
.Preview_popupDataTable__11bT5 thead {
  background: #e9e9e9;
}
.Preview_popupDataTable__11bT5 thead th {
  text-align: left;
  padding: 10px;
  font-weight: 600;
  color: #333;
  border-bottom: 2px solid #ccc;
}
.Preview_popupDataTable__11bT5 tbody tr:nth-child(even) {
  background: #f9f9f9;
}
.Preview_popupDataTable__11bT5 tbody tr:hover {
  background: #f1f1f1;
}
.Preview_popupDataTable__11bT5 th,
.Preview_popupDataTable__11bT5 td {
  padding: 10px;
  color: #292B3D;
  border-bottom: 1px solid #ddd;
  overflow-wrap: break-word;
  word-break: break-word;
}
.Preview_dataKey__lj_Xh {
  width: 30%;
  font-weight: bold;
  color: #333;
}
.Preview_dataValue__RcWTj {
  width: 70%;
  color: #555;
}

/* Ensure the unordered list inside the dataValue wraps properly */
.Preview_popupDataTable__11bT5 .Preview_dataValue__RcWTj ul {
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;
}

/* Wrap the preview & optimization summary side by side on desktop */
.Preview_previewAndOptimizationRow__3VwzA {
  display: flex;
  flex-direction: column; /* default: stack in a column (mobile) */
  grid-gap: 20px;
  gap: 20px;
}
@media (min-width: 768px) {
  .Preview_previewAndOptimizationRow__3VwzA {
    flex-direction: row;
    align-items: stretch;
  }
}

/* Product Preview and Optimization Summary containers share a consistent look */
.Preview_productPreviewContainer__1mMw9 {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  padding: 10px;
  grid-gap: 15px;
  gap: 15px;
  display: flex;
  align-items: center;

  flex: 1 1 50%;
}

.Preview_optimizationSummary__37nx8 {
  flex: 1 1 50%;
  background-color: #fff;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}
.Preview_optimizationSummary__37nx8 h4 {
  margin-top: 0;
  margin-bottom: 10px;
}

/* Circle chart container + text */
.Preview_optimizationScoreContainer__lx5CW {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  flex-wrap: wrap;
}
.Preview_optimizationScoreSvg__ep6iC {
  width: 80px;
  height: 80px;
  overflow: visible;
}
.Preview_optimizationScoreBg__1oKn9 {
  fill: none;
  stroke: #eee;
  stroke-width: 8;
}
.Preview_optimizationScoreProgress__2Jv29 {
  fill: none;
  stroke: #5CD685;
  stroke-width: 8;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.Preview_optimizationScoreText__29cSe {
  font-size: 16px;
  font-weight: bold;
  fill: #333;
}
.Preview_optimizationScoreTextContainer__UbW-y {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}
.Preview_optimizationBreakdown__2plr8 {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.Preview_comparisonInfo__eYPGN {
  margin-top: 20px;
  background-color: #f4f4f4;
  border-left: 4px solid #4758EB;
  padding: 10px 15px;
  margin-bottom: 20px;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #555;
  font-style: italic;
}

.Preview_comparisonInfo__eYPGN p {
  margin: 0 0 8px;
  line-height: 1.4;
}

body {
  background-color: #F3F3F7;
  font-family: 'DM Sans', sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

#app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100vw;
}

#app-container.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.config-button, .logout-button, button {
  background-color: #6c7bbf;
  color: white;
  border: none;
  padding: 8px 12px;
  margin-top: 5px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.config-button:hover, .logout-button:hover, button:hover:not(:disabled) {
  background-color: #565fb3;
}

button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.main-content {
  width: 100%;
  max-width: 98%;
  padding: 20px;
}

.column-section {
  background: #FFF;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.list-container {
  display: flex;
  align-items: stretch;
  grid-gap: 20px;
  gap: 20px;
}

.scrollable-list-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  min-width: 280px;
}

.scrollable-list-container h3 {
  font-size: 18px;
  font-family: 'Lexend Deca', sans-serif;
  color: #292B3D;
  margin-bottom: 10px;
}

.scrollable-list {
  flex: 1 1;
  overflow-y: auto;
  max-height: 250px;
  background-color: #ffffff;
  border: 1px solid #E0E1EB;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.scrollable-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.scrollable-list li {
  display: flex;
  align-items: center;
  padding: 6px;
  margin-bottom: 2px;
  background-color: #F3F3F7;
  border: 1px solid #E0E1EB;
  border-radius: 6px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #292B3D;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.scrollable-list li:hover {
  background-color: #E0E1EB;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}

.scrollable-list li.selected {
  background-color: #4758EB;
  color: #ffffff;
  border-color: #4758EB;
}

.scrollable-list li.selected:hover {
  background-color: #3949ab;
}

.scrollable-list li img {
  margin-left: auto;
  width: 20px;
  height: 20px;
}

.scrollable-list li .column-name {
  flex-grow: 1;
}

.scrollable-list li .icon {
  margin-right: 10px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.table-controls {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 600px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-family: 'Lexend Deca', sans-serif;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.button-group-column-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}

.button-group-column-selection button {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  width: 40px;
  height: 40px;
}

.button-group-column-selection button:hover {
  background-color: #6c7bbf;
}

.button-group-column-selection button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

th, td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  background: #848EE0;
  color: white;
  position: relative;
  top: 0;
  z-index: 1;
  font-weight: 500;
}

td input[type="text"], td textarea {
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

td textarea {
  resize: vertical;
  min-height: 50px;
}

.editable-textarea {
  display: block;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  min-height: 50px;
  max-height: 200px;
  resize: vertical;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  background-color: #f9f9f9;
  overflow-x: hidden;
  transition: border-color 0.3s, background-color 0.3s;
}

.editable-textarea:hover{
  border-color: #848EE0;
}

.editable-textarea:focus {
  outline: none;
  border-color: #848EE0;
  background-color: #f9f9fe;
}

button:disabled:hover {
  background: #ccc;
  cursor: not-allowed;
}

.field-selection {
  margin-top: 20px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.field-selection h3 {
  margin-bottom: 10px;
}

.field-selection {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 10px;
}

.field-list {
  list-style: none;
  padding: 0;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  border: 0;
  border-radius: 5px;
  margin-bottom: 20px;
}

.field-list li:last-child {
  border-bottom: none;
}

.field-list li.selected {
  background-color: #848EE0;
  color: white;
}

.field-list li.selected:hover {
  background-color: #6b76d5;
}

.field-list li:hover {
  background-color: #e0e0e0;
}

.field-selection-collapsible {
  margin-top: 20px;
  margin-bottom: 50px;
}

.field-selection-toggle {
  display: none;
}

.field-selection-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
}

.collapsible-icon::before {
  content: '+';
  margin-left: 10px;
  font-size: 22px;
}

.field-selection-content {
  display: none;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-top: 10px;
}

.field-selection-toggle:checked + .field-selection-label .collapsible-icon::before {
  content: '-';
}

.field-selection-toggle:checked + .field-selection-label + .field-selection-content {
  display: block;
}

.logout-button {
  background-color: #ff4d4d;
  border: none;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 5px;
}

.logout-button:hover {
  background-color: #d43d3d;
}

.global-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.global-loading-spinner {
  background: white;
  padding: 20px;
  border-radius: 50%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

body.resizing {
  -webkit-user-select: none;
          user-select: none;
  cursor: col-resize;
}

td input[type="checkbox"] {
  width: auto;
  margin: 0;
  transform: scale(1.2);
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.table-container {
  margin-bottom: 80px;
  overflow-x: auto;
  display: block;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: auto;
  position: relative;
}

.full-width-table th, .full-width-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  position: relative;
}

.full-width-table {
  width: max-content;
}

.full-width-table th {
  background: #4758EB;
  color: white;
}

th div, td div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

th div div,
td div div {
  width: 10px;
  height: 100%;
  cursor: col-resize;
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent;
  z-index: 1;
}

.ai-generated-icon {
  margin-left: 5px;
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.saving {
  position: relative;
  background-color: #f3f3f3;
}

.saving:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-image: url('https://storage.googleapis.com/quantum-feed-engine/qfe_toolbox/media/loading.gif');
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 10;
}

.replace-input-group {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
}

.replace-input-group label {
  font-size: 16px;
  font-weight: bold;
}

.replace-input-group input,
.replace-input-group select {
  flex: 1 1;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s;
}

.replace-input-group input:focus,
.replace-input-group select:focus {
  border-color: #848ee0;
  outline: none;
}

.replace-input-group select {
  width: auto;
  min-width: 150px;
}

.replace-case-sensitive {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 10px;
}

.replace-case-sensitive label {
  font-weight: bold;
  font-size: 16px;
}

.replace-case-sensitive input[type="radio"] {
  margin-right: 5px;
  transform: scale(1.2);
  accent-color: #848ee0;
}

button { 
  padding: 6px 12px; 
  margin: 5px; 
  background-color: #848EE0; 
  color: white; 
  border: none; 
  cursor: pointer; 
  font-size: 0.8rem; 
  border-radius: 5px; 
  white-space: nowrap;
}

input, select { 
  padding: 6px; 
  margin: 5px; 
  border: 1px solid #ccc; 
  width: 100%; 
  box-sizing: border-box; 
  font-size: 0.8rem;
  border-radius: 5px;
}

th, td { 
  border: 1px solid #E0E1EB;
  padding: 10px;
  text-align: left;
  font-size: 0.8rem;
}
/*
li {
  padding: 8px;
  margin-bottom: 4px;
  background: #F3F3F7;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  max-height: 10px;
  font-size: 0.8rem;
}
*/
li.selected {
  background: #848EE0;
  color: #fff;
}

li img {
  margin-left: 5px;
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.field-list li {
  padding: 10px;
  margin: 0;
  background-color: #f3f3f3;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  display: flex;
  align-items: center;
}

img, video {
  max-width: 100px;  
  max-height: 100px; 
  width: auto;       
  height: auto;      
  object-fit: contain;
  margin-right: 5px;
}

table {
  width: auto; 
  margin-top: 20px;
  border-collapse: collapse;
  background: white; 
  border: 1px solid #E0E1EB; 
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  border-radius: 5px;
  overflow: hidden;
}

.PromptbuilderButton {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  margin-right: -5px;
}

.PromptbuilderButton:hover {
  background-color: #848EE0 !important;
}

.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  margin-top: 20px;
  margin-right: 18px;
  margin-left: 18px;
  background-color: #ffffff;
  color: #4758EB;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: nowrap;
  box-sizing: border-box; 
  align-self: stretch; 
}

.left-section, .right-section {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.left-section {
  flex: 1 1;
}

.right-section {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.client-select {
  min-width: 200px;
}

.header-text-container {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  min-width: 250px;
}

.feed-viewer-version {
  font-size: 14px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
  color: #4758EB;
}

.header-subtitle {
  font-size: 22px;
  font-family: 'DM Serif Text', serif;
  color: #292B3D;
}

.header-image {
  height: 100px;
  width: auto;
  padding-top: 20px;
  object-fit: contain;
  margin-left: auto;
}

.back-button {
  background-color: #d0d0d0;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.back-button:hover {
  background-color: #7c7c7c !important;
}

.logout-button {
  background-color: #E0858C;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.logout-button:hover {
  background-color: #EB4755 !important;
}

.refresh-button {
  background-color: transparent;
  color: #d0d0d0;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.refresh-button:hover {
  color: #4758EB;
  background-color: #ffffff !important;
}

.config-button {
  background-color: transparent;
  color: #d0d0d0;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.config-button:hover {
  color: #4758EB;
  background-color: #ffffff !important;
}

.credit-info {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  font-size: 20px;
  color: #292B3D;
  margin-left: 20px;
}

.credit-info span {
  font-size: 12px;
  font-family: 'Lexend Deca', sans-serif;
  color: #d0d0d0;
}

.credit-info i {
  color: #d0d0d0;
}

.no-client-selected {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  text-align: center;
  color: #555;
}

.no-client-selected h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.no-client-selected p {
  font-size: 16px;
  max-width: 400px;
}

.selected-columns-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.selected-columns-header h3 {
  margin: 0;
  margin-right: 1rem;
}

.column-configuration {
  display: flex;
  align-items: center;
}

.configuration-select {
  min-width: 150px;
  max-width: 200px;
  margin-right: 0.5rem;
}

.addColConfigButton {
  background-color: #5CD685;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  width: 20px;
  height: 20px;
}

.addColConfigButton:hover {
  background-color: #94D1A8 !important;
}

.addColConfigButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.RmColConfigButton {
  background-color: #EB4755;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  width: 20px;
  height: 20px;
}

.RmColConfigButton:hover {
  background-color: #E0858C !important;
}

.RmColConfigButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.filter-section:not(.hidden) .filter-box {
  display: flex;
  flex-direction: column;
  grid-gap: 0px;
  gap: 0px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  font-family: 'Lexend Deca', sans-serif;
}

.filter-group {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  margin-bottom: 0px;
  margin-top: 10px;
}

.filter-group select,
.filter-group input[type="text"] {
  padding: 8px;
  border: 1px solid #e0e1eb;
  border-radius: 4px;
  font-family: 'Lexend Deca', sans-serif;
  transition: border-color 0.3s;
  font-size: 12px;
  background-color: #f4f4f4;
}

.filter-group select:focus,
.filter-group input[type="text"]:focus {
  border-color: #4758EB;
}

.filter-remove-button {
  background-color: #EB4755;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 20px;
  height: 20px;
  font-size: 10px!important;
  transition: background-color 0.3s;
}

.filter-remove-button:hover {
  background-color: #E0858C !important;
}

.filter-add-button {
  background-color: #5CD685 !important;
  color: #ffffff !important;
  border: none !important;
  border-radius: 50% !important;
  cursor: pointer !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 40px !important;
  height: 40px !important;
  transition: background-color 0.3s !important;
}

.filter-add-button:hover {
  background-color: #94D1A8 !important;
}

.filter-action-button {
  background-color: #4758EB !important;
  color: #ffffff !important;
  border: none !important;
  border-radius: 50% !important;
  cursor: pointer !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 40px !important;
  height: 40px !important;
  transition: background-color 0.3s !important;
  padding: 0 !important;
}

.filter-action-button:hover {
  background-color: #848EE0 !important;
}

.order-by-select {
  min-width: 80px;
  max-width: 200px;
  padding-left: 8px;
  border: 1px solid #e0e1eb !important;
  border-radius: 4px !important;
  font-family: 'Lexend Deca', sans-serif !important;
  background-color: #f4f4f4 !important;
  transition: border-color 0.3s !important;
  font-size: 12px !important;
}

.order-by-select:focus {
  border-color: #4758EB !important;
}

.filter-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 10px;
  font-family: 'Lexend Deca', sans-serif;
}

.filter-actions label {
  margin-right: 10px;
}

.filter-actions label:last-of-type {
  margin-right: auto;
}

.filter-actions button {
  margin-left: 0px;
}

.nav-button.icon-button {
  background-color: #4758EB;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  width: 26px;
  height: 26px;
}

.nav-button.icon-button:hover {
  background-color: #565fb3;
}

.nav-button.icon-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.pagination-container {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.pagination-inputs {
  display: flex;
  align-items: center;
  font-size: 12px;
  grid-gap: 10px;
  gap: 10px;
}

.pagination-label {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.page-input {
  min-width: 10px;
  max-width: 100px;
  text-align: center;
}

.input-select,
.input-text {
  font-size: 12px;
  font-family: 'Lexend Deca', sans-serif;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px 6px;
  transition: border-color 0.3s;
  outline: none;
}

.input-text {
  width: 40px;
  text-align: center;
}

.input-select:focus,
.input-text:focus {
  border-color: #565fb3;
}

.pagination-total {
  font-size: 12px;
  color: #666;
}

.pagination-buttons {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 10px;
}

.action-button {
  background-color: #4758EB;
  color: white;
  border: none;
  padding: 8px 16px;
  margin: 5px;
  border-radius: 40px;
  font-family: 'Lexend Deca', sans-serif;
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}

.action-button:hover:not(:disabled) {
  background-color: #565fb3;
}

.action-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.publish-button {
  background-color: #4758EB;
  color: white;
  border: none;
  font-family: 'Lexend Deca', sans-serif;
  padding: 8px 16px;
  margin: 5px;
  border-radius: 40px;
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}

.publish-button:hover:not(:disabled) {
  background-color: #565fb3;
}

.publish-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.download-button {
  background-color: #d0d0d0;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  size: 6px;
  padding: 11px;
  font-size: 10px;
}

.download-button:hover:not(:disabled) {
  background-color: #7c7c7c;
}

.download-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.full-width-table-container {
  margin-top: 20px;
}

.toolbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  grid-gap: 16px;
  gap: 16px;
}

.button-group {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

/* Updated progress bar container to ensure the progress bar grows */
.progress-info.running-progress .progress-details {
  flex: 1 1;
}
.progress-status-container {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  width: 100%;
  width: 300px; /* Adjust as needed */
}

.progress-bar-container {
  flex: 1 1;
  background-color: #e0e0e0;
  border-radius: 6px;
  height: 10px;
  overflow: hidden;
  position: relative;
}

.progress-bar {
  background-color: #ff9800;
  height: 100%;
  transition: width 0.4s ease;
}

.progress-info-container {
  flex-shrink: 0;
}

.progress-counter {
  font-size: 0.85rem;
  color: #333;
  white-space: nowrap;
}


.progress-info {
  display: flex;
  align-items: center;
  min-width: 350px;
  max-width: 100%;
  grid-gap: 8px;
  gap: 8px;
  color: #333;
  margin-bottom: 4px;
}

.status-icon {
  font-size: 18px;
  color: #4758EB;
}

.cancel-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: #EB4755;
  color: white;
  border: none;
  padding: 4px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 10px;
  transition: background-color 0.3s, transform 0.2s;
}

.cancel-button:hover {
  background-color: #E0858C !important;
  transform: scale(1.1);
}

.cancel-button:active {
  transform: scale(0.95);
}

.cancel-button:disabled {
  background-color: #e0aeb1;
  cursor: not-allowed;
  transform: none;
}

.progress-info.running-progress {
  position: relative;
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  background-color: #fff8e1;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.progress-info.running-progress .progress-details strong {
  display: block;
  font-size: 1rem;
  color: #ff9800;
}

.progress-info.running-progress .progress-details span {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 6px;
}

.progress-bar-container .progress-bar {
  background-color: #ff9800;
}

.progress-info.completed-progress {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  background-color: #E0EBE4;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.progress-info.completed-progress .progress-details strong {
  display: block;
  font-size: 1rem;
  color: #5CD685;
}

.progress-info.completed-progress .progress-details span {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 6px;
}

.progress-info.cancelled-progress {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  background-color: #ffebee;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.progress-info.cancelled-progress .progress-details strong {
  display: block;
  font-size: 1rem;
  color: #f44336;
}

.progress-info.cancelled-progress .progress-details span {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 6px;
}

.progress-info.error-progress {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  background-color: #ffebee;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.progress-info.error-progress .progress-details strong {
  display: block;
  font-size: 1rem;
  color: #f44336;
}

.progress-info.error-progress .progress-details span {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 6px;
}

.progress-info.timeout-progress {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  background-color: #fff3e0;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.progress-info.timeout-progress .progress-details strong {
  display: block;
  font-size: 1rem;
  color: #ff9800;
}

.progress-info.timeout-progress .progress-details span {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 6px;
}

.progress-info.unknown-progress {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  background-color: #e3f2fd;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.progress-info.unknown-progress .progress-details strong {
  display: block;
  font-size: 1rem;
  color: #2196f3;
}

.progress-info.unknown-progress .progress-details span {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 6px;
}

.progress-info.no-progress {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  background-color: #e0e0e0;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.progress-info.no-progress .progress-details strong {
  display: block;
  font-size: 1rem;
  color: #757575;
}

.progress-info.no-progress .progress-details span {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 6px;
}

.running-progress .status-icon {
  color: #ffa500;
}

.completed-progress .status-icon {
  color: #5CD685;
}

.cancelled-progress .status-icon,
.error-progress .status-icon,
.timeout-progress .status-icon {
  color: #f44336;
}

.unknown-progress .status-icon {
  color: #2196f3;
}

.no-progress .status-icon {
  color: #757575;
}

/* Overlay Styles */
.PopupRowData_popup-overlay__sknYI {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Lexend Deca', sans-serif;
}

/* Popup Inner Container */
.PopupRowData_popup-inner__1hjek {
    background: #ffffff;
    max-width: 1200px; 
    width: 90%;
    padding: 20px;
    position: relative;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 80vh;
    overflow-y: auto;
}

/* Override any conflicting styles by increasing specificity */
.PopupRowData_popup-editable-textarea__2iopk.PopupRowData_description-textarea__Fz9ft {
    min-height: 200px !important; /* Use !important as a last resort */
    height: auto !important;
}

/* Close Button */
.PopupRowData_new-popup-close-button__1Qcsv {
    position: absolute;
    top: 20px;
    right: 20px;
    border: none;
    background: #E0858C;
    color: #ffffff;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    cursor: pointer;
    font-size: 20px;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.PopupRowData_new-popup-close-button__1Qcsv:hover {
    background-color: #EB4755 !important;
}

/* Header Section */
.PopupRowData_popup-header__6OfYy {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    color: #292B3D;
    font-family: 'Lexend Deca', sans-serif;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.PopupRowData_popup-preview__2i1ll {
    display: flex;
    align-items: flex-start;
    grid-gap: 20px;
    gap: 20px;
}

.PopupRowData_popup-preview-image__3gbh6 img {
    max-width: 120px;
    border-radius: 8px;
    object-fit: contain;
}

.PopupRowData_popup-preview-details__3taXE p {
    margin: 5px 0;
    font-size: 14px;
    color: #292B3D;
}

.PopupRowData_popup-controls__tq4fl {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.PopupRowData_popup-save-button__2VPd1 {
    background: #4758EB;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 14px;
    transition: background-color 0.3s;
}

.PopupRowData_popup-save-button__2VPd1:hover {
    background-color: #848EE0 !important;
}

/* Generate QFE Fields Section */
.PopupRowData_field-selection-container__20bB- {
    margin-top: 20px;
    background-color: #f9f9fc;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #ddd;
}

.PopupRowData_field-selection-header__2Yiq8 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.PopupRowData_field-selection-header__2Yiq8 h3 {
    margin: 0;
    font-size: 16px;
    color: #292B3D;
    font-weight: 600;
}

.PopupRowData_field-selection-actions__5abV2 {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.PopupRowData_field-selection-actions__5abV2 button {
    background-color: #4758EB;
    color: #ffffff;
    border: none;
    padding: 8px 12px;
    border-radius: 20px;
    cursor: pointer;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 12px;
    transition: background-color 0.3s;
}

.PopupRowData_field-selection-actions__5abV2 button:hover {
    background-color: #848EE0 !important;
}

.PopupRowData_field-selection-actions__5abV2 select {
    font-size: 12px;
    padding: 5px 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-family: 'Lexend Deca', sans-serif;
}

.PopupRowData_popup-generate-button__2p9aH {
    background: #4758EB;
    color: #ffffff;
    border: none;
    padding: 8px 16px;
    border-radius: 20px;
    cursor: pointer;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 14px;
    transition: background-color 0.3s;
}

.PopupRowData_popup-generate-button__2p9aH:hover {
    background-color: #848EE0 !important;
}

.PopupRowData_field-selection-list__3vXrl {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
}

.PopupRowData_field-item__ikHgr {
    background: #ffffff;
    border: 1px solid #ccc;
    padding: 6px 10px;
    border-radius: 8px;
    font-size: 12px;
    font-family: 'Lexend Deca', sans-serif;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.PopupRowData_field-item__ikHgr.PopupRowData_selected__2gJ1y {
    background: #4758EB;
    color: #ffffff;
}

.PopupRowData_field-item__ikHgr:hover {
    background-color: #e0e1eb;
}

.PopupRowData_field-item__ikHgr input[type="checkbox"] {
    margin: 0;
}


/* Row Details Section */
.PopupRowData_new-row-details__2e1qY {
    margin-top: 20px;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 14px;
    color: #292B3D !important;
}

.PopupRowData_new-row-detail__1e2Pa {
    display: flex;
    width: 100%; /* Ensure full width */
    margin-bottom: 10px;
    background-color: #ffffff;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

/* Equal Widths for Left and Right Sections */
.PopupRowData_new-row-detail-left__1PN0C {
    width: 45%;
    margin: 0;
    color: #292B3D;
    padding-right: 20px;
    word-break: break-word;
}

.PopupRowData_new-row-detail-left__1PN0C strong {
    color: #292B3D;
}

.PopupRowData_new-row-detail-right__1nE9m {
    width: 55%;
    margin: 0;
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
}

.PopupRowData_new-row-detail-right__1nE9m strong {
    color: #292B3D;
}

/* Editable Textarea */
.PopupRowData_popup-editable-textarea__2iopk {
    width: 100%;
    min-height: 50px;
    resize: vertical;
    font-family: 'Lexend Deca', sans-serif;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px;
    transition: border-color 0.3s;
}

.PopupRowData_popup-editable-textarea__2iopk:focus {
    border-color: #4758EB;
}

/* Toggle Button */
.PopupRowData_toggle-button__2TISz {
    margin-left: 10px;
    padding: 0.5em 1em;
    cursor: pointer;
    font-family: 'Lexend Deca', sans-serif;
    border-radius: 20px;
    border: none;
    width: 110px;
    text-align: center;
    transition: background-color 0.3s, opacity 0.3s;
}

.PopupRowData_toggle-button__2TISz.PopupRowData_active__3ahtN {
    background: #94D1A8;
    color: #ffffff;
}

.PopupRowData_toggle-button__2TISz.PopupRowData_deactivate__1EOtx {
    background: #f8eec0;
    color: #292B3D;
}

.PopupRowData_toggle-button__2TISz.PopupRowData_active__3ahtN:hover {
    background: #94D1A8;
    color: #ffffff;
    opacity: 0.7 !important;
}

.PopupRowData_toggle-button__2TISz.PopupRowData_deactivate__1EOtx:hover {
    background: #f1e5ad;
    color: #292B3D;
    opacity: 0.7 !important;
}

.PopupRowData_toggle-button__2TISz.PopupRowData_loading__3IpVe {
    cursor: not-allowed;
    opacity: 0.6;
}

/* Loading States */
.PopupRowData_saving__2_h35 {
    opacity: 0.6;
}
  

.PopupRowData_popup-success__QHPg5 {
    flex: 1 1;
    background-color: #e6ffed; /* A light green background */
    color: #256029;            /* A deep green for clear readability */
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    border: 1px solid #a3d9a5; /* A gentle border to match the background */
    border-radius: 4px;
    margin-right: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, color 0.3s;
  }

/* Warning Overlay */
.PopupRowData_popup-warning-overlay__2BOHk {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Lexend Deca', sans-serif;
}

.PopupRowData_popup-warning-inner__1Hovl {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
}

.PopupRowData_popup-warning-inner__1Hovl p {
    margin-bottom: 20px;
    font-size: 14px;
    color: #292B3D;
}

.PopupRowData_popup-warning-inner__1Hovl button {
    background: #4758EB;
    color: #ffffff;
    border: none;
    padding: 8px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-family: 'Lexend Deca', sans-serif;
    margin: 0 10px;
    font-size: 14px;
    transition: background-color 0.3s;
}

.PopupRowData_popup-warning-inner__1Hovl button:hover {
    background-color: #848EE0 !important;
}

/* General Button Styles */
.PopupRowData_popup-save-button__2VPd1,
.PopupRowData_popup-generate-button__2p9aH,
.PopupRowData_popup-warning-inner__1Hovl button {
    width: 100px;
}

.PopupRowData_toggle-button__2TISz,
.PopupRowData_popup-save-button__2VPd1,
.PopupRowData_popup-generate-button__2p9aH,
.PopupRowData_popup-warning-inner__1Hovl button {
    height: 35px;
}

.PopupRowData_save-messages__1f13R {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    margin-right: 10px;
  }
/* Sticky Save Button Container */
.PopupRowData_sticky-save-container__37IY5 {
    position: sticky;
    bottom: 0;
    background-color: #ffffff;
    padding: 10px;
    border-top: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
  }
  /* Enhanced styling for unsaved changes message */
  .PopupRowData_popup-unsaved__1P9ZZ {
    flex: 1 1;
    background-color: #fff4e5; /* A soft amber background */
    color: #a15d0a;            /* A darker amber for good contrast */
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    border: 1px solid #ffcc80; /* A subtle border to emphasize the message */
    border-radius: 4px;
    margin-right: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, color 0.3s;
  }

/* Separate Class for the Save Button */
.PopupRowData_sticky-save-button__jr8-7 {
    background: #5CD685;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    width: 130px;
    border-radius: 20px;
    cursor: pointer;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 14px;
    transition: background-color 0.3s, box-shadow 0.3s;
    /* Remove fixed width */
    /* width: 140px; */ /* This line is removed */
    display: flex;
    align-items: center;
    justify-content: center;
}

.PopupRowData_sticky-save-button__jr8-7:hover {
    background-color: #94D1A8 !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.PopupRowData_sticky-save-button__jr8-7:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}


/* Optional: Smooth scrolling when content is behind the sticky button */
.PopupRowData_new-popup-inner__3rg_o {
    overflow-y: auto;
    padding-bottom: 0px; /* Ensure content isn't hidden behind the sticky button */
}


.PopupRowData_other-feed-container__r3_zI {
    -webkit-user-select: text !important;
            user-select: text !important;
    background-color: #f9f9fc;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #ddd;
    margin-top: 20px;
  }
  
  .PopupRowData_other-feed-header__NsyMd {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .PopupRowData_copy-button__2Adi- {
    background-color: #4758EB;
    color: #ffffff;
    border: none;
    padding: 5px 10px;
    border-radius: 20px;
    cursor: pointer;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 12px;
    transition: background-color 0.3s;
  }
  
  .PopupRowData_copy-button__2Adi-:hover {
    background-color: #848EE0 !important;
  }
  
  .PopupRowData_other-feed-list__1Psuo {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
  }
  
  .PopupRowData_other-feed-row__3HTR5 {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 5px;
    gap: 5px;
  }
  
  .PopupRowData_other-feed-key__1SnzO {
    font-weight: 600;
    color: #292B3D;
  }
  
  .PopupRowData_other-feed-value__A-Le9 {
    color: #555;
  }
  
.PopupTextReplace_popupOverlay__iAfN5 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Lexend Deca', sans-serif;
}

.PopupTextReplace_popupContainer__3ramF {
  background: #ffffff;
  width: 90%;
  max-width: 700px;
  padding: 30px;
  position: relative;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 90vh;
}

.PopupTextReplace_closeButton__1F7e- {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: #E0858C;
  color: #ffffff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  cursor: pointer;
  font-size: 20px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.PopupTextReplace_closeButton__1F7e-:hover {
  background-color: #EB4755 !important;
}

.PopupTextReplace_header__2zDmX {
  text-align: left;
  margin-bottom: 20px;
}

.PopupTextReplace_title__vH1qJ {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  color: #333;
}

.PopupTextReplace_body__1PTHl {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 20px;
  color: #292B3D;
}

.PopupTextReplace_instructions__cCmcM {
  font-size: 14px;
  color: #555;
  margin: 0;
  line-height: 1.4;
}

.PopupTextReplace_inputGroup__3Yucz {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  align-items: baseline;
}

.PopupTextReplace_field__3Ql3m {
  display: flex;
  align-items: center; /* This aligns the input and label in the center */
  flex: 1 1;
  min-width: 120px;
}


.PopupTextReplace_fieldLabel__2IlHX {
  font-size: 14px;
  font-weight: 600;
  color: #292B3D;
  margin-bottom: 5px;
}

.PopupTextReplace_input__3g8Uy {
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s;
  width: 100%;
  height: 40px; /* Ensure consistent height */
  box-sizing: border-box;
}

.PopupTextReplace_input__3g8Uy:focus {
  border-color: #848EE0;
  outline: none;
}

.PopupTextReplace_select__31bL9 {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s;
  height: 40px; /* Ensure consistent height */
  box-sizing: border-box;
}

.PopupTextReplace_select__31bL9:focus {
  border-color: #848EE0;
  outline: none;
}

.PopupTextReplace_withLabel__3Bm0H {
  font-size: 14px;
  color: #292B3D;
  font-weight: 500;
  margin: 0 5px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.PopupTextReplace_caseSensitiveGroup__2CHYR {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
}

.PopupTextReplace_caseSensitiveLabel__w7-in {
  font-weight: 600;
  font-size: 14px;
  color: #292B3D;
}

.PopupTextReplace_radioLabel__rftx2 {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #292B3D;
  grid-gap: 5px;
  gap: 5px;
  cursor: pointer;
}

.PopupTextReplace_radioLabel__rftx2 input[type="radio"] {
  margin: 0;
  transform: scale(1.2);
  accent-color: #848EE0;
}

/* Footer Section */
.PopupTextReplace_footer__25sTA {
  border-top: 1px solid #ddd;
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PopupTextReplace_updateMessageContainer__DQFlM {
  min-width: 150px; /* Reserve space to prevent shifting */
}

.PopupTextReplace_updateMessage__2MJie {
  font-size: 14px;
  color: #292B3D;
  font-weight: 600;
}

.PopupTextReplace_buttons__fO0B_ {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.PopupTextReplace_replaceButton__3FIKH {
  background: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PopupTextReplace_replaceButton__3FIKH:hover {
  background-color: #848EE0 !important;
}

.PopupTextReplace_replaceButton__3FIKH:disabled {
  background-color: #ccc !important;
  cursor: not-allowed;
}

.PopupTextBulkActions_popupOverlay__253mv {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    font-family: 'Lexend Deca', sans-serif;
}

.PopupTextBulkActions_popupInner__Hpugx {
    background: #fff;
    padding: 30px 40px;
    border-radius: 8px;
    width: 60%;
    max-width: 800px;
    position: relative;
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
    overflow-y: auto;
    max-height: 90vh;
}

.PopupTextBulkActions_title__8mFUc {
    font-size: 22px;
    font-family: 'DM Serif Text', serif;
    color: #292B3D;
    margin-bottom: 25px;
}

.PopupTextBulkActions_segmentedControl__5mmLF {
    display: flex;
    background-color: #F0F0F5;
    border-radius: 24px;
    overflow: hidden;
    margin-bottom: 25px;
}

.PopupTextBulkActions_segmentButton__2bTyP {
    flex: 1 1;
    border: none;
    background: transparent;
    padding: 12px 0;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 16px;
    color: #555;
    cursor: pointer;
    transition: background 0.3s;
    border-radius: 24px;
    text-align: center;
}

.PopupTextBulkActions_segmentButton__2bTyP:hover {
    background-color: #E0E1EB !important;
}

.PopupTextBulkActions_activeSegment__2kJM7 {
    background-color: #4758EB;
    color: #fff;
    border-radius: 24px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.PopupTextBulkActions_description__2RSPw {
    font-size: 14px;
    color: #555;
    margin-bottom: 25px;
}

.PopupTextBulkActions_subTitle__3aSfp {
    font-size: 16px;
    font-weight: 600;
    color: #292B3D;
    margin-bottom: 12px;
    font-family: 'DM Sans', sans-serif;
}

.PopupTextBulkActions_fieldSelectionSection__2B216 {
    margin-bottom: 25px;
}

.PopupTextBulkActions_fieldsHeader__1zrOt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.PopupTextBulkActions_fieldsControls__2Lop9 {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    font-size: 14px;
}

.PopupTextBulkActions_selectAllButton__3C6zG {
    background-color: #f0f0f5;
    border: 1px solid #ccc;
    border-radius: 24px;
    padding: 8px 16px;
    font-size: 14px;
    font-family: 'Lexend Deca', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
    color: #292b3d;
    font-weight: 600;
    text-align: center;
    -webkit-user-select: none;
            user-select: none;
}

.PopupTextBulkActions_selectAllButton__3C6zG:hover {
    background-color: #e0e1eb !important;
    border-color: #aaa;
}

.PopupTextBulkActions_selectedCount__2LtxJ {
    font-size: 14px;
    color: #555;
}

.PopupTextBulkActions_fieldSearchContainer__1TnAg {
    margin-bottom: 15px;
}

.PopupTextBulkActions_fieldSearchInput__L1o5Q {
    width: 100%;
    padding: 10px 12px;
    border: 1px solid #E0E1EB;
    border-radius: 4px;
    font-size: 14px;
    font-family: 'Lexend Deca', sans-serif;
}

.PopupTextBulkActions_fieldsGrid__292KJ {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 12px;
    gap: 12px;
    padding: 12px;
    border: 1px solid #E0E1EB;
    border-radius: 4px;
    background: #FAFAFA;
    max-height: 300px;
    overflow-y: auto;
}

.PopupTextBulkActions_fieldItem__1LJRB {
    position: relative;
    display: block; /* Make label a block-level element */
    cursor: pointer; /* Change cursor to pointer for better UX */
    padding: 0; /* Remove padding to let .fieldName handle it */
}

.PopupTextBulkActions_fieldName__3HUcl {
    display: flex; /* Use flex to center content */
    align-items: center;
    padding: 8px 12px; /* Add padding for clickable area */
    border: 1px solid transparent;
    border-radius: 4px;
    background: #fff;
    font-size: 14px;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s;
    width: 100%; /* Make .fieldName take full width */
    box-sizing: border-box;
    position: relative; /* To ensure pseudo-elements are positioned correctly */
}

.PopupTextBulkActions_fieldName__3HUcl::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    pointer-events: none; /* Ensure the pseudo-element doesn't block clicks */
}

.PopupTextBulkActions_fieldItem__1LJRB input[type=checkbox] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.PopupTextBulkActions_fieldItem__1LJRB input[type=checkbox]:checked + .PopupTextBulkActions_fieldName__3HUcl {
    background-color: #4758EB;
    color: #fff;
    border-color: #4758EB;
}

.PopupTextBulkActions_fieldItem__1LJRB:hover .PopupTextBulkActions_fieldName__3HUcl {
    background-color: #f0f0f5;
}

.PopupTextBulkActions_noResults__1rGyZ {
    grid-column: 1 / -1;
    text-align: center;
    color: #999;
    font-size: 14px;
}

.PopupTextBulkActions_scopeSection__13jbk,
.PopupTextBulkActions_promptSection__F8U-X,
.PopupTextBulkActions_stateChoiceSection__1-zi6 {
    margin-bottom: 25px;
}

.PopupTextBulkActions_scopeSelector__2SUdl {
    display: flex;
    grid-gap: 12px;
    gap: 12px;
    margin-top: 12px;
}

.PopupTextBulkActions_scopeButton__1nYmD {
    border: 1px solid #ccc;
    background: #fff;
    color: #292B3D;
    border-radius: 24px;
    padding: 8px 16px;
    font-size: 14px;
    font-family: 'Lexend Deca', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    text-align: center;
    min-width: 120px;
}

.PopupTextBulkActions_scopeButton__1nYmD:hover {
    background-color: #E0E1EB !important;
    color: #292B3D;
}

.PopupTextBulkActions_activeScope__36dfs {
    background-color: #4758EB;
    color: #fff;
    border: none;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.PopupTextBulkActions_dropdown__1rfuN {
    width: 100%;
    padding: 10px 12px;
    font-size: 14px;
    border: 1px solid #E0E1EB;
    border-radius: 4px;
    font-family: 'Lexend Deca', sans-serif;
    background: #fff;
}

.PopupTextBulkActions_radioGroup__1pMPi {
    display: flex;
    grid-gap: 25px;
    gap: 25px;
    margin-top: 12px;
    font-size: 14px;
}

.PopupTextBulkActions_radioOption__jQwkP {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.PopupTextBulkActions_actionButtons__28BUm {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    margin-top: 25px;
}

.PopupTextBulkActions_primaryActionButton__33B-P,
.PopupTextBulkActions_secondaryActionButton__NJXSu {
    border-radius: 24px;
    padding: 12px 24px;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.PopupTextBulkActions_primaryActionButton__33B-P {
    background-color: #4758EB;
    color: #fff;
}

.PopupTextBulkActions_primaryActionButton__33B-P:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.PopupTextBulkActions_secondaryActionButton__NJXSu {
    background-color: #EB4755;
    color: #fff;
}

.PopupTextBulkActions_secondaryActionButton__NJXSu:hover {
    background-color: #E0858C;
}

/* Styles for the smaller toggle group */
.PopupTextBulkActions_smallToggleGroup__3brE0 {
    display: flex;
    background-color: #F0F0F5;
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 12px;
    width: 220px; /* Adjust width as needed */
}

/* Styles for smaller toggle buttons */
.PopupTextBulkActions_smallToggleButton__itr0R {
    flex: 1 1;
    border: none;
    background: transparent;
    padding: 8px 0; /* Reduced padding for smaller size */
    font-family: 'Lexend Deca', sans-serif;
    font-size: 14px; /* Smaller font size */
    color: #555;
    cursor: pointer;
    transition: background 0.3s;
    border-radius: 16px;
    text-align: center;
    -webkit-user-select: none;
            user-select: none;
}

.PopupTextBulkActions_smallToggleButton__itr0R:hover {
    background-color: #E0E1EB !important;
}

.PopupTextBulkActions_activeSmallToggle__3ZwOs {
    background-color: #4758EB;
    color: #fff;
    border-radius: 16px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}


/* Progress Information Section */
.PopupTextBulkActions_progressSection__1JVuG {
    position: relative; /* To position the "X" absolutely within */
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #E0E1EB;
    border-radius: 8px;
    background-color: #F9F9FF;
}

.PopupTextBulkActions_progressTitle__2qdve {
    font-size: 18px;
    font-weight: 600;
    color: #292B3D;
    margin-bottom: 15px;
}

.PopupTextBulkActions_progressBarContainer__13OdQ {
    width: 100%;
    background-color: #E0E1EB;
    border-radius: 8px;
    overflow: hidden;
    height: 20px;
    margin-bottom: 15px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

.PopupTextBulkActions_progressBar__3xBfh {
    height: 100%;
    background-color: #4758EB;
    transition: width 0.4s ease;
}

.PopupTextBulkActions_progressDetails__3Uv8Q {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #555;
    margin-bottom: 15px;
}



.PopupTextBulkActions_cancelJobXButton__3Opkn {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: #EB4755;
    color: white;
    border: none;
    padding: 4px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    font-size: 10px;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .PopupTextBulkActions_cancelJobXButton__3Opkn:hover {
    background-color: #E0858C !important;
    transform: scale(1.1);
  }
  
  .PopupTextBulkActions_cancelJobXButton__3Opkn:active {
    transform: scale(0.95);
  }
  
  .PopupTextBulkActions_cancelJobXButton__3Opkn:disabled {
    background-color: #e0aeb1;
    cursor: not-allowed;
    transform: none;
  }


/* Optional: Spinner for Loading Indicator */
.PopupTextBulkActions_spinner__E_q4a {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #4758EB; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: PopupTextBulkActions_spin__1JGuE 1s linear infinite;
    margin-right: 8px;
}

@keyframes PopupTextBulkActions_spin__1JGuE {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}



  .PopupTextBulkActions_buttonContent__2DIFB {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px; /* Space between spinner and text */
  }
/* src/popups/css/PopupTextPublish.module.css */

.PopupTextPublish_overlay__39niv {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.PopupTextPublish_popupContainer__jzbFB {
  background-color: #ffffff;
  padding: 25px 35px;
  border-radius: 12px;
  width: 95%;
  max-width: 650px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
  animation: PopupTextPublish_slideDown__1X_la 0.3s ease-in-out;
}

.PopupTextPublish_header__1ZF6I {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.PopupTextPublish_header__1ZF6I h2 {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
  color: #2c3e50;
  font-size: 24px;
}

.PopupTextPublish_closeButton__38PMI {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #7f8c8d;
  transition: color 0.2s;
}

.PopupTextPublish_closeButton__38PMI:hover {
  color: #2c3e50;
}

.PopupTextPublish_message__2bDNi {
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
}

.PopupTextPublish_success__5OZ3K {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.PopupTextPublish_error__3SdSi {
  background-color: #f8d7da;
  color: #EB4755;
  border: 1px solid #f5c6cb;
}

.PopupTextPublish_lastUpdatedSection__24wQC {
  font-family: 'Lexend Deca', sans-serif;
  color: #34495e;
  font-size: 16px;
  margin-bottom: 20px;
}

.PopupTextPublish_controlsSection__BzbNi {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 15px;
  gap: 15px;
  margin-bottom: 25px;
}

.PopupTextPublish_toggleContainer__1v3Sa {
  display: flex;
  align-items: center;
}

.PopupTextPublish_toggleLabel__3WLS8 {
  margin-right: 10px;
  font-weight: 500;
  font-family: 'Lexend Deca', sans-serif;
  color: #2c3e50;
  font-size: 14px;
}

.PopupTextPublish_toggleInput__3sSHh {
  width: 24px;
  height: 24px;
  cursor: pointer;
  accent-color: #4758EB !important;
  transition: transform 0.2s;
}

.PopupTextPublish_toggleInput__3sSHh:focus {
  outline: 2px solid #E0E1EB !important;
  outline-offset: 2px;
}

.PopupTextPublish_publishButton__1QjeN {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.1s;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.PopupTextPublish_publishButton__1QjeN:hover {
  background-color: #555EAA !important;
}

.PopupTextPublish_publishButton__1QjeN:active {
  transform: scale(0.98);
}

.PopupTextPublish_publishButton__1QjeN:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

.PopupTextPublish_buttonIcon__156yA {
  display: inline-block;
  vertical-align: middle;
}

.PopupTextPublish_divider__3TNt6 {
  border: none;
  border-top: 1px solid #ecf0f1;
  margin: 0 0 25px 0;
}

.PopupTextPublish_section__36I3W {
  margin-bottom: 35px;
}

.PopupTextPublish_section__36I3W h3 {
  font-family: 'Lexend Deca', sans-serif;
  color: #2c3e50;
  font-size: 20px;
  margin-bottom: 15px;
}

.PopupTextPublish_feedPreviewContainer__3YdRf {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

.PopupTextPublish_feedItem__v8tSx {
  background-color: #E0E1EB;
  border-radius: 10px;
  padding: 18px 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: background-color 0.3s;
}

.PopupTextPublish_feedInfo__7d9-R {
  margin-bottom: 12px;
}

.PopupTextPublish_feedInfo__7d9-R h4 {
  margin: 0 0 6px 0;
  font-family: 'Lexend Deca', sans-serif;
  color: #2c3e50;
  font-size: 18px;
}

.PopupTextPublish_feedInfo__7d9-R p {
  margin: 0;
  font-family: 'Lexend Deca', sans-serif;
  color: #7f8c8d;
  font-size: 14px;
}

.PopupTextPublish_buttonGroup__2_Enu {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
}

.PopupTextPublish_actionButton__1JwGL {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.1s;
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
}

.PopupTextPublish_actionButton__1JwGL:hover {
  background-color: #555EAA !important;
}

.PopupTextPublish_actionButton__1JwGL:active {
  transform: scale(0.98);
}

.PopupTextPublish_actionButton__1JwGL:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

.PopupTextPublish_toggleContainer__1v3Sa {
  display: flex;
  align-items: center;
}

.PopupTextPublish_toggleLabel__3WLS8 {
  margin-right: 10px;
  font-weight: 500;
  font-family: 'Lexend Deca', sans-serif;
  color: #2c3e50;
  font-size: 14px;
}

.PopupTextPublish_toggleInput__3sSHh {
  width: 24px;
  height: 24px;
  cursor: pointer;
  accent-color: #2980b9;
  transition: transform 0.2s;
}

.PopupTextPublish_toggleInput__3sSHh:focus {
  outline: 2px solid #3498db;
  outline-offset: 2px;
}

/* Responsive Design */
@media (max-width: 480px) {
  .PopupTextPublish_popupContainer__jzbFB {
    padding: 20px 25px;
  }

  .PopupTextPublish_header__1ZF6I h2 {
    font-size: 20px;
  }

  .PopupTextPublish_publishButton__1QjeN {
    font-size: 14px;
    padding: 8px 16px;
  }

  .PopupTextPublish_section__36I3W h3 {
    font-size: 18px;
  }

  .PopupTextPublish_feedInfo__7d9-R h4 {
    font-size: 16px;
  }

  .PopupTextPublish_feedInfo__7d9-R p {
    font-size: 13px;
  }

  .PopupTextPublish_actionButton__1JwGL {
    font-size: 13px;
    padding: 6px 12px;
  }

  .PopupTextPublish_controlsSection__BzbNi {
    flex-direction: column;
    align-items: stretch;
  }

  .PopupTextPublish_publishButton__1QjeN {
    width: 100%;
    justify-content: center;
  }
}

.PromptBuilder_promptBuilderContainer__2t0p3 {
  display: flex;
  width: 100%;
  height: 100vh;
  font-family: 'DM Sans', sans-serif;
  position: relative;
}

.PromptBuilder_promptBuilderOptions__1DsuK {
  flex: 1 1;
  padding: 20px;
  border-right: 1px solid #ccc;
  margin-top: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.PromptBuilder_promptPreview__18MOj {
  flex: 1 1;
  margin-top: 20px;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.PromptBuilder_collapsibleSection__-fWpy {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.PromptBuilder_collapsibleHeader__1j2Ae {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.PromptBuilder_collapsibleHeader__1j2Ae h3 {
  margin: 0;
  flex: 1 1;
  font-size: 18px;
}

.PromptBuilder_collapsibleContent__13hbo {
  margin-top: 10px;
  overflow: hidden;
}

.PromptBuilder_collapsibleHeader__1j2Ae .PromptBuilder_indicator__spxaf {
  margin-right: 20px;
  transition: transform 0.3s;
}

.PromptBuilder_resetButton__cFn7r {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #666;
  margin-left: auto;
  transition: transform 0.2s, color 0.2s;
  padding: 4px;
  padding-bottom: 0px;
}

.PromptBuilder_resetButton__cFn7r:hover {
  background-color: rgba(84, 142, 224, 0.2) !important;
  transform: scale(1.1);
}

.PromptBuilder_saveButtonUnsaved__1NJHD {
  position: sticky;
  bottom: 20px;
  right: 20px;
  background-color: #5CD685;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: transform 0.2s, background-color 0.2s;
  float: right;
}

.PromptBuilder_saveButtonUnsaved__1NJHD:hover {
  background-color: #94D1A8 !important;
  transform: scale(1.1);
}

.PromptBuilder_addButton__3OSuO {
  background-color: transparent;
  color: #555EAA;
  border: none;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  font-size: small;
  transition: transform 0.2s, background-color 0.2s;
}

.PromptBuilder_addButton__3OSuO:hover {
  transform: scale(1.1);
  background-color: rgba(84, 142, 224, 0.2) !important;
}

.PromptBuilder_addButton__3OSuO svg {
  margin-right: 8px;
}

.PromptBuilder_addButton__3OSuO span {
  font-size: 16px;
}

.PromptBuilder_clientSelector__gaLy1 {
  margin-bottom: 20px;
}

.PromptBuilder_clientSelector__gaLy1 label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.PromptBuilder_clientSelector__gaLy1 select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.PromptBuilder_clientSelector__gaLy1 select:focus {
  border-color: #555EAA;
}

.PromptBuilder_combinationSelector__3FnXq {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PromptBuilder_combinationSelector__3FnXq select {
  flex: 1 1;
  margin-right: 10px;
}

.PromptBuilder_saveOptions__1HFTb {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.PromptBuilder_saveButtonOverwrite__2OWNh {
  background-color: grey;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}

.PromptBuilder_saveButtonNew__aTEXE {
  background-color: #555EAA;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.PromptBuilder_newCombinationNameInput__2oV64 {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
}

.PromptBuilder_deleteButton__oSDrv {
  background-color: transparent;
  color: #EB4755;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  margin-left: 10px;
  transition: transform 0.2s, background-color 0.2s;
}

.PromptBuilder_deleteButton__oSDrv:hover {
  transform: scale(1.1);
  background-color: rgba(235, 71, 85, 0.2) !important;
}

.PromptBuilder_popupOverlay__28Bgy {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.PromptBuilder_popupContent__3qGPu {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  width: 300px;
}

.PromptBuilder_popupInput__LtRVT {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.PromptBuilder_popupButtons__3N0iG {
  display: flex;
  justify-content: space-between;
}

.PromptBuilder_popupImportButton__3MOPe, .PromptBuilder_popupCloseButton__1FK36 {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.PromptBuilder_popupImportButton__3MOPe {
  background-color: #555EAA;
  color: white;
}

.PromptBuilder_popupImportButton__3MOPe:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.PromptBuilder_popupCloseButton__1FK36 {
  background-color: #EB4755;
  color: white;
}

.PromptBuilder_productDetailsBox__2LTeX {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 20px;
  margin-top: 20px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.PromptBuilder_productImage__3hz8W {
  max-width: 150px; /* Adjust width as needed */
  max-height: 150px;
  margin-right: 20px;
}

.PromptBuilder_productInfo__3SlN8 {
  flex: 1 1;
}

.PromptBuilder_attributeComparison__SURLx {
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  padding: 0px;
  margin-top: 20px;
}

.PromptBuilder_attributeHeader__29ks- {
  /* This row holds the "Attributes Comparison" title and the button side by side */
  display: flex;
  justify-content: space-between;  /* Space the title and button out */
  align-items: center;
  padding: 10px 20px;             /* Some padding around them */
  border-bottom: 1px solid #ccc;
}

.PromptBuilder_attributeHeader__29ks- h3 {
  margin: 0;                      /* Tighten up the spacing if needed */
}
.PromptBuilder_attributeRow__2177c {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
}

.PromptBuilder_attributeColumn__bh2jW {
  flex: 1 1;
}

.PromptBuilder_attributeColumn__bh2jW h4 {
  margin: 0;
  font-weight: bold;
}

.PromptBuilder_attributeColumn__bh2jW p {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.PromptBuilder_attributePair__3umbU {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0;
  padding: 10px 20px;
  border-bottom: 1px solid #eee;
}

.PromptBuilder_attributePair__3umbU p {
  width: 48%;
  margin: 0;
  padding: 5px 0;
}

.PromptBuilder_attributeOriginal__3pP5J,
.PromptBuilder_attributeGenerated__2eneM {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 6px !important;
}

.PromptBuilder_error__157i0 {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

.PromptBuilder_selectPromptMessage__DP9a8 {
  color: #555;
  font-style: italic;
  margin-top: 20px;
}

.PromptBuilder_headerContainer__1XFMz {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.PromptBuilder_boldCenter__3yjXd {
  font-weight: bold;
  text-align: center;
  display: block;
}

.PromptBuilder_fewShotAttribute__31L4T {
  flex: 1 1;
  font-weight: bold;
}

.PromptBuilder_fewShotPrompt__2zI2C {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  background-color: #f1f1f1;
}

.PromptBuilder_fewShotRow__3OQQ1 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.PromptBuilder_fewShotField__1J3dJ {
  flex: 2 1;
  margin-right: 10px;
}

.PromptBuilder_fewShotField__1J3dJ input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.PromptBuilder_fewShotField__1J3dJ input:focus {
  border-color: #555EAA;
}

.PromptBuilder_reactQuillContainer__8SCbb {
  height: 300px;
  overflow: hidden;
}

.PromptBuilder_reactQuillEditor__Sc0YX {
  height: 100%;
  overflow-y: auto;
}

.PromptBuilder_removeButton__2YbGe {
  background-color: transparent;
  color: #EB4755;
  border: none;
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 2px;
  transition: transform 0.2s, background-color 0.2s;
}

.PromptBuilder_removeButton__2YbGe:hover {
  transform: scale(1.1);
  background-color: rgba(235, 71, 85, 0.2) !important;
}

.PromptBuilder_imageWarning__3Y2NN {
  display: flex;
  align-items: center;
  background-color: #fff3cd; /* Light yellow background */
  color: #856404; /* Dark yellow text */
  border: 1px solid #ffeeba; /* Yellow border */
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.PromptBuilder_warningIcon__3a5wN {
  margin-right: 10px;
  font-size: 20px;
  color: #856404; /* Matching dark yellow text */
}

.PromptBuilder_idInput__1HdPX {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.PromptBuilder_generateButton__18AVz {
  background-color: #555EAA;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin-bottom: 20px;
}

.PromptBuilder_generateButton__18AVz:hover {
  background-color: #3d45b0;
  transform: scale(1.05);
}

.PromptBuilder_loadingContainer__Icb0R {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.PromptBuilder_loadingContainer__Icb0R p {
  margin-top: 10px;
  font-size: 16px;
  color: #555;
}

.PromptBuilder_backButton__2qdvE {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #555;
  border: none;
  color: #f0f0f0;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, color 0.3s;
}

.PromptBuilder_backButton__2qdvE:hover {
  background-color: #55555578 !important;
  color: #ffffff !important;
}

.PromptBuilder_importButton__rn72B {
  background-color: transparent;
  color: #555EAA;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  margin-left: 10px;
  transition: transform 0.2s, background-color 0.2s;
}

.PromptBuilder_importButton__rn72B:hover {
  transform: scale(1.1);
  background-color: rgba(84, 142, 224, 0.2) !important;
}


.PromptBuilder_addFewShotButton__1mUU9 {
  background-color: #5CD685; /* A fresh, noticeable color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin-top: 15px;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px; /* Space between icon and text */
}

.PromptBuilder_addFewShotButton__1mUU9:hover {
  background-color: #45B570 !important;
  transform: scale(1.05);
}

.SaveOptionsModal_modalOverlay__NwRcQ {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.SaveOptionsModal_modalContent__1NAsJ {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    position: relative;
}

.SaveOptionsModal_button__3POYd {
    background-color: #4CAF50; /* Green */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px 0;
    transition: background-color 0.3s;
}

.SaveOptionsModal_button__3POYd:hover {
    background-color: #45a049;
}

.SaveOptionsModal_newCombinationNameInput__3Wlxz {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 10px;
    width: 100%;
}

.SaveOptionsModal_closeButton__2eseR {
    background: transparent;
    border: none;
    color: red;
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    transition: transform 0.2s, background-color 0.2s;
}

.SaveOptionsModal_closeButton__2eseR:hover {
    color: red;
    transform: scale(1.1);
    background: transparent !important;
}

.SaveOptionsModal_error__3wqhL {
    color: red;
    margin-top: 10px;
}

.SaveOptionsModal_optionsContainer__1infi {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
}

.SaveOptionsModal_buttonGroup__11HEb {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.ImportPromptModal_modalOverlay__3F9Jr {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .ImportPromptModal_modalContent__On1k8 {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
    width: 300px;
  }
  
  .ImportPromptModal_closeButton__12-YU {
    background-color: transparent;
    border: none;
    font-size: 18px;
    color: #666;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .ImportPromptModal_closeButton__12-YU:hover {
    color: #333;
  }
  
  .ImportPromptModal_formGroup__SK1ay {
    margin-bottom: 15px;
  }
  
  .ImportPromptModal_formGroup__SK1ay label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .ImportPromptModal_formGroup__SK1ay select,
  .ImportPromptModal_formGroup__SK1ay input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s;
  }
  
  .ImportPromptModal_formGroup__SK1ay select:focus,
  .ImportPromptModal_formGroup__SK1ay input:focus {
    border-color: #555EAA;
  }
  
  .ImportPromptModal_importButton__rBBPz {
    background-color: #555EAA;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .ImportPromptModal_importButton__rBBPz:hover {
    background-color: #6E76D1;
  }
  

  /* Export Button Styles */
.ImportPromptModal_exportButton__29Oqg {
  background-color: #5CC0D6;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-left: 10px; /* Adjust spacing as needed */
}

.ImportPromptModal_exportButton__29Oqg:hover {
  background-color: #94D1A8;
}
/* src/components/css/ImageGeneration.module.css */

.ImageGeneration_topBar__2J-Bk {
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
  background-color: #ffffff;
  color: #4758EB;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap; /* Allows items to wrap if space is limited */
}

.ImageGeneration_headerTextContainer__XAv6e {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 40px;
  flex-grow: 0;
}

.ImageGeneration_clientDropdown__2leL3 {
  min-width: 300px;
  font-size: 12px;
}

.ImageGeneration_backButton__2S3dn {
  background-color: #d0d0d0;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.ImageGeneration_backButton__2S3dn:hover {
  background-color: #7c7c7c;
}

.ImageGeneration_refreshButton__1fTzm {
  background-color: #d0d0d000;
  color: #d0d0d0;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-left: 0px;
}

.ImageGeneration_refreshButton__1fTzm:hover {
  color: #4758EB;
  background-color: #ffffff !important;
}

.ImageGeneration_feedViewerVersion__aPPVc {
  font-size: 14px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
  color: #4758EB;
}

.ImageGeneration_headerSubtitle__334Ne {
  font-size: 22px;
  font-family: 'DM Serif Text', serif;
  color: #292B3D;
}

.ImageGeneration_headerImage__3vqo8 {
  height: 100px;
  width: auto;
  padding-top: 20px;
  object-fit: contain;
  margin-left: auto;
}

.ImageGeneration_imageGenerationContainer__25fdm {
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 5px;
}

h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.ImageGeneration_columnSelectionContainer__na2Pj {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ImageGeneration_columnList__1CcXY {
  flex: 1 1;
  margin-right: 30px;
}

.ImageGeneration_moveButton__3kb-i {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 0 20px;
}

.ImageGeneration_moveButton__3kb-i:hover {
  background-color: #3949ab;
}

.ImageGeneration_tableContainer__26Mki {
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: #ffffff;
  margin-bottom: 80px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  overflow-y: hidden;
  position: relative; /* Added position relative */
}

.ImageGeneration_tableWrapper__o_25f {
  position: relative; /* Added position relative */
  overflow-x: auto;
  overflow-y: hidden;
}

.ImageGeneration_table__2I8nL {
  width: max-content;
  border-collapse: collapse;
}

th, td {
  max-width: 450px;
  min-width: 50px;
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
}

tr:hover {
  background-color: #f5f5f5;
}

.ImageGeneration_imageContainer__2tw6F {
  position: relative;
  display: inline-block;
  padding: 0px;
  width: 70px;
  height: 90px;
  margin-right: 5px;
  text-align: center;
  transition: opacity 0.3s ease-in-out, border 0.3s ease-in-out;
  box-sizing: border-box;
}

.ImageGeneration_imageStyle__2x2FE {
  width: 100%;
  height: 60px;
  padding: 0px;
  padding-top: 5px;
  object-fit: contain;
  border-radius: 8px;
  box-sizing: border-box;
}

.ImageGeneration_imageText__2bY7R {
  margin-top: 5px;
  font-size: 10px !important;
  color: #292B3D;
  font-family: 'Lexend Deca', sans-serif;
}

.ImageGeneration_imageOverlay__2xrwb {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  color: #292B3D;
  display: none;
  align-items: center;
  justify-content: center;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: bold;
  transition: opacity 0.2s ease-in-out;
  font-size: 10px !important;
  border-radius: 8px;
  cursor: pointer;
}

.ImageGeneration_imageContainer__2tw6F:hover .ImageGeneration_imageOverlay__2xrwb {
  display: flex;
}

.ImageGeneration_combinedFilterContainer__1M36i *:not(h1):not(h2):not(h3) {
  font-family: 'DM Sans', sans-serif;
  font-size: 12px;
}

.ImageGeneration_combinedFilterContainer__1M36i {
  display: flex;
  flex-direction: column;
  grid-gap: 0px;
  gap: 0px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
}

.ImageGeneration_filterContainer__VlKeo {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.ImageGeneration_filterDropdown__gmyo5 {
  min-width: 250px;
  font-family: 'Lexend Deca', sans-serif;
}

.ImageGeneration_filterTypeSelect__1lPY7 {
  padding: 8px;
  font-family: 'Lexend Deca', sans-serif;
  background-color: #f4f4f4;
  border: 1px solid #e0e1eb;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.ImageGeneration_filterTypeSelect__1lPY7:focus {
  border-color: #4758EB;
}

.ImageGeneration_filterInput__-tDdV {
  padding: 8px;
  min-width: 200px;
  border: 1px solid #e0e1eb;
  border-radius: 4px;
  font-family: 'Lexend Deca', sans-serif;
  transition: border-color 0.3s;
}

.ImageGeneration_filterInput__-tDdV:focus {
  border-color: #4758EB;
}

.ImageGeneration_sortColumnDropdown__1mFOE {
  min-width: 250px;
  max-width: 300px;
}

.ImageGeneration_sortOrderSelect__3vuZJ {
  width: 120px;
}

.ImageGeneration_removeFilterButton__MjEgz {
  background-color: #EB4755;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 20px;
  height: 20px;
  font-size: 10px!important;
}

.ImageGeneration_removeFilterButton__MjEgz:hover {
  background-color: #E0858C !important;
}

.ImageGeneration_addFilterButton__xWawi {
  background-color: #5CD685;
  color: #ffffff;
  border: none;
  padding: 10px!important;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px!important;
  height: 40px!important;
}

.ImageGeneration_addFilterButton__xWawi:hover {
  background-color: #94D1A8 !important;
}

.ImageGeneration_applyFilterButton__2RbZE {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 9px;
  padding-left: 11px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px!important;
  height: 40px!important;
}

.ImageGeneration_applyFilterButton__2RbZE:hover {
  background-color: #848EE0 !important;
}

.ImageGeneration_filterSection__3qPrC {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ImageGeneration_sortByContainer__1ZfK6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 10px;
}

.ImageGeneration_sortByGroup__glIDg {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.ImageGeneration_buttonGroup__3G9WE {
  display: flex;
  align-items: center;
  grid-gap: 0px;
  gap: 0px;
}

.ImageGeneration_filterRow__1huwe {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  margin-bottom: 0px;
}

.ImageGeneration_logicSelect__1-EsQ {
  width: 80px;
  padding: 5px;
  font-family: 'Lexend Deca', sans-serif;
  background-color: #f4f4f4;
  border: 1px solid #e0e1eb;
  border-radius: 4px;
  transition: border-color 0.3s;
  text-align: center;
}

.ImageGeneration_logicSelect__1-EsQ:focus {
  border-color: #4758EB;
}

.ImageGeneration_applyFilterButton__2RbZE:disabled:hover {
  background-color: #CCCCCC !important;
}

.ImageGeneration_paginationContainer__3pMOM {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  width: 600px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 100;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-left: auto;
  margin-right: auto;
}

.ImageGeneration_paginationButton__oCrTz {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.ImageGeneration_paginationButton__oCrTz:hover {
  background-color: #848EE0 !important;
}

.ImageGeneration_paginationButton__oCrTz:disabled {
  background-color: #ccc !important;
  cursor: not-allowed;
}

.ImageGeneration_paginationButton__oCrTz:disabled:hover {
  background-color: #CCCCCC !important;
}

.ImageGeneration_pageInput__1q_l7 {
  width: 40px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px;
  font-size: 14px;
  font-family: 'Lexend Deca', sans-serif;
}

.ImageGeneration_pageInput__1q_l7:focus {
  border-color: #4758EB;
  outline: none;
}

/* TOGGLE BUTTON */
.ImageGeneration_toggleSwitch__2WWpr {
  display: flex;
  align-items: center;
  background-color: #e0e1eb;
  border-radius: 24px;
  padding: 5px;
  width: -moz-fit-content;
  width: fit-content;
  margin: 20px 0;
  grid-gap: 10px;
  gap: 10px; /* Add gap for spacing between buttons */
  justify-content: space-between; /* Align buttons beside each other */
}

.ImageGeneration_toggleButton__2nm5O {
  background-color: transparent;
  border: none;
  color: #292B3D;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 20px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.ImageGeneration_generateMode__13eSn:hover {
  background-color: #4758EB !important;
  color: white !important;
}

.ImageGeneration_selectorMode__D0p2L:hover {
  background-color: #5CC0D6 !important;
  color: white !important;
}

.ImageGeneration_transformMode__3jRXZ:hover {
  background-color: #EB7B47 !important;
  color: white !important;
}

.ImageGeneration_generateActiveButton__OPKYX {
  background-color: #4758EB;
  color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.ImageGeneration_selectorActiveButton__9yfSl {
  background-color: #5CC0D6;
  color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.ImageGeneration_transformActiveButton__23kw- {
  background-color: #EB7B47;
  color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.ImageGeneration_generateActiveButton__OPKYX:hover {
  background-color: #4758EB !important;
  color: white !important;
}

.ImageGeneration_selectorActiveButton__9yfSl:hover {
  background-color: #5CC0D6 !important;
  color: white !important;
}

.ImageGeneration_transformActiveButton__23kw-:hover {
  background-color: #EB7B47 !important;
  color: white !important;
}

.ImageGeneration_generateHeader__25CxM th {
  background-color: #4758EB !important;
  color: white;
  font-family: 'Lexend Deca', sans-serif;
}

.ImageGeneration_selectorHeader__1U-hC th {
  background-color: #5CC0D6 !important;
  color: white;
  font-family: 'Lexend Deca', sans-serif;
}

.ImageGeneration_transformHeader__7uz3a th {
  background-color: #EB7B47 !important;
  color: white;
  font-family: 'Lexend Deca', sans-serif;
}

.ImageGeneration_selectedImageContainer__2bjzx {
  border: 3px solid #5CC0D6;
  border-radius: 8px;
  box-sizing: border-box;
}

.ImageGeneration_imageContainer__2tw6F {
  transition: opacity 0.3s ease-in-out, border 0.3s ease-in-out;
}

.ImageGeneration_spinnerContainer__1LGLy {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
}

/* Image type label */
.ImageGeneration_imageTypeLabel__3Uuq8 {
  position: absolute;
  top: 4px; /* Adjusts the position to the top */
  right: 4px; /* Adjusts the position to the right */
  padding: 2px 5px; /* Reduces the padding to make the label smaller */
  font-size: 10px; /* Adjusts the font size for better readability */
  color: white;
  background-color: #000; /* Default background, will be overridden */
  border-radius: 3px;
  font-family: 'Lexend Deca', sans-serif;
  line-height: 1;
  display: inline-block;
  width: auto; /* Ensures the label only takes up necessary space */
  height: auto; /* Ensures the label only takes up necessary space */
}

.ImageGeneration_storageInfo__2JFKr {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  color: #292B3D;
  margin-left: 10px;
}

.ImageGeneration_storageInfo__2JFKr span {
  font-size: 12px;
  font-family: 'Lexend Deca', sans-serif;
  color: #d0d0d0;
}

.ImageGeneration_storageInfo__2JFKr svg {
  color: #d0d0d0;
}

/* New styles for credit balance */
.ImageGeneration_creditInfo__1mtEc {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  color: #292B3D;
  margin-left: 20px;
}

.ImageGeneration_creditInfo__1mtEc span {
  font-size: 12px;
  font-family: 'Lexend Deca', sans-serif;
  color: #d0d0d0;
}

.ImageGeneration_creditInfo__1mtEc svg {
  color: #d0d0d0;
}

.ImageGeneration_buttonContainer__2-Eq1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  left: 0;
  top: 0; /* Optional: If you want it to stick to the top when scrolling vertically */
  z-index: 2;
  background-color: #ffffff;
  padding: 10px 0;
}

/* New rightButtons container */
.ImageGeneration_rightButtons__3GP8Y {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

/* Styles for Bulk Actions button */
.ImageGeneration_bulkActionsButton__q8wCV {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: all 0.2s ease;
}

.ImageGeneration_bulkActionsButton__q8wCV:hover {
  background-color: #848EE0 !important;
}

/* Adjusted exportButton if needed */
.ImageGeneration_exportButton__3lrPq {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.ImageGeneration_exportButton__3lrPq:hover {
  background-color: #848EE0 !important;
}

.ImageGeneration_clientError__3tGNI {
  display: flex;
  align-items: center;
  color: red; /* Adjust color as needed */
  margin-top: 8px;
}

/* Additional Styles (if any) */
.ImageGeneration_selectedRow__NCi73 {
  background-color: #e0e1eb !important;
}

.ImageGeneration_checkbox__3yJ5Q {
  width: 16px;
  height: 16px;
}

.ImageGeneration_logoutBtn__R-xBN {
  background-color: #E0858C;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.ImageGeneration_logoutBtn__R-xBN:hover {
  background-color: #EB4755 !important;
}

.ImageGeneration_schedulerButton__2yYl_ {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-left: 10px; /* Add some spacing if needed */
  margin-right: -5px;
}

.ImageGeneration_schedulerButton__2yYl_:hover {
  background-color: #848EE0 !important;
}


/* Add these styles at the end of your existing CSS file */

/* Styles for the refresh status messages */
.ImageGeneration_refreshStatusContainer__3VqgB {
  margin: 20px;
  font-family: 'Lexend Deca', sans-serif;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.ImageGeneration_refreshStatusMessage__2qowC, .ImageGeneration_refreshErrorMessage__335vH {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
}

.ImageGeneration_refreshStatusMessage__2qowC {
  background-color: #d4edda; /* Light green background */
  color: #155724;            /* Dark green text */
  border: 1px solid #c3e6cb;
}

.ImageGeneration_refreshErrorMessage__335vH {
  background-color: #f8d7da; /* Light red background */
  color: #721c24;            /* Dark red text */
  border: 1px solid #f5c6cb;
}

.ImageGeneration_statusIcon__DEPkd {
  margin-right: 10px;
  font-size: 20px;
}


/* Custom styles for react-select */
.ImageGeneration_react-select__control__2t5V_ {
  min-width: 300px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.ImageGeneration_react-select__control--is-focused__brfdg {
  border-color: #4758EB;
  box-shadow: 0 0 0 1px #4758EB;
}

.ImageGeneration_react-select__menu__2ZEVy {
  font-size: 12px;
}

.ImageGeneration_react-select__option--is-selected__1ccZA {
  background-color: #4758EB;
  color: white;
}

.ImageGeneration_react-select__option___wvpt:hover {
  background-color: #6c7bbf;
  color: white;
}

.ImageGeneration_react-select__placeholder__2kYQk {
  color: #999;
}

.ImageGeneration_react-select__single-value__1j2r1 {
  color: #333;
}

/* Popup overlay */
.PopupUploaded_popupOverlay__GJTLP {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Popup content */
  .PopupUploaded_popupContent__4eNTg {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 800px;
    text-align: center;
    position: relative;
  }
  
  /* Popup header */
  .PopupUploaded_popupHeader__2ZENl {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Close button */
  .PopupUploaded_closeButton__1Yjhz {
    background-color: #d0d0d0;
    color: #ffffff;
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .PopupUploaded_closeButton__1Yjhz:hover {
    background-color: #7c7c7c;
  }
  
  /* Dropzone styling */
  .PopupUploaded_dropzone__1vGsq {
    width: 100%;
    min-height: 300px;
    border: 2px dashed #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ccc;
    background-color: #f9f9f9;
    margin-bottom: 15px;
    transition: border-color 0.3s, background-color 0.3s;
    font-family: 'Lexend Deca', sans-serif;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
  }
  
  .PopupUploaded_dropzoneActive__2cNm4 {
    border-color: #4758EB;
    background-color: rgba(108, 99, 255, 0.1);
  }
  
  /* Dropzone content (cloud, text, and button) */
  .PopupUploaded_dropzoneContent__di26h {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .PopupUploaded_dropzoneIcon__3mZYk {
    font-size: 48px;
    color: #4758EB;
    margin-top: 20px;
  }
  
  .PopupUploaded_dropzoneText__3RGfu {
    font-size: 16px;
    color: #888;
    margin-bottom: 10px;
  }
  
  .PopupUploaded_browseButton__3Nqlz {
    background-color: #4758EB;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .PopupUploaded_browseButton__3Nqlz:hover {
    background-color: #3949ab;
  }
  

  /* Styling for the uploaded image */
.PopupUploaded_uploadedImage__2HvJP {
    max-width: 600px; /* Ensure the image fits within the dropzone width */
    max-height: 250px; /* Constrain the height to a reasonable size */
    object-fit: contain; /* Ensure the image scales without being distorted */
    margin-top: 10px;
    border-radius: 8px; /* Optional: if you want rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add shadow for effect */
  }
  .PopupUploaded_buttonContainer__CFZHc {
    display: flex;
    justify-content: flex-end; /* Align button to the right */
    align-items: center; /* Align items vertically */
    margin-top: 20px; /* Add space between the dropzone and button */
    position: relative; /* Make sure the button stays in place */
    min-height: 50px; /* Ensure the container height stays fixed */
  }
  
  .PopupUploaded_successMessage__1b7jq {
    color: #5CD685;
    font-size: 14px;
    margin-right: auto; /* Ensure the message appears on the left side */
  }
  
  .PopupUploaded_uploadButton__2IweG {
    background-color: #5CD685; /* Lighter green */
    color: #ffffff; /* White icon color */
    border: none;
    padding: 10px!important;
    border-radius: 50%; /* Make the button circular */
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px!important;
    height: 40px!important;
    position: relative; /* Stay within the flex container */
  }
  
  .PopupUploaded_uploadButton__2IweG:disabled {
    background-color: #ccc; /* Gray color for disabled state */
    cursor: not-allowed;
  }
  
  .PopupUploaded_uploadButton__2IweG:hover {
    background-color: #94D1A8 !important; /* Darker green on hover */
  }
  
  .PopupUploaded_uploadButton__2IweG:disabled:hover {
    background-color: #ccc !important; /* Ensure the hover color stays gray for disabled */
  }
/* src/components/popups/css/PopupBackground.module.css */

/* CSS Variables for Consistency */
:root {
  --popup-overlay-bg: rgba(41, 43, 61, 0.8);
  --popup-content-bg: #ffffff;
  --primary-color: #4758eb;
  --primary-hover: #3949ab;
  --danger-color: #eb4755;
  --danger-hover: #d43c3a;
  --success-color: #28a745;
  --warning-color: #ffc107;
  --text-color: #292b3d;
  --secondary-text-color: #666666;
  --border-color: #ccc;
  --legend-added-bg: rgba(0, 128, 0, 0.3);
  --legend-cropped-bg: rgba(255, 0, 0, 0.3);
  --canvas-bg: #f9f9f9;
  --overlay-bg: rgba(255, 255, 255, 0.7);
  --processing-text-color: #4758eb;
}

/* Popup Overlay */
.PopupBackground_popupOverlay__oh1Ue {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--popup-overlay-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 10px;
  box-sizing: border-box;
}

/* Popup Content */
.PopupBackground_popupContent__1vW6E {
  background-color: var(--popup-content-bg);
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 1200px; /* Increased max-width for better side-by-side display */
  position: relative;
  overflow-y: auto;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

/* Popup Header */
.PopupBackground_popupHeader__2vD6d {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff; /* Replace with your desired color */
}

.PopupBackground_popupHeader__2vD6d h2 {
  font-family: 'DM Serif Text', serif;
  font-size: 24px;
  color: var(--text-color);
  margin: 0;
}

.PopupBackground_closeButton__1zk2E {
  background: none;
  border: none;
  font-size: 28px;
  cursor: pointer;
  color: var(--text-color);
  transition: color 0.3s;
}

.PopupBackground_closeButton__1zk2E:hover {
  color: var(--danger-color);
}

/* Configuration Section */
.PopupBackground_configurationSection__3J76M {
  margin-bottom: 30px;
}

.PopupBackground_dropdownRow__r9aHW {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
}

.PopupBackground_dropdownSection__2SNnU {
  flex: 1 1;
  position: relative;
  min-width: 150px;
}

.PopupBackground_dropdownHeader__2PnxH {
  background-color: #e0e1eb;
  border: none;
  color: var(--text-color);
  padding: 10px 20px;
  height: 40px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 20px;
  font-size: 14px;
  transition: background-color 0.3s ease;
  width: 100%;
  text-align: center;
}

.PopupBackground_dropdownHeader__2PnxH:hover {
  background-color: var(--primary-color); /* Change background color on hover */
  color: #ffffff; /* Change text color to white on hover */
}

.PopupBackground_dropdownContent__3RCoi {
  position: absolute;
  top: 50px;
  left: 0;
  background-color: var(--popup-content-bg);
  padding: 15px;
  border-radius: 16px;
  margin-top: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  z-index: 999;
}

.PopupBackground_option__24p_2 {
  margin: 5px 0;
  padding: 10px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  transition: background-color 0.2s;
}

.PopupBackground_option__24p_2:hover {
  background-color: #f0f0f0;
}

.PopupBackground_option__24p_2.PopupBackground_active__IQ5Jx {
  background-color: var(--primary-color);
  color: #ffffff;
}

.PopupBackground_colorPicker__K-3k7 {
  margin-top: 15px;
  width: 89%;
}

.PopupBackground_option__24p_2 span {
  font-size: 16px;
}

.PopupBackground_buttonContainer__1ELxx {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.PopupBackground_submitButton__2jT8Z {
  background-color: var(--primary-color);
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  border-radius: 24px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.2s;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.PopupBackground_submitButton__2jT8Z:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.PopupBackground_submitButton__2jT8Z:hover:not(:disabled) {
  background-color: var(--primary-hover);
  transform: translateY(-2px);
}

.PopupBackground_submitButton__2jT8Z:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(71, 88, 235, 0.4);
}

/* Images Container */
.PopupBackground_imagesContainer__2HHIe {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 30px;
  flex-wrap: wrap;
  align-items: stretch; /* Ensure containers stretch equally */
}

.PopupBackground_sideBySideImageContainer__z0d8z {
  flex: 1 1;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.PopupBackground_sideBySideImageContainer__z0d8z .PopupBackground_imageLabel__1pPQw {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: 'Lexend Deca', sans-serif;
  color: var(--text-color);
}

.PopupBackground_sideBySideImage__aSlBQ {
  min-height: 40%; /* Adjust as needed */
  max-height: 80%; /* Adjust as needed */
  min-width: 40%; /* Adjust as needed */
  max-width: 80%; /* Adjust as needed */
  object-fit: contain;
  border-radius: 10px;
  /* Add shadow to images */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.PopupBackground_imageWrapper__3psS2 {
  position: relative;
  flex: 1 1; /* Ensure the wrapper takes up available space */
  display: flex;
  align-items: center;
  justify-content: center;
}

.PopupBackground_loadingOverlay__SnMK3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(71, 88, 235, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.PopupBackground_loader__IrV2i {
  width: 50px;
  height: 50px;
  border: 5px solid #ffffff;
  border-top: 5px solid var(--primary-color);
  border-radius: 50%;
  animation: PopupBackground_spin__V1gjK 1s linear infinite;
}

/* Keyframes for the loader animation */
@keyframes PopupBackground_spin__V1gjK {
  to {
    transform: rotate(360deg);
  }
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .PopupBackground_dropdownRow__r9aHW {
    flex-direction: column;
  }

  .PopupBackground_imagesContainer__2HHIe {
    flex-direction: column;
    align-items: center;
  }

  .PopupBackground_sideBySideImage__aSlBQ {
    max-width: 80%;
  }
}

.PopupBackground_dropdownHeaderContent__32w9t {
  display: flex;
  align-items: center;
  justify-content: center;
}

.PopupBackground_colorIndicator__1qtx5 {
  width: 20px;
  height: 20px;
  margin-left: 8px;
  border: 1px solid #000;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
}

.PopupBackground_checkerboardBackground__WJ4K6 {
  background-color: #fff;
  background-image:
    linear-gradient(45deg, #ccc 25%, transparent 25%),
    linear-gradient(-45deg, #ccc 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #ccc 75%),
    linear-gradient(-45deg, transparent 75%, #ccc 75%);
  background-size: 8px 8px;
  background-position: 0 0, 0 4px, 4px -4px, -4px 0px;
}

.PopupBackground_option__24p_2 {
  margin: 5px 0;
  padding: 10px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  transition: background-color 0.2s;
}

.PopupBackground_option__24p_2:hover {
  background-color: #f0f0f0;
}

.PopupBackground_option__24p_2.PopupBackground_active__IQ5Jx {
  background-color: var(--primary-color);
  color: #ffffff;
}

.PopupBackground_sizeInputContainer__2SJG4 {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 10px;
}

.PopupBackground_sizeInput__3ZlIH {
  width: 60px;
  padding: 5px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.PopupBackground_sizeInput__3ZlIH:disabled {
  background-color: #e0e0e0;
}

.PopupBackground_sizeInputContainer__2SJG4 span {
  font-size: 18px;
}

.PopupBackground_subSection__2GlFA {
  margin-bottom: 20px;
}

.PopupBackground_subSectionTitle__3OTIY {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  color: var(--text-color);
  display: flex;
  align-items: center;
  position: relative;
}

.PopupBackground_radioGroup__3ecO7 {
  display: flex;
  grid-gap: 0px;
  gap: 0px; /* Reduced gap for better spacing */
  flex-wrap: wrap; /* Allow wrapping if space is limited */
}

.PopupBackground_customRadioOption__1WCqT {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  cursor: pointer;
  position: relative;
  font-family: 'Lexend Deca', sans-serif;
  flex: 1 1 auto; /* Allow flexible width */
}

.PopupBackground_customRadioOption__1WCqT input[type='radio'] {
  opacity: 0;
  position: absolute;
  left: -9999px;
}

.PopupBackground_customRadio__2eYWL {
  width: 16px;
  height: 16px;
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  transition: background-color 0.3s, border-color 0.3s;
}

.PopupBackground_customRadioOption__1WCqT input[type='radio']:checked + .PopupBackground_customRadio__2eYWL {
  background-color: var(--primary-color);
}

.PopupBackground_customRadioOption__1WCqT input[type='radio']:focus + .PopupBackground_customRadio__2eYWL {
  box-shadow: 0 0 0 3px rgba(71, 88, 235, 0.3);
}

.PopupBackground_customRadioOption__1WCqT span:last-child {
  margin-left: 0px;
}

/* Hover and Active States */
.PopupBackground_customRadioOption__1WCqT:hover .PopupBackground_customRadio__2eYWL {
  border-color: #3949ab;
}

.PopupBackground_customRadioOption__1WCqT:hover span {
  color: #3949ab;
}

/* Padding Input Styles */
.PopupBackground_paddingInputContainer__W8-jG {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.PopupBackground_paddingInput__SYcc9 {
  width: 60px;
  padding: 5px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.PopupBackground_paddingUnitSelect__3uQXf {
  padding: 5px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

/* Tooltip Styles */
.PopupBackground_tooltipContainer__baD9p {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
}

.PopupBackground_infoIcon__1iwwq {
  color: var(--primary-color);
  transition: color 0.3s;
}

.PopupBackground_infoIcon__1iwwq:hover {
  color: var(--danger-color);
}

.PopupBackground_tooltipText__1K1DZ {
  visibility: hidden;
  width: 220px;
  background-color: #555;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1001;
  bottom: 125%; /* Position above the icon */
  left: 50%;
  margin-left: -110px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.PopupBackground_tooltipText__1K1DZ::after {
  content: '';
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.PopupBackground_tooltipContainer__baD9p:hover .PopupBackground_tooltipText__1K1DZ {
  visibility: visible;
  opacity: 1;
}

/* New styles for error messages */
.PopupBackground_errorMessageContainer__3f-QM {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  background-color: #fdecea; /* Light red background */
  color: var(--danger-color);
  padding: 10px 15px;
  border: 1px solid #f5c6cb;
  border-radius: 8px;
  margin-top: 10px;
  font-family: 'Lexend Deca', sans-serif;
}

.PopupBackground_successMessageContainer__2s3az {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  background-color: #e6ffed; /* Light green background */
  color: var(--success-color);
  padding: 10px 15px;
  border: 1px solid #c3e6cb;
  border-radius: 8px;
  margin-top: 10px;
  font-family: 'Lexend Deca', sans-serif;
}

.PopupBackground_errorMessage__1nlRM {
  color: var(--danger-color); /* Error color */
  font-size: 12px;
  margin-top: 5px;
}

/* Additional Styles for Image Upload */
.PopupBackground_imageUpload__2x4uw {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.PopupBackground_loaderContainer__2c7N8 {
  margin-top: 10px;
}

.PopupBackground_backgroundImagePreview__P7ZGa {
  margin-top: 10px;
}

.PopupBackground_previewImage__Cl7kQ {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
}

/* Ensuring the color indicator doesn't interfere */
.PopupBackground_colorIndicator__1qtx5::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

/* Stack radio buttons vertically */
.PopupBackground_verticalRadioGroup__1oqdj {
  display: flex;
  flex-direction: column;
}

.PopupBackground_customRadioOption__1WCqT {
  margin-bottom: 10px; /* Space between radio options */
}

/* Switch Styles */
.PopupBackground_switch__p7i4U {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.PopupBackground_switch__p7i4U input {
  opacity: 0;
  width: 0;
  height: 0;
}

.PopupBackground_slider__288XE {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.PopupBackground_slider__288XE:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .PopupBackground_slider__288XE {
  background-color: #2196F3;
}

input:checked + .PopupBackground_slider__288XE:before {
  transform: translateX(26px);
}

/* Effect Option Container */
.PopupBackground_effectOption__1WBRf {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

/* Remove Text Modes */
.PopupBackground_removeTextModes__2IbGw {
  margin-left: 20px;
  margin-top: 10px;
}

/* Cost Container */
.PopupBackground_costContainer__cVYCW {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  color: var(--text-color);
  margin-top: 10px;
}

/* Messages Container */
.PopupBackground_messagesContainer__1B8Up {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .PopupBackground_dropdownRow__r9aHW {
    flex-direction: column;
  }

  .PopupBackground_imagesContainer__2HHIe {
    flex-direction: column;
    align-items: center;
  }

  .PopupBackground_sideBySideImage__aSlBQ {
    max-width: 80%;
  }
}

@media (max-width: 768px) {
  .PopupBackground_dropdownRow__r9aHW {
    flex-direction: column;
  }

  .PopupBackground_imagesContainer__2HHIe {
    flex-direction: column;
    align-items: center;
  }

  .PopupBackground_sideBySideImage__aSlBQ {
    max-width: 80%;
  }
}

/* src/popups/css/PopupUncropped.module.css */

/* CSS Variables for Consistency */
:root {
  --popup-overlay-bg: rgba(41, 43, 61, 0.8);
  --popup-content-bg: #ffffff;
  --primary-color: #4758eb;
  --primary-hover: #3949ab;
  --danger-color: #eb4755;
  --danger-hover: #d43c3a;
  --success-color: #28a745;
  --warning-color: #ffc107;
  --text-color: #292b3d;
  --secondary-text-color: #666666;
  --border-color: #ccc;
  --legend-added-bg: rgba(0, 128, 0, 0.3);
  --legend-cropped-bg: rgba(255, 0, 0, 0.3);
  --canvas-bg: #f9f9f9;
  --overlay-bg: rgba(255, 255, 255, 0.7);
  --processing-text-color: #4758eb;
}

/* Popup Overlay */
.PopupUncropped_popupOverlay__2o9hm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--popup-overlay-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 10px;
  box-sizing: border-box;
}

/* Popup Content */
.PopupUncropped_popupContent__36oH1 {
  background-color: var(--popup-content-bg);
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 1200px; /* Increased max-width for better side-by-side display */
  position: relative;
  overflow-y: auto;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

/* Popup Header */
.PopupUncropped_popupHeader__n56y2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff; /* Replace with your desired color */
}

.PopupUncropped_popupHeader__n56y2 h2 {
  font-family: 'DM Serif Text', serif;
  font-size: 24px;
  color: var(--text-color);
  margin: 0;
}

/* Top Error Message */
.PopupUncropped_topErrorMessage__8aA9p {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  background-color: #fdecea; /* Light red background */
  color: var(--danger-color);
  padding: 10px 15px;
  border: 1px solid #f5c6cb;
  border-radius: 8px;
  margin-bottom: 15px;
}

/* Content Wrapper */
.PopupUncropped_contentWrapper__21F-f {
  display: flex;
  flex-direction: row; /* Ensure row direction for side-by-side layout */
  flex-wrap: nowrap; /* Prevent wrapping on larger screens */
  grid-gap: 20px;
  gap: 20px;
  justify-content: space-between;
}

/* Original Image Section */
.PopupUncropped_originalImageSection__2l3TO {
  flex: 1 1 48%; /* Adjusted flex-basis for better side-by-side display */
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

.PopupUncropped_originalImageSection__2l3TO h3 {
  margin: 0 0 10px 0;
  font-size: 18px;
  color: var(--text-color);
}

.PopupUncropped_imageFigure__3Lfwz {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

/* Processed Image Section */
.PopupUncropped_processedImageSection__3LNd9 {
  flex: 1 1 48%; /* Adjusted flex-basis for better side-by-side display */
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.PopupUncropped_processedImageSection__3LNd9 h3 {
  margin: 0 0 10px 0;
  font-size: 18px;
  color: var(--text-color);
}

.PopupUncropped_processedImageContainer__1QssG {
  position: relative; /* For positioning the overlay */
  width: 100%;
  height: 500px; /* Same height as CANVAS_SIZE to ensure equal sizing */
  border: 1px solid var(--border-color);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
}

.PopupUncropped_processedImage__1HjEi {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Processing Overlay */
.PopupUncropped_processingOverlay__1aCXN {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlay-bg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  z-index: 10; /* Ensure it overlays the image */
}

.PopupUncropped_processingText__2qpxq {
  font-size: 16px;
  color: var(--processing-text-color);
  font-family: 'Lexend Deca', sans-serif;
}

/* Processed Status */
.PopupUncropped_processedStatus__3V9XH {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  font-size: 14px;
  color: var(--text-color);
}

/* Legend */
.PopupUncropped_legend__nMI6f {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}

.PopupUncropped_legendItem__319X2 {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.PopupUncropped_addedArea__rMNkI {
  width: 20px;
  height: 20px;
  background-color: var(--legend-added-bg);
  border: 2px dashed rgba(0, 128, 0, 0.6);
  border-radius: 4px;
}

.PopupUncropped_croppedArea__36olX {
  width: 20px;
  height: 20px;
  background-color: var(--legend-cropped-bg);
  border: 2px dashed rgba(255, 0, 0, 0.6);
  border-radius: 4px;
}

/* Controls */
.PopupUncropped_controls__TtaGQ {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
}

.PopupUncropped_dimensionInput__daOdc {
  display: flex;
  flex-direction: column;
}

.PopupUncropped_dimensionInput__daOdc label {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: var(--text-color);
  margin-bottom: 5px;
}

.PopupUncropped_dimensionInput__daOdc input {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 14px;
}

.PopupUncropped_dimensionInput__daOdc input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(71, 88, 235, 0.2);
}

.PopupUncropped_originalDimension__1aIk7 {
  font-size: 12px;
  color: var(--secondary-text-color);
  margin-top: 4px;
}

/* Canvas Wrapper */
.PopupUncropped_canvasWrapper__3x6jO {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.PopupUncropped_canvasStage__2YfFm {
  border: 1px solid var(--border-color);
  background-color: var(--canvas-bg);
  border-radius: 8px;
}

/* Loader Container */
.PopupUncropped_loaderContainer__1DjzW {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

/* Error Message */
.PopupUncropped_errorMessage__3DrzE {
  color: var(--danger-color);
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 8px;
  gap: 8px;
}

/* Top and Bottom Error Messages */
.PopupUncropped_topErrorMessage__8aA9p,
.PopupUncropped_bottomErrorMessage__1ThWz {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  background-color: #fdecea; /* Light red background */
  color: var(--danger-color);
  padding: 10px 15px;
  border: 1px solid #f5c6cb;
  border-radius: 8px;
  margin-bottom: 15px;
}

/* Button Footer */
.PopupUncropped_buttonFooter__a3GRR {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

/* Button Container */
.PopupUncropped_buttonContainer__2CSZ6 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  width: 100%;
}

/* Submit Button */
.PopupUncropped_submitButton__SWoVU {
  background-color: var(--primary-color);
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  border-radius: 24px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.2s;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.PopupUncropped_submitButton__SWoVU:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.PopupUncropped_submitButton__SWoVU:not(:disabled):hover {
  background-color: var(--primary-hover);
  transform: translateY(-2px);
}

.PopupUncropped_submitButton__SWoVU:not(:disabled):focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(71, 88, 235, 0.4);
}

/* Cancel Button */
.PopupUncropped_cancelButton__2Y5d2 {
  background-color: var(--danger-color);
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  border-radius: 24px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.2s;
}

.PopupUncropped_cancelButton__2Y5d2:hover {
  background-color: var(--danger-hover);
  transform: translateY(-2px);
}

.PopupUncropped_cancelButton__2Y5d2:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(235, 71, 85, 0.4);
}

/* Credit Info */
.PopupUncropped_creditInfo__DkqKg {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  font-size: 14px;
  color: var(--secondary-text-color);
}

.PopupUncropped_creditInfo__DkqKg strong {
  color: var(--text-color);
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .PopupUncropped_contentWrapper__21F-f {
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
  }

  .PopupUncropped_originalImageSection__2l3TO,
  .PopupUncropped_processedImageSection__3LNd9 {
    flex: 1 1 100%; /* Stack vertically */
  }

  .PopupUncropped_processedImageContainer__1QssG {
    height: 400px; /* Adjust height when stacking vertically */
  }

  .PopupUncropped_buttonContainer__2CSZ6 {
    justify-content: space-between;
  }

  .PopupUncropped_creditInfo__DkqKg {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .PopupUncropped_controls__TtaGQ {
    align-items: center;
  }

  .PopupUncropped_dimensionInput__daOdc {
    width: 100%;
    max-width: 300px;
  }

  .PopupUncropped_canvasWrapper__3x6jO {
    width: 100%;
  }

  .PopupUncropped_processedImageContainer__1QssG {
    max-height: 300px;
    height: 300px; /* Ensure consistent height on smaller screens */
  }

  .PopupUncropped_buttonContainer__2CSZ6 {
    flex-direction: column;
    align-items: flex-end;
    grid-gap: 10px;
    gap: 10px;
  }

  .PopupUncropped_creditInfo__DkqKg {
    align-self: flex-end;
  }
}

@media (max-width: 480px) {
  .PopupUncropped_popupContent__36oH1 {
    padding: 15px;
  }

  .PopupUncropped_popupHeader__n56y2 h2 {
    font-size: 20px;
  }

  .PopupUncropped_originalImageSection__2l3TO h3,
  .PopupUncropped_processedImageSection__3LNd9 h3 {
    font-size: 16px;
  }

  .PopupUncropped_submitButton__SWoVU,
  .PopupUncropped_cancelButton__2Y5d2 {
    padding: 10px 20px;
    font-size: 14px;
  }

  .PopupUncropped_dimensionInput__daOdc input {
    padding: 6px;
    font-size: 12px;
  }

  .PopupUncropped_processingText__2qpxq {
    font-size: 14px;
  }

  .PopupUncropped_buttonContainer__2CSZ6 {
    flex-direction: column;
    align-items: stretch;
  }

  .PopupUncropped_creditInfo__DkqKg {
    align-self: stretch;
    justify-content: center;
    text-align: right;
  }
}

/* CSS Variables for Consistency */
:root {
  --popup-overlay-bg: rgba(41, 43, 61, 0.8);
  --popup-content-bg: #ffffff;
  --primary-color: #4758eb;
  --primary-hover: #3949ab;
  --danger-color: #eb4755;
  --danger-hover: #d43c3a;
  --success-color: #28a745;
  --warning-color: #ffc107;
  --text-color: #292b3d;
  --secondary-text-color: #666666;
  --border-color: #ccc;
  --legend-added-bg: rgba(0, 128, 0, 0.3);
  --legend-cropped-bg: rgba(255, 0, 0, 0.3);
  --canvas-bg: #f9f9f9;
  --overlay-bg: rgba(255, 255, 255, 0.7);
  --processing-text-color: #4758eb;
  --tooltip-bg: #333333;
  --tooltip-text: #ffffff;
}

/* Popup Overlay */
.PopupImport_popupOverlay__1nu9V {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--popup-overlay-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
  box-sizing: border-box;
  overflow: visible;
}

/* Popup Content */
.PopupImport_popupContent__b4uxm {
  background-color: var(--popup-content-bg);
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 1200px;
  position: relative;
  overflow: visible;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  animation: PopupImport_fadeIn__1Srib 0.3s ease-in-out;
  z-index: 1001;
  box-sizing: border-box;
}

/* Fade-in Animation */
@keyframes PopupImport_fadeIn__1Srib {
  from { opacity: 0; transform: scale(0.95); }
  to { opacity: 1; transform: scale(1); }
}

/* Popup Header */
.PopupImport_popupHeader__32VPG {
  display: flex;
  background-color: #ffffff;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  box-sizing: border-box;
  padding-bottom: 10px;
}

/* Title */
.PopupImport_title__yMU5h {
  font-family: 'DM Serif Text', serif;
  font-size: 24px;
  color: var(--text-color);
  margin: 0;
}

/* Image and ID Container */
.PopupImport_imageAndIdContainer__Xc2DE {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  flex-wrap: wrap;
  padding-left: 20px;
}

/* Current Image */
.PopupImport_currentImage__38UJi {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid var(--border-color);
}

/* ID Badge */
.PopupImport_idBadge__2BTEZ {
  display: flex;
  align-items: center;
  background-color: var(--overlay-bg);
  border-radius: 12px;
  padding: 4px 8px;
  font-size: 16px;
  color: var(--text-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.3s;
}

/* Increased z-index for tooltips */
.PopupImport_tooltipText__kVE-I {
  z-index: 1002;
}

/* ID Badge Hover Effect */
.PopupImport_idBadge__2BTEZ:hover {
  transform: scale(1.05);
}

/* ID Icon */
.PopupImport_idIcon__1QdEc {
  margin-right: 6px;
  color: var(--primary-color);
  flex-shrink: 0;
}

/* Info Icon */
.PopupImport_infoIcon__35UZI {
  margin-left: 6px;
  color: var(--secondary-text-color);
  cursor: pointer;
  transition: color 0.3s;
  flex-shrink: 0;
}

.PopupImport_infoIcon__35UZI:hover {
  color: var(--primary-color);
}

/* Tooltip Container */
.PopupImport_tooltipContainer__3yFVX {
  position: relative;
  display: inline-block;
}

/* Tooltip Text */
.PopupImport_tooltipText__kVE-I {
  visibility: hidden;
  width: 220px;
  background-color: var(--tooltip-bg);
  color: var(--tooltip-text);
  text-align: left;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1002;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 14px;
  pointer-events: none;
}

/* Tooltip Arrow */
.PopupImport_tooltipText__kVE-I::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: var(--tooltip-bg) transparent transparent transparent;
}

/* Show Tooltip on Hover */
.PopupImport_tooltipContainer__3yFVX:hover .PopupImport_tooltipText__kVE-I {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

/* Input Container */
.PopupImport_inputContainer__3D384 {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  position: relative;
  animation: PopupImport_fadeIn__1Srib 0.5s ease-in-out;
  box-sizing: border-box;
  padding-bottom: 22px;
  padding-left: 20px;
  border-bottom: 1px solid var(--border-color);
}

/* Input Label */
.PopupImport_inputLabel__3d-vX {
  font-size: 16px;
  color: var(--text-color);
  font-weight: 500;
  animation: PopupImport_fadeIn__1Srib 0.5s ease-in-out;
  box-sizing: border-box;
}

/* ID Input Field */
.PopupImport_idInput__2AhUr {
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 14px;
  width: 400px;
  max-width: 100%;
  transition: border-color 0.3s, box-shadow 0.3s;
  box-sizing: border-box;
}

.PopupImport_idInput__2AhUr:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(71, 88, 235, 0.4);
}

/* Instructional Text */
.PopupImport_instructionalText__3lMHP {
  padding: 10px;
  background-color: var(--overlay-bg);
  border-left: 4px solid var(--primary-color);
  border-radius: 4px;
  font-size: 14px;
  color: var(--text-color);
  animation: PopupImport_slideIn__2P3WB 0.5s ease-out;
  box-sizing: border-box;
}

/* Slide-in Animation for Instructional Text */
@keyframes PopupImport_slideIn__2P3WB {
  from { opacity: 0; transform: translateX(-10px); }
  to { opacity: 1; transform: translateX(0); }
}

/* Popup Body */
.PopupImport_popupBody__1fEbF {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  overflow-y: auto;
  padding-right: 10px;
  box-sizing: border-box;
}

/* Groups Container */
.PopupImport_groupsContainer__QShKI {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  box-sizing: border-box;
}

/* Image Group */
.PopupImport_imageGroup__wMwgE {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-left: 10px;
  padding-bottom: 5px;
}

/* Group Title */
.PopupImport_groupTitle__1P0jT {
  margin-bottom: 10px;
  font-size: 1.2em;
  color: var(--text-color);
  font-family: 'Lexend Deca', sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Images Row */
.PopupImport_imagesRow__36zoP {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  box-sizing: border-box;
}

/* Image Wrapper */
.PopupImport_imageWrapper__22-ms {
  border: 2px solid transparent;
  border-radius: 8px;
  padding: 4px;
  cursor: pointer;
  position: relative;
  transition: border-color 0.3s, box-shadow 0.3s, transform 0.2s;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 0;
}

.PopupImport_imageWrapper__22-ms:hover {
  border-color: var(--primary-color);
  transform: scale(1.02);
}

.PopupImport_imageWrapper__22-ms:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(71, 88, 235, 0.4);
}

.PopupImport_selected__3TJdH {
  border: 3px solid var(--primary-color);
  box-shadow: 0 0 10px rgba(71, 88, 235, 0.5);
}

/* Checkmark Overlay */
.PopupImport_checkmark__1QxVf {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.PopupImport_selected__3TJdH .PopupImport_checkmark__1QxVf {
  display: flex;
}

/* Image Styling */
.PopupImport_image__2kAXI {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  display: block;
}

/* Labels Container */
.PopupImport_labelsContainer__AVFqh {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
  width: 100%;
  padding: 0 4px;
  box-sizing: border-box;
  overflow: hidden;
}

/* Image Main Label */
.PopupImport_imageMainLabel__3TTnv {
  text-align: center;
  font-size: 14px;
  color: var(--text-color);
  margin: 0;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

/* Image Sub Label */
.PopupImport_imageSubLabel__3Y_St {
  text-align: center;
  font-size: 12px;
  color: var(--secondary-text-color);
  margin: 2px 0 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

/* Popup Footer */
.PopupImport_popupFooter__VzGW- {
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;
  gap: 10px;
  padding-top: 10px;
  border-top: 1px solid var(--border-color);
  box-sizing: border-box;
  background-color: var(--popup-content-bg);
}

/* Import Button */
.PopupImport_importButton__247R4 {
  background-color: var(--primary-color);
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  border-radius: 24px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.2s;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.PopupImport_importButton__247R4:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.PopupImport_importButton__247R4:not(:disabled):hover {
  background-color: var(--primary-hover);
  transform: translateY(-2px);
}

.PopupImport_importButton__247R4:not(:disabled):focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(71, 88, 235, 0.4);
}

/* Cancel Button */
.PopupImport_cancelButton__2pW8E {
  background-color: var(--danger-color);
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  border-radius: 24px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.2s;
}

.PopupImport_cancelButton__2pW8E:hover {
  background-color: var(--danger-hover);
  transform: translateY(-2px);
}

.PopupImport_cancelButton__2pW8E:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(235, 71, 85, 0.4);
}

/* Loader Container */
.PopupImport_loaderContainer__12gCO {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  box-sizing: border-box;
}

/* Error Message */
.PopupImport_errorText__1GT4C {
  color: var(--danger-color);
  font-size: 16px;
  text-align: center;
}

/* Success Message */
.PopupImport_successText__2XeBq {
  color: var(--success-color);
  font-size: 16px;
  text-align: center;
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .PopupImport_popupContent__b4uxm {
    max-width: 1000px;
  }

  .PopupImport_imageWrapper__22-ms {
    width: 140px;
    height: 140px;
  }

  .PopupImport_groupTitle__1P0jT {
    font-size: 1.1em;
  }

  .PopupImport_imagesRow__36zoP {
    grid-gap: 14px;
    gap: 14px;
  }

  .PopupImport_imageMainLabel__3TTnv {
    font-size: 13px;
  }

  .PopupImport_imageSubLabel__3Y_St {
    font-size: 11px;
  }

  .PopupImport_importButton__247R4,
  .PopupImport_cancelButton__2pW8E {
    padding: 10px 20px;
    font-size: 15px;
  }

  .PopupImport_idBadge__2BTEZ {
    font-size: 15px;
    padding: 3px 7px;
  }

  .PopupImport_idIcon__1QdEc {
    width: 16px;
    height: 16px;
  }

  .PopupImport_idText__1IfB7 {
    font-size: 15px;
  }

  .PopupImport_currentImage__38UJi {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 768px) {
  .PopupImport_popupContent__b4uxm {
    max-width: 90%;
    padding: 15px;
  }

  .PopupImport_imageWrapper__22-ms {
    width: 130px;
    height: 130px;
  }

  .PopupImport_groupTitle__1P0jT {
    font-size: 1em;
  }

  .PopupImport_imagesRow__36zoP {
    grid-gap: 12px;
    gap: 12px;
  }

  .PopupImport_imageMainLabel__3TTnv {
    font-size: 12px;
  }

  .PopupImport_imageSubLabel__3Y_St {
    font-size: 10px;
  }

  .PopupImport_importButton__247R4,
  .PopupImport_cancelButton__2pW8E {
    padding: 10px 20px;
    font-size: 14px;
  }

  .PopupImport_idBadge__2BTEZ {
    font-size: 14px;
    padding: 3px 6px;
  }

  .PopupImport_idIcon__1QdEc {
    width: 14px;
    height: 14px;
  }

  .PopupImport_idText__1IfB7 {
    font-size: 14px;
  }

  .PopupImport_imagesRow__36zoP {
    grid-gap: 10px;
    gap: 10px;
  }

  .PopupImport_imageWrapper__22-ms {
    width: 120px;
    height: 120px;
  }

  .PopupImport_inputContainer__3D384 {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .PopupImport_currentImage__38UJi {
    width: 45px;
    height: 45px;
  }
}

@media (max-width: 480px) {
  .PopupImport_popupContent__b4uxm {
    max-width: 95%;
    padding: 10px;
  }

  .PopupImport_popupHeader__32VPG h2 {
    font-size: 20px;
  }

  .PopupImport_idBadge__2BTEZ {
    font-size: 13px;
    padding: 2px 5px;
  }

  .PopupImport_idIcon__1QdEc {
    width: 12px;
    height: 12px;
  }

  .PopupImport_idText__1IfB7 {
    font-size: 13px;
  }

  .PopupImport_imagesRow__36zoP {
    grid-gap: 8px;
    gap: 8px;
  }

  .PopupImport_imageWrapper__22-ms {
    width: 100px;
    height: 100px;
  }

  .PopupImport_groupTitle__1P0jT {
    font-size: 1em;
  }

  .PopupImport_imageMainLabel__3TTnv {
    font-size: 11px;
  }

  .PopupImport_imageSubLabel__3Y_St {
    font-size: 9px;
  }

  .PopupImport_importButton__247R4,
  .PopupImport_cancelButton__2pW8E {
    padding: 8px 16px;
    font-size: 13px;
  }

  .PopupImport_checkmark__1QxVf {
    width: 20px;
    height: 20px;
    font-size: 14px;
  }

  .PopupImport_idInput__2AhUr {
    width: 100%;
    margin-top: 10px;
  }

  .PopupImport_imageAndIdContainer__Xc2DE {
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 10px;
    gap: 10px;
  }

  .PopupImport_instructionalText__3lMHP {
    font-size: 12px;
  }

  .PopupImport_currentImage__38UJi {
    width: 40px;
    height: 40px;
  }
}

/* src/popups/css/TransformPopup.module.css */

.PopupTransform_overlay__tAWIy {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .PopupTransform_popup__26hdy {
    background-color: #fff;
    padding: 20px;
    width: 400px;
    max-width: 90%;
    border-radius: 8px;
    position: relative;
    text-align: center;
  }
  
  .PopupTransform_closeButton__-4vMk {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .PopupTransform_previewImage__95ydK {
    width: 100%;
    max-height: 200px;
    object-fit: contain;
    margin-bottom: 20px;
  }
  
  .PopupTransform_optionsContainer__1qNp6 {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }
  
  .PopupTransform_transformButton__3lu2e {
    background-color: #4758EB;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 16px;
  }
  
  .PopupTransform_transformButton__3lu2e:hover {
    background-color: #3949ab;
  }
  
  .PopupTransform_transformButton__3lu2e:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  

  /* ... (existing styles) */

.PopupTransform_message__1hGs9 {
    margin-top: 20px;
    font-size: 14px;
    color: #292B3D;
    font-family: 'Lexend Deca', sans-serif;
  }
  
  .PopupTransform_success__yPRVO {
    color: #5CD685;
  }
  
  .PopupTransform_error___6VPA {
    color: #EB4755;
  }
  
  .PopupTransform_noOptions__2YiHQ {
    font-size: 14px;
    color: #999;
    font-family: 'Lexend Deca', sans-serif;
    margin-top: 10px;
  }
  

  /* Add these styles to your TransformerPage.module.css */

.PopupTransform_successMessage__LCGin {
  padding: 15px;
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  border-radius: 4px;
  margin-top: 20px;
}

.PopupTransform_errorMessage__2trdG {
  padding: 15px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  margin-top: 20px;
}

.PopupExport_overlay__gEWvQ {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.PopupExport_popupContainer__1YUnS {
  background-color: #ffffff;
  padding: 20px 30px;
  border-radius: 10px;
  width: 90%;
  max-width: 800px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
}

.PopupExport_header__WiQ8F {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.PopupExport_header__WiQ8F h2 {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
  color: #292B3D;
}

.PopupExport_section__3jmm8 {
  margin-bottom: 30px;
}

.PopupExport_section__3jmm8 h3 {
  margin-bottom: 15px;
  font-family: 'DM Sans', sans-serif;
  color: #292B3D;
  font-size: 18px;
}

.PopupExport_updateFeedContainer__2g0rg {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  flex-wrap: wrap;
}

.PopupExport_updateButton__3FkES {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PopupExport_updateButton__3FkES:hover {
  background-color: #848EE0 !important;
}

.PopupExport_updateButton__3FkES:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.PopupExport_spinner__JUEba {
  animation: PopupExport_spin__1RHMa 1s linear infinite;
}

@keyframes PopupExport_spin__1RHMa {
  to {
    transform: rotate(360deg);
  }
}

.PopupExport_lastUpdated__1BIEm {
  font-family: 'Lexend Deca', sans-serif;
  color: #292B3D;
  font-size: 14px;
}

.PopupExport_successMessage__M5OEN {
  margin-top: 10px;
  color: #28a745;
  font-family: 'Lexend Deca', sans-serif;
}

.PopupExport_errorMessage__2fxq1 {
  margin-top: 10px;
  color: #dc3545;
  font-family: 'Lexend Deca', sans-serif;
}

.PopupExport_imagePreviewContainer__37ijG {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 20px; /* This sets the gap between rows only */
  grid-column-gap: 20px;
  column-gap: 20px; /* This sets the gap between columns only */
}

.PopupExport_imageItem__FQ1yL {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding-bottom: 15px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  height: 190px;
}

.PopupExport_previewImage__rxKgX {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  transition: transform 0.3s;
}

.PopupExport_previewImage__rxKgX:hover {
  transform: scale(1.05);
}

.PopupExport_imageLabel__1DX0Z {
  margin-top: 8px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #292B3D;
  text-align: center;
  word-wrap: break-word;
  max-width: 200px;
}

.PopupExport_buttonContainer__3Cc72 {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 5px;
}

.PopupExport_actionButton__b6Lpd {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 13px;
  transition: background-color 0.3s;
}

.PopupExport_actionButton__b6Lpd:hover {
  background-color: #848EE0 !important;
}

.PopupExport_actionButton__b6Lpd:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* src/popups/css/PopupBulkActions.module.css */

.PopupBulkActions_popupOverlay__2s7V7 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .PopupBulkActions_popup__1Q-Cm {
    background-color: #ffffff;
    width: 95%;
    max-width: 2440px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 90%;
    max-height: 1000px;
  }
  
  .PopupBulkActions_headerAndTabs__hLHBH {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px; /* Reduced padding */
    padding-top: 20px; /* Reduced padding */
    padding-right: 16px; /* Reduced padding */
    background-color: #FFFFFF;
    color: #292B3D;
  }
  
  .PopupBulkActions_headerLeft__2X1ix {
    display: flex;
    align-items: center; /* Center title and tabs vertically */
    font-family: 'DM Serif Text', serif;
    flex-grow: 1; /* Allow the header left to grow */
  }
  
.PopupBulkActions_headerTitle__Szy1n h2 {
    font-size: 20px; /* Reduced font size */
    color: #292B3D;
    margin-right: 0;
}
  
.PopupBulkActions_tabContainer__-E27Q {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
}
  
.PopupBulkActions_tabGroup__3kNSx {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 16px; /* Reduced margin */
    padding-right: 16px; /* Reduced padding */
    border-right: 1px solid #E0E1EB;
}

.PopupBulkActions_tabGroup__3kNSx:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
}
  
  
  .PopupBulkActions_groupLabel__3McTg {
    font-family: 'Lexend Deca', sans-serif;
    font-size: 11px;
    color: #4758EB;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: bold;
    margin-bottom: 4px;
  }
  
  .PopupBulkActions_tabRow__3M17h {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 4px; /* Reduced margin */
}
  
  .PopupBulkActions_tabButton__1Uhif {
    padding: 6px 10px;
    background-color: transparent;
    border: 2px dashed transparent; /* Reserve space for the border */
    color: #292B3D;
    cursor: pointer;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 13px;
    display: flex;
    align-items: center;
    transition: background-color 0.2s, border-color 0.2s; /* Smooth transition */
    border-radius: 4px;
    margin-right: 8px; /* Reduced margin */
    margin-bottom: 4px; /* Reduced margin */
    box-sizing: border-box; /* Include padding and border in size */
  }
  
  .PopupBulkActions_tabButton__1Uhif:hover {
    background-color: #E0E1EB !important;
  }
  
  .PopupBulkActions_activeTab__1LcwW {
    box-sizing: border-box; /* Ensure border is included in the element's width and height */
    border: 2px dashed #292B3D !important;
    color: #292B3D;
    border-radius: 4px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  }
  
  
  .PopupBulkActions_tabIcon__1Hm0r {
    margin-right: 6px;
  }
  
  .PopupBulkActions_closeButton__1x_yT {
    background-color: transparent;
    border: none;
    color: #292B3D;
    cursor: pointer;
    margin-left: auto; /* Pushes the button to the right */
  }
  
  .PopupBulkActions_contentArea__1vQ3D {
    padding: 20px;
    overflow-y: auto;
    flex-grow: 1;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin-top: 20px;
  }
  

  
/* src/popups/PopupBulkActionPages/UncropperPage.module.css */

/* You can copy the contents from TransformerPage.module.css and modify as needed */

/* Example: */

.UncropperPage_pageContainer__2f7oP {
  padding: 20px;
  font-family: 'Lexend Deca', sans-serif;
  min-height: 100vh;
}

/* Header Section: Flex container for description and image */
.UncropperPage_headerSection__1xjlx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Allows items to wrap on smaller screens */
}

/* Description styling */
.UncropperPage_description__3C4RG {
  flex: 1 1 70%; /* Takes up 60% of the container */
  margin-right: 20px;
  color: #292B3D;
  font-size: 16px;
  line-height: 1.5;
}

/* Header Image styling */
.UncropperPage_headerImage__2Okd- {
  flex: 1 1 25%; /* Takes up 35% of the container */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px; /* Adjust for spacing on smaller screens */
}

.UncropperPage_headerImage__2Okd- img {
  max-width: 100%; /* Desired width */
  height: auto; /* Maintains aspect ratio */
  object-fit: contain; /* Ensures the image scales correctly */
  border-radius: 8px; /* Optional: adds rounded corners */
}

/* Description and Image responsiveness */
@media (max-width: 768px) {
  .UncropperPage_description__3C4RG {
    flex: 1 1 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .UncropperPage_headerImage__2Okd- {
    flex: 1 1 100%;
    justify-content: center;
  }

  .UncropperPage_headerImage__2Okd- img {
    width: 100px; /* Smaller size on mobile devices */
  }
}

.UncropperPage_section__1UAED {
  margin-top: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.UncropperPage_sectionHeader__NNaP_ {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.UncropperPage_sectionHeader__NNaP_ h4 {
  margin: 0;
  font-size: 20px;
  color: #292B3D;
  font-family: 'Lexend Deca', sans-serif;
}

.UncropperPage_tooltip__2FKJU {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  color: #d0d0d0;
  cursor: pointer;
}

.UncropperPage_tooltip__2FKJU .UncropperPage_tooltiptext__1OgcK {
  visibility: hidden;
  width: 220px;
  background-color: #292B3D;
  color: #fff;
  text-align: left;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 25px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.UncropperPage_tooltip__2FKJU:hover .UncropperPage_tooltiptext__1OgcK {
  visibility: visible;
  opacity: 1;
}

.UncropperPage_multiSelect__1C-BK {
  font-family: 'Lexend Deca', sans-serif;
}

.UncropperPage_checkboxGroup__2ITnv {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}

.UncropperPage_checkboxLabel__1_mL3 {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

.UncropperPage_checkboxLabel__1_mL3 input {
  margin-right: 5px;
  cursor: pointer;
}

.UncropperPage_scopeGroup__1RuHi {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}

.UncropperPage_scopeButton__2Cf0Y {
  padding: 10px 20px;
  border: 1px solid #d0d0d0;
  border-radius: 20px;
  color: #292B3D;
  background-color: transparent;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  transition: all 0.3s ease;
  font-size: 14px;
  min-width: 120px;
  text-align: center;
}

.UncropperPage_scopeButton__2Cf0Y:hover {
  color: #292B3D !important;
  background-color: #EBE2E0 !important;
  border-color: #EBE2E0 !important;
}

.UncropperPage_activeScopeButton__2RJ95 {
  background-color: #4758EB;
  color: #ffffff;
  border-color: #4758EB;
}

.UncropperPage_actionSelect__1vXuO {
  width: 200px;
  flex: 1 1;
  min-width: 150px;
}

.UncropperPage_applyButton__Ta68F {
  background-color: #5CD685;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: all 0.2s ease;
  margin-left: 15px;
  white-space: nowrap;
}

.UncropperPage_applyButton__Ta68F:hover {
  background-color: #94D1A8 !important;
}

.UncropperPage_applyButton__Ta68F:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.UncropperPage_loadingOverlay__wO6-t {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.UncropperPage_loadingOverlay__wO6-t p {
  margin-top: 10px;
  font-size: 16px;
  color: #4758EB;
}

.UncropperPage_resultSummary__3yXDj {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.UncropperPage_failureTable__3XdvD {
  margin-top: 20px;
  overflow-x: auto; /* Enable horizontal scrolling for tables on smaller screens */
}

.UncropperPage_failureTable__3XdvD table {
  width: 100%;
  border-collapse: collapse;
}

.UncropperPage_failureTable__3XdvD th, .UncropperPage_failureTable__3XdvD td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  vertical-align: top; /* Ensure text doesn't overlap with images */
}

.UncropperPage_failureTable__3XdvD th {
  background-color: #4758EB;
  color: #ffffff;
}

.UncropperPage_failureTable__3XdvD img {
  width: 100px;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  object-fit: contain;
}

.UncropperPage_failureTable__3XdvD img:hover {
  transform: scale(1.2);
  transition: transform 0.2s ease-in-out;
}

.UncropperPage_failureTable__3XdvD a {
  color: #4758EB;
  text-decoration: none;
}

.UncropperPage_failureTable__3XdvD a:hover {
  text-decoration: underline;
}

.UncropperPage_formatAndButton__2JHF_ {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .UncropperPage_formatAndButton__2JHF_ {
    flex-direction: column;
    align-items: stretch;
  }

  .UncropperPage_applyButton__Ta68F {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
  }

  .UncropperPage_scopeGroup__1RuHi {
    flex-direction: column;
  }

  .UncropperPage_scopeButton__2Cf0Y {
    width: 100%;
  }
}

.UncropperPage_imageContainer__1rHDI {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 4px;
}

.UncropperPage_imageContainer__1rHDI img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.UncropperPage_inputGroup__2qCeX {
    display: flex;
    grid-gap: 10px;
    gap: 10px; /* Adjust spacing between inputs as needed */
    margin-bottom: 10px; /* Optional: space below the input group */
  }
  
  /* Style for each dimension input */
  .UncropperPage_dimensionInput__S5yrh {
    flex: 1 1; /* Allows inputs to grow equally within the container */
    padding: 8px 12px; /* Adjust padding to match existing inputs */
    border: 1px solid #ccc; /* Match existing border styles */
    border-radius: 4px; /* Match existing border radius */
    font-size: 14px; /* Match existing font size */
    /* Add any other existing input styles here */
  }
  
  /* Optional: Add focus styles for better UX */
  .UncropperPage_dimensionInput__S5yrh:focus {
    outline: none;
    border-color: #4758EB; /* Highlight border on focus */
    box-shadow: 0 0 5px rgba(102, 175, 233, 0.6); /* Optional: add a subtle shadow */
  }
  
  /* Adjust the container holding inputs and button */
  .UncropperPage_dimensionsAndButton__B_9Q7 {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px; /* Space between input group and button */
  }
  
  
/* BackgroundManipulatorPage.module.css */

.BackgroundManipulatorPage_pageContainer__3f1Rq {
  padding: 20px;
  font-family: 'Lexend Deca', sans-serif;
  min-height: 100vh;
}

/* Header Section: Flex container for description and image */
.BackgroundManipulatorPage_headerSection__1Pw1u {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Allows items to wrap on smaller screens */
}

/* Description styling */
.BackgroundManipulatorPage_description__1NsaH {
  flex: 1 1 70%; /* Takes up 70% of the container */
  margin-right: 20px;
  color: #292B3D;
  font-size: 16px;
  line-height: 1.5;
}

/* Header Image styling */
.BackgroundManipulatorPage_headerImage__1EkA4 {
  flex: 1 1 25%; /* Takes up 25% of the container */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px; /* Adjust for spacing on smaller screens */
}

.BackgroundManipulatorPage_headerImage__1EkA4 img {
  max-width: 100%; /* Desired width */
  height: auto; /* Maintains aspect ratio */
  object-fit: contain; /* Ensures the image scales correctly */
  border-radius: 8px; /* Optional: adds rounded corners */
}

/* Description and Image responsiveness */
@media (max-width: 768px) {
  .BackgroundManipulatorPage_description__1NsaH {
    flex: 1 1 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .BackgroundManipulatorPage_headerImage__1EkA4 {
    flex: 1 1 100%;
    justify-content: center;
  }

  .BackgroundManipulatorPage_headerImage__1EkA4 img {
    width: 100px; /* Smaller size on mobile devices */
  }
}

.BackgroundManipulatorPage_section__3yH1Y {
  margin-top: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.BackgroundManipulatorPage_sectionHeader__1S76R {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.BackgroundManipulatorPage_sectionHeader__1S76R h4 {
  margin: 0;
  font-size: 20px;
  color: #292B3D;
  font-family: 'Lexend Deca', sans-serif;
}

.BackgroundManipulatorPage_tooltip__732mx {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  color: #d0d0d0;
  cursor: pointer;
}

.BackgroundManipulatorPage_tooltip__732mx .BackgroundManipulatorPage_tooltiptext__2KMdL {
  visibility: hidden;
  width: 220px;
  background-color: #292B3D;
  color: #fff;
  text-align: left;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 25px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.BackgroundManipulatorPage_tooltip__732mx:hover .BackgroundManipulatorPage_tooltiptext__2KMdL {
  visibility: visible;
  opacity: 1;
}

.BackgroundManipulatorPage_multiSelect__14MwE {
  font-family: 'Lexend Deca', sans-serif;
}

.BackgroundManipulatorPage_checkboxGroup__22c4q {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}

.BackgroundManipulatorPage_checkboxLabel__1hyrV {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

.BackgroundManipulatorPage_checkboxLabel__1hyrV input {
  margin-right: 5px;
  cursor: pointer;
}

.BackgroundManipulatorPage_scopeGroup__2tCBg {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}

.BackgroundManipulatorPage_scopeButton__1CuU5 {
  padding: 10px 20px;
  border: 1px solid #d0d0d0;
  border-radius: 20px;
  color: #292B3D;
  background-color: transparent;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  transition: all 0.3s ease;
  font-size: 14px;
  min-width: 120px;
  text-align: center;
}

.BackgroundManipulatorPage_scopeButton__1CuU5:hover {
  color: #292B3D !important;
  background-color: #E0E1EB !important;
  border-color: #EBE2E0 !important;
}

.BackgroundManipulatorPage_activeScopeButton__3wf8C {
  background-color: #4758EB;
  color: #ffffff;
  border-color: #4758EB;
}

.BackgroundManipulatorPage_actionSelect__2yhcJ {
  width: 200px;
  flex: 1 1;
  min-width: 150px;
}

.BackgroundManipulatorPage_smallSelect____BFr {
  width: 80px;
}

.BackgroundManipulatorPage_applyButton__S-eme {
  background-color: #5CD685;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: all 0.2s ease;
  margin-left: 15px;
  white-space: nowrap;
}

.BackgroundManipulatorPage_applyButton__S-eme:hover {
  background-color: #94D1A8 !important;
}

.BackgroundManipulatorPage_applyButton__S-eme:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.BackgroundManipulatorPage_loadingOverlay__1S1zV {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.BackgroundManipulatorPage_loadingOverlay__1S1zV p {
  margin-top: 10px;
  font-size: 16px;
  color: #EB7B47;
}

.BackgroundManipulatorPage_resultSummary__29a4J {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.BackgroundManipulatorPage_failureTable__1S0nF {
  margin-top: 20px;
  overflow-x: auto; /* Enable horizontal scrolling for tables on smaller screens */
}

.BackgroundManipulatorPage_failureTable__1S0nF table {
  width: 100%;
  border-collapse: collapse;
}

.BackgroundManipulatorPage_failureTable__1S0nF th,
.BackgroundManipulatorPage_failureTable__1S0nF td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  vertical-align: top; /* Ensure text doesn't overlap with images */
}

.BackgroundManipulatorPage_failureTable__1S0nF th {
  background-color: #EB7B47;
  color: #ffffff;
}

.BackgroundManipulatorPage_failureTable__1S0nF img {
  width: 100px;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  object-fit: contain;
}

.BackgroundManipulatorPage_failureTable__1S0nF img:hover {
  transform: scale(1.2);
  transition: transform 0.2s ease-in-out;
}

.BackgroundManipulatorPage_failureTable__1S0nF a {
  color: #4758EB;
  text-decoration: none;
}

.BackgroundManipulatorPage_failureTable__1S0nF a:hover {
  text-decoration: underline;
}

.BackgroundManipulatorPage_formatAndButton__3jZOS {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .BackgroundManipulatorPage_formatAndButton__3jZOS {
    flex-direction: column;
    align-items: stretch;
  }

  .BackgroundManipulatorPage_applyButton__S-eme {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
  }

  .BackgroundManipulatorPage_scopeGroup__2tCBg {
    flex-direction: column;
  }

  .BackgroundManipulatorPage_scopeButton__1CuU5 {
    width: 100%;
  }
}

.BackgroundManipulatorPage_imageContainer__1eHHC {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 4px;
}

.BackgroundManipulatorPage_imageContainer__1eHHC img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.BackgroundManipulatorPage_optionGroup__1a6j3 {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.BackgroundManipulatorPage_optionGroupRow__v5UhG {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
}

.BackgroundManipulatorPage_optionGroupCheckbox__3dde0 {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  grid-gap: 20px;
  gap: 20px;
}

.BackgroundManipulatorPage_optionLabel__2jiyo {
  min-width: 150px;
  font-size: 14px;
  color: #292B3D;
  margin-right: 10px;
}

.BackgroundManipulatorPage_colorInput__1XJQR {
  width: 50px;
  height: 30px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.BackgroundManipulatorPage_textInput__1u0LN,
.BackgroundManipulatorPage_numberInput__fZ7W3 {
  padding: 8px;
  font-size: 14px;
  width: 200px;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
}

.BackgroundManipulatorPage_numberInput__fZ7W3 {
  width: 100px;
}

.BackgroundManipulatorPage_errorMessage__392Pe {
  color: #d93025;
  background-color: #fdecea;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.BackgroundManipulatorPage_successMessage__1c_GJ {
  color: #188038;
  background-color: #e6f4ea;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.BackgroundManipulatorPage_subSection__2qOd8 {
  margin-bottom: 30px;
}

.BackgroundManipulatorPage_subSection__2qOd8 h5 {
  font-size: 16px;
  color: #292B3D;
  margin-bottom: 15px;
}

.BackgroundManipulatorPage_messageBox__WVdvo {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.BackgroundManipulatorPage_messageBox__WVdvo i {
  margin-right: 8px;
}

.BackgroundManipulatorPage_pricingSection__bFRp1 {
  margin-top: 20px;
  padding: 20px;
  background-color: #f0f4ff;
  border-radius: 8px;
  border: 1px solid #d0d7e2;
}

.BackgroundManipulatorPage_pricingSection__bFRp1 h4 {
  margin-top: 0;
  font-size: 18px;
  color: #292B3D;
}

.BackgroundManipulatorPage_pricingSection__bFRp1 p {
  margin: 5px 0;
  color: #292B3D;
}

.BackgroundManipulatorPage_pricingSection__bFRp1 ul {
  list-style-type: disc;
  padding-left: 20px;
  color: #292B3D;
}

.BackgroundManipulatorPage_premiumLabel__3iPno {
  background-color: #fdd835;
  color: #000;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  margin-left: 5px;
}

.BackgroundManipulatorPage_optionGroupCheckbox__3dde0 .BackgroundManipulatorPage_premiumLabel__3iPno {
  margin-left: 0;
  margin-right: 5px;
}

/* src/popups/PopupBulkActionPages/ImporterPage.module.css */

.ImporterPage_pageContainer__lb8Lq {
  padding: 20px;
  font-family: 'Lexend Deca', sans-serif;
  min-height: 100vh;
}

.ImporterPage_toggleSwitch__1XSUs {
  display: flex;
  align-items: center;
  background-color: #e0e1eb;
  border-radius: 24px;
  padding: 5px;
  width: -moz-fit-content;
  width: fit-content;
  margin: 20px 0;
  grid-gap: 10px;
  gap: 10px;
  justify-content: space-between;
}

.ImporterPage_toggleButton__snCW5 {
  background-color: transparent;
  border: none;
  color: #292B3D;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 20px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.ImporterPage_internalMode__YI8-9:hover,
.ImporterPage_fileMode__3C9ec:hover {
  background-color: #4758EB !important;
  color: white !important;
}

.ImporterPage_internalActiveButton__2UKwX,
.ImporterPage_fileActiveButton__2Lxmz {
  background-color: #4758EB;
  color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.ImporterPage_internalActiveButton__2UKwX:hover,
.ImporterPage_fileActiveButton__2Lxmz:hover {
  background-color: #4758EB !important;
  color: white !important;
}

.ImporterPage_internalImportContainer__1l6kx,
.ImporterPage_fileImportContainer__2szO5 {
  margin-top: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ImporterPage_fieldGroup__3YXAU {
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
}

.ImporterPage_fieldLabel__d-_ho {
  flex: 0 0 120px; /* Increased width for better alignment */
  font-weight: bold;
  color: #292B3D;
  margin-right: 20px;
  align-self: center;
}

.ImporterPage_fieldRow__22WNt {
  display: flex;
  flex: 1 1;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
}

.ImporterPage_selectContainer__CCPGY {
  flex: 1 1;
}

.ImporterPage_select__1LIKB {
  width: 100%;
  font-family: 'Lexend Deca', sans-serif;
}

.ImporterPage_imageTypeContainer__r1GIj {
  flex: 1 1;
  display: flex;
  align-items: center;
}

.ImporterPage_imageTypeLabel__2iLC5 {
  margin-right: 10px;
  font-weight: bold;
  white-space: nowrap;
}

.ImporterPage_radioGroup__2ZRiF {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}

.ImporterPage_radioLabel__vDif1 {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.ImporterPage_radioLabel__vDif1 input {
  margin-right: 5px;
}

.ImporterPage_importPlaceholder__p-lhq {
  font-size: 14px;
  color: #555555;
  flex: 1 1; /* Allow the placeholder to take available space */
}

.ImporterPage_buttonGroup__-KJKy {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end; /* Align the button to the right */
}

.ImporterPage_applyButton__1NRdQ {
  background-color: #5CD685;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: all 0.2s ease;
}

.ImporterPage_applyButton__1NRdQ:hover {
  background-color: #94D1A8 !important;
}

.ImporterPage_applyButton__1NRdQ:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.ImporterPage_scopeGroup__1D1a0 {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}

.ImporterPage_scopeButton__VcreC {
  padding: 8px 16px;
  border: 1px solid #d0d0d0;
  border-radius: 20px;
  color: #292B3D;
  background-color: transparent;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  transition: all 0.3s ease;
  font-size: 14px;
}

.ImporterPage_scopeButton__VcreC:hover {
  color: #292B3D !important;
  background-color: #E0E1EB !important;
  border-color: #E0E1EB !important;
}

.ImporterPage_activeScopeButton__1V_CS {
  background-color: #4758EB;
  color: #ffffff;
  border-color: #4758EB;
}

.ImporterPage_loadingOverlay__1Rtns {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1000;
}

.ImporterPage_loadingOverlay__1Rtns p {
  margin-top: 10px;
  font-size: 16px;
  color: #4758EB;
}

.ImporterPage_idSelectionContainer__2lp4T {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

/* Ensure dropdowns and image type sections have the same width */
.ImporterPage_fieldRow__22WNt > .ImporterPage_selectContainer__CCPGY,
.ImporterPage_fieldRow__22WNt > .ImporterPage_imageTypeContainer__r1GIj {
  flex: 1 1;
}

/* Ensure dropdowns and image type sections have the same width on smaller screens */
@media (max-width: 768px) {
  .ImporterPage_fieldGroup__3YXAU {
    flex-direction: column;
    align-items: flex-start;
  }

  .ImporterPage_fieldLabel__d-_ho {
    flex: 0 0 auto;
    margin-bottom: 10px;
  }

  .ImporterPage_fieldRow__22WNt {
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }

  .ImporterPage_selectContainer__CCPGY,
  .ImporterPage_imageTypeContainer__r1GIj {
    width: 100%;
  }

  .ImporterPage_scopeGroup__1D1a0 {
    flex-direction: column;
    width: 100%;
  }

  .ImporterPage_scopeButton__VcreC {
    width: 100%; /* Make buttons full width on smaller screens */
  }

  .ImporterPage_buttonGroup__-KJKy {
    justify-content: center; /* Center the button on smaller screens */
  }
}

/* New Styles for Column Selection */
.ImporterPage_columnSelection__kuxbo {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 20px;
}

.ImporterPage_selectWrapper__2uXhy {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.ImporterPage_selectionLabel__cnnwJ {
  margin-bottom: 5px;
  font-weight: bold;
  color: #292B3D;
}

.ImporterPage_tooltip__2G8OY {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  color: #d0d0d0;
  cursor: pointer;
}

.ImporterPage_tooltip__2G8OY .ImporterPage_tooltiptext__3HA3v {
  visibility: hidden;
  width: 220px;
  background-color: #292B3D;
  color: #fff;
  text-align: left;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 25px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.ImporterPage_tooltip__2G8OY:hover .ImporterPage_tooltiptext__3HA3v {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 768px) {
  .ImporterPage_columnSelection__kuxbo {
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }
}

/* New Styles for Preview Section */
.ImporterPage_previewContainer__9TcOG {
  margin-top: 20px;
}

.ImporterPage_previewTableContainer__3I_RI {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.ImporterPage_previewTable__3Pe-1 {
  width: 100%;
  border-collapse: collapse;
}

.ImporterPage_previewTable__3Pe-1 th,
.ImporterPage_previewTable__3Pe-1 td {
  padding: 8px 12px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.ImporterPage_previewTable__3Pe-1 th {
  background-color: #4758EB;
  color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1;
}

.ImporterPage_loadingPreview__3Ym8e {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 10px;
}

.ImporterPage_errorMessage__1MF9t {
  color: red;
  margin-top: 10px;
}

@media (max-width: 768px) {
  /* Adjust preview table for smaller screens */
  .ImporterPage_previewTable__3Pe-1 th,
  .ImporterPage_previewTable__3Pe-1 td {
    padding: 6px 8px;
    font-size: 12px;
  }
}

/* New Styles for To Field Row in File Import */
.ImporterPage_toFieldRow__3Ha0d {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 10px;
}

/* Ensures the placeholder text aligns properly */
.ImporterPage_importPlaceholder__p-lhq {
  font-size: 14px;
  color: #555555;
  flex: 1 1; /* Allow the placeholder to take available space */
}

@media (max-width: 768px) {
  .ImporterPage_toFieldRow__3Ha0d {
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 10px;
    gap: 10px;
  }

  .ImporterPage_importPlaceholder__p-lhq {
    font-size: 12px;
  }
}

/* New Styles for To Field Container */
.ImporterPage_toFieldContainer__1rFJ- {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px; /* Space between label and dropdown */
  flex: 1 1;
}

/* Updated Styles for To Label */
.ImporterPage_toLabel__1wpvn {
  font-weight: bold;
  color: #292B3D;
  display: flex;
  align-items: center;
}

/* Ensure the Select component takes the remaining space */
.ImporterPage_toFieldContainer__1rFJ- .ImporterPage_select__1LIKB {
  flex: 1 1;
}

/* Adjust Image Type Container to align properly */
.ImporterPage_imageTypeContainer__r1GIj {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 10px; /* Add margin for spacing on smaller screens */
}

@media (max-width: 768px) {
  .ImporterPage_columnSelection__kuxbo {
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }

  .ImporterPage_toFieldContainer__1rFJ- {
    width: 100%;
  }

  .ImporterPage_imageTypeContainer__r1GIj {
    width: 100%;
    margin-top: 5px;
  }
}

/* New Styles for Step Container */
.ImporterPage_stepContainer__3kcYk {
  margin-bottom: 30px;
}

.ImporterPage_stepHeader__3IjQg {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ImporterPage_stepNumber__1NtXb {
  width: 30px;
  height: 30px;
  background-color: #4758EB;
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 10px;
}

.ImporterPage_stepTitle__FE4ce {
  font-size: 18px;
  color: #292B3D;
}

/* Adjust file input */
.ImporterPage_fileInput__1viOV {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .ImporterPage_stepHeader__3IjQg {
    flex-direction: row;
    align-items: center;
  }

  .ImporterPage_stepNumber__1NtXb {
    width: 25px;
    height: 25px;
    font-size: 14px;
  }

  .ImporterPage_stepTitle__FE4ce {
    font-size: 16px;
  }
}


/* src/popups/PopupBulkActionPages/TransformerPage.module.css */

.TransformerPage_pageContainer__Rnm7h {
  padding: 20px;
  font-family: 'Lexend Deca', sans-serif;
  min-height: 100vh;
}

/* Header Section: Flex container for description and image */
.TransformerPage_headerSection__30STY {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Allows items to wrap on smaller screens */
}

/* Description styling */
.TransformerPage_description__1Axef {
  flex: 1 1 70%; /* Takes up 60% of the container */
  margin-right: 20px;
  color: #292B3D;
  font-size: 16px;
  line-height: 1.5;
}

/* Header Image styling */
.TransformerPage_headerImage__2B7Wq {
  flex: 1 1 25%; /* Takes up 35% of the container */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px; /* Adjust for spacing on smaller screens */
}

.TransformerPage_headerImage__2B7Wq img {
  max-width: 100%; /* Desired width */
  height: auto; /* Maintains aspect ratio */
  object-fit: contain; /* Ensures the image scales correctly */
  border-radius: 8px; /* Optional: adds rounded corners */
}


/* Description and Image responsiveness */
@media (max-width: 768px) {
  .TransformerPage_description__1Axef {
    flex: 1 1 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .TransformerPage_headerImage__2B7Wq {
    flex: 1 1 100%;
    justify-content: center;
  }

  .TransformerPage_headerImage__2B7Wq img {
    width: 100px; /* Smaller size on mobile devices */
  }
}

.TransformerPage_section__l6nvR {
  margin-top: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.TransformerPage_sectionHeader__3T5mF {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.TransformerPage_sectionHeader__3T5mF h4 {
  margin: 0;
  font-size: 20px;
  color: #292B3D;
  font-family: 'Lexend Deca', sans-serif;
}

.TransformerPage_tooltip__1V57A {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  color: #d0d0d0;
  cursor: pointer;
}

.TransformerPage_tooltip__1V57A .TransformerPage_tooltiptext__2qMrP {
  visibility: hidden;
  width: 220px;
  background-color: #292B3D;
  color: #fff;
  text-align: left;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 25px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.TransformerPage_tooltip__1V57A:hover .TransformerPage_tooltiptext__2qMrP {
  visibility: visible;
  opacity: 1;
}

.TransformerPage_multiSelect__33B0r {
  font-family: 'Lexend Deca', sans-serif;
}

.TransformerPage_checkboxGroup__2UvJM {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}

.TransformerPage_checkboxLabel__qXWhb {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

.TransformerPage_checkboxLabel__qXWhb input {
  margin-right: 5px;
  cursor: pointer;
}

.TransformerPage_scopeGroup__1FCNV {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}

.TransformerPage_scopeButton__2hkQ2 {
  padding: 10px 20px;
  border: 1px solid #d0d0d0;
  border-radius: 20px;
  color: #292B3D;
  background-color: transparent;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  transition: all 0.3s ease;
  font-size: 14px;
  min-width: 120px;
  text-align: center;
}

.TransformerPage_scopeButton__2hkQ2:hover {
  color: #292B3D !important;
  background-color: #EBE2E0 !important;
  border-color: #EBE2E0 !important;
}

.TransformerPage_activeScopeButton__1a5nK {
  background-color: #EB7B47;
  color: #ffffff;
  border-color: #EB7B47;
}

.TransformerPage_actionSelect__3Rtv1 {
  width: 200px;
  flex: 1 1;
  min-width: 150px;
}

.TransformerPage_applyButton__24EUn {
  background-color: #5CD685;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: all 0.2s ease;
  margin-left: 15px;
  white-space: nowrap;
}

.TransformerPage_applyButton__24EUn:hover {
  background-color: #94D1A8 !important;
}

.TransformerPage_applyButton__24EUn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.TransformerPage_loadingOverlay__2QRJt {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.TransformerPage_loadingOverlay__2QRJt p {
  margin-top: 10px;
  font-size: 16px;
  color: #EB7B47;
}

.TransformerPage_resultSummary__29uk6 {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.TransformerPage_failureTable__32guU {
  margin-top: 20px;
  overflow-x: auto; /* Enable horizontal scrolling for tables on smaller screens */
}

.TransformerPage_failureTable__32guU table {
  width: 100%;
  border-collapse: collapse;
}

.TransformerPage_failureTable__32guU th, .TransformerPage_failureTable__32guU td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  vertical-align: top; /* Ensure text doesn't overlap with images */
}

.TransformerPage_failureTable__32guU th {
  background-color: #EB7B47;
  color: #ffffff;
}

.TransformerPage_failureTable__32guU img {
  width: 100px;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  object-fit: contain;
}

.TransformerPage_failureTable__32guU img:hover {
  transform: scale(1.2);
  transition: transform 0.2s ease-in-out;
}

.TransformerPage_failureTable__32guU a {
  color: #4758EB;
  text-decoration: none;
}

.TransformerPage_failureTable__32guU a:hover {
  text-decoration: underline;
}

.TransformerPage_formatAndButton__i5HGg {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .TransformerPage_formatAndButton__i5HGg {
    flex-direction: column;
    align-items: stretch;
  }

  .TransformerPage_applyButton__24EUn {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
  }

  .TransformerPage_scopeGroup__1FCNV {
    flex-direction: column;
  }

  .TransformerPage_scopeButton__2hkQ2 {
    width: 100%;
  }
}

.TransformerPage_imageContainer__h5X2w {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 4px;
}

.TransformerPage_imageContainer__h5X2w img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.SelectorPage_pageContainer__11amp {
  padding: 20px;
  font-family: 'Lexend Deca', sans-serif;
}

/* Unified Top Section */
.SelectorPage_topSection__30pzi {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.SelectorPage_textContainer__G-dW2 {
  flex: 1 1;
  min-width: 250px;
  margin-right: 20px;
}

.SelectorPage_headerTitle__1jXoq {
  font-size: 28px;
  color: #292B3D;
  margin: 0 0 10px 0;
}

.SelectorPage_description__3dULG {
  font-size: 16px;
  color: #555555;
  line-height: 1.5;
}

.SelectorPage_headerImage__QAgN- {
  width: 200px;
  height: auto;
  max-width: 100%;
}

/* Select Image Columns */
.SelectorPage_section__3ZKqb {
  margin-top: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.SelectorPage_sectionHeader__3IrHK {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.SelectorPage_sectionHeader__3IrHK h4 {
  margin: 0;
  font-size: 20px;
  color: #292B3D;
  font-family: 'Lexend Deca', sans-serif;
}

.SelectorPage_tooltip__11R_f {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  color: #d0d0d0;
  cursor: pointer;
}

.SelectorPage_tooltip__11R_f .SelectorPage_tooltiptext__GRGxY {
  visibility: hidden;
  width: 220px;
  background-color: #292B3D;
  color: #fff;
  text-align: left;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 25px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.SelectorPage_tooltip__11R_f:hover .SelectorPage_tooltiptext__GRGxY {
  visibility: visible;
  opacity: 1;
}

.SelectorPage_multiSelect__3-xnK {
  font-family: 'Lexend Deca', sans-serif;
}

.SelectorPage_checkboxGroup__1vz-i {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}

.SelectorPage_checkboxLabel__1S5XQ {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.SelectorPage_checkboxLabel__1S5XQ input {
  margin-right: 5px;
}

.SelectorPage_scopeGroup__33MxS {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}

.SelectorPage_scopeButton__3ZBYn {
  padding: 10px 20px;
  border: 1px solid #d0d0d0;
  border-radius: 20px;
  color: #292B3D;
  background-color: transparent;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  transition: all 0.3s ease;
}

.SelectorPage_scopeButton__3ZBYn:hover {
  color: #292B3D !important;
  background-color: #E0E9EB !important;
  border-color: #E0E9EB !important;
}

.SelectorPage_activeScopeButton__3eFfM {
  background-color: #5CC0D6;
  color: #ffffff;
  border-color: #5CC0D6;
}

.SelectorPage_actionGroup__2WDUk {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.SelectorPage_actionSelect__nMHJ_ {
  width: 200px;
}

.SelectorPage_applyButton__1XaBZ {
  background-color: #5CD685;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: all 0.2s ease;
}

.SelectorPage_applyButton__1XaBZ:hover {
  background-color: #94D1A8 !important;
}

.SelectorPage_applyButton__1XaBZ:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.SelectorPage_loadingOverlay__3RLyQ {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.SelectorPage_loadingOverlay__3RLyQ p {
  margin-top: 10px;
  font-size: 16px;
  color: #4758EB;
}

/* Responsive Design */
@media (max-width: 768px) {
  .SelectorPage_topSection__30pzi {
    flex-direction: column;
    align-items: flex-start;
  }

  .SelectorPage_headerImage__QAgN- {
    margin-top: 20px;
    width: 150px;
  }

  .SelectorPage_textContainer__G-dW2 {
    margin-right: 0;
  }

  .SelectorPage_scopeGroup__33MxS {
    flex-direction: column;
  }

  .SelectorPage_actionGroup__2WDUk {
    flex-direction: column;
    align-items: stretch;
  }

  .SelectorPage_actionSelect__nMHJ_ {
    width: 100%;
  }

  .SelectorPage_applyButton__1XaBZ {
    width: 100%;
  }
}

/* src/components/css/CustomAttributes.module.css */

/* Top Bar Styling */
.CustomAttributes_topBar__RWM6q {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 20px;
  background-color: #ffffff;
  color: #4758EB;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  grid-gap: 10px;
  gap: 10px;
}

/* Back Button Styling */
.CustomAttributes_backButton__1TGcS {
  background-color: #d0d0d0;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 0px;
}

.CustomAttributes_backButton__1TGcS:hover {
  background-color: #7c7c7c9f !important;
}

/* Logout Button Styling */
.CustomAttributes_logoutBtn__2r14s {
  background-color: #E0858C;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.CustomAttributes_logoutBtn__2r14s:hover {
  background-color: #EB4755 !important;
}

.CustomAttributes_logoutBtn__2r14s svg {
  margin-right: 0;
}

/* Header and Dropdown Container Styling */
.CustomAttributes_headerAndDropdown__3TcMm {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  flex-grow: 1;
}

/* Header Text Container */
.CustomAttributes_headerTextContainer__fPrig {
  display: flex;
  flex-direction: column;
}

.CustomAttributes_feedViewerVersion__135Yn {
  font-size: 12px;
  font-weight: 600;
  color: #4758EB;
  font-family: 'Lexend Deca', sans-serif;
}

.CustomAttributes_headerSubtitle__3Pq-Y {
  font-size: 22px;
  font-family: 'DM Serif Text', serif;
  color: #292B3D;
  margin-top: 4px;
}

/* Dropdown Container - adds extra spacing around dropdowns */
.CustomAttributes_dropdownContainer__3leLe {
  margin: 10px 20px;
  padding: 5px;
}

/* Client Dropdown Styling */
.CustomAttributes_clientDropdown__3WC8x {
  min-width: 350px;       /* increased width for better visibility */
  font-size: 14px;        /* slightly larger text */
  padding: 8px 12px;      /* added padding for more breathing room */
}

/* General Select Dropdown Styling (for project dropdowns, etc.) */
.CustomAttributes_selectDropdown__2Xn3x {
  min-width: 350px;
  font-size: 14px;
  padding: 8px 12px;
}

/* Header Image Styling */
.CustomAttributes_headerImage__2RziV {
  height: 100px;
  width: auto;
  padding-top: 20px;
  object-fit: contain;
  margin-left: auto;
}

/* Navigation Menu Styling */
.CustomAttributes_navMenu__2gQ8c {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  grid-gap: 10px;
  gap: 10px;
}

.CustomAttributes_navBtn__32bz3 {
  background-color: #ffffff;
  color: #4758EB;
  border: 1px solid #4758EB;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
  display: flex;
  align-items: center;
}

.CustomAttributes_navBtn__32bz3:hover {
  background-color: #4758EB;
  color: #ffffff;
}

.CustomAttributes_navBtn__32bz3.CustomAttributes_active__2Xqkg {
  background-color: hsl(234, 80%, 60%);
  color: #ffffff;
}

.CustomAttributes_navBtn__32bz3:disabled {
  background-color: #d0d0d0;
  color: #a0a0a0;
  cursor: not-allowed;
}

.CustomAttributes_navBtn__32bz3:disabled .CustomAttributes_icon__1yyfG {
  color: #a0a0a0;
}

.CustomAttributes_navBtn__32bz3 .CustomAttributes_icon__1yyfG {
  margin-right: 8px;
}

/* Content Area Styling */
.CustomAttributes_contentArea__23Hn_ {
  padding: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .CustomAttributes_topBar__RWM6q {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
  }

  .CustomAttributes_headerAndDropdown__3TcMm {
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
  }

  .CustomAttributes_headerImage__2RziV {
    margin-left: 0;
    margin-top: 10px;
    height: 80px;
  }

  .CustomAttributes_dropdownContainer__3leLe {
    width: 100%;
    margin: 10px 0;
  }

  .CustomAttributes_clientDropdown__3WC8x, .CustomAttributes_selectDropdown__2Xn3x {
    min-width: 100%;
    font-size: 14px;
  }

  .CustomAttributes_navMenu__2gQ8c {
    flex-direction: column;
    width: 100%;
  }

  .CustomAttributes_navBtn__32bz3 {
    width: 100%;
    justify-content: center;
  }
}

/* Message when no client is selected */
.CustomAttributes_selectClientMessage__3p1l_ {
  padding: 20px;
  text-align: center;
  font-size: 14px;
  color: #4758EB;
  font-family: 'Lexend Deca', sans-serif;
}

.AggregatedValues_container__2pFlZ {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  font-family: 'Lexend Deca', sans-serif;
  color: #292B3D;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.AggregatedValues_container__2pFlZ h2 {
  color: #292B3D; /* Updated from #4758EB */
  margin-bottom: 20px;
}

/* Project Selection Styles */
.AggregatedValues_projectSelection__3imsL {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.AggregatedValues_selectContainer__34-Eq {
  flex: 1 1;
}

.AggregatedValues_selectDropdown__rpIHD {
  width: 100%;
}

/* Button Group */
.AggregatedValues_buttonGroup__32uKk {
  display: flex;
  align-items: center;
  margin-left: 10px; /* Space between dropdown and buttons */
}

/* Add Button Styles */
.AggregatedValues_addButton__4VBaZ {
  background-color: #5CD685; /* Green */
  color: #ffffff;
  border: none;
  padding: 10px!important; /* Ensures padding as per provided CSS */
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px!important; /* Ensures width as per provided CSS */
  height: 40px!important; /* Ensures height as per provided CSS */
}

.AggregatedValues_addButton__4VBaZ:hover {
  background-color: #94D1A8 !important; /* Lighter green on hover */
}

.AggregatedValues_addButton__4VBaZ:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(92, 214, 133, 0.5);
}

/* Remove Button Styles */
.AggregatedValues_removeButton__2Ndiq {
  background-color: #EB4755; /* Red */
  color: #ffffff !important; /* Ensures the icon color is white */
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; /* Matches addButton size */
  height: 40px; /* Matches addButton size */
  font-size: 16px!important; /* Increased font size for better visibility */
  margin-left: 10px; /* Space between add and remove buttons */
  transition: background-color 0.3s;
}

.AggregatedValues_removeButton__2Ndiq:hover {
  background-color: #E0858C !important; /* Lighter red on hover */
}

.AggregatedValues_removeButton__2Ndiq:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(235, 71, 85, 0.5);
}

/* Toggle Container */
.AggregatedValues_updateAllButton__3e9yR {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px; /* Space between toggle and button */
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: background-color 0.3s;
}

.AggregatedValues_updateAllButton__3e9yR:hover {
  background-color: #848EE0;
}

.AggregatedValues_updateAllButton__3e9yR:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
}

/* Adjust toggleContainer to align items */
.AggregatedValues_toggleContainer__2PCIp {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.AggregatedValues_toggleContainer__2PCIp label {
  margin-right: 10px;
  font-weight: 600;
}

.AggregatedValues_toggleContainer__2PCIp .AggregatedValues_react-toggle__2R0PN {
  margin-right: 10px;
}

/* Aggregation Section */
.AggregatedValues_aggregationSection__3KGzS {
  background-color: #f1f1f1;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 20px;
}

.AggregatedValues_aggregationSection__3KGzS h3 {
  color: #292B3D; /* Updated from #4758EB */
  margin-bottom: 15px;
}

.AggregatedValues_aggregationDropdowns__3HfrW {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  flex-wrap: wrap;
}

.AggregatedValues_dropdownContainer__3tKj5 {
  flex: 1 1;
  min-width: 200px;
  position: relative;
}

/* Advanced Toggle Styles */
.AggregatedValues_advancedToggle__2Je2I {
  margin-top: 10px;
}

.AggregatedValues_advancedToggleButton__dnwxI {
  background: none;
  border: none;
  color: #4758EB;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  transition: color 0.3s;
}

.AggregatedValues_advancedToggleButton__dnwxI:hover {
  color: #ffffff;
}

.AggregatedValues_advancedToggleButton__dnwxI:focus {
  outline: none;
  text-decoration: underline;
}

/* Advanced Options Panel */
.AggregatedValues_advancedOptionsPanel__3FTQ1 {
  margin-top: 10px;
  padding: 10px 0;
  border-top: 1px solid #e0e1eb;
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  animation: AggregatedValues_fadeIn__268zG 0.3s ease-in-out;
}

/* Advanced Options Row for Separator and Level Side by Side */
.AggregatedValues_advancedOptionsRow__Pzvn4 {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  flex-wrap: wrap;
}

.AggregatedValues_advancedOptionsRow__Pzvn4 .AggregatedValues_inputContainer__1ptgv {
  flex: 1 1;
  min-width: 200px;
}

/* Advanced Option Row */
.AggregatedValues_advancedOptionRow__12UDs {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

/* Helper Text for Inputs */
.AggregatedValues_helperText__2Pct7 {
  font-size: 12px;
  color: #6c757d;
  margin-top: 5px;
  font-family: 'Lexend Deca', sans-serif;
}

/* Improved Checkbox Container */
.AggregatedValues_checkboxContainer__Xp1-t {
  display: flex;
  align-items: center;
  margin-bottom: 5px; /* Space between checkbox and helper text */
}

.AggregatedValues_checkboxContainer__Xp1-t input {
  margin-right: 10px;
  width: 18px;
  height: 18px;
}

/* Info Icon Styling */
.AggregatedValues_infoIcon__2SX48 {
  margin-left: 5px;
  color: #6c757d;
  cursor: pointer;
  font-size: 14px;
}

.AggregatedValues_infoIcon__2SX48:hover {
  color: #292B3D;
}

.AggregatedValues_exampleContainer__2oaJw label {
  font-weight: 600;
}

.AggregatedValues_exampleContainer__2oaJw p {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 4px;
  margin-top: 5px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
}

/* Highlighted Level in Example Group Value */
.AggregatedValues_highlightedLevel__3z7LK {
  background-color: #fff3cd;
  padding: 2px 4px;
  border-radius: 3px;
  font-weight: bold;
}

/* Filters Section Styles */
.AggregatedValues_filtersSection___fANm {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

/* Header for the filters section with toggle button */
.AggregatedValues_filtersHeader__3AFEC {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AggregatedValues_filtersHeader__3AFEC h3 {
  color: #292B3D;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 18px;
  margin: 0;
}

.AggregatedValues_filtersToggleButton__2MEhP {
  background: none;
  border: none;
  color: #4758EB;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  transition: color 0.3s;
}

.AggregatedValues_filtersToggleButton__2MEhP:hover {
  color: #ffffff;
}

.AggregatedValues_filtersToggleButton__2MEhP:focus {
  outline: none;
  text-decoration: underline;
}

/* Panel that contains all the filter rows */
.AggregatedValues_filtersPanel__5rFH0 {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  animation: AggregatedValues_fadeIn__268zG 0.3s ease-in-out;
}

/* Individual filter row */
.AggregatedValues_filterRow__2onxt {
  display: flex;
  align-items: center; /* Changed from flex-start to center for vertical alignment */
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: nowrap; /* Prevent wrapping to keep elements in a single row */
}

/* Connector dropdown (e.g., AND/OR) */
.AggregatedValues_connector__iPd1k {
  min-width: 80px;
  /* Removed margin-top to align vertically */
}

.AggregatedValues_connector__iPd1k .AggregatedValues_react-select__control__2aHsC {
  min-height: 40px;
}

.AggregatedValues_connector__iPd1k .AggregatedValues_react-select__menu__2Apdy {
  z-index: 1000;
}

/* Fields within a filter row */
.AggregatedValues_filterFields__1i2sz {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex: 1 1;
  min-width: 300px; /* Increased minimum width for better input visibility */
  align-items: center; /* Ensures vertical alignment */
}

.AggregatedValues_filterFields__1i2sz .AggregatedValues_react-select__control__2aHsC {
  min-height: 40px;
  flex: 1 1;
}

.AggregatedValues_filterFields__1i2sz .AggregatedValues_filterInput__1g6DQ {
  flex: 2 1;
  padding: 10px;
  border: 1px solid #e0e1eb;
  border-radius: 4px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: border-color 0.3s;
}

.AggregatedValues_filterFields__1i2sz .AggregatedValues_filterInput__1g6DQ:focus {
  border-color: #4758EB;
  outline: none;
  box-shadow: 0 0 0 2px rgba(71, 88, 235, 0.3);
}

/* Remove filter button */
.AggregatedValues_removeFilterButton__2SMXK {
  background-color: #EB4755;
  color: #ffffff;
  border: none;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-weight: bold;
  font-size: 12px;
  transition: background-color 0.3s;
  margin-left: 10px; /* Space between fields and remove button */
}

.AggregatedValues_removeFilterButton__2SMXK:hover {
  background-color: #E0858C;
}

/* Add filter button */
.AggregatedValues_addFilterButton__2UdiW {
  background-color: #5CD685;
  color: #ffffff;
  border: none;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 24px; /* Adjusted to align with FaPlus icon */
}

.AggregatedValues_addFilterButton__2UdiW:hover {
  background-color: #94D1A8;
}

/* Action Buttons */
.AggregatedValues_actionButtons__3zHFs {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 15px;
  flex-wrap: wrap;
}

.AggregatedValues_saveButton__yvzYI {
  background-color: #5CD685 !important;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s;
}

.AggregatedValues_saveButton__yvzYI:hover {
  background-color: #94D1A8 !important;
}

.AggregatedValues_saveButton__yvzYI:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
}

.AggregatedValues_previewButton__1gRuT {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s;
}

.AggregatedValues_previewButton__1gRuT:hover {
  background-color: #848EE0 !important;
}

/* Project Details Styles */
.AggregatedValues_projectDetails__SfH80 {
  background-color: #f9f9f9;
  border-radius: 6px;
  padding: 15px;
  font-family: 'DM Sans', sans-serif;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.AggregatedValues_projectDetails__SfH80 h3 {
  color: #4758EB;
  margin-bottom: 10px;
}

.AggregatedValues_projectDetails__SfH80 p {
  margin: 5px 0;
}

/* Modal Styles */
.AggregatedValues_modalOverlay__29hcl {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 43, 61, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.AggregatedValues_modalContent__3EYnd {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 30px;
  width: 90%;
  max-width: 400px;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  font-family: 'Lexend Deca', sans-serif;
}

.AggregatedValues_closeButton__2cFWu {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: #292B3D;
  cursor: pointer;
  font-size: 18px;
  transition: color 0.3s ease;
}

.AggregatedValues_closeButton__2cFWu:hover {
  color: #4758EB;
}

.AggregatedValues_modalContent__3EYnd h3 {
  color: #292B3D; /* Updated from #4758EB */
  margin-bottom: 20px;
}

.AggregatedValues_form__20uVs {
  display: flex;
  flex-direction: column;
}

.AggregatedValues_form__20uVs label {
  margin-bottom: 5px;
  font-weight: 600;
}

.AggregatedValues_form__20uVs input {
  padding: 10px;
  border: 1px solid #E0E1EB;
  border-radius: 4px;
  margin-bottom: 15px;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.AggregatedValues_form__20uVs input:focus {
  border-color: #4758EB;
  outline: none;
  box-shadow: 0 0 0 2px rgba(71, 88, 235, 0.3);
}

.AggregatedValues_submitButton__1Xj6w {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.AggregatedValues_submitButton__1Xj6w:hover {
  background-color: #3641b7;
}

.AggregatedValues_error__2gmTd {
  color: #ff4d4f;
  margin-bottom: 10px;
  font-size: 14px;
}

/* Preview Section Styles */
.AggregatedValues_previewSection___M-XV {
  margin-top: 40px;
  margin-bottom: 40px;
}

.AggregatedValues_previewSection___M-XV h3 {
  color: #292B3D; /* Updated from #4758EB */
  margin-bottom: 10px;
}

.AggregatedValues_previewTable__2FSY- {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.AggregatedValues_previewTable__2FSY- th,
.AggregatedValues_previewTable__2FSY- td {
  border: 1px solid #ddd;
  padding: 8px;
}

.AggregatedValues_previewTable__2FSY- th {
  background-color: #4758EB;
  color: #ffffff;
  text-align: left;
}

.AggregatedValues_previewTable__2FSY- tr:nth-child(even) {
  background-color: #f9f9f9;
}

.AggregatedValues_previewTable__2FSY- tr:hover {
  background-color: #f5f5f5;
}

.AggregatedValues_examplePreviewSection__2d90j {
  margin-top: 20px;
}

.AggregatedValues_examplePreviewSection__2d90j h3 {
  color: #292B3D; /* Updated from #4758EB */
  margin-bottom: 10px;
}

.AggregatedValues_examplePreviewSection__2d90j table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.AggregatedValues_examplePreviewSection__2d90j th,
.AggregatedValues_examplePreviewSection__2d90j td {
  border: 1px solid #ddd;
  padding: 8px;
}

.AggregatedValues_examplePreviewSection__2d90j th {
  background-color: #4758EB;
  color: #ffffff;
  text-align: left;
}

.AggregatedValues_examplePreviewSection__2d90j tr:nth-child(even) {
  background-color: #f9f9f9;
}

.AggregatedValues_examplePreviewSection__2d90j tr:hover {
  background-color: #f5f5f5;
}

/* Loading Spinner for Preview */
.AggregatedValues_sectionSpinner__kJghk {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

/* Global Spinner Overlay */
.AggregatedValues_globalSpinner__BMXCk {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Custom Option Label Styles */
.AggregatedValues_optionLabel__1kEhC {
  display: flex;
  align-items: center;
}

.AggregatedValues_activeDot__jGqhk,
.AggregatedValues_inactiveDot__1n9oU {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.AggregatedValues_activeDot__jGqhk {
  background-color: #5CD685; /* Green */
}

.AggregatedValues_inactiveDot__1n9oU {
  background-color: #EB4755; /* Red */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .AggregatedValues_aggregationDropdowns__3HfrW {
    flex-direction: column;
  }

  .AggregatedValues_filtersSection___fANm .AggregatedValues_filterRow__2onxt {
    flex-direction: column;
    align-items: flex-start;
  }

  .AggregatedValues_connector__iPd1k {
    width: 100%;
    margin-bottom: 5px;
  }

  .AggregatedValues_filterFields__1i2sz {
    flex-direction: column;
    width: 100%;
  }

  .AggregatedValues_filterFields__1i2sz .AggregatedValues_react-select__control__2aHsC {
    width: 100%;
  }

  .AggregatedValues_filterFields__1i2sz .AggregatedValues_filterInput__1g6DQ {
    width: 100%;
  }

  .AggregatedValues_actionButtons__3zHFs {
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }

  .AggregatedValues_advancedOptionsRow__Pzvn4 {
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }

  .AggregatedValues_toggleContainer__2PCIp {
    flex-direction: column;
    align-items: flex-start;
  }
  .AggregatedValues_lastUpdatedAt__174mQ,
  .AggregatedValues_lastErrorMessage__1NsGV {
    margin-left: 0;
    margin-top: 10px;
  }

  .AggregatedValues_toggleContainerInline__IWeNR {
    flex-direction: column; /* Stack vertically on smaller screens */
    align-items: flex-start;
  }
}

/* Inline Input for Attribute Name */
.AggregatedValues_inlineInputContainer__3D8Ql {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px; /* Space between label and input */
  margin-bottom: 15px; /* Space below the input field */
}

/* Specific Styling for Attribute Name Input */
.AggregatedValues_attributeInput__2sPwl {
  width: 100%; /* Changed from 300px to 100% for responsiveness */
  max-width: 400px; /* Optional: Set a max-width for larger screens */
  padding: 10px;
  border: 1px solid #E0E1EB;
  border-radius: 4px;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.AggregatedValues_attributeInput__2sPwl:focus {
  border-color: #4758EB;
  outline: none;
  box-shadow: 0 0 0 2px rgba(71, 88, 235, 0.3);
}

/* Adjusted filterInput for better visibility */
.AggregatedValues_filterInput__1g6DQ {
  flex: 2 1; /* Increased flex to allocate more space */
  min-width: 250px; /* Ensures a minimum width */
  width: 100%; /* Allows the input to expand as needed */
  max-width: 500px; /* Optional: Prevents overly wide inputs on large screens */
}

/* Style for Last Updated At */
.AggregatedValues_lastUpdatedAt__174mQ {
  margin-left: 15px;
  font-size: 14px;
  color: #6c757d;
  font-family: 'Lexend Deca', sans-serif;
}

/* Style for Last Error Message */
.AggregatedValues_lastErrorMessage__1NsGV {
  margin-left: 15px;
  font-size: 14px;
  color: #ff4d4f;
  font-family: 'Lexend Deca', sans-serif;
}

/* InfoBox Styles */
.AggregatedValues_infoBox__1yWyR {
  background-color: #e7f3fe;
  color: #292B3D;
  padding: 15px;
  border-left: 6px solid #5CC0D6;
  border-radius: 4px;
  margin-bottom: 20px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
}

/* Add these styles for the inline toggle beside the attribute name */
.AggregatedValues_toggleContainerInline__IWeNR {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px; /* Space between label and toggle */
}

.AggregatedValues_toggleContainerInline__IWeNR label {
  font-weight: 600;
  margin-right: 5px; /* Space between label and toggle */
}

/* Success Message */
.AggregatedValues_successMessage__3FaO6 {
  color: #5CD685; /* Green color */
  font-size: 14px;
  margin-top: 10px;
  font-family: 'Lexend Deca', sans-serif;
}

/* Unsaved Changes Message */
.AggregatedValues_unsavedChangesMessage__1bSvi {
  color: #EB4755; /* Red color */
  font-size: 14px;
  margin-top: 10px;
  font-family: 'Lexend Deca', sans-serif;
}

.CoreVariants_coreVariantsContainer__cdaCV {
  font-family: 'Lexend Deca', sans-serif;
}

/* TOP BAR */
.CoreVariants_topBar__3jno9 {
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 15px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 30px;
}

/* Container for dropdown and project buttons (left side) */
.CoreVariants_leftSideContainer__2LZen {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

/* Dropdown wrapper */
.CoreVariants_dropdownWrapper__W79l_ {
  min-width: 250px;
  max-width: 400px;
}

/* Container for icon buttons (next to the dropdown) */
.CoreVariants_iconButtons__13mw4 {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

/* Container for the toggle (right side) */
.CoreVariants_rightSideContainer__2i4E- {
  margin-left: auto;
}

/* ---- Toggle Switch Styles ---- */
.CoreVariants_toggleSwitch__31Xam {
  display: flex;
  background-color: #E0E1EB;
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #d4d4d4;
}

.CoreVariants_toggleOption__gJYH3 {
  padding: 8px 16px;
  cursor: pointer;
  background-color: transparent;
  color: #292B3D;
  transition: background-color 0.3s, color 0.3s;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  -webkit-user-select: none;
          user-select: none;
}

.CoreVariants_toggleOption__gJYH3:hover {
  background-color: #c9c9c9;
}

.CoreVariants_toggleOption__gJYH3.CoreVariants_active__1xzhy {
  background-color: #4758EB;
  color: #ffffff;
}

/* ---- Icon Button Styles ---- */
.CoreVariants_addIconBtn__22oHo {
  background-color: #5CD685; /* Green */
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CoreVariants_addIconBtn__22oHo:hover {
  background-color: #94D1A8 !important; 
}

/* Trash Button – Red */
.CoreVariants_trashIconBtn__2FxXV {
  background-color: #EB4755; 
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CoreVariants_trashIconBtn__2FxXV:hover {
  background-color: #E0858C !important;
}

/* Send Button – Blue */
.CoreVariants_sendIconBtn__3m_7m {
  background-color: #4758EB; 
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CoreVariants_sendIconBtn__3m_7m:hover {
  background-color: #848EE0 !important; 
}

/* SCORE CARDS GRID */
.CoreVariants_scoreCardsContainer___4FFc {
  display: grid;
  grid-gap: 20px;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
}

@media (min-width: 1500px) {
  .CoreVariants_scoreCardsContainer___4FFc {
    grid-template-columns: repeat(5, 1fr);
  }
}

/* Each Score Card */
.CoreVariants_scoreCard__3o9TC {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  min-height: 180px;
  position: relative;
  overflow: hidden;
}

.CoreVariants_cardHeader__2xz2j {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  z-index: 2;
  padding: 5px 0;
}

.CoreVariants_cardHeader__2xz2j h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.CoreVariants_cardFooter__2rxWg {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 2;
  padding: 5px 0;
}

.CoreVariants_centeredMessage__JjwyY {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  text-align: center;
  z-index: 1;
}

.CoreVariants_messageValue__u2Z2z {
  font-size: 20px;
  font-weight: 500;
  color: #292B3D;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.CoreVariants_metricValue__2GSz0 {
  font-size: 28px;
  font-weight: 600;
  color: #292B3D;
}

.CoreVariants_timestamp__cPJjl {
  font-size: 10px;
  color: #6c757d;
}

/* Activate/Deactivate Buttons */
.CoreVariants_activateButton__F9GnA {
  background-color: #5CD685; 
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: background-color 0.3s;
}

.CoreVariants_activateButton__F9GnA:hover {
  background-color: #94D1A8 !important;
}

.CoreVariants_deactivateButton__2IlGg {
  background-color: #EB4755; 
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: background-color 0.3s;
}

.CoreVariants_deactivateButton__2IlGg:hover {
  background-color: #E0858C !important;
}

.CoreVariants_viewLogsButton__yIb6j {
  background-color: #4758EB; 
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: background-color 0.3s;
}

.CoreVariants_viewLogsButton__yIb6j:hover {
  background-color: #848EE0;
}

/* MODAL OVERLAY */
.CoreVariants_modalOverlay__qR1Ji {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 43, 61, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.CoreVariants_modalContent__1U2sU {
  background-color: #ffffff;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  padding: 20px;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.CoreVariants_closeModalBtn__MOz0N {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #292B3D;
  cursor: pointer;
  font-size: 18px;
  transition: color 0.2s ease;
}

.CoreVariants_closeModalBtn__MOz0N:hover {
  color: #4758EB !important;
}

.CoreVariants_modalContent__1U2sU h3 {
  margin-top: 0;
  font-size: 18px;
  font-weight: 600;
  color: #4758EB;
  margin-bottom: 15px;
}

.CoreVariants_errorLogs__1dlH6 {
  max-height: 300px;
  overflow-y: auto;
}

.CoreVariants_errorLogs__1dlH6 p {
  margin: 5px 0;
  color: #292B3D;
}

.CoreVariants_errorIcon__SvWu8 {
  color: #EB4755;
  margin-right: 6px;
}

/* Loading & Error states */
.CoreVariants_loadingSpinner__2f1b5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #E0E1EB;
  border-radius: 8px;
  margin: 20px 0;
}

.CoreVariants_loadingSpinner__2f1b5 p {
  margin: 0;
  font-size: 16px;
  color: #292B3D;
}

.CoreVariants_errorMessage__3SWX1 p {
  color: #EB4755;
  margin: 0;
}

/* SETTINGS TAB */
.CoreVariants_settingsContainer__2fA_7 {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.CoreVariants_settingsDescription__2dcS8 {
  margin-bottom: 20px;
  color: #292B3D;
  font-size: 14px;
}

.CoreVariants_settingsForm__1URI6 {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

/* Each group */
.CoreVariants_settingsGroup__MysEb {
  background-color: #F9F9FB;
  border: 1px solid #E0E1EB;
  border-radius: 8px;
  padding: 15px 20px;
}

.CoreVariants_settingsGroupTitle__J7wRD {
  margin: 0 0 5px 0;
  font-size: 16px;
  font-weight: 600;
  color: #292B3D;
}

.CoreVariants_groupSubtitle__2MNN2 {
  font-size: 13px;
  color: #777;
  margin-bottom: 15px;
}

.CoreVariants_settingsRow__2q3wY {
  display: grid;
  grid-template-columns: 300px 120px;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  margin-bottom: 8px;
}

.CoreVariants_settingsLabel__1B9Gl {
  font-weight: 500;
  color: #292B3D;
  cursor: help; /* indicates there's a tooltip */
  display: inline-block;
}

.CoreVariants_settingsInput__2kpDu {
  width: 100%;
  padding: 8px;
  font-family: 'Lexend Deca', sans-serif;
  border: 1px solid #E0E1EB;
  border-radius: 4px;
  font-size: 14px;
  color: #292B3D;
}

.CoreVariants_settingsInput__2kpDu:focus {
  outline: none;
  border-color: #4758EB;
}

.CoreVariants_tooltipLabel__2RmjB {
  display: inline-flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

/* Explanatory note for advanced logic or general overview */
.CoreVariants_explanatoryNote__1YjOM {
  background-color: #fff;
  border: 1px dashed #CCC;
  border-radius: 6px;
  padding: 10px 15px;
  font-size: 13px;
  color: #555;
}

/* Save / Reset buttons row */
.CoreVariants_saveSettingsRow__1SC7o {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;
  gap: 10px;
}

.CoreVariants_saveSettingsButton__GIQ6g {
  background-color: #4758EB; 
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: background-color 0.3s;
}

.CoreVariants_saveSettingsButton__GIQ6g:hover {
  background-color: #848EE0;
}

.CoreVariants_resetSettingsButton__3IWGF {
  background-color: #EBB847; /* Yellowish */
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: background-color 0.3s;
}

.CoreVariants_resetSettingsButton__3IWGF:hover {
  background-color: #EBCB70 !important; 
}

/* Fullscreen overlay that dims the background */
.CreateProjectWizard_wizardOverlay__1TCpb {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 43, 61, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200; /* Increased to ensure it appears above other elements */
  padding: 20px;
}

/* Big modal in the center with minimal spacing around edges on mobile */
.CreateProjectWizard_wizardModal__3caO9 {
  background-color: #ffffff;
  width: 100%;
  max-width: 900px;
  max-height: 80vh;
  overflow-y: auto;
  border-radius: 8px;
  padding: 30px 40px;
  position: relative;
  font-family: 'Lexend Deca', sans-serif;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Simple step indicator with step circles */
.CreateProjectWizard_stepIndicator__dyT2r {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.CreateProjectWizard_stepNumber__2NVGY {
  width: 40px;
  height: 40px;
  background: #e9ecef;
  color: #6c757d;
  border-radius: 50%;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: default;
  font-size: 18px;
  transition: background-color 0.3s;
}
.CreateProjectWizard_stepNumber__2NVGY.CreateProjectWizard_active__1oTbX {
  background-color: #4758eb;
  color: #fff;
}
.CreateProjectWizard_stepNumber__2NVGY.CreateProjectWizard_completed__1iYj6 {
  background-color: #5cd685;
  color: #fff;
}

/* Container for each step’s content */
.CreateProjectWizard_stepContainer__lYHUa {
  min-height: 250px;
}

.CreateProjectWizard_step__34cM- {
  animation: CreateProjectWizard_fadeIn__3r5p4 0.3s ease;
}
@keyframes CreateProjectWizard_fadeIn__3r5p4 {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Shared text styling */
.CreateProjectWizard_step__34cM- h2 {
  margin-top: 0;
  font-size: 20px;
  margin-bottom: 20px;
  color: #4758eb;
}
.CreateProjectWizard_step__34cM- label {
  display: block;
  margin-bottom: 6px;
  font-weight: 600;
  color: #292b3d;
}

/* Input styling */
.CreateProjectWizard_step__34cM- input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #E0E1EB;
  border-radius: 4px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: border-color 0.3s;
}
.CreateProjectWizard_step__34cM- input:focus {
  border-color: #4758eb;
  outline: none;
  box-shadow: 0 0 0 2px rgba(71, 88, 235, 0.3);
}

/* Step 1 project preview */
.CreateProjectWizard_preview__2QYz2 {
  margin-top: 10px;
  font-size: 14px;
  color: #6c757d;
}

/* Verification button (Steps 2 & 3) */
.CreateProjectWizard_verifyBtn__ZNaj6 {
  min-width: 120px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #4758eb;
  color: #ffffff;
  border: none;
  padding: 0 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
}
.CreateProjectWizard_verifyBtn__ZNaj6:hover {
  background-color: #343fbd;
}
.CreateProjectWizard_verifyBtn__ZNaj6:disabled,
.CreateProjectWizard_verifyBtn__ZNaj6:disabled:hover {
  background-color: #d0d0d0;
  cursor: not-allowed;
}

/* Error message styling */
.CreateProjectWizard_errorMsg__2_JYr {
  color: #e74c3c;
  margin-top: 10px;
}

.CreateProjectWizard_successMessageStep1__3DelB {
  padding-bottom: 2px !important;
}

/* Green success box for Merchant Center Verified */
.CreateProjectWizard_successBox__2ASRw {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #dfffe8;
  border: 1px solid #b6f2ca;
  border-radius: 4px;
  color: #2e7d32;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  font-weight: 600;
}

/* Metadata box within success message */
.CreateProjectWizard_metadata__11p-O {
  margin-top: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
}
.CreateProjectWizard_metadata__11p-O p {
  margin: 4px 0;
}

/* Review card styling in step 4 */
.CreateProjectWizard_reviewCard__-5cM6 {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  font-size: 15px;
}
.CreateProjectWizard_reviewItem__4hCWp {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}
.CreateProjectWizard_reviewItem__4hCWp:last-child {
  margin-bottom: 0;
}

/* Footer nav area with circular icon buttons */
.CreateProjectWizard_wizardFooter__1CDGS {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  grid-gap: 10px;
  gap: 10px;
}

/* Base style for circular navigation buttons */
.CreateProjectWizard_buttonBase__3-jQg {
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}
.CreateProjectWizard_backButton__3VDC3 {
  background-color: #d0d0d0;
  color: #ffffff;
}
.CreateProjectWizard_backButton__3VDC3:hover:not(:disabled) {
  background-color: #7c7c7c !important;
}
.CreateProjectWizard_nextButton__1RnW0 {
  background-color: #4758eb;
  color: #fff;
}
.CreateProjectWizard_nextButton__1RnW0:hover:not(:disabled) {
  background-color: #343fbd !important;
}
.CreateProjectWizard_saveButton__1oXvg {
  background-color: #5cd685;
  color: #fff;
}
.CreateProjectWizard_saveButton__1oXvg:hover:not(:disabled) {
  background-color: #86e0a1;
}
.CreateProjectWizard_buttonBase__3-jQg:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Spinner (loading) */
.CreateProjectWizard_spinner__3j5tP {
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #4758eb;
  border-radius: 50%;
  animation: CreateProjectWizard_spin__1NvyR 1s linear infinite;
}
@keyframes CreateProjectWizard_spin__1NvyR {
  to {
    transform: rotate(360deg);
  }
}

/* Schedule section styling (Step 2) */
.CreateProjectWizard_scheduleSection__3FEL4 {
  margin-top: 20px;
  background-color: #f4f4f4;
  border-radius: 6px;
  padding: 20px;
}
.CreateProjectWizard_scheduleSection__3FEL4 h3 {
  margin-top: 0;
  font-size: 16px;
  margin-bottom: 10px;
  color: #4758eb;
}
.CreateProjectWizard_scheduleToggle__BIAdA {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 15px;
  align-items: center;
}
.CreateProjectWizard_scheduleToggle__BIAdA label {
  margin: 0;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  color: #292b3d;
}
.CreateProjectWizard_scheduleToggle__BIAdA input[type="radio"] {
  cursor: pointer;
  margin: 0 5px 0 0;
  accent-color: #4758eb;
}
.CreateProjectWizard_scheduleDetails__2Ea4S {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 15px;
  font-size: 14px;
  border: 1px solid #e1e1e1;
}
.CreateProjectWizard_scheduleRow__2Qx-g {
  margin-bottom: 8px;
}
.CreateProjectWizard_scheduleRow__2Qx-g strong {
  display: inline-block;
  min-width: 100px;
  font-weight: 600;
}

/* Validation Summary Card for Google Ads (Step 3) */
.CreateProjectWizard_validationSummary__3xJ1s {
  margin-top: 15px;
  padding: 10px 15px;
  background-color: #f9f9f9;
  border: 1px solid #e0e1eb;
  border-radius: 4px;
  font-family: 'Lexend Deca', sans-serif;
}
.CreateProjectWizard_validationSuccess__2rQor {
  display: flex;
  align-items: center;
  color: #5cd685;
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 15px;
}
.CreateProjectWizard_validationMetrics__3UraN p {
  margin: 5px 0;
  font-size: 14px;
}
.CreateProjectWizard_metricSubtext__33FL8 {
  font-size: 0.85em;
  color: #6c757d;
  margin-left: 8px;
}
.CreateProjectWizard_validationError__1j4Np {
  margin-top: 15px;
  padding: 10px 15px;
  background-color: #fdecea;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  color: #e74c3c;
  font-size: 14px;
  display: flex;
  align-items: center;
}

/* New: Usage Warning styling (yellow visual impression) */
.CreateProjectWizard_usageWarning__Xa6in {
  margin-top: 15px;
  padding: 10px 15px;
  background-color: #fff3cd;  /* Light yellow background */
  border: 1px solid #ffeeba;  /* Yellow border */
  border-radius: 4px;
  color: #856404;             /* Dark yellow text */
  font-size: 14px;
  display: flex;
  align-items: center;
}

/* Disabled hover state for base buttons */
.CreateProjectWizard_buttonBase__3-jQg:disabled:hover {
  background-color: #d0d0d0;
}

/* Two column container styling */
.CreateProjectWizard_twoColumnContainer__27giQ {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 15px;
}
.CreateProjectWizard_schedulerSettings__3YkIo,
.CreateProjectWizard_backfillSettings__rbnCV {
  flex: 1 1 300px;
  background-color: #f9f9f9;
  padding: 10px 15px;
  border: 1px solid #e0e1eb;
  border-radius: 4px;
}
.CreateProjectWizard_sectionTitle__bgx3x {
  font-size: 14px;
  margin-bottom: 8px;
  color: #4758eb;
  font-weight: 600;
}
.CreateProjectWizard_customField__2OEZF {
  margin-bottom: 10px;
}
.CreateProjectWizard_infoIcon__1xzJA {
  background-color: #4758eb;
  border-radius: 50%;
  padding: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  margin-left: 4px;
}
.CreateProjectWizard_referenceSchedule__2xDBj {
  margin-top: 10px;
  font-size: 12px;
  color: #6c757d;
}
.CreateProjectWizard_selectedMerchantCenter__3zy1y {
  background-color: #f0f4ff;
  color: #4758eb;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 10px;
  display: inline-flex;
  align-items: center;
}

/* Review card details */
.CreateProjectWizard_summaryRow__3J_Q9 {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 20px;
}
.CreateProjectWizard_summaryBox__3AyCx {
  flex: 1 1;
  background-color: #f9f9f9;
  border: 1px solid #e0e1eb;
  border-radius: 6px;
  padding: 15px;
  text-align: center;
}
.CreateProjectWizard_summaryBox__3AyCx h4 {
  margin: 0;
  font-size: 14px;
  color: #4758eb;
  margin-bottom: 8px;
}
.CreateProjectWizard_summaryBox__3AyCx span {
  font-size: 18px;
  font-weight: 600;
}

/* Distribution details */
.CreateProjectWizard_distributionDetails__1zvHX {
  margin-top: 10px;
  background-color: #f9f9f9;
  border: 1px solid #e0e1eb;
  border-radius: 6px;
  padding: 15px;
}
.CreateProjectWizard_distributionDetails__1zvHX ul {
  margin: 10px 0 0 20px;
}

/* Core Variant Eligibility messaging */
.CreateProjectWizard_variantMessage__dze8p {
  margin-top: 15px;
  padding: 10px 15px;
  border-radius: 6px;
  display: flex;
  align-items: flex-start;
  grid-gap: 12px;
  gap: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  background-color: #f9f9f9;
  border-left: 6px solid #ccc;
}
.CreateProjectWizard_variantBrilliant__3o6DJ {
  border-left-color: #5cd685 !important;
}
.CreateProjectWizard_variantGood__1bJYX {
  border-left-color: #a5e3a8 !important;
}
.CreateProjectWizard_variantOk__1gnNE {
  border-left-color: #f1c40f !important;
}
.CreateProjectWizard_variantBad__e2sOw {
  border-left-color: #e74c3c !important;
}
.CreateProjectWizard_variantContent__liLgr {
  display: flex;
  flex-direction: column;
  grid-gap: 6px;
  gap: 6px;
}
.CreateProjectWizard_variantTitle__FjlEV {
  font-size: 16px;
  font-weight: 600;
  color: #292b3d;
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
}
.CreateProjectWizard_variantDescription__ja_aS {
  font-size: 14px;
  color: #6c757d;
}


/* A row wrapper for each distribution line */
.CreateProjectWizard_distributionRow__ZAkJQ {
  display: flex;
  align-items: center;
  margin: 6px 0;
  font-size: 13px;
}

/* Label block for "X var(s): Y grp(s)" */
.CreateProjectWizard_distributionLabel__YGJdd {
  width: 25%;
  min-width: 150px; /* or whatever suits you */
  margin-right: 10px;
}

/* Container for each bar + percentage text */
.CreateProjectWizard_distributionBarContainer__1Vhqz {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  margin-right: 20px; /* space between the two bars */
}

/* Container for the entire distribution table in Step 5 */
.CreateProjectWizard_distributionGrid__291rb {
  display: grid;
  grid-template-columns: 140px 1fr 1fr; /* narrower left column */
  grid-column-gap: 20px;
  column-gap: 20px;
  grid-row-gap: 8px;
  row-gap: 8px;
  width: 100%;
  margin-top: 10px;
}

/* distributionGridHeader: optional headings for the middle & right columns */
.CreateProjectWizard_distributionGridHeader__2z9Ve {
  font-weight: 600;
  color: #6c757d;
  font-size: 13px;
  padding-bottom: 4px;
  border-bottom: 1px solid #e0e1eb;
  display: flex;
  align-items: center;
}

/* The label on the left (e.g. "2 var(s): 1056 grp(s)") */
.CreateProjectWizard_distributionLabelCell__10DQH {
  font-size: 13px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

/* For each bar cell (the bars + percentage text) */
.CreateProjectWizard_distributionBarCell__2N-6e {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Reuse your existing bar styles, or adapt them: */
.CreateProjectWizard_distributionBarBackground__1tflk {
  width: 100%;
  height: 6px;
  margin-top: 4px;
  background-color: #E0E1EB;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}
.CreateProjectWizard_distributionBarFill__3yz6x {
  height: 100%;
  border-radius: 3px;
}
.CreateProjectWizard_distributionPercentage__WKw2R {
  color: #6c757d;
  font-size: 10px;
}

/* Optional: reduce default spacing in step 5’s text */
.CreateProjectWizard_step5Compact__2x3mX p {
  margin: 6px 0;
  font-size: 14px;
  line-height: 1.3;
}

.ManageProjectModal_modalOverlay__1XHRD {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(41, 43, 61, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  
  .ManageProjectModal_modalContent__1upom {
    background-color: #ffffff;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    padding: 20px;
    position: relative;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  .ManageProjectModal_header__1WFbU {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .ManageProjectModal_header__1WFbU h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
  }
  
  .ManageProjectModal_section__1p9Fq {
    margin-bottom: 20px;
  }
  
  .ManageProjectModal_sectionTitle__1RAwP {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #292B3D;
  }
  
  .ManageProjectModal_userList__-fK1D {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .ManageProjectModal_userItem__K1Ifp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    margin-bottom: 8px;
    background-color: #F6F7FB;
    border-radius: 4px;
  }
  
  .ManageProjectModal_currentUser__3m6cH {
    background-color: #e0e0e0;
    color: #7d7d7d;
  }
  
  .ManageProjectModal_removeButton__h3aNr {
    background-color: #EB4755;
    border: none;
    color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    font-size: 8px;
    padding: 0;
  }
  
  .ManageProjectModal_removeButton__h3aNr:hover {
    background-color: #E0858C !important;
  }
  
  .ManageProjectModal_addUserSection__1v8oG {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
  }
  
  .ManageProjectModal_selectContainer__3VgIv {
    flex: 1 1;
  }
  
  .ManageProjectModal_addUserButton__3DXAq {
    background-color: #5CD685;
    border: none;
    color: #ffffff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
  
  .ManageProjectModal_addUserButton__3DXAq:hover {
    background-color: #94D1A8 !important;
  }
  
  .ManageProjectModal_loadingText__3dT_s {
    text-align: center;
    color: #292B3D;
    font-size: 16px;
    padding: 20px;
  }
  
/* ================================
   Container, Layout, Panes
   (same as your original)
================================ */

.PromptBuilderPdp_container__2RpfJ {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
   /* Prevent overall page scroll */
}

/* Left Pane */
.PromptBuilderPdp_leftPane__1IyHy {
  width: 50%;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

/* Scrollable content in left pane */
.PromptBuilderPdp_leftPaneContent__1caG3 {
  flex: 1 1;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
}

/* Right Pane */
.PromptBuilderPdp_rightPane__189_F {
  width: 50%;
  overflow-y: auto;
  padding: 20px;
  background-color: #ffffff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

/* Top Row: Back Button + Title */
.PromptBuilderPdp_topRow__2Ma0z {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 20px;
}

.PromptBuilderPdp_backButton__1WIiC {
  background-color: #d0d0d0;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}
.PromptBuilderPdp_backButton__1WIiC:hover {
  background-color: #7c7c7c;
}

.PromptBuilderPdp_pageTitle__yY-3B {
  font-family: 'DM Serif Text', serif;
  font-size: 24px;
  color: #292B3D;
  margin: 0;
}

/* Row containing two columns */
.PromptBuilderPdp_dropdownRow__1k2fq {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 20px;
}

.PromptBuilderPdp_clientColumn__2gHhB {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.PromptBuilderPdp_promptColumn__3anHi {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

/* Prompt dropdown and buttons side by side */
.PromptBuilderPdp_promptAndButtons__2h3Qo {
  display: flex;
  align-items: flex-end;
  grid-gap: 10px;
  gap: 10px;
  margin-top: auto;
}

.PromptBuilderPdp_promptDropdownContainer__1bT1T {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.PromptBuilderPdp_iconButtons__14lvv {
  display: flex;
  flex-direction: row;
}

.PromptBuilderPdp_dropdownLabel__1NI26 {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #333333;
  margin-bottom: 4px;
}

/* Error Message */
.PromptBuilderPdp_errorMessage__28Rsi {
  margin-top: 10px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  padding: 10px;
  border-radius: 8px;
  font-family: 'Lexend Deca', sans-serif;
}

/* Status bar (for success / error messages) */
.PromptBuilderPdp_statusBar__15YZr {
  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
}
.PromptBuilderPdp_success__3-eyv {
  background-color: #d4edda;
  color: #155724;
}
.PromptBuilderPdp_error__1H_-B {
  background-color: #f8d7da;
  color: #721c24;
}

/* Buttons */
.PromptBuilderPdp_addButton__w9tiK {
  background-color: #5CD685;
  color: #ffffff;
  border: none;
  padding: 10px !important;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px !important;
  height: 30px !important;
}
.PromptBuilderPdp_addButton__w9tiK:hover {
  background-color: #94D1A8 !important;
}
.PromptBuilderPdp_addButton__w9tiK:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.PromptBuilderPdp_removeButton__2yfnq {
  background-color: #EB4755;
  color: #ffffff !important;
  border: none;
  padding: 10px !important;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px !important;
  height: 30px !important;
}
.PromptBuilderPdp_removeButton__2yfnq:hover {
  background-color: #E0858C !important;
}
.PromptBuilderPdp_removeButton__2yfnq:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.PromptBuilderPdp_importButton__3TcDh {
  background-color: #d0d0d0;
  color: #ffffff;
  border: none;
  padding: 10px !important;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px !important;
  height: 30px !important;
}
.PromptBuilderPdp_importButton__3TcDh:hover {
  background-color: #7c7c7c !important;
}
.PromptBuilderPdp_importButton__3TcDh:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Save button */
.PromptBuilderPdp_saveButton__6jQfq {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  border-radius: 40px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
}
.PromptBuilderPdp_saveButton__6jQfq:hover {
  background-color: #3947c2;
}

/* Sticky unsaved changes bar at bottom of leftPane */
.PromptBuilderPdp_stickyUnsavedBar__y8x0l {
  position: sticky;
  bottom: 0;
  left: 0;
  padding: 10px;
  background-color: #ffdca8; /* light highlight color */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ccc;
}

.PromptBuilderPdp_stickyUnsavedText__1VO4w {
  color: #663c00;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
}

/* Loading container */
.PromptBuilderPdp_loadingContainer__2BKcN {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.PromptBuilderPdp_loadingContainer__2BKcN p {
  margin-top: 10px;
  color: #4758EB;
  font-weight: bold;
}

/* Prompt containers (system, image, instruction) */
.PromptBuilderPdp_promptContainer__2MrOD {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
  overflow: hidden;
}
.PromptBuilderPdp_promptHeader__2Z7lV {
  background-color: #EAEAEA;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.PromptBuilderPdp_promptHeaderTitle__26XWU {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}
.PromptBuilderPdp_promptHeaderActions__d_eYk {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}
.PromptBuilderPdp_promptBody__3VzT_ {
  padding: 8px 12px;
  padding-right: 30px;
  background-color: #fff;
}
.PromptBuilderPdp_textAreaPrompt__Pqmol {
  width: 100%;
  min-height: 100px;
  resize: vertical;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  font-family: sans-serif;
}
.PromptBuilderPdp_textAreaPromptFocused__1gvWM {
  /* Keep the same as above but highlight the border on focus: */
  width: 100%;
  min-height: 100px;
  resize: vertical;
  border: 2px solid #000000;
  border-radius: 4px;
  padding: 8px;
  font-family: sans-serif;
  transition: border-color 0.2s ease-in-out;
}

.PromptBuilderPdp_textAreaFewShotPrompt__37zFb {
  width: 100%;
  min-height: 60px;
  resize: vertical;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  padding-top: 5px;
  padding-left: 5px;
  font-family: sans-serif;
}

/* Section label for multi-select, etc. */
.PromptBuilderPdp_section__2urv0 {
  margin-bottom: 20px;
}
.PromptBuilderPdp_sectionLabel__1BLeb {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #333333;
  margin-bottom: 4px;
}
.PromptBuilderPdp_columnsMultiSelect__dnNPs {
  margin-bottom: 8px;
}

/* =============== FEW SHOT SECTION =============== */

/* Container that wraps the entire few-shot area */
.PromptBuilderPdp_fewShotSection__3wh4T {
  margin-top: 40px;
  padding: 20px;
  background-color: #fcfcfc;
  border: 1px solid #ececec;
  border-radius: 8px;
}

/* Section header: Title + "Add Example" button */
.PromptBuilderPdp_fewShotSectionHeader__2Qdkz {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.PromptBuilderPdp_fewShotSectionTitle__bgOox {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: #292b3d;
}

/* Optional short description/instructions under the header */
.PromptBuilderPdp_fewShotSectionDescription__3ty4n {
  font-size: 14px;
  color: #555;
  margin-bottom: 16px;
  font-family: 'Lexend Deca', sans-serif;
  line-height: 1.4;
}

/* Add Example button in the top section */
.PromptBuilderPdp_addFewShotButton__2TPEF {
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  background-color: #5cd685;
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: 'Lexend Deca', sans-serif;
}

.PromptBuilderPdp_addFewShotButton__2TPEF:hover {
  background-color: #48b76b !important;
}

/* List of existing few-shot prompts */
.PromptBuilderPdp_fewShotList__a9xvX {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px; /* space between each prompt container */
}

/* Each card-like container for an individual "Few Shot Prompt" */
.PromptBuilderPdp_fewShotPromptContainer__147va {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.06);
  overflow: hidden;
}

/* Header (title + remove button) */
.PromptBuilderPdp_fewShotPromptHeader__CyoJt {
  background-color: #EAEAEA;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer; /* [NEW: Make entire header clickable for expand/collapse] */
}

.PromptBuilderPdp_fewShotPromptTitle__3FGof {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: #292b3d;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.PromptBuilderPdp_fewShotPromptActions__BGE6q {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}

/* The remove button specifically for few-shot prompts */
.PromptBuilderPdp_removeFewShotButton__-iY7E {
  background-color: #eb4755;
  color: #ffffff !important;
  border: none;
  padding: 6px 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PromptBuilderPdp_removeFewShotButton__-iY7E:hover {
  background-color: #c73a46 !important;
}

/* Body content inside a single few-shot card */
.PromptBuilderPdp_fewShotPromptBody__3gDmM {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px; /* vertical spacing */
  padding: 15px 20px; /* [NEW: Moved from container to body for better spacing] */
}

/* Row that holds ID input + Import button + Toggle RTE button */
.PromptBuilderPdp_fewShotPromptRow__2mfqE > * {
  flex: none;
}

/* Group for ID label + input */
.PromptBuilderPdp_fewShotPromptButtons__3VeM5 {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

/* Optional: Adjust the width of the ID input to take more space */
.PromptBuilderPdp_fewShotPromptIdGroup__ioCPP {
  flex: 1 1; /* Allow it to grow and take available space */
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
}

.PromptBuilderPdp_fewShotPromptIdGroup__ioCPP label {
  font-size: 14px;
  color: #333;
  font-family: 'Lexend Deca', sans-serif;
}

.PromptBuilderPdp_fewShotPromptInput__3-Wnw {
  padding: 6px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: sans-serif;
  font-size: 14px;
  min-width: 100px;
}

/* Import button in each row */
.PromptBuilderPdp_importFewShotButton__2O6K8 {
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  background-color: #d0d0d0;
  color: #fff;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  transition: background-color 0.3s;
}

.PromptBuilderPdp_importFewShotButton__2O6K8:hover {
  background-color: #7c7c7c !important;
}

/* Toggle button for Rich Text */
.PromptBuilderPdp_richTextToggleButton__1BvyA {
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  background-color: #d0d0d0;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 6px 10px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  transition: background-color 0.3s;
}

.PromptBuilderPdp_richTextToggleButton__1BvyA:hover {
  background-color: #7c7c7c;
}

/* A scroll box to show a small preview of the data */
.PromptBuilderPdp_fewShotPromptScrollBox__2bzrj {
  max-height: 160px; /* Limit vertical space */
  overflow-y: auto;
  background-color: #f9f9f9;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: sans-serif;
  font-size: 14px;
  color: #333;
  margin-bottom: 10px; /* some spacing below */
}

/* Grid for base columns (meta_title, meta_description, etc.) */
.PromptBuilderPdp_fewShotColumnsGrid__Uv418 {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px; /* or whatever spacing you prefer between editors */
}

@media (max-width: 800px) {
  .PromptBuilderPdp_fewShotColumnsGrid__Uv418 {
    grid-template-columns: 1fr; /* single column on smaller screens */
  }
}

.PromptBuilderPdp_fewShotColumnField__3nzHs {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}

.PromptBuilderPdp_fewShotColumnLabel__1bFMi {
  font-size: 13px;
  font-weight: 500;
  color: #444;
  font-family: 'Lexend Deca', sans-serif;
}




/* If toggled to Rich Text, we simply show a placeholder. 
 Replace with a real WYSIWYG editor if you want. */
.PromptBuilderPdp_richTextEditorPlaceholder__aqME2 {
  border: 1px dashed #aaa;
  padding: 8px;
  min-height: 80px;
  border-radius: 4px;
  background-color: #fcfcfc;
}

.PromptBuilderPdp_richTextEditorQuill__3lF0C {
  /* Make the editor's text area the same general size as your textarea. */
  margin-bottom: 0px;
  margin-top: 0px;
}

/* Optional: if you want to adjust the toolbar styling or spacing */
.PromptBuilderPdp_ql-toolbar__1zdDU.PromptBuilderPdp_ql-snow__3-xRJ {
  border-radius: 4px 4px 0 0;
}
.PromptBuilderPdp_ql-container__2Y8ZW.PromptBuilderPdp_ql-snow__3-xRJ {
  border-radius: 0 0 4px 4px;
}

/* Toggle Switch Styles */
.PromptBuilderPdp_toggleSwitch__zVaGg {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.PromptBuilderPdp_toggleSwitch__zVaGg input {
  opacity: 0;
  width: 0;
  height: 0;
}

.PromptBuilderPdp_slider__3ZZas {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.PromptBuilderPdp_slider__3ZZas:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.PromptBuilderPdp_toggleSwitch__zVaGg input:checked + .PromptBuilderPdp_slider__3ZZas {
  background-color: #4758EB;
}

.PromptBuilderPdp_toggleSwitch__zVaGg input:checked + .PromptBuilderPdp_slider__3ZZas:before {
  transform: translateX(26px);
}

/* Adjust the fewShotPromptRow to align items */
.PromptBuilderPdp_fewShotPromptRow__2mfqE {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  justify-content: space-between; /* Push buttons to the right */
}

/* New container for buttons to align them to the right */
.PromptBuilderPdp_fewShotPromptButtons__3VeM5 {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

/* Optional: Style for the fewShotPromptRow to handle space */
.PromptBuilderPdp_fewShotPromptRow__2mfqE > * {
  flex: none;
}

/* A small grid to show label/value pairs for each column. */
.PromptBuilderPdp_previewTable__2QhMP {
  display: grid;
  grid-template-columns: auto 1fr; 
  /* 'auto' for label width, '1fr' for value width */
  grid-gap: 4px 8px;
  gap: 4px 8px;
  margin-top: 4px;
}

/* Each row is displayed as "display: contents" or just separate divs. */
.PromptBuilderPdp_previewRow__34Q3Z {
  display: contents;
}

/* Label cell in the grid. */
.PromptBuilderPdp_previewLabel__ewZeK {
  font-weight: 600;
  color: #444;
}

/* Value cell in the grid. */
.PromptBuilderPdp_previewValue__8hcLx {
  color: #555;
  word-wrap: break-word;
  white-space: pre-wrap; /* handle newlines if any */
  overflow-wrap: anywhere;
}


/* Example: Similar styling to .fewShotSection but with a distinct background color. */

.PromptBuilderPdp_auditPromptSection__2ErPJ {
  margin-top: 40px;
  padding: 20px;
  background-color: #fff8e6; /* a soft yellowish background */
  border: 1px solid #eccb91;
  border-radius: 8px;
}

.PromptBuilderPdp_auditPromptHeader__2hBOD {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
}

.PromptBuilderPdp_auditPromptHeaderTitle__h0uwb {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #292b3d;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  margin: 0;
}

.PromptBuilderPdp_auditPromptBody__2TM6h {
  margin-top: 10px;
  margin-right: 20px;
}

.PromptBuilderPdp_auditPromptDescription__2wv-S {
  font-size: 14px;
  color: #444;
  margin-bottom: 15px;
  line-height: 1.4;
  font-family: 'Lexend Deca', sans-serif;
}

.PromptBuilderPdp_auditPromptToggleRow__-s-YT {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  margin-bottom: 15px;
}

.PromptBuilderPdp_auditPromptToggleLabel__pucX7 {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #333;
}


/* Header for Prompt Preview */
.PromptBuilderPdp_promptPreviewHeader__8J2Mt {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px; /* Match existing header sizes */
  font-weight: 600;
  color: #292B3D;
  margin-bottom: 16px; /* Consistent spacing */
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

/* Container for the ID input and Generate Preview button */
.PromptBuilderPdp_previewInputRow__2pySA {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 20px;
}

/* Input field for entering the ID */
.PromptBuilderPdp_previewInput__2cOLJ {
  flex: 1 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  box-sizing: border-box;
}

/* Generate Preview Button */
.PromptBuilderPdp_generatePreviewButton__3Nrgc {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PromptBuilderPdp_generatePreviewButton__3Nrgc:hover {
  background-color: #3947c2;
}

.PromptBuilderPdp_generatePreviewButton__3Nrgc:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Error Message for Preview Generation */
.PromptBuilderPdp_previewErrorMessage__3QGgU {
  margin-bottom: 10px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  padding: 10px;
  border-radius: 8px;
  font-family: 'Lexend Deca', sans-serif;
}

/* Container for the Generated Preview */
.PromptBuilderPdp_generatedPreviewBox__2Wy83 {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 12px;
  background-color: #fafafa;
  max-height: 60vh;
  overflow-y: auto;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
}

.PromptBuilderPdp_generatedPreviewBox__2Wy83 h3 {
  margin-top: 0;
  font-size: 18px;
  color: #292B3D;
}

/* Content inside the Generated Preview */
.PromptBuilderPdp_generatedPreviewContent__2ohjO {
  white-space: pre-wrap;
  word-wrap: break-word;
  background: #fff;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
}

/* Loader for Right Pane (Optional: Reuse existing loading styles or customize) */
.PromptBuilderPdp_rightPaneLoading__32UMv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.PromptBuilderPdp_rightPaneLoading__32UMv p {
  margin-top: 10px;
  color: #4758EB;
  font-weight: bold;
  font-family: 'Lexend Deca', sans-serif;
}

.PromptBuilderPdp_rightPaneHeader__1HfND {
font-family: 'DM Serif Text', serif;
font-size: 24px;
color: #292B3D;

}

.PromptBuilderPdp_comparisonHeader__1T8LZ {
  background-color: #EAEAEA;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.PromptBuilderPdp_comparisonHeaderTitle__3te-Z {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: #292B3D;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.PromptBuilderPdp_richTextToggleContainer__1IjZ7 {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

/* Label for the Rich Text toggle in the right pane */
.PromptBuilderPdp_richTextToggleLabelRightPane__3gPqj {
  font-size: 14px;
  font-weight: 500;
  color: #292B3D;
}

/* The "Import as Few Shot" button in the right pane */
.PromptBuilderPdp_importFewShotButtonRightPane__1JZ0D {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  transition: background-color 0.3s;
  white-space: nowrap; /* Prevent wrapping */
}

.PromptBuilderPdp_importFewShotButtonRightPane__1JZ0D:hover {
  background-color: #3947c2;
}

.PromptBuilderPdp_comparisonHeaderActions__19L1- {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* or "space-between" if you prefer */
  grid-gap: 12px;
  gap: 12px; 
  padding-right: 16px; /* some spacing from the right edge */
}

.PromptBuilderPdp_comparisonBody__Wv3ab {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
}

.PromptBuilderPdp_comparisonIdLabel__3hUe3 {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #444;
  margin-bottom: 2px;
}

/* columns_used displayed as small tags */
.PromptBuilderPdp_columnsUsedContainer__sK6As {
  background-color: #f9f9f9;
  border: 1px dashed #ccc;
  border-radius: 4px;
  padding: 8px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 13px;
  color: #555;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 6px;
  gap: 6px;
}

.PromptBuilderPdp_columnTag__2gPjc {
  background-color: #e0e1eb;
  color: #333;
  border-radius: 3px;
  padding: 2px 6px;
}

/* Toggle Switch */
.PromptBuilderPdp_toggleSwitchComparison__3_gwX {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.PromptBuilderPdp_toggleSwitchComparison__3_gwX input {
  opacity: 0;
  width: 0;
  height: 0;
}

.PromptBuilderPdp_sliderComparison__2Hfoj {
  position: absolute;
  cursor: pointer;
  top: 0; left: 0; right: 0; bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}
.PromptBuilderPdp_sliderComparison__2Hfoj:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}
.PromptBuilderPdp_toggleSwitchComparison__3_gwX input:checked + .PromptBuilderPdp_sliderComparison__2Hfoj {
  background-color: #4758EB;
}
.PromptBuilderPdp_toggleSwitchComparison__3_gwX input:checked + .PromptBuilderPdp_sliderComparison__2Hfoj:before {
  transform: translateX(26px);
}

/* Side-by-side columns */
.PromptBuilderPdp_sideBySideContainer__WL4W2 {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  flex-wrap: nowrap; /* Prevent wrapping so they stay side by side */
}


/* Stacked layout container (new) */
.PromptBuilderPdp_stackedContainer__2lCGD {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  gap: 24px;
  width: 100%;
}

.PromptBuilderPdp_dataColumn__1vIZ1 {
  flex: 1 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 6px;
  overflow: hidden;
}

/* Data section for stacked layout (new) */
.PromptBuilderPdp_dataSection__3z1W0 {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
}

.PromptBuilderPdp_columnHeader__1Rg-g {
  background-color: #F0F0F0;
  padding: 8px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #444;
}

.PromptBuilderPdp_columnContent__33wyk {
  flex: 1 1;
  overflow-y: auto;
  padding: 8px;
}

.PromptBuilderPdp_dataRow__1rTIe {
  margin-bottom: 16px;
}

.PromptBuilderPdp_dataLabel__2nHaT {
  font-weight: 500;
  margin-bottom: 4px;
  font-size: 13px;
  color: #555;
}

.PromptBuilderPdp_dataValuePlainText__1H8dI {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px;
  font-size: 13px;
  width: 100%;
  font-family: sans-serif;
  min-height: 100px;
  background-color: #fff;
  color: #333;
  box-sizing: border-box;
  resize: vertical;
}


/* Borrow Quill styling as needed */
.PromptBuilderPdp_ql-toolbar__1zdDU.PromptBuilderPdp_ql-snow__3-xRJ {
  border-radius: 4px 4px 0 0;
}
.PromptBuilderPdp_ql-container__2Y8ZW.PromptBuilderPdp_ql-snow__3-xRJ {
  border-radius: 0 0 4px 4px;
}

.PromptBuilderPdp_itemPreviewContainer__X3LbC {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
  margin-bottom: 20px;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 8px;
  padding-bottom: 0px;
  padding-top: 0px;
  position: relative;
  min-height: 100px; /* Ensure consistent height */
}

.PromptBuilderPdp_itemPreviewButtons__3jJ_m {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  margin-left: auto; /* Push to the right */
  padding-left: 10px;
}

.PromptBuilderPdp_itemPreviewImageContainer__3HQXa {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
}

.PromptBuilderPdp_itemPreviewImage__3Dz0k {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.PromptBuilderPdp_itemPreviewDetails__Oppiz {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px; 
  font-family: 'Lexend Deca', sans-serif;
  flex: 1 1; /* Take available space */
  min-width: 0; /* Allow text truncation */
}

.PromptBuilderPdp_itemPreviewTitle__C0gR1 {
  font-size: 16px;
  font-weight: 600;
  color: #292B3D;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.PromptBuilderPdp_itemPreviewBrand__1dWxE {
  font-size: 14px;
  color: #555;
  margin: 0;
}

.PromptBuilderPdp_itemPreviewId__1-lku {
  font-size: 13px;
  color: #888;
  margin: 0;
}

/* Improved toggle switch container for better alignment */
.PromptBuilderPdp_richTextToggleContainer__1IjZ7 {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  white-space: nowrap; /* Prevent wrapping */
}


.PromptBuilderPdp_columnLabelRow__1Qdca {
  display: flex;
  justify-content: space-between; /* push toggle to far right */
  align-items: center;
}

.PromptBuilderPdp_columnFormatToggle__16q6X {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px; /* space between checkbox and text label */
}


/* === Card Container Shared === */
.PromptBuilderPdp_dataCard__1KSe3 {
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #fff;
  margin-bottom: 20px; 
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
  max-height: 400px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1 1;
}

/* Scrollable content container */
.PromptBuilderPdp_dataCardScrollable__fIsYn {
  flex: 1 1;
  overflow-y: auto;
}

/* Header (Included Data) */
.PromptBuilderPdp_dataCardHeaderIncluded__YfTBP {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  background-color: #E0E1EB;
  color: #555EAA;
  z-index: 10;
  padding: 6px 10px;
  font-weight: 600;
  font-family: 'Lexend Deca', sans-serif;
}
.PromptBuilderPdp_dataCardHeaderIncluded__YfTBP .PromptBuilderPdp_dataCardHeaderIcon__OOiYa {
  color: #555EAA; /* icon color, or use #2d7a42 */
  font-size: 16px;
}


.PromptBuilderPdp_dataCardHeaderGenerated__1aZsR {
  position: sticky;
  top: 0;
  z-index: 10;

  background-color: #e9f8ef; /* light green background */
  color: #2d7a42;            /* darker green text */

  padding: 8px 12px;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  font-weight: 600;
  font-family: 'Lexend Deca', sans-serif;
}
.PromptBuilderPdp_dataCardHeaderGenerated__1aZsR .PromptBuilderPdp_dataCardHeaderGeneratedIcon__WOBgx {
  color: #43b05c; /* icon color, or use #2d7a42 */
  font-size: 16px;
}


/* Header (Excluded Data) */
.PromptBuilderPdp_dataCardHeaderExcluded__21K_4 {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  background-color: #EBE0E1; /* or a subtle red tint, e.g. #fceaea */
  color: #AA555C; 
  z-index: 10;
  padding: 6px 10px;
  font-weight: 600;
  font-family: 'Lexend Deca', sans-serif;
}
.PromptBuilderPdp_dataCardHeaderExcluded__21K_4 .PromptBuilderPdp_dataCardHeaderIcon__OOiYa {
  color: #AA555C; /* or #cc4a4a if you want red */
  font-size: 16px;
}

/* Card Content Area */
.PromptBuilderPdp_dataCardContent__i9HgX {
  padding: 8px;
  display: flex;
  max-height: 200px;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px; /* spacing between rows */
}

.PromptBuilderPdp_dataCardGenerated__KhxRq {
  display: flex;
  flex-direction: column;
  border: 2px solid #43b05c;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
  max-height: 600px;
  overflow: hidden;
  flex: 1 1;
  }


  .PromptBuilderPdp_dataCardGeneratedContent__2O45- {
    /* This is the scrollable part. */
    flex: 1 1;             /* fill the remaining vertical space so the content can scroll */
    overflow-y: auto;    /* enable scrolling in just this area */
    padding: 12px;
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px; /* spacing between rows */
  }

/* Each label-value row */
.PromptBuilderPdp_dataRow__1rTIe {
  margin-bottom: 0px; /* Less space between rows */
  grid-gap: 0px;
  gap: 0px;           /* Smaller gap if using flex or grid layout */
}

/* Label */
.PromptBuilderPdp_dataLabel__2nHaT {
  font-size: 13px;
  font-weight: 500;
  color: #555;
}

/* For an empty "No data found" message */
.PromptBuilderPdp_emptyDataMessage__1wMGp {
  font-size: 14px;
  color: #777;
  font-style: italic;
  margin: 0;
}

.PromptBuilderPdp_insertedMention__1JGxh {
  background-color: #b3e5fc; /* a subtle teal or sky-blue highlight */
  color: #0b1e21;
  border-radius: 3px;
  padding: 0 3px;
  font-weight: 600; /* or as you like */
}

/* Add a style for invalid mentions */
.PromptBuilderPdp_invalidMention__3G4NU {
  background-color: #ffebee; /* light red background */
  color: #c62828; /* dark red text */
  border-radius: 3px;
  padding: 0 3px;
  font-weight: 600;
  border-bottom: 1px dashed #c62828; /* add a dashed underline to emphasize the error */
}

/* PromptBuilderPdp.module.css (or your relevant CSS module) */

.PromptBuilderPdp_researchPromptButton__2aFAP {
  margin-left: 10px;
  background-color: #848EE0;
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.PromptBuilderPdp_researchPromptButton__2aFAP:hover {
  background-color: #3947c2 !important;
}

.PromptBuilderPdp_finalResearchPromptBox__1hUd8 {
  background-color: #f6f7ff;
  border-bottom: 1px solid #dce0ff;
  margin: 0;
  padding: 8px;
}

.PromptBuilderPdp_finalResearchPromptBox__1hUd8 strong {
  display: block;
  margin-bottom: 4px;
}

.PromptBuilderPdp_pdfLinkButton__2Z-aN {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 12px;
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.PromptBuilderPdp_pdfLinkButton__2Z-aN:hover {
  background-color: #3947c2;
}

/* New ColumnTagger Component Styles */
.PromptBuilderPdp_columnTaggerContainer__x7N0m {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.PromptBuilderPdp_columnTaggerInputWrapper__2YOje {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.PromptBuilderPdp_columnTaggerTextarea__2fWoA {
  width: 100%;
  min-height: 100px;
  resize: vertical;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 12px;
  font-family: sans-serif;
  font-size: 14px;
  line-height: 1.5;
  outline: none;
  transition: border-color 0.2s ease;
}

.PromptBuilderPdp_columnTaggerTextarea__2fWoA:focus {
  border-color: #4758EB;
  box-shadow: 0 0 0 2px rgba(71, 88, 235, 0.1);
}

.PromptBuilderPdp_columnTaggerHint__3Ye-I {
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  margin-top: 6px;
  font-size: 12px;
  color: #555;
}

.PromptBuilderPdp_columnTaggerPreview__2_WBR {
  margin-top: 10px;
  padding: 8px;
  background-color: #f9f9fb;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  min-height: 40px;
  font-family: sans-serif;
  font-size: 14px;
  line-height: 1.5;
  white-space: pre-wrap;
  word-break: break-word;
}

/* Dropdown styles for column tagger */
.PromptBuilderPdp_columnTaggerSuggestions__3aTzd {
  position: fixed !important; /* Use fixed positioning to ensure it's not clipped */
  z-index: 10000 !important; /* Very high z-index to ensure it's on top */
  background-color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4) !important; /* Stronger shadow */
  border-radius: 6px;
  width: auto; /* Let the JS control width */
  max-height: 300px; /* Taller dropdown */
  overflow-y: auto;
  border: 2px solid #4758EB !important;
}

.PromptBuilderPdp_suggestionHeader__MVT4n {
  background-color: #4758EB;
  color: white;
  padding: 8px 12px;
  font-weight: bold;
  font-size: 13px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

/* Make sure the dropdown can scroll properly */
.PromptBuilderPdp_columnTaggerSuggestions__3aTzd ul {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 250px; /* Allow scrolling through options */
  overflow-y: auto;
  border-top: 1px solid #eee;
}

.PromptBuilderPdp_columnTaggerSuggestions__3aTzd li {
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.1s ease;
  border-bottom: 1px solid #f0f0f0;
}

.PromptBuilderPdp_columnTaggerSuggestions__3aTzd li:hover {
  background-color: #f0f2ff;
}

.PromptBuilderPdp_activeColumnSuggestion__1Yu-b {
  background-color: #e0e3ff;
  font-weight: 500;
  border-left: 3px solid #4758EB;
}

.PromptBuilderPdp_noSuggestions__R-eAX {
  padding: 15px;
  text-align: center;
  color: #777;
  font-style: italic;
  font-size: 13px;
}

/* New Data Sources Section with Tabs */
.PromptBuilderPdp_dataSourcesSection__DNwtn {
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.06);
}

.PromptBuilderPdp_dataSourcesSectionTitle__B4e_J {
  padding: 12px 16px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: #292B3D;
  background-color: #EAEAEA;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.PromptBuilderPdp_dataSourceTabs__WEgR3 {
  display: flex;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
  overflow-x: auto;
  padding: 0;
}

.PromptBuilderPdp_dataSourceTab__39vCt {
  padding: 10px 16px;
  border: none;
  background: none;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #555;
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  transition: all 0.2s ease;
  white-space: nowrap;
  position: relative;
}

.PromptBuilderPdp_dataSourceTab__39vCt:hover {
  color: #4758EB !important;
  background-color: rgba(71, 88, 235, 0.05) !important;
}

.PromptBuilderPdp_activeTab__eWxkn {
  color: #4758EB;
  font-weight: 600;
  border-bottom: 3px solid #4758EB;
  background-color: rgba(71, 88, 235, 0.08);
}

.PromptBuilderPdp_dataSourceContainer__3gHES {
  margin: 12px;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #ddd;
}

.PromptBuilderPdp_dataSourceHeader__1G25M {
  padding: 10px 12px;
  background-color: #EAEAEA;
  border-bottom: 1px solid #ddd;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #292B3D;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PromptBuilderPdp_dataSourceIcon__2GVcl {
  margin-right: 8px;
  font-size: 16px;
  color: #4758EB;
}

.PromptBuilderPdp_feedDataSection__3tzaV {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  padding: 12px;
}

@media (max-width: 768px) {
  .PromptBuilderPdp_feedDataSection__3tzaV {
    flex-direction: column;
  }
}

.PromptBuilderPdp_webSearchContent___GZor, .PromptBuilderPdp_pdfContent__2_KPK {
  padding: 12px;
  line-height: 1.5;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #333;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #eee;
}

.PromptBuilderPdp_dataSourceHeaderCentered__3fspL {
  padding: 10px 12px;
  background-color: #EAEAEA;
  border-bottom: 1px solid #ddd;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #292B3D;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.PromptBuilderPdp_dataSourceHeaderWithActions__2xOHS {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PromptBuilderPdp_headerActions__2cNcD {
  position: absolute;
  right: 12px;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}

/* View Prompt Button - styled to match PDF link button */
.PromptBuilderPdp_viewPromptButton__3DG7w {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 12px;
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.PromptBuilderPdp_viewPromptButton__3DG7w:hover {
  background-color: #3947c2;
}

/* src/components/css/PopupCreatePromptCombinationPdp.module.css */

.PopupCreatePromptCombinationPdp_popupOverlay__bCinw {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); /* Semi-transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* ensure popup is on top */
  }
  
  .PopupCreatePromptCombinationPdp_popupContent__2sZvw {
    background-color: #fff;
    width: 450px;
    max-width: 90%;
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.3);
    padding: 20px;
    font-family: 'Lexend Deca', sans-serif;
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
  }
  
  .PopupCreatePromptCombinationPdp_popupHeader__1r0wL {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .PopupCreatePromptCombinationPdp_popupHeader__1r0wL h2 {
    margin: 0;
    font-size: 18px;
    color: #292B3D;
    font-family: 'DM Serif Text', serif;
  }
  
  .PopupCreatePromptCombinationPdp_closeButton__1ksmQ {
    background-color: transparent;
    border: none;
    color: #888;
    background-color: #ffffff;
    cursor: pointer;
    font-size: 16px;
    padding: 8px;
    border-radius: 50%;
    transition: background-color 0.3s;
  }
  .PopupCreatePromptCombinationPdp_closeButton__1ksmQ:hover {
    background-color: #ffffff !important;
    color: #000000 !important;
  }
  
  .PopupCreatePromptCombinationPdp_popupBody__I-tHx {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
  }
  
  .PopupCreatePromptCombinationPdp_fieldLabel__2OWLp {
    font-size: 14px;
    color: #333;
    margin-bottom: 4px;
  }
  
  .PopupCreatePromptCombinationPdp_textInput__37YLJ {
    height: 34px;
    padding: 6px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    outline: none;
    transition: border-color 0.3s;
  }
  .PopupCreatePromptCombinationPdp_textInput__37YLJ:focus {
    border-color: #4758EB;
  }
  
  .PopupCreatePromptCombinationPdp_errorMessage__1KH_0 {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    padding: 8px;
    border-radius: 6px;
  }
  
  .PopupCreatePromptCombinationPdp_successMessage__3YJAf {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
    padding: 8px;
    border-radius: 6px;
  }
  
  .PopupCreatePromptCombinationPdp_warningMessage__2YVlh {
    background-color: #fff3cd;
    color: #856404;
    border: 1px solid #ffeeba;
    padding: 8px;
    border-radius: 6px;
  }
  
  .PopupCreatePromptCombinationPdp_infoMessage__1uO-l {
    background-color: #e7f3fe;
    color: #0c5460;
    border: 1px solid #bee5eb;
    padding: 8px;
    border-radius: 6px;
  }
  
  /* Footer buttons */
  .PopupCreatePromptCombinationPdp_popupFooter__3dyMG {
    display: flex;
    justify-content: flex-end;
    grid-gap: 8px;
    gap: 8px;
  }
  
  /* Similar to your PdpGeneration styles for filter buttons */
  .PopupCreatePromptCombinationPdp_createButton__1LfHe {
    background-color: #4758EB;
    color: #ffffff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 14px;
  }
  .PopupCreatePromptCombinationPdp_createButton__1LfHe:hover {
    background-color: #848EE0;
  }
  .PopupCreatePromptCombinationPdp_createButton__1LfHe:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

/* src/popups/css/PopupImportDefaultPromptPdp.module.css */

/* Overall overlay */
.PopupImportDefaultPromptPdp_popupOverlay__2ZMIQ {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

/* Popup content */
.PopupImportDefaultPromptPdp_popupContent__jJl-j {
    background: #fff;
    width: 600px;
    max-height: 80vh;
    overflow-y: auto;
    border-radius: 8px;
    padding: 20px;
    position: relative;
}

/* Header with title + close button */
.PopupImportDefaultPromptPdp_popupHeader__2y_9q {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.PopupImportDefaultPromptPdp_closeButton__11jsa {
    background: none;
    border: none;
    cursor: pointer;
    color: #333;
    transition: color 0.3s;
}

.PopupImportDefaultPromptPdp_closeButton__11jsa:hover {
    color: #4758EB; /* Match hover color from PromptBuilderPdp */
}

/* Body area */
.PopupImportDefaultPromptPdp_popupBody__1lw9l {
    padding: 10px 0;
}

/* Loading container */
.PopupImportDefaultPromptPdp_loadingContainer__3R_bB {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.PopupImportDefaultPromptPdp_loadingContainer__3R_bB p {
    margin: 0;
    font-weight: bold;
    color: #4758EB; /* Consistent color */
}

/* Errors, warnings, etc */
.PopupImportDefaultPromptPdp_errorMessage__3LCEu {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 8px;
}

/* List items for prompts */
.PopupImportDefaultPromptPdp_promptItem__1uPtY {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ececec;
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 4px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}

.PopupImportDefaultPromptPdp_promptItem__1uPtY:hover {
    background-color: #f1f1f1;
}

.PopupImportDefaultPromptPdp_selected__2MLgo {
    border-color: #4758EB; /* Highlight selected item */
    background-color: #e0e1eb; /* Subtle background color */
}

.PopupImportDefaultPromptPdp_promptName__183E2 {
    font-weight: 500;
    color: #292B3D;
}

/* Action buttons on each item */
.PopupImportDefaultPromptPdp_promptActions__2_pmI {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
}

/* Reuse button styles from PromptBuilderPdp */
.PopupImportDefaultPromptPdp_actionButton__1iaSO {
    background-color: #d0d0d0;
    color: #ffffff;
    border: none;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
}

.PopupImportDefaultPromptPdp_actionButton__1iaSO:hover {
    background-color: #7c7c7c;
}

/* Specific button styles */
.PopupImportDefaultPromptPdp_editButton__1wLU6 {
    background-color: #4758EB;
}

.PopupImportDefaultPromptPdp_editButton__1wLU6:hover {
    background-color: #3947c2;
}

.PopupImportDefaultPromptPdp_deleteButton__3notf {
    background-color: #EB4755;
}

.PopupImportDefaultPromptPdp_deleteButton__3notf:hover {
    background-color: #E0858C;
}

.PopupImportDefaultPromptPdp_importButton__3FV80 {
    background-color: #5CD685;
}

.PopupImportDefaultPromptPdp_importButton__3FV80:hover {
    background-color: #94D1A8 !important;
}

/* Admin Create new button */
.PopupImportDefaultPromptPdp_newPromptButton__1CVVZ {
    margin-top: 10px;
    background-color: #5CD685;
    color: #fff;
    border: none;
    padding: 10px 16px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
    transition: background-color 0.3s;
}

.PopupImportDefaultPromptPdp_newPromptButton__1CVVZ:hover {
    background-color: #94D1A8;
}

/* No prompts */
.PopupImportDefaultPromptPdp_noPrompts__2mUEK {
    font-style: italic;
    color: #999;
}

/* Admin Panel for editing/creating */
.PopupImportDefaultPromptPdp_adminPanel__sd6wO {
    padding: 10px 0;
}

.PopupImportDefaultPromptPdp_fieldLabel__1G-Id {
    display: block;
    margin-top: 8px;
    margin-bottom: 2px;
    font-weight: 500;
    color: #292B3D;
}

.PopupImportDefaultPromptPdp_textInput__nlaf_ {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.PopupImportDefaultPromptPdp_textArea__qhg9- {
    width: 100%;
    height: 120px;
    padding: 8px;
    box-sizing: border-box;
    resize: vertical;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.PopupImportDefaultPromptPdp_saveButton__2L7kd {
    margin-top: 10px;
    background-color: #4758EB;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.PopupImportDefaultPromptPdp_saveButton__2L7kd:hover {
    background-color: #3947c2;
}

/* Back button */
.PopupImportDefaultPromptPdp_backButton__3BJuX {
    background: none;
    border: none;
    color: #4758EB; /* Consistent color */
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
    cursor: pointer;
    margin-bottom: 10px;
    font-weight: 500;
    transition: color 0.3s;
}

.PopupImportDefaultPromptPdp_backButton__3BJuX:hover {
    color: #3947c2;
}

/* File: ImageGenerationScheduler.module.css */

/* Existing CSS... */

/* Top Bar Styles */
.ImageGenerationScheduler_topBar__3Xpj5 {
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin: 20px;
  background-color: #ffffff;
  color: #4758EB;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}

.ImageGenerationScheduler_headerTextContainer__tr1Ug {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 40px;
  flex-grow: 0;
}

.ImageGenerationScheduler_clientDropdown__3cbm0 {
  min-width: 300px;
  font-size: 12px;
}

.ImageGenerationScheduler_backButton__3QVPb {
  background-color: #d0d0d0;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.ImageGenerationScheduler_backButton__3QVPb:hover {
  background-color: #7c7c7c;
}

.ImageGenerationScheduler_feedViewerVersion__1miCD {
  font-size: 14px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
  color: #4758EB;
}

.ImageGenerationScheduler_headerSubtitle__3HM20 {
  font-size: 22px;
  font-family: 'DM Serif Text', serif;
  color: #292B3D;
}

.ImageGenerationScheduler_headerImage__3-6KA {
  height: 100px;
  width: auto;
  padding-top: 20px;
  object-fit: contain;
  margin-left: auto;
}

.ImageGenerationScheduler_logoutBtn__Tw8C5 {
  background-color: #E0858C;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.ImageGenerationScheduler_logoutBtn__Tw8C5:hover {
  background-color: #EB4755 !important;
}

/* Client Error Message */
.ImageGenerationScheduler_clientError__1hvPB {
  display: flex;
  align-items: center;
  color: #EB4755;
  margin-top: 8px;
}

/* Overview Container Styles */
.ImageGenerationScheduler_overviewContainer__2oeFL {
  margin: 20px;
}

/* Tables Wrapper Styles */
.ImageGenerationScheduler_tablesWrapper__w5eei {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #ffffff;
  color: #292B3D;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Tables Header Styles */
.ImageGenerationScheduler_tablesHeader__Rq6uD {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.ImageGenerationScheduler_tableHeader__xbuAD {
  flex: 1 1;
}

.ImageGenerationScheduler_tableHeader__xbuAD h2 {
  font-size: 18px;
  color: #292B3D;
}

.ImageGenerationScheduler_tableHeader__xbuAD p {
  margin: 0;
  font-size: 14px;
  color: #292B3D;
}

/* Tables Container */
.ImageGenerationScheduler_tablesContainer__2DrH9 {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  flex-wrap: wrap;
}

/* Table Wrapper */
.ImageGenerationScheduler_tableWrapper__11AWh {
  flex: 1 1;
  max-height: 275px; /* Fixed height */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal overflow */
}

/* Table Styles */
.ImageGenerationScheduler_tableContainer__BihNh {
  width: 100%;
  overflow-x: auto; /* Add horizontal scrolling */
}

.ImageGenerationScheduler_table__1spJb {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Lexend Deca', sans-serif;
  table-layout: fixed; /* Ensures consistent column widths */
}

.ImageGenerationScheduler_table__1spJb th,
.ImageGenerationScheduler_table__1spJb td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  word-wrap: break-word; /* Allow long words to break */
  white-space: normal;   /* Allow text to wrap to the next line */
}

.ImageGenerationScheduler_table__1spJb th {
  background-color: #4758EB;
  position: sticky;
  top: 0;
  z-index: 1;
  color: #ffffff;
}

.ImageGenerationScheduler_table__1spJb tr:hover {
  background-color: #f5f5f5;
}

/* Icon Button Styles */
.ImageGenerationScheduler_iconButton__cNL2O {
  background-color: #d0d0d0;
  color: #ffffff;
  border: none;
  padding: 6px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ImageGenerationScheduler_iconButton__cNL2O:hover {
  background-color: #7c7c7c;
}

/* Modal Styles */
.ImageGenerationScheduler_modalOverlay__2kDpF {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 43, 61, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.ImageGenerationScheduler_modalContent__1mxh2 {
  background-color: #ffffff;
  padding: 30px; /* Increased padding for better spacing */
  border-radius: 8px;
  width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
}

.ImageGenerationScheduler_modalContent__1mxh2 h3 {
  font-family: 'Lexend Deca', sans-serif;
  color: #4758EB;
  margin-bottom: 20px;
}

.ImageGenerationScheduler_closeModalButton__3zPC4 {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #4758EB; /* Change color to match theme */
}

.ImageGenerationScheduler_closeModalButton__3zPC4:hover {
  color: #3641b2 !important;
}

.ImageGenerationScheduler_modalSection__2x9wa {
  margin-bottom: 20px;
}

.ImageGenerationScheduler_modalSection__2x9wa h4 {
  font-family: 'Lexend Deca', sans-serif;
  color: #292B3D;
  margin-bottom: 10px;
}

.ImageGenerationScheduler_idList__ES-Y_ {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 4px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 12px;
  max-height: 200px;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

/* Grouped Modified Images Container */
.ImageGenerationScheduler_groupedModifiedImagesContainer__21Wzm {
  max-height: 400px; /* Adjust as needed */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

/* Modified Image Group */
.ImageGenerationScheduler_modifiedImageGroup__1WxsS {
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

.ImageGenerationScheduler_modifiedImageGroup__1WxsS h5 {
  font-size: 14px;
  color: #4758EB;
  margin-bottom: 10px;
}

/* Image Pairs Container */
.ImageGenerationScheduler_imagePairs__3fIfD {
  display: flex;
  flex-wrap: wrap;
  justify-content: left; /* Center the images */
  margin-top: 20px
}

/* Single Image Pair */
.ImageGenerationScheduler_imagePair__2diHG {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 12px;
  gap: 12px; /* Slightly increased gap between image and label */
  width: 110px; /* Adjust width for uniformity */
}

/* Image Wrapper */
.ImageGenerationScheduler_imageWrapper__yxGSz {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px; /* Fixed width */
  height: 80px; /* Fixed height */
  border-radius: 6px;
  overflow: hidden; /* Ensure content does not overflow */
}


.ImageGenerationScheduler_currentImage__1Pl_m {
  border: 4px solid #E0858C; /* Blue border for Current images */
  padding: 5px;
  border-radius: 6px; /* Slightly round the corners */
}

.ImageGenerationScheduler_newImage__33Pw8 {
  border: 4px solid #5CD685; /* Green border for New images */
  border-radius: 6px;
}

/* Style for Image Labels */
.ImageGenerationScheduler_imageLabel__3k39_ {
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  margin-top: 6px;
  color: #292B3D;
}

/* Adjust Image Sizes for Smaller Display */
.ImageGenerationScheduler_image__or3c6 {
  width: 40px; /* Set to a more consistent size */
  height: 40px;
  height: auto;
  object-fit: cover;
}

/* Responsive Adjustments for Image Pairs */
@media (max-width: 768px) {
  .ImageGenerationScheduler_imagePairs__3fIfD {
    grid-gap: 10px;
    gap: 10px;
  }
  
  .ImageGenerationScheduler_imagePair__2diHG {
    width: 100px; /* Smaller width on smaller screens */
  }
  
  .ImageGenerationScheduler_image__or3c6 {
    max-width: 100px;
  }
}

/* Scrollbar Styling (Optional for better aesthetics) */
.ImageGenerationScheduler_groupedModifiedImagesContainer__21Wzm::-webkit-scrollbar {
  width: 10px;
}

.ImageGenerationScheduler_groupedModifiedImagesContainer__21Wzm::-webkit-scrollbar-thumb {
  background: #4758EB;
  border-radius: 5px;
}

.ImageGenerationScheduler_groupedModifiedImagesContainer__21Wzm::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.ImageGenerationScheduler_groupedModifiedImagesContainer__21Wzm::-webkit-scrollbar-thumb:hover {
  background: #3641b2;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .ImageGenerationScheduler_tablesContainer__2DrH9 {
    flex-direction: column;
  }

  .ImageGenerationScheduler_tablesHeader__Rq6uD {
    flex-direction: column;
  }
}

/* Feed Dates Styles */
.ImageGenerationScheduler_feedDatesContainer__2VakN {
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ImageGenerationScheduler_cardsWrapper__1AP2Q {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
}

.ImageGenerationScheduler_card__23jSb {
  background-color: #ffffff;
  border: 1px solid #e0e1eb;
  border-radius: 8px;
  padding: 20px;
  flex: 1 1;
  min-width: 250px;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ImageGenerationScheduler_statusDot__3891G {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 15px;
}

.ImageGenerationScheduler_cardContent__Iie0W {
  display: flex;
  flex-direction: column;
}

.ImageGenerationScheduler_cardContent__Iie0W h3 {
  margin: 0;
  font-size: 16px;
  color: #292B3D;
  margin-bottom: 8px;
}

.ImageGenerationScheduler_cardContent__Iie0W p {
  margin: 0;
  font-size: 14px;
  color: #292B3D;
}

/* Scheduling Settings Container */
.ImageGenerationScheduler_schedulingSettingsContainer__3k1Og {
  margin-top: 20px;
  padding: 20px;
  background-color: #ffffff;
  color: #292B3D;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ImageGenerationScheduler_schedulingSettingsContainer__3k1Og h2 {
  font-size: 18px;
  color: #292B3D;
}

.ImageGenerationScheduler_settingsWrapper__3R8nf {
  display: flex;
  align-items: center; /* Ensures vertical alignment for all elements */
  grid-gap: 20px;
  gap: 20px; /* Adjust space between each setting item */
  justify-content: space-between; /* Distribute space between elements */
}

/* Align toggle labels and dropdown container */
.ImageGenerationScheduler_settingItem__AUr4u {
  display: flex;
  align-items: center; /* Ensures the label and toggle/dropdown are aligned */
  grid-gap: 10px;
  gap: 10px; /* Adds some space between label and input */
}

.ImageGenerationScheduler_toggleLabel__1pHAb {
  display: flex;
  align-items: center;
  grid-gap: 0px;
  gap: 0px; /* Adds space between text and toggle */
  font-family: 'DM Sans', sans-serif;
  color: #292B3D;
  margin-top: 10px;
}


.ImageGenerationScheduler_toggleLabel__1pHAb input {
  opacity: 0;
  width: 0;
  height: 0;
}

.ImageGenerationScheduler_toggleLabel__1pHAb input:checked + .ImageGenerationScheduler_toggleSlider__DAIPg {
  background-color: #4758EB;
}

.ImageGenerationScheduler_toggleSlider__DAIPg {
  position: relative;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 34px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.ImageGenerationScheduler_toggleSlider__DAIPg:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

.ImageGenerationScheduler_toggleLabel__1pHAb input:focus + .ImageGenerationScheduler_toggleSlider__DAIPg {
  box-shadow: 0 0 1px #4758EB;
}

.ImageGenerationScheduler_toggleSlider__DAIPg::before {
  content: "";
  position: absolute;
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.2s;
}

.ImageGenerationScheduler_toggleLabel__1pHAb input:checked + .ImageGenerationScheduler_toggleSlider__DAIPg::before {
  transform: translateX(20px);
}

.ImageGenerationScheduler_toggleLabel__1pHAb input:disabled + .ImageGenerationScheduler_toggleSlider__DAIPg {
  background-color: #e0e1eb;
}

.ImageGenerationScheduler_switch__3cBMB {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.ImageGenerationScheduler_switch__3cBMB input {
  opacity: 0;
  width: 0;
  height: 0;
}

.ImageGenerationScheduler_slider__n4OpZ {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.ImageGenerationScheduler_slider__n4OpZ:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .ImageGenerationScheduler_slider__n4OpZ {
  background-color: #4758EB;
}

input:checked + .ImageGenerationScheduler_slider__n4OpZ:before {
  transform: translateX(20px);
}

input:disabled + .ImageGenerationScheduler_slider__n4OpZ {
  background-color: #e0e1eb;
}

.ImageGenerationScheduler_switch__3cBMB input:focus + .ImageGenerationScheduler_slider__n4OpZ {
  box-shadow: 0 0 1px #4758EB;
}

/* Dropdown label should align horizontally */
.ImageGenerationScheduler_dropdownLabel__2HcDK {
  display: flex; /* Align label and dropdown horizontally */
  align-items: center; /* Vertically center them */
  justify-content: flex-start; /* Align items to the left */
  grid-gap: 10px;
  gap: 10px; /* Space between label and dropdown */
}

.ImageGenerationScheduler_scheduleDropdown__1vMsj {
  margin-left: 10px; /* Adds space between the label and dropdown */
  flex-grow: 1; /* Allows the dropdown to grow */
  max-width: 150px; /* Limits the width of the dropdown */
}

/* Latest Export Button */
.ImageGenerationScheduler_latestExportButton__13Nbm {
  background-color: #d0d0d0;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
  font-family: 'Lexend Deca', sans-serif;
}

.ImageGenerationScheduler_latestExportButton__13Nbm:hover {
  background-color: #7c7c7c;
}

/* Loading, Error, and No Data Containers */
.ImageGenerationScheduler_loadingContainer__iohE3,
.ImageGenerationScheduler_errorContainer__2vJ95,
.ImageGenerationScheduler_noDataContainer__2jbe_ {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
  font-size: 14px;
  color: #292B3D;
}

/* Processing Jobs Header Styles */
.ImageGenerationScheduler_processingJobsHeader__1_oUr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px; /* Adjust spacing as needed */
}

/* Optional: Adjustments for the Processing Jobs Container */
.ImageGenerationScheduler_processingJobsContainer__kLKPg {
  margin-top: 20px;
  padding: 20px;
  background-color: #ffffff;
  color: #292B3D;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ImageGenerationScheduler_processingJobsContainer__kLKPg h2 {
  font-size: 18px;
  color: #292B3D;
}

.ImageGenerationScheduler_createJobButton__2oeji {
  background-color: #5CD685; /* Green color */
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
  font-family: 'Lexend Deca', sans-serif;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.ImageGenerationScheduler_createJobButton__2oeji:hover {
  background-color: #94D1A8 !important;
}

.ImageGenerationScheduler_jobsTableWrapper__3_VCK {
  width: 100%;
  overflow-x: auto;
}

.ImageGenerationScheduler_jobsTable__3Pj6t {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Lexend Deca', sans-serif;
  table-layout: fixed;
}

.ImageGenerationScheduler_jobsTable__3Pj6t th,
.ImageGenerationScheduler_jobsTable__3Pj6t td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  word-wrap: break-word;
  white-space: normal;
}

.ImageGenerationScheduler_jobsTable__3Pj6t th {
  background-color: #4758EB;
  position: sticky;
  top: 0;
  z-index: 1;
  color: #ffffff;
}

.ImageGenerationScheduler_jobsTable__3Pj6t tr:hover {
  background-color: #f5f5f5;
}

/* Create Job Modal */
.ImageGenerationScheduler_createJobForm__1K14D {
  display: flex;
  flex-direction: column;
}

.ImageGenerationScheduler_formGroup__2WEot {
  display: flex;
  flex-direction: column;
}

.ImageGenerationScheduler_formGroup__2WEot label {
  margin-bottom: 5px;
  font-weight: 500;
}

.ImageGenerationScheduler_formGroup__2WEot input[type="text"],
.ImageGenerationScheduler_formGroup__2WEot input[type="number"],
.ImageGenerationScheduler_formGroup__2WEot .ImageGenerationScheduler_selectInput__mJSMd {
  padding: 8px;
  border: 1px solid #e0e1eb;
  border-radius: 4px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
}

.ImageGenerationScheduler_formGroup__2WEot input[type="number"] {
  width: 100px;
}

.ImageGenerationScheduler_formActions__1RKhY {
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 20px;
}

.ImageGenerationScheduler_submitButton__19MDm {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
}

.ImageGenerationScheduler_submitButton__19MDm:hover {
  background-color: #3641b2;
}

.ImageGenerationScheduler_cancelButton__2GdxP {
  background-color: #d0d0d0;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
}

.ImageGenerationScheduler_cancelButton__2GdxP:hover {
  background-color: #7c7c7c;
}

/* Enhanced Delete Button Styles */
.ImageGenerationScheduler_deleteButton__3Lcvj {
  background-color: #EB4755; /* Match logout button background */
  color: #ffffff;
  border: none;
  padding: 7px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
}

.ImageGenerationScheduler_deleteButton__3Lcvj:hover {
  background-color: #E0858C !important; /* Match logout button hover color */
}

.ImageGenerationScheduler_deleteButton__3Lcvj:disabled {
  background-color: #e0e1eb;
  cursor: not-allowed;
}

.ImageGenerationScheduler_activeToggle__3T2dc {
  cursor: pointer;
}

/* Toggle Switch Styles */
.ImageGenerationScheduler_switch__3cBMB {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.ImageGenerationScheduler_switch__3cBMB input {
  opacity: 0;
  width: 0;
  height: 0;
}

.ImageGenerationScheduler_slider__n4OpZ {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.ImageGenerationScheduler_slider__n4OpZ:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .ImageGenerationScheduler_slider__n4OpZ {
  background-color: #4758EB;
}

input:checked + .ImageGenerationScheduler_slider__n4OpZ:before {
  transform: translateX(20px);
}

input:disabled + .ImageGenerationScheduler_slider__n4OpZ {
  background-color: #e0e1eb;
}

.ImageGenerationScheduler_switch__3cBMB input:focus + .ImageGenerationScheduler_slider__n4OpZ {
  box-shadow: 0 0 1px #4758EB;
}

/* Dropdown label should align horizontally */
.ImageGenerationScheduler_dropdownLabel__2HcDK {
  display: flex; /* Align label and dropdown horizontally */
  align-items: center; /* Vertically center them */
  justify-content: flex-start; /* Align items to the left */
  grid-gap: 10px;
  gap: 10px; /* Space between label and dropdown */
}

.ImageGenerationScheduler_scheduleDropdown__1vMsj {
  margin-left: 10px; /* Adds space between the label and dropdown */
  flex-grow: 1; /* Allows the dropdown to grow */
  max-width: 150px; /* Limits the width of the dropdown */
}

/* Latest Export Button */
.ImageGenerationScheduler_latestExportButton__13Nbm {
  background-color: #d0d0d0;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
  font-family: 'Lexend Deca', sans-serif;
}

.ImageGenerationScheduler_latestExportButton__13Nbm:hover {
  background-color: #7c7c7c;
}

/* Responsive Adjustments for Processing Jobs */
@media (max-width: 768px) {
  .ImageGenerationScheduler_processingJobsContainer__kLKPg {
    padding: 15px;
  }

  .ImageGenerationScheduler_createJobButton__2oeji {
    width: 100%;
    justify-content: center;
  }

  .ImageGenerationScheduler_jobsTable__3Pj6t th,
  .ImageGenerationScheduler_jobsTable__3Pj6t td {
    font-size: 12px;
    padding: 8px;
  }
}

/* Additional Styles for Toggle Sliders in Modals */
.ImageGenerationScheduler_createJobForm__1K14D .ImageGenerationScheduler_formGroup__2WEot .ImageGenerationScheduler_toggleLabel__1pHAb input[type="checkbox"] {
  margin-left: 10px;
}

.ImageGenerationScheduler_createJobForm__1K14D .ImageGenerationScheduler_formGroup__2WEot .ImageGenerationScheduler_toggleSlider__DAIPg {
  margin-left: 10px;
}

/* Ensure select inputs within forms take full width */
.ImageGenerationScheduler_selectInput__mJSMd {
  width: 100%;
}


/* ImageGenerationScheduler.module.css */

/* Existing styles... */

/* New Styles for Format Transformer Section */
.ImageGenerationScheduler_formatTransformerSection__3Ay-V {
  border: 1px solid #E0E1EB;
  padding: 15px;
  border-radius: 8px;
  margin-top: 20px;
  background-color: #F9F9F9;
}

.ImageGenerationScheduler_checkboxGroup__a8XYr {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 10px;
}

.ImageGenerationScheduler_checkboxLabel__2W-28 {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  font-family: 'DM Sans', sans-serif;
  color: #292B3D;
}

.ImageGenerationScheduler_radioGroup__3NWRr {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 10px;
}

.ImageGenerationScheduler_radioLabel__1QDsk {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  font-family: 'DM Sans', sans-serif;
  color: #292B3D;
}

/* Additional Styles */
.ImageGenerationScheduler_formGroup__2WEot {
  margin-bottom: 15px;
}

.ImageGenerationScheduler_multiSelect__3mJSv {
  width: 100%;
}

.ImageGenerationScheduler_formActions__1RKhY {
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 20px;
}

.ImageGenerationScheduler_submitButton__19MDm {
  background-color: #4758EB;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
}

.ImageGenerationScheduler_submitButton__19MDm:hover {
  background-color: #3949ab;
}

.ImageGenerationScheduler_cancelButton__2GdxP {
  background-color: #ccc;
  color: #292B3D;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
}

.ImageGenerationScheduler_cancelButton__2GdxP:hover {
  background-color: #b3b3b3;
}

/* Responsive Design */
@media (max-width: 600px) {
  .ImageGenerationScheduler_radioGroup__3NWRr {
    flex-direction: column;
  }
}

/* pdpgeneration.module.css */

/* Top Bar */
.PdpGeneration_topBar__WPAFl {
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
  background-color: #ffffff;
  color: #4758EB;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}

.PdpGeneration_topBarLeft__1yx_7 {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.PdpGeneration_topBarActions__aubjP {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.PdpGeneration_headerTextContainer__1mW6w {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 40px;
  flex-grow: 0;
}

.PdpGeneration_feedViewerVersion__nQzQr {
  font-size: 14px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
  color: #4758EB;
}

.PdpGeneration_headerSubtitle__1vCbi {
  font-size: 22px;
  font-family: 'DM Serif Text', serif;
  color: #292B3D;
}

.PdpGeneration_headerImage__3GQkO {
  height: 100px;
  width: auto;
  padding-top: 20px;
  object-fit: contain;
  margin-left: auto;
}

/* Buttons */
.PdpGeneration_backButton__26Maa {
  background-color: #d0d0d0;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}
.PdpGeneration_backButton__26Maa:hover {
  background-color: #7c7c7c;
}

.PdpGeneration_logoutButton__x0x4c {
  background-color: #E0858C;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}
.PdpGeneration_logoutButton__x0x4c:hover {
  background-color: #EB4755 !important;
}

.PdpGeneration_refreshButton__pRwen {
  background-color: transparent;
  color: #d0d0d0;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}
.PdpGeneration_refreshButton__pRwen:hover {
  color: #4758EB;
  background-color: #ffffff !important;
}

.PdpGeneration_clientDropdown__1Nmr8 {
  min-width: 200px;
  font-size: 12px;
}

/* Filter Section */
.PdpGeneration_filterSection__2qjqS {
  /* Remove width: 100% */
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px; /* Simplify margins */
}

/* Each row uses a 5-column grid with flexible sizing */
.PdpGeneration_filterRow__1IKMN {
  display: grid;
  grid-template-columns: 
    80px                       /* column 1: Logic (AND/OR) */
    minmax(100px, 1fr)         /* column 2: Column select */
    minmax(100px, 1fr)         /* column 3: Filter type */
    minmax(150px, 3fr)         /* column 4: Input */
    40px;                      /* column 5: Remove button */
  grid-column-gap: 10px;
  column-gap: 10px;
  align-items: center;
  width: 100%;
  margin: 0; 
}


.PdpGeneration_tableImage__2GzY2 {
  max-width: 60px; /* Adjust as needed */
  max-height: 60px; /* Adjust as needed */
  width: auto;
  height: auto;
  object-fit: contain;
  border-radius: 4px; /* Optional: Adds slight rounding to images */
}

.PdpGeneration_filterDropdown__2Qw_4 {
  width: 100%;
  min-width: 0; 
  font-family: 'Lexend Deca', sans-serif;
  font-size: 12px !important;
  background-color: #f4f4f4;
  border-radius: 4px;
  transition: border-color 0.3s;
  outline: none;
  
  /* Add the desired height */
  height: 34px;
}

/* Ensure other dropdowns and inputs have consistent heights */
.PdpGeneration_filterLogicSelect__2H4ov,
.PdpGeneration_filterTypeSelect__1ZER_,
.PdpGeneration_filterInput__MtPyt {
  height: 34px; /* Match the desired height */
  box-sizing: border-box; /* Ensure padding and borders are included in the height */
}

/* Adjust padding to ensure alignment */
.PdpGeneration_filterLogicSelect__2H4ov,
.PdpGeneration_filterTypeSelect__1ZER_ {
  padding: 6px 8px;
}

.PdpGeneration_pageLabel__3g146 {
  font-size: 12px; /* Adjust to your preferred size (e.g., 12px, 16px) */
  color: #333333;  /* Change to your desired color */
  font-family: 'Lexend Deca', sans-serif;
  margin-left: 8px;
}

.PdpGeneration_filterTypeSelect__1ZER_ {
  height: 34px;
  box-sizing: border-box;
  padding: 6px 8px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s;
  outline: none;
  color: #292B3D;
}

.PdpGeneration_filterInput__MtPyt {
  padding: 8px;
}

.PdpGeneration_removeFilterButton__xjNVD {
  background-color: #EB4755;
  color: #ffffff !important;
  border: none;
  padding: 0px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 20px;
  height: 20px;
  font-size: 10px;
}
.PdpGeneration_removeFilterButton__xjNVD:hover {
  background-color: #E0858C !important;
}
.PdpGeneration_removeFilterButton__xjNVD svg {
  color: #ffffff !important; 
}

.PdpGeneration_filterActionsContainer__2aKMF {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 0px;
}

.PdpGeneration_addFilterButton__RJDk6 {
  background-color: #5CD685;
  color: #ffffff;
  border: none;
  padding: 10px!important;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px!important;
  height: 40px!important;
}
.PdpGeneration_addFilterButton__RJDk6:hover {
  background-color: #94D1A8 !important;
}

.PdpGeneration_applyFilterButton__nORjy {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px!important;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px!important;
  height: 40px!important;
}
.PdpGeneration_applyFilterButton__nORjy:hover {
  background-color: #848EE0 !important;
}



.PdpGeneration_RowDataPopupButton__ho3tw {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 2px !important; /* Reduced padding */
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px !important; /* Increased size for better visibility */
  height: 20px !important;
  box-sizing: border-box; /* Ensures padding is included in size */
}

.PdpGeneration_RowDataPopupButton__ho3tw:hover {
  background-color: #848EE0 !important;
}

/* Status Container */
.PdpGeneration_statusContainer__3XUCj {
  margin: 20px;
  font-family: 'Lexend Deca', sans-serif;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.PdpGeneration_errorMessage__bpmSo {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  padding: 10px;
  border-radius: 8px;
  text-align: left;
}

.PdpGeneration_successMessage__1G9q- {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  padding: 10px;
  border-radius: 8px;
  text-align: left;
}

.PdpGeneration_infoMessage__Fmke9 {
  background-color: #e7f3fe; 
  color: #0c5460;
  border: 1px solid #bee5eb;
  padding: 10px;
  border-radius: 8px;
  text-align: left;
  display: flex; /* so spinner and text can be aligned */
  align-items: center;
}

.PdpGeneration_warningMessage__1DF9M {
  background-color: #fff3cd;
  color: #856404;
  border: 1px solid #ffeeba;
  padding: 10px;
  border-radius: 8px;
  text-align: left;
}

/* Column Selection Container */
.PdpGeneration_columnSection__3NcQa {
  background: #FFF;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.PdpGeneration_listContainer__zSt1p {
  display: flex;
  align-items: stretch;
  grid-gap: 20px;
  gap: 20px;
}

.PdpGeneration_scrollableListContainer__1we64 {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  min-width: 280px;
}

.PdpGeneration_scrollableListContainer__1we64 h3 {
  font-size: 18px;
  font-family: 'Lexend Deca', sans-serif;
  color: #292B3D;
  margin-bottom: 10px;
}

.PdpGeneration_scrollableList__1rxrj {
  flex: 1 1;
  overflow-y: auto;
  max-height: 250px;
  background-color: #ffffff;
  border: 1px solid #E0E1EB;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.PdpGeneration_scrollableList__1rxrj ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.PdpGeneration_scrollableList__1rxrj li {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Add this */
  padding: 6px;
  margin-bottom: 2px;
  background-color: #F3F3F7;
  border: 1px solid #E0E1EB;
  border-radius: 6px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #292B3D;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.PdpGeneration_scrollableList__1rxrj li:hover {
  background-color: #E0E1EB;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}

.PdpGeneration_scrollableList__1rxrj li.PdpGeneration_selected__39gDM {
  background-color: #4758EB;
  color: #ffffff;
  border-color: #4758EB;
}

.PdpGeneration_scrollableList__1rxrj li.PdpGeneration_selected__39gDM:hover {
  background-color: #3949ab;
}

.PdpGeneration_buttonGroupColumnSelection__jowiP {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}

.PdpGeneration_buttonGroupColumnSelection__jowiP button {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  width: 40px;
  height: 40px;
}

.PdpGeneration_buttonGroupColumnSelection__jowiP button:hover {
  background-color: #6c7bbf;
}

/* Table Container */
.PdpGeneration_tableContainer__2dd0a {
  position: relative; /* Add this line */
  margin: 20px;
  margin-bottom: 60px;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  overflow-x: auto;
}

.PdpGeneration_toolbox__3hgMX {
  display: flex;
  justify-content: flex-start; /* Align buttons to the right */
  align-items: center;       /* Vertically center the buttons */
  grid-gap: 10px;
  gap: 10px;                 /* Space between buttons */
  margin: 20px;              /* Consistent margin with other sections */
  padding: 10px;             /* Optional: Add padding if needed */
  background-color: #ffffff; /* Optional: Background color to match other sections */
  border-radius: 8px;        /* Optional: Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Shadow for depth */
}

.PdpGeneration_tableOverlay__2xlMA {
  position: absolute; /* Position overlay absolutely within the table container */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2; /* Ensure the overlay sits above the table */
  border-radius: 8px;
}

.PdpGeneration_tableContainer__2dd0a table {
  border-collapse: collapse;
  min-width: 800px;
  background-color: #fff;
  width: max-content;
}

.PdpGeneration_tableContainer__2dd0a th, .PdpGeneration_tableContainer__2dd0a td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  font-size: 0.8rem;
}

.PdpGeneration_tableContainer__2dd0a th {
  background: #4758EB;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1;
  font-weight: 500;
}

/* Pagination */
.PdpGeneration_tableControls__WU0ex {
  /* Position it at the bottom center, just like the first page */
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 600px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 2;
  font-family: 'Lexend Deca', sans-serif;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

/* Pagination Total Text */
.PdpGeneration_paginationTotal__27wDq {
  font-size: 12px; /* Adjust to desired size (e.g., 14px, 16px) */
  color: #666666;   /* Optional: Change text color if needed */
  font-family: 'Lexend Deca', sans-serif; /* Ensure consistency with other text */
}


.PdpGeneration_navButton__1LK-d {
  background-color: #4758EB;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  width: 26px;
  height: 26px;
}

.PdpGeneration_navButton__1LK-d:hover {
  background-color: #565fb3;
}

.PdpGeneration_navButton__1LK-d:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.PdpGeneration_navButton__1LK-d:disabled:hover {
  background: #ccc;
}

.PdpGeneration_pageInput__2NFOM {
  width: 50px; /* Original width */
  text-align: center;
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 4px;
  
  /* Adjust the width as needed */

  max-width: 100px;
  
  /* Optional: Ensure consistency with other inputs */
  font-size: 12px;
  font-family: 'Lexend Deca', sans-serif;
  transition: border-color 0.3s;
  outline: none;
  background-color: #fff;
}

.PdpGeneration_pageInput__2NFOM:focus {
  border-color: #565fb3;
}

.PdpGeneration_inputSelect__kJ82Z {
  font-size: 12px;
  font-family: 'Lexend Deca', sans-serif;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px 6px;
  transition: border-color 0.3s;
  outline: none;
  margin-left: 10px;
  margin-right: 8px;
  
  /* Set the desired width */
  width: 80px; /* Example: 100px */
  
  /* Optional: Adjust min and max widths for responsiveness */
  min-width: 80px;
  max-width: 150px;
}

.PdpGeneration_inputSelect__kJ82Z:focus {
  border-color: #565fb3;
}

.PdpGeneration_noDataContainer__1JtYC {
  text-align: center;
  margin-top: 50px;
}

.PdpGeneration_noDataMessage__31HmF {
  font-size: 18px;
  color: #555555;
}


/* Disabled Input and Select Styles */
.PdpGeneration_tableControls__WU0ex input[type="number"]:disabled,
.PdpGeneration_tableControls__WU0ex select:disabled {
  background-color: #f0f0f0;
  color: #a0a0a0;
  cursor: not-allowed;
}

.PdpGeneration_tableControls__WU0ex input[type="number"]::-webkit-inner-spin-button,
.PdpGeneration_tableControls__WU0ex input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.PdpGeneration_tableControls__WU0ex input[type="number"] {
  -moz-appearance: textfield;
}


/* Add this new class for the View column */
.PdpGeneration_viewColumn__2QbH_ {
  width: 40px;          /* Set your desired fixed width */
  text-align: center;   /* Centers the button horizontally within the cell */
  box-sizing: border-box; /* Ensures padding is included within the width */
}

.PdpGeneration_selectColumn__2t0me {
  width: 40px;         
  text-align: center; 
  box-sizing: border-box;
}


/* Example (like the refreshButton but with your color preference) */
.PdpGeneration_promptBuilderButton__2zJqa {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.PdpGeneration_promptBuilderButton__2zJqa:hover {
  background-color: #848EE0 !important;
}

.PdpGeneration_cancelGenerationButton__4A0PK {
  background-color: #EB4755;
  color: #ffffff;
  border: none;
  padding: 0px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 10px;
}

.PdpGeneration_cancelGenerationButton__4A0PK:hover {
  background-color: #E0858C !important;
}

.PdpGeneration_cancelGenerationButton__4A0PK:disabled {
  background-color: #e0aeb1;
  cursor: not-allowed;
}
/* PopupRowDataPdp.module.css */

/* Overlay: full screen, centered */
.PopupRowDataPdp_popupOverlay__1dNYe {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3000; /* higher than main content */
    font-family: 'Lexend Deca', sans-serif;
}

/* Popup Content: dual column layout */
.PopupRowDataPdp_popupContentDual__1LahV {
    background-color: #ffffff;
    width: 90%;
    max-width: 1920px;
    max-height: 90vh; /* allows for internal scrolling */
    border-radius: 8px;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden; /* prevent outer scrolling */
}

/* Close Button (circular style) */
.PopupRowDataPdp_closeButton__3Ooem {
    position: absolute;
    top: 20px;
    right: 20px;
    background: #E0858C;
    color: #ffffff;
    border: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    cursor: pointer;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
}

.PopupRowDataPdp_closeButton__3Ooem:hover {
    background-color: #EB4755 !important;
}

/* Header Section */
.PopupRowDataPdp_popupHeader__yCJbK {
    padding: 1rem;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    color: #292B3D;
    flex: 0 0 auto; /* Fixed height */
}

.PopupRowDataPdp_popupPreview__39A8Q {
    display: flex;
    align-items: center;
    width: 100%;
}

.PopupRowDataPdp_popupPreviewImage__2SnA0 {
    width: 100px;
    height: 100px;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PopupRowDataPdp_popupPreviewImage__2SnA0 img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 4px;
}

.PopupRowDataPdp_noImage__2EO4j {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    color: #888;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    text-align: center;
    padding: 0.5rem;
}

.PopupRowDataPdp_popupPreviewDetails__2BsUu {
    display: flex;
    flex-direction: column;
}

.PopupRowDataPdp_popupPreviewDetails__2BsUu p {
    margin: 4px 0;
    font-size: 0.95rem;
    color: #333;
}

/* Dual Container: holds left and right columns */
.PopupRowDataPdp_dualContainer__2tbnn {
    flex: 1 1; /* Take up remaining space */
    display: flex;
    flex-direction: row;
    overflow: hidden; /* prevent overall scroll */
}

/* LEFT SIDE */
.PopupRowDataPdp_leftSide__2boJC {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #ddd;
    padding: 1rem;
    box-sizing: border-box;
    background-color: #ffffff;
}

.PopupRowDataPdp_leftSide__2boJC h3 {
    margin-bottom: 0.5rem;
    color: #292B3D;
    font-size: 1.1rem;
    flex: 0 0 auto;
}

.PopupRowDataPdp_leftContent__2XJQE {
    flex: 1 1;
    overflow-y: auto;
    padding-right: 0.5rem;
    min-height: 0; /* for proper flexbox scrolling */
}

/* RIGHT SIDE */
.PopupRowDataPdp_rightSide__yxNcc {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    box-sizing: border-box;
    background-color: #ffffff;
}

.PopupRowDataPdp_rightSide__yxNcc h3 {
    margin-bottom: 0.5rem;
    color: #292B3D;
    font-size: 1.1rem;
    flex: 0 0 auto;
}

.PopupRowDataPdp_rightContent__Nk7tS {
    flex: 1 1;
    overflow-y: auto;
    padding-right: 0.5rem;
    min-height: 0; /* for proper flexbox scrolling */
}

/* Row Item: key-value pair */
.PopupRowDataPdp_rowItem__qnLEi {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.PopupRowDataPdp_rowItemKey__BGY1D {
    font-weight: 600;
    color: #292B3D;
    margin-bottom: 0.25rem;
    display: flex;
    align-items: center;
    line-height: 1.3;
}

.PopupRowDataPdp_rowItemValue__3fi7h {
    display: flex; 
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.95rem;
    line-height: 1.4;
}

/* Column Icons */
.PopupRowDataPdp_columnIcon__1JfmM {
    margin-left: 8px;
    color: #292B3D;
    font-size: 0.9rem;
}

/* Row Images */
.PopupRowDataPdp_rowImage__1gYXb {
    display: block; 
    max-width: 100px; 
    max-height: 100px; 
    object-fit: contain; 
    border-radius: 4px;
    margin: 0.5rem 0;
}

/* Unsaved Changes Message */
.PopupRowDataPdp_popupUnsaved__325FB {
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff3cd;
    color: #856404;
    padding: 0.75rem 1.25rem;
    border: 1px solid #ffeeba;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    z-index: 4000;
    font-size: 0.95rem;
}

/* Save Success Message */
.PopupRowDataPdp_popupSuccess__3E-V_ {
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #d4edda;
    color: #155724;
    padding: 0.75rem 1.25rem;
    border: 1px solid #c3e6cb;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    z-index: 4000;
    font-size: 0.95rem;
}

/* Sticky Save Button Container */
.PopupRowDataPdp_stickySaveContainer__3Wgyb {
    position: sticky;
    bottom: 0;
    background-color: #ffffff;
    padding: 1rem;
    border-top: 1px solid #ddd;
    text-align: right;
    flex: 0 0 auto;
}

/* Save Button */
.PopupRowDataPdp_saveButton__1YcKj {
    background-color: #4758EB;
    color: #ffffff;
    border: none;
    padding: 0.6rem 1.4rem;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1rem;
    font-family: 'Lexend Deca', sans-serif;
    transition: background-color 0.3s ease, box-shadow 0.3s;
}

.PopupRowDataPdp_saveButton__1YcKj:hover {
    background-color: #848EE0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.PopupRowDataPdp_saveButton__1YcKj:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Responsive Design */
@media (max-width: 768px) {
    .PopupRowDataPdp_dualContainer__2tbnn {
        flex-direction: column;
    }

    .PopupRowDataPdp_leftSide__2boJC {
        border-right: none;
        border-bottom: 1px solid #ddd;
    }

    .PopupRowDataPdp_rightSide__yxNcc {
        padding-top: 1rem;
    }
}

/* Optional: Smooth Scrolling and Custom Scrollbars */
.PopupRowDataPdp_leftContent__2XJQE,
.PopupRowDataPdp_rightContent__Nk7tS {
    scroll-behavior: smooth;
}

.PopupRowDataPdp_leftContent__2XJQE::-webkit-scrollbar,
.PopupRowDataPdp_rightContent__Nk7tS::-webkit-scrollbar {
    width: 6px;
}

.PopupRowDataPdp_leftContent__2XJQE::-webkit-scrollbar-track,
.PopupRowDataPdp_rightContent__Nk7tS::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.PopupRowDataPdp_leftContent__2XJQE::-webkit-scrollbar-thumb,
.PopupRowDataPdp_rightContent__Nk7tS::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
}

.PopupRowDataPdp_leftContent__2XJQE::-webkit-scrollbar-thumb:hover,
.PopupRowDataPdp_rightContent__Nk7tS::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
}


/* Add to PopupRowDataPdp.module.css */

/* Global Toggle Container */
.PopupRowDataPdp_globalToggle__1uSYW {
    margin-top: 50px !important;
    display: flex;
    align-items: center;
    width: 210px;
    margin-left: auto; /* Push to the right */
}

/* Toggle Label */
.PopupRowDataPdp_toggleLabel__4OSrm {
    margin-right: 0.5rem;
    font-size: 0.95rem;
    color: #292B3D;
}




/* Add to PopupRowDataPdp.module.css */

/* Plain Text Editor */
.PopupRowDataPdp_plainTextEditor__UumRj {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 0.95rem;
    min-height: 100px; /* Ensure sufficient height */
    box-sizing: border-box;
    background-color: #f9f9f9; /* Light background for readability */
    color: #333; /* Dark text for contrast */
    
}


/* Wrap row header in a flex container so label is on left & toggle is on right. */

  .PopupRowDataPdp_rowItemLabel__3HgF8 {
    display: inline-flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px; /* space between text and icon */
  }
  
  .PopupRowDataPdp_toggleButton__nqmwG {
    background: none;
    border: none;
    font-size: 1.2rem;
    color: #666;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
  }
  
  .PopupRowDataPdp_toggleButton__nqmwG:hover {
    cursor: pointer;
    color: #4758EB;
    background: #ffffff !important;
    transition: color 0.3s;
}

.PopupRowDataPdp_toggleButtonActive__1ieYT {
    color: #4758EB;  /* blue color when activated */
  }


.PopupRowDataPdp_rowItemHeader__2pSkJ {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
  }
  
  .PopupRowDataPdp_editorToggle__2o874 {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
  }
  
  .PopupRowDataPdp_editorToggleLabel__191RL {
    font-size: 0.9rem;
    color: #555;
  }
  

  
/* src\popups\css\PopupPdpBulkActions.module.css */

.PopupPdpBulkActions_popupOverlay__2-GW6 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    font-family: 'Lexend Deca', sans-serif;
}

.PopupPdpBulkActions_popupInner__3Vt5t {
    background: #fff;
    padding: 30px 40px;
    border-radius: 8px;
    width: 60%;
    max-width: 800px;
    position: relative;
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
    overflow-y: auto;
    max-height: 90vh;
}

.PopupPdpBulkActions_title__2PFqj {
    font-size: 22px;
    font-family: 'DM Serif Text', serif;
    color: #292B3D;
    margin-bottom: 25px;
}

.PopupPdpBulkActions_segmentedControl__18l9U {
    display: flex;
    background-color: #F0F0F5;
    border-radius: 24px;
    overflow: hidden;
    margin-bottom: 25px;
}

.PopupPdpBulkActions_segmentButton__Tqeb3 {
    flex: 1 1;
    border: none;
    background: transparent;
    padding: 12px 0;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 16px;
    color: #555;
    cursor: pointer;
    transition: background 0.3s;
    border-radius: 24px;
    text-align: center;
}

.PopupPdpBulkActions_segmentButton__Tqeb3:hover {
    background-color: #E0E1EB !important;
}

.PopupPdpBulkActions_activeSegment__1r3dO {
    background-color: #4758EB;
    color: #fff;
    border-radius: 24px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.PopupPdpBulkActions_description__5QvXC {
    font-size: 14px;
    color: #555;
    margin-bottom: 25px;
}

.PopupPdpBulkActions_subTitle__3gPAV {
    font-size: 16px;
    font-weight: 600;
    color: #292B3D;
    margin-bottom: 12px;
    font-family: 'DM Sans', sans-serif;
}

.PopupPdpBulkActions_fieldSelectionSection__2j5hN {
    margin-bottom: 25px;
}

.PopupPdpBulkActions_fieldsHeader__2jRQL {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.PopupPdpBulkActions_fieldsControls__JCnTH {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    font-size: 14px;
}

.PopupPdpBulkActions_selectAllButton__C9eea {
    background-color: #f0f0f5;
    border: 1px solid #ccc;
    border-radius: 24px;
    padding: 8px 16px;
    font-size: 14px;
    font-family: 'Lexend Deca', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
    color: #292b3d;
    font-weight: 600;
    text-align: center;
    -webkit-user-select: none;
            user-select: none;
}

.PopupPdpBulkActions_selectAllButton__C9eea:hover {
    background-color: #e0e1eb !important;
    border-color: #aaa;
}

.PopupPdpBulkActions_selectedCount__1R9Id {
    font-size: 14px;
    color: #555;
}

.PopupPdpBulkActions_fieldSearchContainer__n17-U {
    margin-bottom: 15px;
}

.PopupPdpBulkActions_fieldSearchInput__lG9NK {
    width: 100%;
    padding: 10px 12px;
    border: 1px solid #E0E1EB;
    border-radius: 4px;
    font-size: 14px;
    font-family: 'Lexend Deca', sans-serif;
}

.PopupPdpBulkActions_fieldsGrid__SnBQo {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 12px;
    gap: 12px;
    padding: 12px;
    border: 1px solid #E0E1EB;
    border-radius: 4px;
    background: #FAFAFA;
    max-height: 300px;
    overflow-y: auto;
}

.PopupPdpBulkActions_fieldItem__12rSI {
    position: relative;
    display: block;
    cursor: pointer;
    padding: 0;
}

.PopupPdpBulkActions_fieldName__3dkQO {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border: 1px solid transparent;
    border-radius: 4px;
    background: #fff;
    font-size: 14px;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s;
    width: 100%;
    box-sizing: border-box;
    position: relative;
}

.PopupPdpBulkActions_fieldItem__12rSI input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.PopupPdpBulkActions_fieldItem__12rSI input[type="checkbox"]:checked + .PopupPdpBulkActions_fieldName__3dkQO {
    background-color: #4758EB;
    color: #fff;
    border-color: #4758EB;
}

.PopupPdpBulkActions_fieldItem__12rSI:hover .PopupPdpBulkActions_fieldName__3dkQO {
    background-color: #f0f0f5;
}

.PopupPdpBulkActions_fieldItem__12rSI.PopupPdpBulkActions_disabled__2VHQM .PopupPdpBulkActions_fieldName__3dkQO {
    background-color: #f5f5f5;
    color: #999;
    cursor: not-allowed;
    border-color: #e0e1eb;
}

.PopupPdpBulkActions_fieldItem__12rSI.PopupPdpBulkActions_disabled__2VHQM:hover .PopupPdpBulkActions_fieldName__3dkQO {
    background-color: #f5f5f5;
}

.PopupPdpBulkActions_fieldItem__12rSI.PopupPdpBulkActions_disabled__2VHQM input[type="checkbox"] {
    cursor: not-allowed;
}

/* Only show tooltip in Generate tab */
.PopupPdpBulkActions_fieldItem__12rSI.PopupPdpBulkActions_disabled__2VHQM.PopupPdpBulkActions_generateTab__1qHKZ .PopupPdpBulkActions_fieldName__3dkQO {
    padding-right: 180px; /* Make room for the tooltip text */
}

.PopupPdpBulkActions_fieldItem__12rSI.PopupPdpBulkActions_disabled__2VHQM.PopupPdpBulkActions_generateTab__1qHKZ .PopupPdpBulkActions_fieldName__3dkQO::after {
    position: absolute;
    right: 12px;
    font-size: 12px;
    color: #999;
    font-style: italic;
    white-space: nowrap; /* Prevent text wrapping */
}

.PopupPdpBulkActions_noResults__3zB_b {
    grid-column: 1 / -1;
    text-align: center;
    color: #999;
    font-size: 14px;
}

.PopupPdpBulkActions_scopeSection__d4H0n,
.PopupPdpBulkActions_promptSection__2Gtwz,
.PopupPdpBulkActions_stateChoiceSection__bAuq5 {
    margin-bottom: 25px;
}

.PopupPdpBulkActions_scopeSelector__1As00 {
    display: flex;
    grid-gap: 12px;
    gap: 12px;
    margin-top: 12px;
}

.PopupPdpBulkActions_scopeButton__1UumM {
    border: 1px solid #ccc;
    background: #fff;
    color: #292B3D;
    border-radius: 24px;
    padding: 8px 16px;
    font-size: 14px;
    font-family: 'Lexend Deca', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    text-align: center;
    min-width: 120px;
}

.PopupPdpBulkActions_scopeButton__1UumM:hover {
    background-color: #E0E1EB !important;
    color: #292B3D !important;
}

/* Increased specificity by combining selectors */
.PopupPdpBulkActions_scopeButton__1UumM.PopupPdpBulkActions_activeScope__3oRwa {
    background-color: #4758EB !important;
    color: #fff !important;
    border: none;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}


.PopupPdpBulkActions_dropdown__dD3-U {
    width: 100%;
    padding: 10px 12px;
    font-size: 14px;
    border: 1px solid #E0E1EB;
    border-radius: 4px;
    font-family: 'Lexend Deca', sans-serif;
    background: #fff;
}

.PopupPdpBulkActions_radioGroup__1lifw {
    display: flex;
    grid-gap: 25px;
    gap: 25px;
    margin-top: 12px;
    font-size: 14px;
}

.PopupPdpBulkActions_radioOption__2PD7j {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.PopupPdpBulkActions_actionButtons__1-OFW {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    margin-top: 25px;
}

.PopupPdpBulkActions_primaryActionButton__1krKg,
.PopupPdpBulkActions_secondaryActionButton__1FP1y {
    border-radius: 24px;
    padding: 12px 24px;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.PopupPdpBulkActions_primaryActionButton__1krKg {
    background-color: #4758EB;
    color: #fff;
}

.PopupPdpBulkActions_primaryActionButton__1krKg:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.PopupPdpBulkActions_secondaryActionButton__1FP1y {
    background-color: #EB4755;
    color: #fff;
}

.PopupPdpBulkActions_secondaryActionButton__1FP1y:hover {
    background-color: #E0858C;
}

/* Styles for the smaller toggle group */
.PopupPdpBulkActions_smallToggleGroup__357Ic {
    display: flex;
    background-color: #F0F0F5;
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 12px;
    width: 220px; /* Adjust width as needed */
}

/* Styles for smaller toggle buttons */
.PopupPdpBulkActions_smallToggleButton__ybtaV {
    flex: 1 1;
    border: none;
    background: transparent;
    padding: 8px 0; /* Reduced padding for smaller size */
    font-family: 'Lexend Deca', sans-serif;
    font-size: 14px; /* Smaller font size */
    color: #555;
    cursor: pointer;
    transition: background 0.3s;
    border-radius: 16px;
    text-align: center;
    -webkit-user-select: none;
            user-select: none;
}

.PopupPdpBulkActions_smallToggleButton__ybtaV:hover {
    background-color: #E0E1EB !important;
}

.PopupPdpBulkActions_activeSmallToggle__3zxqW {
    background-color: #4758EB;
    color: #fff;
    border-radius: 16px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}


/* Progress Information Section */
.PopupPdpBulkActions_progressSection__23FnW {
    position: relative; /* To position the "X" absolutely within */
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #E0E1EB;
    border-radius: 8px;
    background-color: #F9F9FF;
}

.PopupPdpBulkActions_progressTitle__2k2WS {
    font-size: 18px;
    font-weight: 600;
    color: #292B3D;
    margin-bottom: 15px;
}

.PopupPdpBulkActions_progressBarContainer__3k5lg {
    width: 100%;
    background-color: #E0E1EB;
    border-radius: 8px;
    overflow: hidden;
    height: 20px;
    margin-bottom: 15px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

.PopupPdpBulkActions_progressBar___YNw8 {
    height: 100%;
    background-color: #4758EB;
    transition: width 0.4s ease;
}

.PopupPdpBulkActions_progressDetails__228kA {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #555;
    margin-bottom: 15px;
}





/* Optional: Spinner for Loading Indicator */
.PopupPdpBulkActions_spinner__3uY70 {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #4758EB; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: PopupPdpBulkActions_spin__1hGxf 1s linear infinite;
    margin-right: 8px;
}

@keyframes PopupPdpBulkActions_spin__1hGxf {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}



  .PopupPdpBulkActions_buttonContent__1coKZ {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px; /* Space between spinner and text */
  }

  /* Example minimal styling for the new progress box in the popup */
.PopupPdpBulkActions_popupProgressBox__IIjb7 {
    margin: 10px 0;
    padding: 10px;
    background-color: #f4f6fb;
    border-radius: 8px;
    border: 1px solid #e0e1eb;
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
  }
  
  .PopupPdpBulkActions_progressInfo__3H1GD {
    font-size: 0.85rem;
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
  }
  
  .PopupPdpBulkActions_progressBarWrapper__3enp5 {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px; /* Space between progress bar and cancel button */
    width: 100%;
}

.PopupPdpBulkActions_progressBarOuter__2XbBE {
    flex-grow: 1;  /* Allows the progress bar to take full width */
    height: 8px;
    background-color: #ccc;
    border-radius: 4px;
    overflow: hidden;
}

.PopupPdpBulkActions_progressBarInner__2870R {
    height: 100%;
    background-color: #4758EB;
    transition: width 0.4s ease;
}

/* Cancel button styling */
.PopupPdpBulkActions_cancelGenerationButton__2wJAl {
    background-color: #EB4755;
    color: #ffffff;
    border: none;
    padding: 0;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    font-size: 12px;
    flex-shrink: 0; /* Prevents button from shrinking */
}

.PopupPdpBulkActions_cancelGenerationButton__2wJAl:hover {
    background-color: #E0858C !important;
}

  .PopupPdpBulkActions_cancelGenerationButton__2wJAl:disabled {
    background-color: #e0aeb1;
    cursor: not-allowed;
  }
  
/* Popup Overlay and Container (unchanged) */
.PopupExportDataPdp_popupOverlay__25qsm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Lexend Deca', sans-serif;
}

.PopupExportDataPdp_popupContainer__2EGt5 {
  background: #ffffff;
  width: 90%;
  height: 90%;
  max-width: 1200px;
  max-height: 90%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  font-family: 'Lexend Deca', sans-serif;
}

/* 
  HEADER
*/
.PopupExportDataPdp_exportsTopBar__3LTFp {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  background: #ffffff;
  padding: 12px 20px;
  border-bottom: 1px solid #E0E1EB;
}

.PopupExportDataPdp_exportConfigTitle__1LpWL {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #292B3D;
}

/* Just a wrapper for the last published date (which we will move to the footer). */
.PopupExportDataPdp_exportInfo__3tVX7 {
  margin-left: auto;
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}

.PopupExportDataPdp_lastPublished__3qwoW {
  font-size: 12px;
  color: #555;
}

.PopupExportDataPdp_notPublished__23_f0 {
  font-size: 12px;
  color: #aaa;
}

.PopupExportDataPdp_noExportSelected__3jiht {
  flex: 1 1; /* Take up the available space */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center */
  align-items: center;     /* Horizontally center */
  text-align: center;
  padding: 40px 20px;
  color: #555;
  background-color: #f7f7fc;
  font-size: 16px;
}

.PopupExportDataPdp_noExportSelected__3jiht p {
  margin-top: 16px;
  font-size: 18px;
}

.PopupExportDataPdp_noExportIcon__2XtgW {
  font-size: 48px;
  color: #ffcc00; /* A distinct color to grab attention */
}

/* 
  BUTTONS 
*/
.PopupExportDataPdp_createExportButton__wbzBA,
.PopupExportDataPdp_deleteExportButton__kqJTi,
.PopupExportDataPdp_cancelCreateButton__2hcW2,
.PopupExportDataPdp_confirmCreateButton__34NQ7 {
  cursor: pointer;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-family: 'Lexend Deca', sans-serif;
  transition: background-color 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.PopupExportDataPdp_createExportButton__wbzBA {
  background-color: #5CD685;
  border-radius: 50%;
  width: 36px; 
  height: 36px;
}

.PopupExportDataPdp_createExportButton__wbzBA:hover {
  background-color: #94D1A8 !important;
}

.PopupExportDataPdp_deleteExportButton__kqJTi,
.PopupExportDataPdp_cancelCreateButton__2hcW2 {
  background-color: #EB4755;
  border-radius: 50%;
  width: 36px; 
  height: 36px;
}

.PopupExportDataPdp_cancelCreateButton__2hcW2,
.PopupExportDataPdp_deleteExportButton__kqJTi:hover {
  background-color: #E0858C !important;
}

.PopupExportDataPdp_confirmCreateButton__34NQ7 {
  background-color: #5CD685;
  border-radius: 50%;
  width: 36px; 
  height: 36px;
}

.PopupExportDataPdp_confirmCreateButton__34NQ7:hover {
  background-color: #94D1A8 !important;
}
/* 
  MAIN CONTENT AREA 
*/
.PopupExportDataPdp_popupContent__3_Z6o {
  display: flex;
  flex: 1 1;
  overflow-y: auto;
  background-color: #f7f7fc;
}

/* 
  LEFT SIDE
*/
.PopupExportDataPdp_leftSide__3Hx90 {
  flex: 1 1;
  padding: 16px 20px;
  background-color: #ffffff;
  margin: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
}

/* 
  SMALL HEADER INSIDE THE LEFT SIDE 
*/
.PopupExportDataPdp_leftSideHeader__31gRJ {
  font-size: 16px;
  font-weight: 600;
  color: #292B3D;
  margin: 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
}

/* 
  CHECKBOXES 
*/
.PopupExportDataPdp_checkboxWrapper__Zc1nD {
  display: grid;
  grid-template-columns: auto 1fr; /* First column for checkbox, second for label */
  align-items: center;
  grid-gap: 8px ;
  gap: 8px ;
  width: 100% ;
}

.PopupExportDataPdp_checkboxWrapper__Zc1nD input[type="checkbox"] {
  justify-self: start ;
}

.PopupExportDataPdp_checkboxWrapper__Zc1nD label {
  justify-self: start ;
  white-space: nowrap ;
}


/* 
  RIGHT SIDE
*/
.PopupExportDataPdp_rightSide__w_L7g {
  flex: 1 1;
  padding: 16px 20px;
  margin: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
  /* remove:  height: calc(100% - 40px); */
}

/* 
  HEADERS ON THE RIGHT SIDE 
*/
.PopupExportDataPdp_rightSideHeader__13vGn {
  font-size: 16px;
  font-weight: 600;
  color: #292B3D;
  margin: 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
}

/* 
  We'll wrap each set (available columns, export columns) in a "columnsContainer"
  that is 50% height
*/
.PopupExportDataPdp_columnsContainer__1yrns {
  flex: 1 1; /* Each container will grow to fill half of .rightSide */
  display: flex;
  flex-direction: column;
  min-height: 0; /* helps avoid flex overflow issues */
}

/* 
  The actual scrollable list 
*/
.PopupExportDataPdp_columnsList__1oERN {
  flex: 1 1; /* fill up remainder of container */
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fafafa;
  overflow-y: auto;
  padding: 8px;
  margin-top: 8px; /* space under the header */
}

/* 
  COLUMN ITEM
  – ensure consistent height, center everything
*/
.PopupExportDataPdp_columnItem__2lssj {
  display: flex;
  align-items: center;
  justify-content: space-between; /* push remove button to the right */
  background-color: #F3F3F7;
  border: 1px solid #E0E1EB;
  border-radius: 6px;
  padding: 0px 8px;
  margin-bottom: 2px;
  font-size: 14px;
  color: #292B3D;
  transition: background-color 0.3s, box-shadow 0.3s;
  min-height: 36px; /* ensure consistent row height */
}

.PopupExportDataPdp_columnItem__2lssj:hover {
  background-color: #E0E1EB;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}

/* left side of the item: icon + name + mapping arrow */
.PopupExportDataPdp_columnItemLeft__2RSvj {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

/* 
  Mapped name container 
*/
.PopupExportDataPdp_mappingContainer__TY5B2 {
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  padding: 0px 8px;
  background-color: #ffffff;
  border: 1px solid #E0EBE4;
  border-radius: 4px;
}

.PopupExportDataPdp_mappingArrow__2APG_ {
  color: #999;
}

.PopupExportDataPdp_mappingInput__2ELw6 {
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
  padding: 0px;
  width: 80px;
  text-align: left;
}

/* 
  remove button 
*/
.PopupExportDataPdp_removeColumnButton__1-K5C {
  background-color: #EB4755;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin: 0px;
  font-size: 10px;
  padding: 6px;
  transition: background-color 0.3s;
}

.PopupExportDataPdp_removeColumnButton__1-K5C:hover {
  background-color: #E0858C !important;
}

.PopupExportDataPdp_placeholderText__24Sua {
  font-size: 12px;
  color: #aaa;
  text-align: center;
}

/* 
  FOOTER 
  – last published on bottom-left, buttons on bottom-right
*/
.PopupExportDataPdp_popupFooter__354JT {
  padding: 12px 20px;
  border-top: 1px solid #E0E1EB;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PopupExportDataPdp_footerLeft__2wDwK {
  font-size: 12px;
  color: #555;
}

.PopupExportDataPdp_footerRight__1i2gb {
  display: flex;
  grid-gap: 12px;
  gap: 12px; /* spacing between “View,” “Save,” “Save & Publish” */
}



.PopupExportDataPdp_viewButton__2ha_G {
  background-color: #5CD685;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  margin-right: 16px;
  font-size: 14px;
  /* use the same vertical padding as .saveButton / .publishButton */
  padding: 8px 14px;
  transition: background-color 0.3s;
  font-family: 'Lexend Deca', sans-serif;
  text-decoration: none;
}

.PopupExportDataPdp_viewButton__2ha_G:hover {
  background-color: #94D1A8 !important;
  text-decoration: none;
}

.PopupExportDataPdp_saveButton__3iMce,
.PopupExportDataPdp_publishButton__38fhr {
  background-color: #4758EB;
  color: #fff;
  border: none;
  padding: 8px 14px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Lexend Deca', sans-serif;
  transition: background-color 0.3s;
}

.PopupExportDataPdp_saveButton__3iMce:hover,
.PopupExportDataPdp_publishButton__38fhr:hover {
  background-color: #848EE0 !important;
}


/* 
  ERROR MESSAGE 
*/
.PopupExportDataPdp_errorMessage__WvJoi {
  background-color: #f8d7da;
  color: #721c24;
  padding: 8px 20px;
  margin: 0;
  font-size: 14px;
  border-top: 1px solid #f1b9bd;
  border-bottom: 1px solid #f1b9bd;
}

/* 
  For the new React dropdowns (react-select). 
  react-select auto‐injects classes, 
  you can refine them if you like. 
*/
.PopupExportDataPdp_reactSelectContainer__3vgN1 {
  min-width: 160px;
}
/* ... existing CSS ... */

/* ======== UNSAVED CHANGES MESSAGE ======== */
.PopupExportDataPdp_unsavedChangesMessage__tUONm {
  display: flex;
  align-items: center;
  background-color: #fff3cd; /* Light yellow background */
  color: #856404; /* Dark yellow text */
  padding: 10px 20px;
  border-left: 4px solid #ffeeba; /* Yellow border */
  font-size: 14px;
}

.PopupExportDataPdp_unsavedIcon__eAyDL {
  margin-right: 8px;
  color: #856404; /* Match the text color */
}

/* ... existing CSS ... */

/* Tooltip Wrapper - wraps the icon or the text that triggers the tooltip */
.PopupExportDataPdp_tooltipWrapper__1qGCc {
  position: relative; /* so .tooltipText can be absolutely positioned */
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

/* The small icon or question mark that indicates help is available */
.PopupExportDataPdp_tooltipIcon__3uk0u {
  font-size: 14px;
  color: #888;
  margin-left: 6px; /* small spacing from the label */
}

/* The tooltip's text bubble */
.PopupExportDataPdp_tooltipText__3i5KY {
  visibility: hidden;
  opacity: 0;
  width: 200px; /* Adjust as needed */
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
  text-align: left;
  border-radius: 4px;
  padding: 8px;
  position: absolute;
  z-index: 1000;

  /* Position the tooltip above the icon */
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);

  /* Transition for hover effect */
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}

/* Arrow on the bottom of tooltip (optional styling) */
.PopupExportDataPdp_tooltipText__3i5KY::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.85) transparent transparent transparent;
}

/* Show the tooltipText on hover of the wrapper */
.PopupExportDataPdp_tooltipWrapper__1qGCc:hover .PopupExportDataPdp_tooltipText__3i5KY {
  visibility: visible;
  opacity: 1;
}

/* A small note for better label wrapping + icon alignment */
.PopupExportDataPdp_configLabel__1yQWP {
  display: inline-flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
}

/* Example for columns list if you want to show custom tooltip instead of 'title' attribute */
.PopupExportDataPdp_columnItemWithTooltip__EZLHw {
  position: relative; /* for the tooltip to absolutely position */
}

/* ... existing CSS ... */



/* Top Bar */
.GenerationInput_topBar__1GTtl {
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
  background-color: #ffffff;
  color: #4758EB;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}

.GenerationInput_topBarLeft__1Hh1- {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.GenerationInput_headerTextContainer__3hunY {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 40px;
  flex-grow: 0;
}

.GenerationInput_feedViewerVersion__2XRB9 {
  font-size: 14px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
  color: #4758EB;
}

.GenerationInput_headerSubtitle__2EAEh {
  font-size: 22px;
  font-family: 'DM Serif Text', serif;
  color: #292B3D;
}

.GenerationInput_headerImage__2uX3r {
  height: 100px;
  width: auto;
  padding-top: 20px;
  object-fit: contain;
  margin-left: auto;
}

.GenerationInput_topBarActions__2RULm {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

/* Credits Display */
.GenerationInput_creditsContainer__3kRJd {
  display: flex; 
  align-items: center;
  background-color: #F8F9FF;
  padding: 6px 12px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);
}

.GenerationInput_creditsText__gSbtu {
  display: flex; 
  align-items: center; 
  font-family: 'Lexend Deca', sans-serif;
  color: #292B3D;
  font-size: 14px;
  font-weight: 500;
}

.GenerationInput_noCreditsText__2W7zs {
  font-family: 'Lexend Deca', sans-serif;
  color: #999;
  font-size: 14px;
}

/* Buttons */
.GenerationInput_backButton__1T_zW {
  background-color: #d0d0d0;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.GenerationInput_backButton__1T_zW:hover {
  background-color: #7c7c7c;
}

.GenerationInput_logoutButton__16rhW {
  background-color: #E0858C;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.GenerationInput_logoutButton__16rhW:hover {
  background-color: #EB4755 !important;
}

.GenerationInput_clientDropdown__Cf7Or {
  min-width: 200px;
  font-size: 12px;
}

.GenerationInput_promptBuilderButton__1Nu5S, .GenerationInput_pdpGenerationButton__3ij72 {
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.GenerationInput_promptBuilderButton__1Nu5S:hover, .GenerationInput_pdpGenerationButton__3ij72:hover {
  background-color: #848EE0 !important;
}

/* Rest of CSS remains the same */
.GenerationInput_mainContent__2DXo8 {
  padding: 20px;
  font-family: 'Lexend Deca', sans-serif;
}

.GenerationInput_contentHeader__1CO8e {
  font-size: 24px;
  font-weight: 600;
  color: #292B3D;
  margin-bottom: 16px;
}

.GenerationInput_contentDescription__1YXTK {
  font-size: 16px;
  color: #7C7F9C;
  margin-bottom: 24px;
}

/* For status messages */
.GenerationInput_infoMessage__1oUUD, .GenerationInput_successMessage__3iEch, .GenerationInput_errorMessage__3HlBA, .GenerationInput_warningMessage__r8HzQ {
  padding: 10px 16px;
  border-radius: 6px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
}

.GenerationInput_infoMessage__1oUUD {
  background-color: #E0E6FF;
  color: #4758EB;
}

.GenerationInput_successMessage__3iEch {
  background-color: #E3F9E5;
  color: #0A8F0A;
}

.GenerationInput_errorMessage__3HlBA {
  background-color: #FFEFEF;
  color: #E53939;
}

.GenerationInput_warningMessage__r8HzQ {
  background-color: #FFF8E0;
  color: #FFAD0D;
}

/* Tabs Styling - Updated to match PdpGeneration style */
.GenerationInput_tabsContainer__ls1Ja {
  margin: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.GenerationInput_tabsList__fBzVw {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
  border-bottom: 1px solid #E0E1EB;
}

.GenerationInput_tabItem__2mQM0 {
  padding: 12px 20px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #7C7F9C;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  transition: all 0.3s;
  margin-right: 10px;
  background-color: transparent;
}

.GenerationInput_tabItem__2mQM0:hover {
  color: #4758EB;
}

.GenerationInput_activeTab__2e8h8 {
  color: #4758EB;
  border-bottom: 3px solid #4758EB;
  font-weight: 600;
}

.GenerationInput_tabContent__3ssy5 {
  min-height: 400px;
  background-color: transparent;
  border-radius: 8px;
  padding: 0;
  position: relative;
  z-index: 1;
}

/* No Client Selected Styles */
.GenerationInput_noClientSelectedContainer__1pziP {
  margin: 20px;
  background-color: none;
  border-radius: 8px;
  padding: 50px 20px;
  text-align: center;
}

.GenerationInput_noClientSelectedMessage__2VAhQ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #F8F9FF;
  border-radius: 8px;
  max-width: 500px;
  margin: 0 auto;
}

.GenerationInput_noClientIcon__1RPlC {
  color: #4758EB;
  margin-bottom: 16px;
  opacity: 0.7;
  background-color: #E0E6FF;
  padding: 16px;
  border-radius: 50%;
}

.GenerationInput_noClientSelectedMessage__2VAhQ h3 {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #292B3D;
  margin-bottom: 10px;
}

.GenerationInput_noClientSelectedMessage__2VAhQ p {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #7C7F9C;
  line-height: 1.5;
}

.GenerationInput_buttonGroup__3r8oI {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  margin-top: 20px;
  justify-content: space-between;
} 
.TabularDataMapper_container__3mICK {
  padding: 20px;
  background-color: #ffffff;
  height: 100%;
}

.TabularDataMapper_title__j98ib {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #292B3D;
  margin-bottom: 12px;
  border-bottom: 1px solid #E0E1EB;
  padding-bottom: 10px;
}

.TabularDataMapper_description__Zo9E- {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #7C7F9C;
  margin-bottom: 20px;
  line-height: 1.5;
}

/* Section styling similar to PdpGeneration */
.TabularDataMapper_section__2pgvZ {
  margin-bottom: 24px;
  background-color: #F8F9FF;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
}

.TabularDataMapper_sectionTitle__14h58 {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #4758EB;
  margin-bottom: 12px;
}

.PdfDataMapper_container__1XxG6 {
  padding: 20px;
  background-color: #ffffff;
  height: 100%;
  overflow-y: auto;
}

.PdfDataMapper_title__347JX {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #292B3D;
  margin-bottom: 12px;
  border-bottom: 1px solid #E0E1EB;
  padding-bottom: 10px;
}

.PdfDataMapper_description__338ZZ {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #7C7F9C;
  margin-bottom: 20px;
  line-height: 1.5;
}

/* Progress Steps */
.PdfDataMapper_progressSteps__2wGtm {
  display: flex;
  align-items: center;
  justify-content: center;
}

.PdfDataMapper_horizontalHeader__Y9Kqe {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  width: 100%;
}

.PdfDataMapper_headerActions__Y8xVF {
  min-width: 150px;
}

.PdfDataMapper_centerContainer__ekg_n {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  margin-bottom: -80px;
}

.PdfDataMapper_progressStep__dM8Yi {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #7C7F9C;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 12px;
}

.PdfDataMapper_stepNumber__18UPE {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #E0E1EB;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
  color: #5D6182;
  font-weight: 600;
  transition: all 0.3s ease;
}

.PdfDataMapper_activeStep__13cku {
  color: #4758EB;
}

.PdfDataMapper_activeStep__13cku .PdfDataMapper_stepNumber__18UPE {
  background-color: #4758EB;
  color: white;
}

.PdfDataMapper_stepConnector__2Pz8P {
  height: 2px;
  width: 50px;
  background-color: #E0E1EB;
  margin: 0 10px;
  margin-bottom: 25px;
}

/* Section styling similar to PdpGeneration */
.PdfDataMapper_section__2e5yb {
  margin-bottom: 24px;
  background-color: #F8F9FF;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
  position: static;
  overflow: visible;
}

.PdfDataMapper_sectionContainer__os256 {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  gap: 24px;
  position: static;
  overflow: visible;
}

.PdfDataMapper_sectionTitle__2bPok {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #4758EB;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

/* Form Elements */
.PdfDataMapper_form__1nYJQ {
  margin-top: 16px;
}

.PdfDataMapper_formGroup__3RUQC {
  margin-bottom: 16px;
}

.PdfDataMapper_label__2pzUP {
  display: block;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #292B3D;
  margin-bottom: 8px;
}

.PdfDataMapper_input__1f9o3 {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #E0E1EB;
  border-radius: 6px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #292B3D;
  transition: border-color 0.2s ease;
}

.PdfDataMapper_textarea__37yoQ {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #E0E1EB;
  border-radius: 6px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #292B3D;
  transition: border-color 0.2s ease;
  resize: vertical;
  min-height: 120px;
}

.PdfDataMapper_input__1f9o3:focus, .PdfDataMapper_textarea__37yoQ:focus {
  outline: none;
  border-color: #4758EB;
}

.PdfDataMapper_input__1f9o3:disabled, .PdfDataMapper_textarea__37yoQ:disabled {
  background-color: #F8F9FF;
  cursor: not-allowed;
}

/* Button Styling */
.PdfDataMapper_button__1c2NO {
  background-color: #4758EB;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 16px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
}

.PdfDataMapper_button__1c2NO:hover {
  background-color: #384BD0;
}

.PdfDataMapper_button__1c2NO:disabled {
  background-color: #B8BED9;
  cursor: not-allowed;
}

.PdfDataMapper_buttonSecondary__1JJtM {
  background-color: transparent;
  color: #4758EB;
  border: 1px solid #4758EB;
  border-radius: 6px;
  padding: 10px 16px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PdfDataMapper_buttonSecondary__1JJtM:hover {
  background-color: #F0F2FF !important;
}

.PdfDataMapper_buttonSecondary__1JJtM:disabled {
  border-color: #B8BED9;
  color: #B8BED9;
  cursor: not-allowed;
}

.PdfDataMapper_buttonPreview__rMQMc {
  background-color: #2A9D58;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 16px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 6px;
  gap: 6px;
  min-width: 140px;
}

.PdfDataMapper_buttonPreview__rMQMc:hover {
  background-color: #218A49 !important;
}

.PdfDataMapper_buttonPreview__rMQMc:disabled {
  background-color: #A3D8B7;
  cursor: not-allowed;
}

.PdfDataMapper_buttonGroup__33ZjX {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  margin-top: 20px;
  justify-content: space-between;
}

.PdfDataMapper_buttonGroup__33ZjX > div {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
}

.PdfDataMapper_buttonIcon__1sliX {
  margin-right: 6px;
}

/* Status Messages */
.PdfDataMapper_errorMessage__U4xvf {
  background-color: #FEF0F0;
  color: #D83A3A;
  padding: 12px 16px;
  border-radius: 6px;
  margin-bottom: 16px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.PdfDataMapper_successMessage__3y3Nb {
  background-color: #F0FEF4;
  color: #2A9D58;
  padding: 12px 16px;
  border-radius: 6px;
  margin-bottom: 16px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

/* Spinner Animation */
.PdfDataMapper_spinner__1JGib {
  animation: PdfDataMapper_spin__3h8Jk 1s linear infinite;
}

@keyframes PdfDataMapper_spin__3h8Jk {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* React-Select Customization */
.PdfDataMapper_select__1IFzR {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  position: relative;
  z-index: 2000 !important;
}

.PdfDataMapper_select__1IFzR > div:first-child {
  border-color: #E0E1EB;
  border-radius: 6px;
  min-height: 40px;
}

.PdfDataMapper_select__1IFzR > div:first-child:hover {
  border-color: #4758EB;
}

/* Add a container to ensure proper z-index stacking for form groups */
.PdfDataMapper_formGroupDropdown__1XmKR {
  position: relative;
  z-index: 2000 !important;
  margin-bottom: 16px;
}

/* Higher z-index for ID column (top dropdown) */
.PdfDataMapper_formGroupDropdown__1XmKR:first-of-type {
  z-index: 2001 !important;
}

/* Override global select menu styles to ensure proper rendering */
.css-26l3qy-menu, 
.css-1pahdxg-control, 
.css-1insrsq-menu {
  z-index: 9999 !important;
}

/* PDF Preview Styling */
.PdfDataMapper_pdfPreviewHeader__NhPgK {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.PdfDataMapper_pdfHeaderControls__HZ9VX {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.PdfDataMapper_pdfInfoBadge__35JdO {
  background-color: #E9ECFF;
  color: #4758EB;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
}

.PdfDataMapper_pdfIcon__3EcvY {
  color: #D83A3A;
  margin-right: 6px;
}

.PdfDataMapper_infoButton__18JLp {
  background: none !important;
  border: none;
  color: #4285f4;
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease;
}

.PdfDataMapper_infoButton__18JLp:hover {
  color: #2a75f3;
  background: none !important;
}

/* Side by Side Container */
.PdfDataMapper_sideBySideContainer__3lAH6 {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  margin-bottom: 16px;
}

.PdfDataMapper_pdfColumn__3ueSR {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.PdfDataMapper_columnHeader__2Vsr_ {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 12px;
  background-color: #E9ECFF;
  color: #4758EB;
  border-radius: 6px 6px 0 0;
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
}

.PdfDataMapper_columnIcon__1et8a {
  font-size: 14px;
}

.PdfDataMapper_pdfPreviewContent__ZWNCq {
  padding: 16px;
  background-color: white;
  border-radius: 0 0 6px 6px;
  border: 1px solid #E0E1EB;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  line-height: 1.6;
  height: 400px;
  overflow-y: auto;
  white-space: pre-wrap;
}

.PdfDataMapper_pdfColumn__3ueSR:first-child .PdfDataMapper_pdfPreviewContent__ZWNCq {
  border-radius: 0 0 0 6px;
}

.PdfDataMapper_pdfColumn__3ueSR:last-child .PdfDataMapper_pdfPreviewContent__ZWNCq {
  border-radius: 0 0 6px 0;
}

.PdfDataMapper_pdfPreviewEmpty__1Jaly {
  padding: 16px;
  background-color: white;
  border-radius: 6px;
  border: 1px solid #E0E1EB;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #7C7F9C;
  text-align: center;
}

.PdfDataMapper_pdfSourceLink__1907h {
  margin-top: 12px;
  text-align: center;
}

.PdfDataMapper_pdfLink__KB_A5 {
  color: #4758EB;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
}

.PdfDataMapper_pdfLink__KB_A5:hover {
  text-decoration: underline;
}

/* API Info Popup */
.PdfDataMapper_apiInfoPopupOverlay__1Etqs {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.PdfDataMapper_apiInfoPopup__Tl25s {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  width: 80%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  animation: PdfDataMapper_slideIn__31WvB 0.3s ease-out;
}

@keyframes PdfDataMapper_slideIn__31WvB {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.PdfDataMapper_apiInfoHeader__1BzIM {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #eaeaea;
}

.PdfDataMapper_apiInfoHeader__1BzIM h4 {
  margin: 0;
  color: #333;
  font-weight: 600;
}

.PdfDataMapper_closeButton__7OP-C {
  background: none;
  border: none;
  color: #777;
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease;
}

.PdfDataMapper_closeButton__7OP-C:hover {
  color: #333 !important;
  background: none !important;
}

.PdfDataMapper_apiInfoContent__1h7c3 {
  padding: 20px;
  display: flex;
  flex-direction: column;
  grid-gap: 25px;
  gap: 25px;
}

.PdfDataMapper_apiInfoSection__3Q_8_ {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.PdfDataMapper_apiInfoSection__3Q_8_ h5 {
  margin: 0;
  font-weight: 600;
  color: #555;
  border-bottom: 1px solid #eee;
  padding-bottom: 8px;
}

.PdfDataMapper_apiInfoRow__1hzpv, .PdfDataMapper_apiInfoRowTotal__3l_TN {
  display: flex;
  justify-content: space-between;
  padding: 6px 10px;
  background-color: #f9f9f9;
  border-radius: 4px;
}

.PdfDataMapper_apiInfoRowTotal__3l_TN {
  font-weight: 600;
  background-color: #eef5fd;
  margin-top: 5px;
}

.PdfDataMapper_apiPromptDisplay__-418F {
  background-color: #f5f5f5;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 15px;
  white-space: normal;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  max-height: 200px;
  overflow-y: auto;
  line-height: 1.6;
}

.PdfDataMapper_apiMessageItem__3J1x9 {
  margin-bottom: 15px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  overflow: hidden;
}

.PdfDataMapper_apiMessageRole__2Y9ab {
  background-color: #f0f0f0;
  padding: 8px 12px;
  font-weight: bold;
  font-size: 0.9rem;
  color: #333;
  text-transform: capitalize;
  border-bottom: 1px solid #eaeaea;
}

.PdfDataMapper_apiMessageRole__2Y9ab:before {
  content: '🔹 ';
}

.PdfDataMapper_apiMessageItem__3J1x9:nth-child(odd) .PdfDataMapper_apiMessageRole__2Y9ab {
  background-color: #eef5fd;
}

.PdfDataMapper_apiMessageContent__1KYAI {
  padding: 12px;
  white-space: pre-wrap;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  line-height: 1.6;
  max-height: 300px;
  overflow-y: auto;
  background-color: #ffffff;
}

.PdfDataMapper_apiMessageItem__3J1x9:first-child .PdfDataMapper_apiMessageRole__2Y9ab {
  background-color: #f5f0fe;
  color: #7b51ab;
}

.PdfDataMapper_apiMessageItem__3J1x9:first-child .PdfDataMapper_apiMessageRole__2Y9ab:before {
  content: '⚙️ ';
}

/* Additional responsive styles for small screens */
@media (max-width: 768px) {
  .PdfDataMapper_sideBySideContainer__3lAH6 {
    flex-direction: column;
  }
  
  .PdfDataMapper_pdfColumn__3ueSR {
    margin-bottom: 20px;
  }
  
  .PdfDataMapper_apiInfoPopup__Tl25s {
    width: 95%;
  }
}

/* Confirmation Dialog Styles */
.PdfDataMapper_confirmOverlayContainer__2qE4D {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.PdfDataMapper_confirmDialog__3A15i {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  animation: PdfDataMapper_fadeIn__2goNj 0.3s ease-out;
}

.PdfDataMapper_confirmHeader__1EegJ {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: #e67e22;
}

.PdfDataMapper_confirmHeader__1EegJ h4 {
  margin: 0 0 0 10px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.PdfDataMapper_confirmDialog__3A15i p {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #4a4a4a;
  margin-bottom: 20px;
  line-height: 1.5;
}

.PdfDataMapper_confirmButtons__yk8UZ {
  display: flex;
  justify-content: flex-end;
  grid-gap: 12px;
  gap: 12px;
}

.PdfDataMapper_buttonWarning__33FaE {
  background-color: #e67e22;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 16px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PdfDataMapper_buttonWarning__33FaE:hover {
  background-color: #d35400 !important;
}

@keyframes PdfDataMapper_fadeIn__2goNj {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* List View Styles */
.PdfDataMapper_listView__1_eBN {
  margin-top: 20px;
}

.PdfDataMapper_listHeader__1VpOt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.PdfDataMapper_listTitle__-VJqp {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #4758EB;
  margin: 0;
}

.PdfDataMapper_createButton__3QsBP {
  background-color: #4758EB;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 16px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.PdfDataMapper_createButton__3QsBP:hover {
  background-color: #384BD0 !important;
}

.PdfDataMapper_createButton__3QsBP:disabled {
  background-color: #B8BED9;
  cursor: not-allowed;
}

.PdfDataMapper_emptyState__1veaK {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  background-color: #F8F9FF;
  border-radius: 8px;
  text-align: center;
}

.PdfDataMapper_emptyStateIcon__hespj {
  color: #B8BED9;
  margin-bottom: 16px;
}

.PdfDataMapper_emptyState__1veaK p {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  color: #7C7F9C;
  margin-bottom: 20px;
}

.PdfDataMapper_loadingContainer__zgDYg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  background-color: #F8F9FF;
  border-radius: 8px;
}

.PdfDataMapper_loadingContainer__zgDYg p {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  color: #7C7F9C;
  margin-top: 16px;
}

/* Mapping Card Styles */
.PdfDataMapper_mappingsList___ADRE {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  gap: 20px;
}

.PdfDataMapper_mappingCard__21HQW {
  background-color: #F8F9FF;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  flex-direction: column;
}

.PdfDataMapper_mappingCard__21HQW:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.PdfDataMapper_mappingCardContent__Np1la {
  padding: 16px;
  flex-grow: 1;
}

.PdfDataMapper_mappingName__3z894 {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #292B3D;
  margin-top: 0;
  margin-bottom: 12px;
  border-bottom: 1px solid #E0E1EB;
  padding-bottom: 8px;
}

.PdfDataMapper_mappingDetails__2h0-r {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}

.PdfDataMapper_mappingDetail__8zT-9 {
  display: flex;
  flex-direction: column;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 13px;
}

.PdfDataMapper_detailLabel__3YItc {
  color: #7C7F9C;
  font-weight: 500;
  margin-bottom: 2px;
}

.PdfDataMapper_detailValue__1V9dP {
  color: #292B3D;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.PdfDataMapper_mappingCardActions__gFLkh {
  display: flex;
  padding: 12px;
  background-color: #E9ECFF;
  justify-content: flex-end;
  grid-gap: 8px;
  gap: 8px;
}

.PdfDataMapper_actionButton__L3IYS {
  background-color: white;
  color: #4758EB;
  border: none;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.PdfDataMapper_actionButton__L3IYS:hover {
  background-color: #4758EB !important;
  color: white !important;
}

.PdfDataMapper_deleteButton__28N-l {
  color: #e53939;
}

.PdfDataMapper_deleteButton__28N-l:hover {
  background-color: #e53939 !important;
  color: white !important;
}

/* Form Header Styles */
.PdfDataMapper_formHeader__3PC1h {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 24px;
  grid-gap: 16px;
  gap: 16px;
}

.PdfDataMapper_backToListButton__iwRqm {
  background-color: #E0E1EB;
  color: #5D6182;
  border: none;
  border-radius: 6px;
  padding: 8px 12px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
}

.PdfDataMapper_backToListButton__iwRqm:hover {
  background-color: #D0D2E6 !important;
  color: #292B3D !important;
}

.PdfDataMapper_formTitle__3jlGh {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #4758EB;
  margin: 0;
  margin-bottom: 16px;
}

/* Delete button for confirmation dialog */
.PdfDataMapper_buttonDanger__2Etuq {
  background-color: #e53939;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 16px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PdfDataMapper_buttonDanger__2Etuq:hover {
  background-color: #c62828 !important;
}

/* Preview Required Hint */
.PdfDataMapper_previewRequiredHint__29XHQ {
  color: #e67e22;
  font-size: 12px;
  margin-top: 6px;
  font-family: 'Lexend Deca', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Responsive styles */
@media (max-width: 768px) {
  .PdfDataMapper_mappingsList___ADRE {
    grid-template-columns: 1fr;
  }
  
  .PdfDataMapper_listHeader__1VpOt {
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 12px;
    gap: 12px;
  }
  
  .PdfDataMapper_createButton__3QsBP {
    width: 100%;
    justify-content: center;
  }
}

