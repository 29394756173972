/* src/components/css/AdminDashboard.module.css */

/* ---------------------- */
/*       Global Styles    */
/* ---------------------- */

.adminDashboardContainer {
  font-family: 'Lexend Deca', sans-serif;
  color: #333;
  padding: 0;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: #f4f6f8;
}

/* ---------------------- */
/*       Top Bar Styles   */
/* ---------------------- */

.topBar {
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin: 20px;
  background-color: #ffffff;
  color: #4758EB;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  gap: 10px;
  flex-wrap: wrap;
  position: relative;
}

.headerTextContainer {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 40px;
  flex-grow: 1;
}

.feedViewerVersion {
  font-size: 14px;
  font-weight: 600;
  color: #4758EB;
}

.headerSubtitle {
  font-size: 22px;
  font-family: 'DM Serif Text', serif;
  color: #292B3D;
}

.headerImage {
  height: 100px;
  width: auto;
  padding-top: 20px;
  object-fit: contain;
  margin-left: auto;
}

/* ---------------------- */
/*      Button Styles     */
/* ---------------------- */

/* Common Button Styles */
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: all 0.3s ease;
  font-family: 'Lexend Deca', sans-serif;
}

/* Logout Button */
.logoutBtn {
  background-color: #E0858C;
  color: #ffffff;
  padding: 10px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.logoutBtn:hover {
  background-color: #EB4755 !important;
}

/* Register New User Button */
.addUserButton {
  background-color: #5CD685;
  color: #ffffff;
  padding: 10px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.addUserButton:hover {
  background-color: #94D1A8 !important;
}

/* Config Button */
.configBtn {
  background-color: #E0E1EB;
  color: #333;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.9em;
  transition: background-color 0.3s;
  margin-right: 5px;
}

.configBtn:hover {
  background-color: #4758EB;
  color: white;
}

/* Admin Tools Buttons */
.adminToolsBtn {
  background-color: #4758EB;
  color: #ffffff;
  padding: 15px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.2s;
  font-family: 'Lexend Deca', sans-serif;
  flex: 1;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.adminToolsBtn:hover {
  background-color: #3950C3 !important;
  opacity: 100% !important;
}

.adminToolsBtn:active {
  transform: translateY(0);
  background-color: #3950C3 !important;
}

/* Add Feed Button */
.addFeedButton {
  background-color: #5CD685;
  color: #ffffff;
  padding: 10px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.addFeedButton:hover {
  background-color: #94D1A8 !important;
}

/* Icon Stacking for Add Feed Button */
.iconStack {
  position: relative;
  display: inline-block;
}

.iconStack .plusIcon {
  position: absolute;
  bottom: -2px;
  right: -2px;
  color: #5cd685;
  background-color: #ffffff;
  border-radius: 50%;
  padding: 1px;
}

/* ---------------------- */
/*      Dashboard Layout  */
/* ---------------------- */

.dashboardContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px;
}

/* ---------------------- */
/*   Admin Tools Section  */
/* ---------------------- */

.adminTools {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.adminTools h2 {
  margin-bottom: 10px;
}

.toolsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

/* ---------------------- */
/*     Tab Navigation     */
/* ---------------------- */

.tabContainer {
  width: 100%;
}

.tabNav {
  display: flex;
  border-bottom: 1px solid #ccc;
  background-color: #f9f9f9;
}


.tab {
  flex: 1;
  color: #292B3D;
  padding: 12px 16px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  font-family: 'Lexend Deca', sans-serif;
  justify-content: center;
  font-size: 16px;
  transition: background-color 0.3s;
}

.tab:hover {
  background-color: #e0e0e0 !important;
}

.activeTab {
  border-bottom: 3px solid #4758EB;
  font-weight: bold;
  background-color: #fff;
}

.tabIcon {
  margin-right: 8px;
}

.tabContent {
  padding-top: 16px;
}

.tabPanel {
  display: block;
}

/* ---------------------- */
/* Configuration Section */
/* ---------------------- */

/* Configuration Container Styles */
.configurationContainer {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.userConfigContainer,
.feedConfigContainer {
  flex: 1;
  min-width: 300px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  border-radius: 8px;
}


.tableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}


/* ---------------------- */
/*       Tables Styles    */
/* ---------------------- */

.userTableContainer,
.feedConfigurationContainer .userTableContainer {
  max-height: 600px;
  overflow-y: auto;
  margin-top: 10px;
}

.userTable,
.feedConfigurationContainer .userTable {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.userTable th,
.userTable td,
.feedConfigurationContainer .userTable th,
.feedConfigurationContainer .userTable td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.userTable th,
.feedConfigurationContainer .userTable th {
  background-color: #4758EB;
  color: white;
  font-weight: bold;
}

.userTable tr:nth-child(even),
.feedConfigurationContainer .userTable tr:nth-child(even) {
  background-color: #f9f9f9;
}

.userTable tr:hover,
.feedConfigurationContainer .userTable tr:hover {
  background-color: #f5f5f5;
}

/* ---------------------- */
/*      Search Styles     */
/* ---------------------- */

.searchContainer {
  margin-top: 10px;
  margin-bottom: 10px;
}

.searchInput {
  width: 100%;
  padding: 8px;
  border: 1px solid #E0E1EB;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.searchInput:focus {
  border-color: #4758EB;
  outline: none;
}

/* ---------------------- */
/*      Action Buttons    */
/* ---------------------- */

/* Container for Action Buttons */
.actionButtons {
  display: flex;
  gap: 6px; 
  flex-wrap: wrap;
  color: white;
}

/* Base Action Button Styles */
.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px; 
  height: 32px; 
  border: none;
  padding: 0px;
  margin: 0px;
  border-radius: 50%;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  text-decoration: none;
}

.actionButton:hover {
  opacity: 0.8;
}

.actionButton:active {
  transform: scale(0.95);
}

/* 1. Credits Button */
.creditsBtn {
  background-color: #5cd685; 
}

.creditsBtn:hover {
  background-color: #4caf50 !important; 
}

/* 2. Assign User Button */
.assignBtn {
  background-color: #4758eb; 
}

.assignBtn:hover {
  background-color: #364fc7 !important; 
}

/* 3. Data Link Button */
.linkBtn {
  background-color: #4758eb; 
}

.linkBtn:hover {
  background-color: #364fc7 !important; 
}

/* 4. Modify/Settings Button */
.modifyBtn {
  background-color: #ffa500; 
}

.modifyBtn:hover {
  background-color: #e69500 !important; 
}

/* 5. Delete Button */
.deleteBtn {
  background-color: #e0858c; 
}

.deleteBtn:hover {
  background-color: #d66b7f !important; 
}

/* Remove default link styles */
.actionButton {
  text-decoration: none;
  color: inherit; 
}

/* ---------------------- */
/*     Monitoring Section */
/* ---------------------- */

.monitoringSection {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.monitoringSection h2 {
  margin-bottom: 20px;
}

/* Costs Scorecards Container */
.costsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

/* Generations Scorecards Container */
.generationsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

/* ---------------------- */
/*       Scorecard Styles  */
/* ---------------------- */

.scorecard {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  position: relative;
}

.scorecardIcon {
  font-size: 2.5rem;
  margin-right: 15px;
}

.scorecardContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
}

.scorecardTitle {
  font-size: 1rem;
  color: #777;
  margin-bottom: 5px;
}

.scorecardValue {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.spinner {
  animation: spin 1s linear infinite;
}

.loadingIcon {
  font-size: 24px;
  color: #4758EB;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
/* Spinner Animation */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Specific Styles for Different Scorecards */
.totalCost {
  border-left: 5px solid #4758EB; 
}

.totalGenerations {
  border-left: 5px solid #4758EB; 
}

.comingSoonCard {
  border-left: 5px solid #E0E1EB; 
  color: #555;
  position: relative;
  opacity: 0.9;
}

.comingSoonBadge {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgba(0,0,0,0.7);
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  gap: 4px;
}

.comingSoonBadge svg {
  margin-top: -2px;
}

/* ---------------------- */
/*      Filters Styles    */
/* ---------------------- */

/* Optimized Filters Container */
.optimizedFiltersContainer {
  padding: 15px 20px; 
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; 
}

.optimizedFiltersContainer h3 {
  margin-bottom: 15px;
  color: #4758EB;
}

.filtersGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr)); 
  gap: 15px; 
}

.filterGroup {
  display: flex;
  flex-direction: column;
}

.filterLabel {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: 500;
  color: #333;
  font-size: 0.95rem; 
}

.filterIcon {
  margin-right: 6px; 
  color: #4758EB;
  font-size: 1rem; 
}

.dateRangeContainer {
  display: flex;
  align-items: center;
  gap: 8px; 
}

.toText {
  font-weight: bold;
  color: #555;
  font-size: 0.9rem; 
}

.dateInput {
  width: 100%;
  padding: 6px 10px; 
  border: 1px solid #E0E1EB;
  border-radius: 4px;
  transition: border-color 0.3s;
  font-size: 0.9rem; 
}

.dateInput:focus {
  border-color: #4758EB;
  outline: none;
}

.selectInput {
  font-size: 0.9rem; 
}

.filterButtons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  justify-content: flex-end;
}

.filterButton {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.2s;
}

.applyButton {
  background-color: #4758EB;
  color: #ffffff;
}

.applyButton:hover {
  background-color: #364fc7;
}

.applyButton:active {
  transform: scale(0.98);
}

.resetButton {
  background-color: #e0858c;
  color: #ffffff;
}

.resetButton:hover {
  background-color: #d66b7f;
}

.resetButton:active {
  transform: scale(0.98);
}

/* ---------------------- */
/*      Charts Container    */
/* ---------------------- */

.chartsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 40px;
}

.chartSection {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
}


.chartHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.metricToggle {
  display: flex;
  gap: 10px;
}

.toggleLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toggleInput {
  display: none;
}

.toggleButton {
  background-color: #e0e1eb;
  color: #333;
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 14px;
  user-select: none;
}

.toggleButton:hover {
  background-color: #4758EB;
  color: #ffffff;
}

.activeToggle {
  background-color: #4758EB;
  color: #ffffff;
}

.comingSoonChart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px; 
  font-size: 1.2rem;
  color: #555;
}

.comingSoonChart p {
  margin-top: 10px;
  font-weight: bold;
}

/* ---------------------- */
/*      Modal Styles      */
/* ---------------------- */

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

.modalContent {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  width: 90%;
  max-width: 800px; 
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  position: relative;
  transform: scale(0.9);
  animation: scaleIn 0.3s forwards;
}

/* Modal Animations */
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  to {
    transform: scale(1);
  }
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.closeButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
}

.modalBody {
  max-height: 60vh;
  overflow-y: auto;
}

/* ---------------------- */
/*      Message Styles    */
/* ---------------------- */

.message {
  margin-top: 10px;
  text-align: center;
  font-family: 'Lexend Deca', sans-serif;
  padding: 10px;
  border-radius: 4px;
}

.messageSuccess {
  color: green;
  background-color: #e6ffe6;
  border: 1px solid green;
}

.messageError {
  color: red;
  background-color: #ffe6e6;
  border: 1px solid red;
}

/* ---------------------- */
/*      Responsive Design */
/* ---------------------- */

@media (max-width: 1024px) {
  .dashboardContent {
    flex-direction: column;
  }

  .mainContent {
    flex: 1;
  }

  .leftContainer {
    flex-direction: row; 
  }
}

@media (max-width: 768px) {
  .topBar {
    flex-direction: column;
    align-items: flex-start;
  }

  .headerImage {
    margin-left: 0;
    margin-top: 10px;
  }

  .configurationContainer,
  .feedConfigContainer {
    padding: 15px;
  }

  .adminToolsBtn {
    min-width: 150px;
    padding: 10px 15px;
  }

  .metricToggle {
    flex-direction: column;
    align-items: flex-start;
  }

  .comingSoonBadge {
    top: auto;
    bottom: 10px;
  }

  /* Stack User and Feed Config on smaller screens */
  .configurationContainer {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .adminToolsBtn {
    flex: 1 1 100%;
    min-width: unset;
  }

  .costsContainer,
  .generationsContainer {
    grid-template-columns: 1fr;
  }

  .scorecard {
    padding: 15px;
  }

  .scorecardIcon {
    font-size: 2rem;
    margin-right: 10px;
  }

  .chartsContainer {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

/* ---------------------- */
/*   Additional Elements  */
/* ---------------------- */

/* Metric Selector Styles */
.metricSelector {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.metricSelector label {
  margin-right: 10px;
  font-weight: bold;
  color: #333;
}

.metricSelector .react-select__control {
  min-width: 200px;
}

@media (max-width: 768px) {
  .metricSelector {
    flex-direction: column;
    align-items: flex-start;
  }

  .metricSelector label {
    margin-bottom: 5px;
  }
}

/* ---------------------- */
/*      Actions Column    */
/* ---------------------- */

/* Define a class for the Actions column to set a minimum width */
.actionsColumn {
  min-width: 150px; 
}

/* Ensure the Actions column text is centered */
.actionsColumn {
  text-align: center;
}

/* ---------------------- */
/*      Responsive Tables  */
/* ---------------------- */

/* Make tables responsive */
@media (max-width: 768px) {
  .userTable,
  .feedConfigurationContainer .userTable {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}

/* ---------------------- */
/*    Coming Soon Badge    */
/* ---------------------- */

/* Ensure the Coming Soon badge is properly styled */
.comingSoonBadge {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgba(0,0,0,0.7);
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  gap: 4px;
}

.comingSoonBadge svg {
  margin-top: -2px;
}

/* ---------------------- */
/*   Cloud Cost Alert Styles */
/* ---------------------- */

.cloudCostAlert {
  display: flex;
  align-items: center;
  background-color: #fff3cd; 
  color: #856404; 
  padding: 8px 12px;
  border: 1px solid #ffeeba; 
  border-radius: 4px;
  font-size: 0.9rem;
}

.alertIcon {
  margin-right: 8px;
  font-size: 1.2rem;
  color: #856404;
}

/* Adjustments for the Cloud Costs Value Container */

.scorecardValue {
  display: flex;
  align-items: center;
  justify-content: flex-start; 
  height: 40px;
}

/* ---------------------- */
/*   Combined Scorecard Styles */
/* ---------------------- */

.combinedScorecard {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;
}

.combinedScorecardHeader {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.combinedScorecardIcon {
  font-size: 2.5rem;
  margin-right: 15px;
  color: #4758EB;
}

.combinedScorecardContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.combinedScorecardTitles {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.combinedScorecardTitle {
  font-size: 1rem;
  color: #777;
}

.combinedScorecardSubtitles {
  display: flex;
  gap: 20px;
  font-size: 0.9rem;
  color: #555;
  margin-bottom: -15px;
}

.combinedScorecardValue {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-top: 10px;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px; 
}

.chartLoadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px; 
}

.noData {
  text-align: center;
  padding: 20px;
  color: #888;
}

/* ---------------------- */
/*   Cloud Cost Scorecard */
/* ---------------------- */

.cloudCost {
  cursor: pointer;
}

.cloudCost:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
  transition: transform 0.2s, box-shadow 0.2s;
}

.interactiveIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 1.2rem;
  color: #4758EB;
  opacity: 1;
  transition: opacity 0.3s;
}




