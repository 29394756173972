.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(41, 43, 61, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  
  .modalContent {
    background-color: #ffffff;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    padding: 20px;
    position: relative;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  .header {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .header h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
  }
  
  .section {
    margin-bottom: 20px;
  }
  
  .sectionTitle {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #292B3D;
  }
  
  .userList {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .userItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    margin-bottom: 8px;
    background-color: #F6F7FB;
    border-radius: 4px;
  }
  
  .currentUser {
    background-color: #e0e0e0;
    color: #7d7d7d;
  }
  
  .removeButton {
    background-color: #EB4755;
    border: none;
    color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    font-size: 8px;
    padding: 0;
  }
  
  .removeButton:hover {
    background-color: #E0858C !important;
  }
  
  .addUserSection {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }
  
  .selectContainer {
    flex: 1;
  }
  
  .addUserButton {
    background-color: #5CD685;
    border: none;
    color: #ffffff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
  
  .addUserButton:hover {
    background-color: #94D1A8 !important;
  }
  
  .loadingText {
    text-align: center;
    color: #292B3D;
    font-size: 16px;
    padding: 20px;
  }
  