.promptBuilderContainer {
  display: flex;
  width: 100%;
  height: 100vh;
  font-family: 'DM Sans', sans-serif;
  position: relative;
}

.promptBuilderOptions {
  flex: 1;
  padding: 20px;
  border-right: 1px solid #ccc;
  margin-top: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.promptPreview {
  flex: 1;
  margin-top: 20px;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.collapsibleSection {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.collapsibleHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.collapsibleHeader h3 {
  margin: 0;
  flex: 1;
  font-size: 18px;
}

.collapsibleContent {
  margin-top: 10px;
  overflow: hidden;
}

.collapsibleHeader .indicator {
  margin-right: 20px;
  transition: transform 0.3s;
}

.resetButton {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #666;
  margin-left: auto;
  transition: transform 0.2s, color 0.2s;
  padding: 4px;
  padding-bottom: 0px;
}

.resetButton:hover {
  background-color: rgba(84, 142, 224, 0.2) !important;
  transform: scale(1.1);
}

.saveButtonUnsaved {
  position: sticky;
  bottom: 20px;
  right: 20px;
  background-color: #5CD685;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: transform 0.2s, background-color 0.2s;
  float: right;
}

.saveButtonUnsaved:hover {
  background-color: #94D1A8 !important;
  transform: scale(1.1);
}

.addButton {
  background-color: transparent;
  color: #555EAA;
  border: none;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  font-size: small;
  transition: transform 0.2s, background-color 0.2s;
}

.addButton:hover {
  transform: scale(1.1);
  background-color: rgba(84, 142, 224, 0.2) !important;
}

.addButton svg {
  margin-right: 8px;
}

.addButton span {
  font-size: 16px;
}

.clientSelector {
  margin-bottom: 20px;
}

.clientSelector label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.clientSelector select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.clientSelector select:focus {
  border-color: #555EAA;
}

.combinationSelector {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.combinationSelector select {
  flex: 1;
  margin-right: 10px;
}

.saveOptions {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.saveButtonOverwrite {
  background-color: grey;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}

.saveButtonNew {
  background-color: #555EAA;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.newCombinationNameInput {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
}

.deleteButton {
  background-color: transparent;
  color: #EB4755;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  margin-left: 10px;
  transition: transform 0.2s, background-color 0.2s;
}

.deleteButton:hover {
  transform: scale(1.1);
  background-color: rgba(235, 71, 85, 0.2) !important;
}

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupContent {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  width: 300px;
}

.popupInput {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.popupButtons {
  display: flex;
  justify-content: space-between;
}

.popupImportButton, .popupCloseButton {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popupImportButton {
  background-color: #555EAA;
  color: white;
}

.popupImportButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.popupCloseButton {
  background-color: #EB4755;
  color: white;
}

.productDetailsBox {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 20px;
  margin-top: 20px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.productImage {
  max-width: 150px; /* Adjust width as needed */
  max-height: 150px;
  margin-right: 20px;
}

.productInfo {
  flex: 1;
}

.attributeComparison {
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  padding: 0px;
  margin-top: 20px;
}

.attributeHeader {
  /* This row holds the "Attributes Comparison" title and the button side by side */
  display: flex;
  justify-content: space-between;  /* Space the title and button out */
  align-items: center;
  padding: 10px 20px;             /* Some padding around them */
  border-bottom: 1px solid #ccc;
}

.attributeHeader h3 {
  margin: 0;                      /* Tighten up the spacing if needed */
}
.attributeRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
}

.attributeColumn {
  flex: 1;
}

.attributeColumn h4 {
  margin: 0;
  font-weight: bold;
}

.attributeColumn p {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.attributePair {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0;
  padding: 10px 20px;
  border-bottom: 1px solid #eee;
}

.attributePair p {
  width: 48%;
  margin: 0;
  padding: 5px 0;
}

.attributeOriginal,
.attributeGenerated {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 6px !important;
}

.error {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

.selectPromptMessage {
  color: #555;
  font-style: italic;
  margin-top: 20px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.boldCenter {
  font-weight: bold;
  text-align: center;
  display: block;
}

.fewShotAttribute {
  flex: 1;
  font-weight: bold;
}

.fewShotPrompt {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  background-color: #f1f1f1;
}

.fewShotRow {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.fewShotField {
  flex: 2;
  margin-right: 10px;
}

.fewShotField input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.fewShotField input:focus {
  border-color: #555EAA;
}

.reactQuillContainer {
  height: 300px;
  overflow: hidden;
}

.reactQuillEditor {
  height: 100%;
  overflow-y: auto;
}

.removeButton {
  background-color: transparent;
  color: #EB4755;
  border: none;
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 2px;
  transition: transform 0.2s, background-color 0.2s;
}

.removeButton:hover {
  transform: scale(1.1);
  background-color: rgba(235, 71, 85, 0.2) !important;
}

.imageWarning {
  display: flex;
  align-items: center;
  background-color: #fff3cd; /* Light yellow background */
  color: #856404; /* Dark yellow text */
  border: 1px solid #ffeeba; /* Yellow border */
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.warningIcon {
  margin-right: 10px;
  font-size: 20px;
  color: #856404; /* Matching dark yellow text */
}

.idInput {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.generateButton {
  background-color: #555EAA;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin-bottom: 20px;
}

.generateButton:hover {
  background-color: #3d45b0;
  transform: scale(1.05);
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.loadingContainer p {
  margin-top: 10px;
  font-size: 16px;
  color: #555;
}

.backButton {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #555;
  border: none;
  color: #f0f0f0;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, color 0.3s;
}

.backButton:hover {
  background-color: #55555578 !important;
  color: #ffffff !important;
}

.importButton {
  background-color: transparent;
  color: #555EAA;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  margin-left: 10px;
  transition: transform 0.2s, background-color 0.2s;
}

.importButton:hover {
  transform: scale(1.1);
  background-color: rgba(84, 142, 224, 0.2) !important;
}


.addFewShotButton {
  background-color: #5CD685; /* A fresh, noticeable color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 8px; /* Space between icon and text */
}

.addFewShotButton:hover {
  background-color: #45B570 !important;
  transform: scale(1.05);
}
