/* src\popups\css\PopupPdpBulkActions.module.css */

.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    font-family: 'Lexend Deca', sans-serif;
}

.popupInner {
    background: #fff;
    padding: 30px 40px;
    border-radius: 8px;
    width: 60%;
    max-width: 800px;
    position: relative;
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
    overflow-y: auto;
    max-height: 90vh;
}

.title {
    font-size: 22px;
    font-family: 'DM Serif Text', serif;
    color: #292B3D;
    margin-bottom: 25px;
}

.segmentedControl {
    display: flex;
    background-color: #F0F0F5;
    border-radius: 24px;
    overflow: hidden;
    margin-bottom: 25px;
}

.segmentButton {
    flex: 1;
    border: none;
    background: transparent;
    padding: 12px 0;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 16px;
    color: #555;
    cursor: pointer;
    transition: background 0.3s;
    border-radius: 24px;
    text-align: center;
}

.segmentButton:hover {
    background-color: #E0E1EB !important;
}

.activeSegment {
    background-color: #4758EB;
    color: #fff;
    border-radius: 24px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.description {
    font-size: 14px;
    color: #555;
    margin-bottom: 25px;
}

.subTitle {
    font-size: 16px;
    font-weight: 600;
    color: #292B3D;
    margin-bottom: 12px;
    font-family: 'DM Sans', sans-serif;
}

.fieldSelectionSection {
    margin-bottom: 25px;
}

.fieldsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.fieldsControls {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 14px;
}

.selectAllButton {
    background-color: #f0f0f5;
    border: 1px solid #ccc;
    border-radius: 24px;
    padding: 8px 16px;
    font-size: 14px;
    font-family: 'Lexend Deca', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
    color: #292b3d;
    font-weight: 600;
    text-align: center;
    user-select: none;
}

.selectAllButton:hover {
    background-color: #e0e1eb !important;
    border-color: #aaa;
}

.selectedCount {
    font-size: 14px;
    color: #555;
}

.fieldSearchContainer {
    margin-bottom: 15px;
}

.fieldSearchInput {
    width: 100%;
    padding: 10px 12px;
    border: 1px solid #E0E1EB;
    border-radius: 4px;
    font-size: 14px;
    font-family: 'Lexend Deca', sans-serif;
}

.fieldsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 12px;
    padding: 12px;
    border: 1px solid #E0E1EB;
    border-radius: 4px;
    background: #FAFAFA;
    max-height: 300px;
    overflow-y: auto;
}

.fieldItem {
    position: relative;
    display: block;
    cursor: pointer;
    padding: 0;
}

.fieldName {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border: 1px solid transparent;
    border-radius: 4px;
    background: #fff;
    font-size: 14px;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s;
    width: 100%;
    box-sizing: border-box;
    position: relative;
}

.fieldItem input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.fieldItem input[type="checkbox"]:checked + .fieldName {
    background-color: #4758EB;
    color: #fff;
    border-color: #4758EB;
}

.fieldItem:hover .fieldName {
    background-color: #f0f0f5;
}

.fieldItem.disabled .fieldName {
    background-color: #f5f5f5;
    color: #999;
    cursor: not-allowed;
    border-color: #e0e1eb;
}

.fieldItem.disabled:hover .fieldName {
    background-color: #f5f5f5;
}

.fieldItem.disabled input[type="checkbox"] {
    cursor: not-allowed;
}

/* Only show tooltip in Generate tab */
.fieldItem.disabled.generateTab .fieldName {
    padding-right: 180px; /* Make room for the tooltip text */
}

.fieldItem.disabled.generateTab .fieldName::after {
    position: absolute;
    right: 12px;
    font-size: 12px;
    color: #999;
    font-style: italic;
    white-space: nowrap; /* Prevent text wrapping */
}

.noResults {
    grid-column: 1 / -1;
    text-align: center;
    color: #999;
    font-size: 14px;
}

.scopeSection,
.promptSection,
.stateChoiceSection {
    margin-bottom: 25px;
}

.scopeSelector {
    display: flex;
    gap: 12px;
    margin-top: 12px;
}

.scopeButton {
    border: 1px solid #ccc;
    background: #fff;
    color: #292B3D;
    border-radius: 24px;
    padding: 8px 16px;
    font-size: 14px;
    font-family: 'Lexend Deca', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    text-align: center;
    min-width: 120px;
}

.scopeButton:hover {
    background-color: #E0E1EB !important;
    color: #292B3D !important;
}

/* Increased specificity by combining selectors */
.scopeButton.activeScope {
    background-color: #4758EB !important;
    color: #fff !important;
    border: none;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}


.dropdown {
    width: 100%;
    padding: 10px 12px;
    font-size: 14px;
    border: 1px solid #E0E1EB;
    border-radius: 4px;
    font-family: 'Lexend Deca', sans-serif;
    background: #fff;
}

.radioGroup {
    display: flex;
    gap: 25px;
    margin-top: 12px;
    font-size: 14px;
}

.radioOption {
    display: flex;
    align-items: center;
    gap: 8px;
}

.actionButtons {
    display: flex;
    gap: 15px;
    margin-top: 25px;
}

.primaryActionButton,
.secondaryActionButton {
    border-radius: 24px;
    padding: 12px 24px;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.primaryActionButton {
    background-color: #4758EB;
    color: #fff;
}

.primaryActionButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.secondaryActionButton {
    background-color: #EB4755;
    color: #fff;
}

.secondaryActionButton:hover {
    background-color: #E0858C;
}

/* Styles for the smaller toggle group */
.smallToggleGroup {
    display: flex;
    background-color: #F0F0F5;
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 12px;
    width: 220px; /* Adjust width as needed */
}

/* Styles for smaller toggle buttons */
.smallToggleButton {
    flex: 1;
    border: none;
    background: transparent;
    padding: 8px 0; /* Reduced padding for smaller size */
    font-family: 'Lexend Deca', sans-serif;
    font-size: 14px; /* Smaller font size */
    color: #555;
    cursor: pointer;
    transition: background 0.3s;
    border-radius: 16px;
    text-align: center;
    user-select: none;
}

.smallToggleButton:hover {
    background-color: #E0E1EB !important;
}

.activeSmallToggle {
    background-color: #4758EB;
    color: #fff;
    border-radius: 16px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}


/* Progress Information Section */
.progressSection {
    position: relative; /* To position the "X" absolutely within */
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #E0E1EB;
    border-radius: 8px;
    background-color: #F9F9FF;
}

.progressTitle {
    font-size: 18px;
    font-weight: 600;
    color: #292B3D;
    margin-bottom: 15px;
}

.progressBarContainer {
    width: 100%;
    background-color: #E0E1EB;
    border-radius: 8px;
    overflow: hidden;
    height: 20px;
    margin-bottom: 15px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

.progressBar {
    height: 100%;
    background-color: #4758EB;
    transition: width 0.4s ease;
}

.progressDetails {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #555;
    margin-bottom: 15px;
}





/* Optional: Spinner for Loading Indicator */
.spinner {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #4758EB; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin-right: 8px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}



  .buttonContent {
    display: flex;
    align-items: center;
    gap: 8px; /* Space between spinner and text */
  }

  /* Example minimal styling for the new progress box in the popup */
.popupProgressBox {
    margin: 10px 0;
    padding: 10px;
    background-color: #f4f6fb;
    border-radius: 8px;
    border: 1px solid #e0e1eb;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .progressInfo {
    font-size: 0.85rem;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .progressBarWrapper {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between progress bar and cancel button */
    width: 100%;
}

.progressBarOuter {
    flex-grow: 1;  /* Allows the progress bar to take full width */
    height: 8px;
    background-color: #ccc;
    border-radius: 4px;
    overflow: hidden;
}

.progressBarInner {
    height: 100%;
    background-color: #4758EB;
    transition: width 0.4s ease;
}

/* Cancel button styling */
.cancelGenerationButton {
    background-color: #EB4755;
    color: #ffffff;
    border: none;
    padding: 0;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    font-size: 12px;
    flex-shrink: 0; /* Prevents button from shrinking */
}

.cancelGenerationButton:hover {
    background-color: #E0858C !important;
}

  .cancelGenerationButton:disabled {
    background-color: #e0aeb1;
    cursor: not-allowed;
  }
  