@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@400;600&display=swap');

.coreVariantsContainer {
  font-family: 'Lexend Deca', sans-serif;
}

/* TOP BAR */
.topBar {
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 15px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
}

/* Container for dropdown and project buttons (left side) */
.leftSideContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* Dropdown wrapper */
.dropdownWrapper {
  min-width: 250px;
  max-width: 400px;
}

/* Container for icon buttons (next to the dropdown) */
.iconButtons {
  display: flex;
  gap: 10px;
}

/* Container for the toggle (right side) */
.rightSideContainer {
  margin-left: auto;
}

/* ---- Toggle Switch Styles ---- */
.toggleSwitch {
  display: flex;
  background-color: #E0E1EB;
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #d4d4d4;
}

.toggleOption {
  padding: 8px 16px;
  cursor: pointer;
  background-color: transparent;
  color: #292B3D;
  transition: background-color 0.3s, color 0.3s;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  user-select: none;
}

.toggleOption:hover {
  background-color: #c9c9c9;
}

.toggleOption.active {
  background-color: #4758EB;
  color: #ffffff;
}

/* ---- Icon Button Styles ---- */
.addIconBtn {
  background-color: #5CD685; /* Green */
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addIconBtn:hover {
  background-color: #94D1A8 !important; 
}

/* Trash Button – Red */
.trashIconBtn {
  background-color: #EB4755; 
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trashIconBtn:hover {
  background-color: #E0858C !important;
}

/* Send Button – Blue */
.sendIconBtn {
  background-color: #4758EB; 
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sendIconBtn:hover {
  background-color: #848EE0 !important; 
}

/* SCORE CARDS GRID */
.scoreCardsContainer {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
}

@media (min-width: 1500px) {
  .scoreCardsContainer {
    grid-template-columns: repeat(5, 1fr);
  }
}

/* Each Score Card */
.scoreCard {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  min-height: 180px;
  position: relative;
  overflow: hidden;
}

.cardHeader {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  z-index: 2;
  padding: 5px 0;
}

.cardHeader h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.cardFooter {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 2;
  padding: 5px 0;
}

.centeredMessage {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  text-align: center;
  z-index: 1;
}

.messageValue {
  font-size: 20px;
  font-weight: 500;
  color: #292B3D;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.metricValue {
  font-size: 28px;
  font-weight: 600;
  color: #292B3D;
}

.timestamp {
  font-size: 10px;
  color: #6c757d;
}

/* Activate/Deactivate Buttons */
.activateButton {
  background-color: #5CD685; 
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: background-color 0.3s;
}

.activateButton:hover {
  background-color: #94D1A8 !important;
}

.deactivateButton {
  background-color: #EB4755; 
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: background-color 0.3s;
}

.deactivateButton:hover {
  background-color: #E0858C !important;
}

.viewLogsButton {
  background-color: #4758EB; 
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: background-color 0.3s;
}

.viewLogsButton:hover {
  background-color: #848EE0;
}

/* MODAL OVERLAY */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 43, 61, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modalContent {
  background-color: #ffffff;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  padding: 20px;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.closeModalBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #292B3D;
  cursor: pointer;
  font-size: 18px;
  transition: color 0.2s ease;
}

.closeModalBtn:hover {
  color: #4758EB !important;
}

.modalContent h3 {
  margin-top: 0;
  font-size: 18px;
  font-weight: 600;
  color: #4758EB;
  margin-bottom: 15px;
}

.errorLogs {
  max-height: 300px;
  overflow-y: auto;
}

.errorLogs p {
  margin: 5px 0;
  color: #292B3D;
}

.errorIcon {
  color: #EB4755;
  margin-right: 6px;
}

/* Loading & Error states */
.loadingSpinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #E0E1EB;
  border-radius: 8px;
  margin: 20px 0;
}

.loadingSpinner p {
  margin: 0;
  font-size: 16px;
  color: #292B3D;
}

.errorMessage p {
  color: #EB4755;
  margin: 0;
}

/* SETTINGS TAB */
.settingsContainer {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.settingsDescription {
  margin-bottom: 20px;
  color: #292B3D;
  font-size: 14px;
}

.settingsForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Each group */
.settingsGroup {
  background-color: #F9F9FB;
  border: 1px solid #E0E1EB;
  border-radius: 8px;
  padding: 15px 20px;
}

.settingsGroupTitle {
  margin: 0 0 5px 0;
  font-size: 16px;
  font-weight: 600;
  color: #292B3D;
}

.groupSubtitle {
  font-size: 13px;
  color: #777;
  margin-bottom: 15px;
}

.settingsRow {
  display: grid;
  grid-template-columns: 300px 120px;
  gap: 10px;
  align-items: center;
  margin-bottom: 8px;
}

.settingsLabel {
  font-weight: 500;
  color: #292B3D;
  cursor: help; /* indicates there's a tooltip */
  display: inline-block;
}

.settingsInput {
  width: 100%;
  padding: 8px;
  font-family: 'Lexend Deca', sans-serif;
  border: 1px solid #E0E1EB;
  border-radius: 4px;
  font-size: 14px;
  color: #292B3D;
}

.settingsInput:focus {
  outline: none;
  border-color: #4758EB;
}

.tooltipLabel {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

/* Explanatory note for advanced logic or general overview */
.explanatoryNote {
  background-color: #fff;
  border: 1px dashed #CCC;
  border-radius: 6px;
  padding: 10px 15px;
  font-size: 13px;
  color: #555;
}

/* Save / Reset buttons row */
.saveSettingsRow {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.saveSettingsButton {
  background-color: #4758EB; 
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: background-color 0.3s;
}

.saveSettingsButton:hover {
  background-color: #848EE0;
}

.resetSettingsButton {
  background-color: #EBB847; /* Yellowish */
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  transition: background-color 0.3s;
}

.resetSettingsButton:hover {
  background-color: #EBCB70 !important; 
}
