/* src/components/css/CustomAttributes.module.css */

@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@400;600&family=DM+Sans:wght@400;500&family=DM+Serif+Text&display=swap');

/* Top Bar Styling */
.topBar {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 20px;
  background-color: #ffffff;
  color: #4758EB;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  gap: 10px;
}

/* Back Button Styling */
.backButton {
  background-color: #d0d0d0;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 0px;
}

.backButton:hover {
  background-color: #7c7c7c9f !important;
}

/* Logout Button Styling */
.logoutBtn {
  background-color: #E0858C;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.logoutBtn:hover {
  background-color: #EB4755 !important;
}

.logoutBtn svg {
  margin-right: 0;
}

/* Header and Dropdown Container Styling */
.headerAndDropdown {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-grow: 1;
}

/* Header Text Container */
.headerTextContainer {
  display: flex;
  flex-direction: column;
}

.feedViewerVersion {
  font-size: 12px;
  font-weight: 600;
  color: #4758EB;
  font-family: 'Lexend Deca', sans-serif;
}

.headerSubtitle {
  font-size: 22px;
  font-family: 'DM Serif Text', serif;
  color: #292B3D;
  margin-top: 4px;
}

/* Dropdown Container - adds extra spacing around dropdowns */
.dropdownContainer {
  margin: 10px 20px;
  padding: 5px;
}

/* Client Dropdown Styling */
.clientDropdown {
  min-width: 350px;       /* increased width for better visibility */
  font-size: 14px;        /* slightly larger text */
  padding: 8px 12px;      /* added padding for more breathing room */
}

/* General Select Dropdown Styling (for project dropdowns, etc.) */
.selectDropdown {
  min-width: 350px;
  font-size: 14px;
  padding: 8px 12px;
}

/* Header Image Styling */
.headerImage {
  height: 100px;
  width: auto;
  padding-top: 20px;
  object-fit: contain;
  margin-left: auto;
}

/* Navigation Menu Styling */
.navMenu {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  gap: 10px;
}

.navBtn {
  background-color: #ffffff;
  color: #4758EB;
  border: 1px solid #4758EB;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
  display: flex;
  align-items: center;
}

.navBtn:hover {
  background-color: #4758EB;
  color: #ffffff;
}

.navBtn.active {
  background-color: hsl(234, 80%, 60%);
  color: #ffffff;
}

.navBtn:disabled {
  background-color: #d0d0d0;
  color: #a0a0a0;
  cursor: not-allowed;
}

.navBtn:disabled .icon {
  color: #a0a0a0;
}

.navBtn .icon {
  margin-right: 8px;
}

/* Content Area Styling */
.contentArea {
  padding: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .topBar {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
  }

  .headerAndDropdown {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
  }

  .headerImage {
    margin-left: 0;
    margin-top: 10px;
    height: 80px;
  }

  .dropdownContainer {
    width: 100%;
    margin: 10px 0;
  }

  .clientDropdown, .selectDropdown {
    min-width: 100%;
    font-size: 14px;
  }

  .navMenu {
    flex-direction: column;
    width: 100%;
  }

  .navBtn {
    width: 100%;
    justify-content: center;
  }
}

/* Message when no client is selected */
.selectClientMessage {
  padding: 20px;
  text-align: center;
  font-size: 14px;
  color: #4758EB;
  font-family: 'Lexend Deca', sans-serif;
}
