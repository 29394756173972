/* Container for the entire preview page */
.previewContainer {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: #f9f9f9;
  color: #333;
  font-family: Arial, sans-serif;
}

/* Header row: back button + title */
.headerContainer {
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
  margin-bottom: 1rem;
}

/* Back button styling */
.backButton {
  background-color: #555;
  border: none;
  color: #f0f0f0;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.backButton:hover {
  background-color: #55555578 !important;
  color: #ffffff;
}

/* Title styling */
.title {
  font-size: 1.5rem;
  margin: 0;
  font-weight: 600;
}

/* Row for dropdown and URL input */
.inputRow {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 1rem;
}

/* Container for the react-select dropdown */
.selectContainer {
  width: 180px;
}

/* URL input field styling */
.idInput {
  flex: 1;
  min-height: 38px;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 8px;
  font-size: 14px;
}

/* Generate button styling */
.generateButton {
  width: 100%;
  padding: 10px;
  background-color: #4758EB;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin: 1rem auto;
}
.generateButton:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

/* Error message styling */
.error {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

/* Image warning styling */
.imageWarning {
  display: flex;
  align-items: center;
  background-color: #fff3cd;
  color: #856404;
  border: 1px solid #ffeeba;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 15px;
}
.warningIcon {
  margin-right: 10px;
  font-size: 20px;
  color: #856404;
}

/* Loading container styling */
.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.loadingContainer p {
  margin-top: 10px;
  font-size: 16px;
  color: #555;
}

/* Attribute comparison container */
.attributeComparison {
  margin-top: 20px;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}
.attributeComparison h4 {
  margin-top: 0;
}

/* Desktop Version: Side-by-side table */
.comparisonTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}
.comparisonTable thead th {
  background-color: #f3f3f3;
  color: #333;
  text-align: left;
  padding: 10px;
  font-weight: 600;
  border-bottom: 1px solid #ddd;
}
.comparisonTable tbody td {
  padding: 10px;
  border-bottom: 1px solid #eee;
}
.attrName {
  font-weight: bold;
  background-color: #f9f9f9;
}
.attributeOriginal,
.attributeGenerated {
  background-color: #fff;
}

/* Highlight changed rows */
.changedRow {
  background-color: #f7fff7;
}

/* Mobile Version: Vertically stacked cards for comparison */
.mobileComparison {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.comparisonCard {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 12px;
}
/* Highlight changed cards (mobile) */
.changedCard {
  background-color: #f7fff7;
}
.cardHeader {
  background-color: #f1f1f1;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
}
.cardContent {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.cardSection {
  padding: 8px;
  margin-bottom: 0;
  border-top: 1px solid #eee;
}
.beforeSection {
  background-color: #fafafa;
}
.afterSection {
  background-color: #f7fff7;
}
.cardSectionTitle {
  font-weight: bold;
}
.cardSectionValue {
  line-height: 1.4;
}

/* Responsive display classes */
.desktopOnly {
  display: none;
}
.mobileOnly {
  display: block;
}
@media (min-width: 768px) {
  .desktopOnly {
    display: table;
  }
  .mobileOnly {
    display: none;
  }
  .productPreviewContainer {
    flex-direction: row;
    align-items: center;
  }
  .productImageWrapper {
    margin-bottom: 0;
  }
}

/* Product preview container */
.productPreviewContainer {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  padding: 10px;
  gap: 15px;
}
.productImageWrapper {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #f9f9f9;
  border-radius: 4px;
  border: 1px solid #eee;
}
.productPreviewImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.noImagePlaceholder {
  font-size: 14px;
  color: #999;
  text-align: center;
  padding: 0 5px;
}
.productInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.productTitle {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}
.productBrand {
  margin: 0;
  font-size: 0.9rem;
  color: #666;
}
.infoButton {
  background-color: #4758EB;
  border: none;
  color: #f0f0f0;
  font-size: 18px;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  transition: background-color 0.3s, color 0.3s;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.infoButton:hover {
  background-color: #848EE0 !important;
}

/* --- Enhanced Popup Styles --- */

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes slideDown {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

/* Popup overlay */
.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  animation: fadeIn 0.3s ease-out;
}

/* Popup content container */
.popupContent {
  background: #fff;
  border-radius: 8px;
  position: relative;
  max-width: 1000px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  animation: slideDown 0.3s ease-out;
}

/* Popup header */
.popupHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f5f5f5;
  padding: 12px 20px;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

/* Popup title */
.popupTitle {
  margin: 0;
  font-size: 1.4rem;
  font-weight: 600;
  color: #333;
}

/* Close button inside popup header */
.closePopupButton {
  color: white;
  background-color: #555;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.closePopupButton:hover {
  background-color: #55555578 !important;
  color: #ffffff;
}

/* Popup body */
.popupBody {
  padding: 20px;
}

/* Enhanced table styling inside the popup */
.popupDataTable {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
  font-size: 0.95rem;
}
.popupDataTable thead {
  background: #e9e9e9;
}
.popupDataTable thead th {
  text-align: left;
  padding: 10px;
  font-weight: 600;
  color: #333;
  border-bottom: 2px solid #ccc;
}
.popupDataTable tbody tr:nth-child(even) {
  background: #f9f9f9;
}
.popupDataTable tbody tr:hover {
  background: #f1f1f1;
}
.popupDataTable th,
.popupDataTable td {
  padding: 10px;
  color: #292B3D;
  border-bottom: 1px solid #ddd;
  overflow-wrap: break-word;
  word-break: break-word;
}
.dataKey {
  width: 30%;
  font-weight: bold;
  color: #333;
}
.dataValue {
  width: 70%;
  color: #555;
}

/* Ensure the unordered list inside the dataValue wraps properly */
.popupDataTable .dataValue ul {
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;
}

/* Wrap the preview & optimization summary side by side on desktop */
.previewAndOptimizationRow {
  display: flex;
  flex-direction: column; /* default: stack in a column (mobile) */
  gap: 20px;
}
@media (min-width: 768px) {
  .previewAndOptimizationRow {
    flex-direction: row;
    align-items: stretch;
  }
}

/* Product Preview and Optimization Summary containers share a consistent look */
.productPreviewContainer {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  padding: 10px;
  gap: 15px;
  display: flex;
  align-items: center;

  flex: 1 1 50%;
}

.optimizationSummary {
  flex: 1 1 50%;
  background-color: #fff;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}
.optimizationSummary h4 {
  margin-top: 0;
  margin-bottom: 10px;
}

/* Circle chart container + text */
.optimizationScoreContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}
.optimizationScoreSvg {
  width: 80px;
  height: 80px;
  overflow: visible;
}
.optimizationScoreBg {
  fill: none;
  stroke: #eee;
  stroke-width: 8;
}
.optimizationScoreProgress {
  fill: none;
  stroke: #5CD685;
  stroke-width: 8;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.optimizationScoreText {
  font-size: 16px;
  font-weight: bold;
  fill: #333;
}
.optimizationScoreTextContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.optimizationBreakdown {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.comparisonInfo {
  margin-top: 20px;
  background-color: #f4f4f4;
  border-left: 4px solid #4758EB;
  padding: 10px 15px;
  margin-bottom: 20px;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #555;
  font-style: italic;
}

.comparisonInfo p {
  margin: 0 0 8px;
  line-height: 1.4;
}
