.container {
  padding: 20px;
  background-color: #ffffff;
  height: 100%;
}

.title {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #292B3D;
  margin-bottom: 12px;
  border-bottom: 1px solid #E0E1EB;
  padding-bottom: 10px;
}

.description {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #7C7F9C;
  margin-bottom: 20px;
  line-height: 1.5;
}

/* Section styling similar to PdpGeneration */
.section {
  margin-bottom: 24px;
  background-color: #F8F9FF;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
}

.sectionTitle {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #4758EB;
  margin-bottom: 12px;
}
