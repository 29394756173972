.container {
  padding: 20px;
  background-color: #ffffff;
  height: 100%;
  overflow-y: auto;
}

.title {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #292B3D;
  margin-bottom: 12px;
  border-bottom: 1px solid #E0E1EB;
  padding-bottom: 10px;
}

.description {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #7C7F9C;
  margin-bottom: 20px;
  line-height: 1.5;
}

/* Progress Steps */
.progressSteps {
  display: flex;
  align-items: center;
  justify-content: center;
}

.horizontalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  width: 100%;
}

.headerActions {
  min-width: 150px;
}

.centerContainer {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  margin-bottom: -80px;
}

.progressStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #7C7F9C;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 12px;
}

.stepNumber {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #E0E1EB;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
  color: #5D6182;
  font-weight: 600;
  transition: all 0.3s ease;
}

.activeStep {
  color: #4758EB;
}

.activeStep .stepNumber {
  background-color: #4758EB;
  color: white;
}

.stepConnector {
  height: 2px;
  width: 50px;
  background-color: #E0E1EB;
  margin: 0 10px;
  margin-bottom: 25px;
}

/* Section styling similar to PdpGeneration */
.section {
  margin-bottom: 24px;
  background-color: #F8F9FF;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
  position: static;
  overflow: visible;
}

.sectionContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: static;
  overflow: visible;
}

.sectionTitle {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #4758EB;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Form Elements */
.form {
  margin-top: 16px;
}

.formGroup {
  margin-bottom: 16px;
}

.label {
  display: block;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #292B3D;
  margin-bottom: 8px;
}

.input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #E0E1EB;
  border-radius: 6px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #292B3D;
  transition: border-color 0.2s ease;
}

.textarea {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #E0E1EB;
  border-radius: 6px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #292B3D;
  transition: border-color 0.2s ease;
  resize: vertical;
  min-height: 120px;
}

.input:focus, .textarea:focus {
  outline: none;
  border-color: #4758EB;
}

.input:disabled, .textarea:disabled {
  background-color: #F8F9FF;
  cursor: not-allowed;
}

/* Button Styling */
.button {
  background-color: #4758EB;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 16px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
}

.button:hover {
  background-color: #384BD0;
}

.button:disabled {
  background-color: #B8BED9;
  cursor: not-allowed;
}

.buttonSecondary {
  background-color: transparent;
  color: #4758EB;
  border: 1px solid #4758EB;
  border-radius: 6px;
  padding: 10px 16px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonSecondary:hover {
  background-color: #F0F2FF !important;
}

.buttonSecondary:disabled {
  border-color: #B8BED9;
  color: #B8BED9;
  cursor: not-allowed;
}

.buttonPreview {
  background-color: #2A9D58;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 16px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  min-width: 140px;
}

.buttonPreview:hover {
  background-color: #218A49 !important;
}

.buttonPreview:disabled {
  background-color: #A3D8B7;
  cursor: not-allowed;
}

.buttonGroup {
  display: flex;
  gap: 12px;
  margin-top: 20px;
  justify-content: space-between;
}

.buttonGroup > div {
  display: flex;
  gap: 12px;
}

.buttonIcon {
  margin-right: 6px;
}

/* Status Messages */
.errorMessage {
  background-color: #FEF0F0;
  color: #D83A3A;
  padding: 12px 16px;
  border-radius: 6px;
  margin-bottom: 16px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.successMessage {
  background-color: #F0FEF4;
  color: #2A9D58;
  padding: 12px 16px;
  border-radius: 6px;
  margin-bottom: 16px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Spinner Animation */
.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* React-Select Customization */
.select {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  position: relative;
  z-index: 2000 !important;
}

.select > div:first-child {
  border-color: #E0E1EB;
  border-radius: 6px;
  min-height: 40px;
}

.select > div:first-child:hover {
  border-color: #4758EB;
}

/* Add a container to ensure proper z-index stacking for form groups */
.formGroupDropdown {
  position: relative;
  z-index: 2000 !important;
  margin-bottom: 16px;
}

/* Higher z-index for ID column (top dropdown) */
.formGroupDropdown:first-of-type {
  z-index: 2001 !important;
}

/* Override global select menu styles to ensure proper rendering */
:global(.css-26l3qy-menu), 
:global(.css-1pahdxg-control), 
:global(.css-1insrsq-menu) {
  z-index: 9999 !important;
}

/* PDF Preview Styling */
.pdfPreviewHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.pdfHeaderControls {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.pdfInfoBadge {
  background-color: #E9ECFF;
  color: #4758EB;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
}

.pdfIcon {
  color: #D83A3A;
  margin-right: 6px;
}

.infoButton {
  background: none !important;
  border: none;
  color: #4285f4;
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease;
}

.infoButton:hover {
  color: #2a75f3;
  background: none !important;
}

/* Side by Side Container */
.sideBySideContainer {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.pdfColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.columnHeader {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 12px;
  background-color: #E9ECFF;
  color: #4758EB;
  border-radius: 6px 6px 0 0;
  display: flex;
  align-items: center;
  gap: 6px;
}

.columnIcon {
  font-size: 14px;
}

.pdfPreviewContent {
  padding: 16px;
  background-color: white;
  border-radius: 0 0 6px 6px;
  border: 1px solid #E0E1EB;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  line-height: 1.6;
  height: 400px;
  overflow-y: auto;
  white-space: pre-wrap;
}

.pdfColumn:first-child .pdfPreviewContent {
  border-radius: 0 0 0 6px;
}

.pdfColumn:last-child .pdfPreviewContent {
  border-radius: 0 0 6px 0;
}

.pdfPreviewEmpty {
  padding: 16px;
  background-color: white;
  border-radius: 6px;
  border: 1px solid #E0E1EB;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #7C7F9C;
  text-align: center;
}

.pdfSourceLink {
  margin-top: 12px;
  text-align: center;
}

.pdfLink {
  color: #4758EB;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 6px;
}

.pdfLink:hover {
  text-decoration: underline;
}

/* API Info Popup */
.apiInfoPopupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.apiInfoPopup {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  width: 80%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.apiInfoHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #eaeaea;
}

.apiInfoHeader h4 {
  margin: 0;
  color: #333;
  font-weight: 600;
}

.closeButton {
  background: none;
  border: none;
  color: #777;
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease;
}

.closeButton:hover {
  color: #333 !important;
  background: none !important;
}

.apiInfoContent {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.apiInfoSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.apiInfoSection h5 {
  margin: 0;
  font-weight: 600;
  color: #555;
  border-bottom: 1px solid #eee;
  padding-bottom: 8px;
}

.apiInfoRow, .apiInfoRowTotal {
  display: flex;
  justify-content: space-between;
  padding: 6px 10px;
  background-color: #f9f9f9;
  border-radius: 4px;
}

.apiInfoRowTotal {
  font-weight: 600;
  background-color: #eef5fd;
  margin-top: 5px;
}

.apiPromptDisplay {
  background-color: #f5f5f5;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 15px;
  white-space: normal;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  max-height: 200px;
  overflow-y: auto;
  line-height: 1.6;
}

.apiMessageItem {
  margin-bottom: 15px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  overflow: hidden;
}

.apiMessageRole {
  background-color: #f0f0f0;
  padding: 8px 12px;
  font-weight: bold;
  font-size: 0.9rem;
  color: #333;
  text-transform: capitalize;
  border-bottom: 1px solid #eaeaea;
}

.apiMessageRole:before {
  content: '🔹 ';
}

.apiMessageItem:nth-child(odd) .apiMessageRole {
  background-color: #eef5fd;
}

.apiMessageContent {
  padding: 12px;
  white-space: pre-wrap;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  line-height: 1.6;
  max-height: 300px;
  overflow-y: auto;
  background-color: #ffffff;
}

.apiMessageItem:first-child .apiMessageRole {
  background-color: #f5f0fe;
  color: #7b51ab;
}

.apiMessageItem:first-child .apiMessageRole:before {
  content: '⚙️ ';
}

/* Additional responsive styles for small screens */
@media (max-width: 768px) {
  .sideBySideContainer {
    flex-direction: column;
  }
  
  .pdfColumn {
    margin-bottom: 20px;
  }
  
  .apiInfoPopup {
    width: 95%;
  }
}

/* Confirmation Dialog Styles */
.confirmOverlayContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirmDialog {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-out;
}

.confirmHeader {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: #e67e22;
}

.confirmHeader h4 {
  margin: 0 0 0 10px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.confirmDialog p {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  color: #4a4a4a;
  margin-bottom: 20px;
  line-height: 1.5;
}

.confirmButtons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.buttonWarning {
  background-color: #e67e22;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 16px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonWarning:hover {
  background-color: #d35400 !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* List View Styles */
.listView {
  margin-top: 20px;
}

.listHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.listTitle {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #4758EB;
  margin: 0;
}

.createButton {
  background-color: #4758EB;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 16px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.createButton:hover {
  background-color: #384BD0 !important;
}

.createButton:disabled {
  background-color: #B8BED9;
  cursor: not-allowed;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  background-color: #F8F9FF;
  border-radius: 8px;
  text-align: center;
}

.emptyStateIcon {
  color: #B8BED9;
  margin-bottom: 16px;
}

.emptyState p {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  color: #7C7F9C;
  margin-bottom: 20px;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  background-color: #F8F9FF;
  border-radius: 8px;
}

.loadingContainer p {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  color: #7C7F9C;
  margin-top: 16px;
}

/* Mapping Card Styles */
.mappingsList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.mappingCard {
  background-color: #F8F9FF;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  flex-direction: column;
}

.mappingCard:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.mappingCardContent {
  padding: 16px;
  flex-grow: 1;
}

.mappingName {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #292B3D;
  margin-top: 0;
  margin-bottom: 12px;
  border-bottom: 1px solid #E0E1EB;
  padding-bottom: 8px;
}

.mappingDetails {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.mappingDetail {
  display: flex;
  flex-direction: column;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 13px;
}

.detailLabel {
  color: #7C7F9C;
  font-weight: 500;
  margin-bottom: 2px;
}

.detailValue {
  color: #292B3D;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mappingCardActions {
  display: flex;
  padding: 12px;
  background-color: #E9ECFF;
  justify-content: flex-end;
  gap: 8px;
}

.actionButton {
  background-color: white;
  color: #4758EB;
  border: none;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.actionButton:hover {
  background-color: #4758EB !important;
  color: white !important;
}

.deleteButton {
  color: #e53939;
}

.deleteButton:hover {
  background-color: #e53939 !important;
  color: white !important;
}

/* Form Header Styles */
.formHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 24px;
  gap: 16px;
}

.backToListButton {
  background-color: #E0E1EB;
  color: #5D6182;
  border: none;
  border-radius: 6px;
  padding: 8px 12px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  gap: 6px;
}

.backToListButton:hover {
  background-color: #D0D2E6 !important;
  color: #292B3D !important;
}

.formTitle {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #4758EB;
  margin: 0;
  margin-bottom: 16px;
}

/* Delete button for confirmation dialog */
.buttonDanger {
  background-color: #e53939;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 16px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonDanger:hover {
  background-color: #c62828 !important;
}

/* Preview Required Hint */
.previewRequiredHint {
  color: #e67e22;
  font-size: 12px;
  margin-top: 6px;
  font-family: 'Lexend Deca', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Responsive styles */
@media (max-width: 768px) {
  .mappingsList {
    grid-template-columns: 1fr;
  }
  
  .listHeader {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
  
  .createButton {
    width: 100%;
    justify-content: center;
  }
}
